import EmailFrom from "containers/ListingDetailPage/EmailFrom";
import pinContext from "context/pin/pinContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "shared/Logo/Logo";
import { API } from "utils/config";
import CheckImage from "../../images/avatars/download.png";
import Defaultlogo from "../../images/avatars/undraw_buy_house_re_8xq7.svg";

export interface AgencyDetailPageProps {
  agencyNameprops?: any;
}
const AgencyDetailPage: FC<AgencyDetailPageProps> = () => {
  type QuizParams = {
    id: any;
  };
  let { id } = useParams<any>();
  // console.log(id, "id");

  const {
    singleConsultant,
    getByConsultantId,
    getAllAgentByAgency,
    agentByAgency,
  } = useContext(pinContext);
  useEffect(() => {
    getByConsultantId(id);
  }, []);
  useEffect(() => {
    getAllAgentByAgency(id);
  }, [id]);

  const test = agentByAgency?.items?.filter(
    (item: any) => item?._id?.toString() === id
  );
  console.log(agentByAgency, "agenttest");
  // const {manage,serviseArea,propertyType,propertiesfor,description } = singleConsultant;
  {
    const agencyNameprops = singleConsultant.id?.[0].agencyName;
  }

  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  return (
    <div className="pt-10">
      <div className="container flex justify-between">
        <p className="text-2xl font-semibold capitalize">
          {singleConsultant.id?.[0].agencyName}
        </p>
        <p className="text-3xl cursor-pointer flex flex-row">
          <i className="las la-print sm:hidden lg:inline-block md:inline-block"></i>
          <i className="lar la-share-square pl-5"></i>
        </p>
      </div>
      <hr />
      <div className="container grid lg:grid-cols-6 md:grid-cols-6 grid-cols-1 gap-4 sm:pb-2">
        <div className="lg:col-span-4 md:col-span-4 col-span-1">
          <div className="grid lg:grid-cols-6 grid-cols-1">
            <div className="lg:col-span-2 col-span-1 p-6 m-auto">
              <img
                src={
                  singleConsultant.id?.[0].contentImage
                    ? `${API}/uploads/${singleConsultant.id?.[0].contentImage}`
                    : Defaultlogo
                }
                alt=""
                className="w-full"
              />
            </div>
            {/* {singleConsultant.id?.[0].map((item:any) =>())} */}
            <div className="col-span-4">
              <div className="lg:py-10 text-sm font-semibold flex flex-col text-left">
                <div className="grid grid-cols-4 flex-row bg-gray-200 px-3 py-4 rounded">
                  <div className="col-span-1">
                    <p className="">Agents :</p>
                  </div>
                  <div className="col-span-3">
                    <p className="font-normal">324+</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 flex-row bg-white px-3 py-4 ">
                  <div className="col-span-1">
                    <p className="">Properties :</p>
                  </div>
                  <div className="col-span-3">
                    <p className="font-normal capitalize">
                      {singleConsultant.id?.[0].manage}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-4 flex-row bg-gray-200 px-3 py-4 rounded">
                  <div className="col-span-1">
                    <p className="">Service Areas :</p>
                  </div>
                  <div className="col-span-3">
                    {/* <p className='font-normal line-clamp-3'>{singleConsultant.id?.[0].serviseArea}</p>
                                        <p className='flex justify-end cursor-pointer'>More...</p> */}
                    <p className="text-sm text-justify  capitalize">
                      {expanded
                        ? singleConsultant.id?.[0].serviseArea
                        : singleConsultant.id?.[0].serviseArea.slice(0, 50) + "..."}
                    </p>
                    <button
                      className="text-[#223A64]"
                      onClick={() => setExpanded(!expanded)}
                    >
                      {expanded ? "Read Less" : "Read More"}
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-4 flex-row list-none bg-white px-3 py-4 rounded">
                  <div className="col-span-1">
                    <p className="">Property Types :</p>
                  </div>
                  <div className="col-span-3">
                    <p className="font-normal capitalize">
                      {singleConsultant.id?.[0].propertyType}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-4 flex-row list-none bg-gray-200 px-3 py-4 rounded">
                  <div className="col-span-1">
                    <p className="">Properties for :</p>
                  </div>
                  <div className="col-span-3">
                    <p className="font-normal capitalize">
                      {singleConsultant.id?.[0].propertiesfor}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <h2 className="font-semibold">Description: </h2>
            <p className="text-sm text-justify">
              {expanded2
                ? singleConsultant.id?.[0].description
                : singleConsultant.id?.[0].description.slice(0, 300) + "..."}
            </p>
            <button
              className="text-[#223A64]"
              onClick={() => setExpanded2(!expanded2)}
            >
              {expanded2 ? "Read Less" : "Read More"}
            </button>
          </div>
          <hr className="mt-5" />
          <div className="py-5">
            {agentByAgency?.items?.length && (
              <h2 className="text-2xl font-medium">Team</h2>
            )}
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 py-5 gap-6">
              {agentByAgency?.items?.map((filtered: any, index: any) => (
                <div
                  key={index}
                  className="col-span-1 text-center border border-gray-200 rounded-lg py-4 px-3"
                >
                  <div className="flex flex-col items-center">
                    {/* {`${API}/uploads/${filtered.userImage}`} */}
                    <img
                      src={
                        filtered.userImage
                          ? `${API}/uploads/${filtered.userImage}`
                          : CheckImage
                      }
                      alt=""
                      className="lg:w-[10vw] lg:h-[20vh] w-[20vw] h-[15vh] rounded-full"
                    />
                    <h3 className="font-semibold capitalize pt-3">
                      {filtered.fname}
                    </h3>
                  </div>
                  <div className="flex flex-col lg:text-left text-center text-sm pt-5">
                    <p>
                      Languages:{" "}
                      <span className="font-semibold capitalize">
                        {filtered.languages}
                      </span>
                    </p>
                    <p>
                      Properties:{" "}
                      <span className="font-semibold capitalize">
                        {filtered.propertiesfor}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-between mt-5">
                    <Link to={`/Agent/${filtered?._id}`} className="w-full">
                      <button className="rounded-md bg-white text-center text-[#223A64] border border-[#223A64] mx-1 w-full py-2 hover:bg-red-100 uppercase">
                        View Profile
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 md:col-span-2 col-span-1">
          <div className="listingSectionSidebar__wrap sticky top-20 shadow-xl">
            <div className="flex justify-center text-center">
              {/* <Logo /> */}
              <img
                src={`${API}/uploads/${singleConsultant.id?.[0].contentImage}`}
                alt=""
                className="w-24"
              />
            </div>
            <EmailFrom />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyDetailPage;
