import { Popover } from '@headlessui/react';
import { Box, CircularProgress } from '@mui/material';
import ListedPropertyCard from 'components/StayCardH/ListedPropertyCard';
import pinContext from 'context/pin/pinContext';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react'
import { Loader, Transition } from 'semantic-ui-react';
import PaginationCheck from 'shared/Pagination/PaginationCheck';

export interface ConsultantPropertyListingProps {
    id: string;
    consultant?: any;
    email?: any;
    phone?: any;
    name?: any;
    image: any;

}
const ConsultantPropertyListing: FC<ConsultantPropertyListingProps> = ({
    consultant,
    email,
    name,
    image,
    phone,
}) => {
    const { loading } = useContext(pinContext)
    const ListedProperties = consultant?.listedProperties
    const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPropertiesData, setCurrentPropertiesData] = useState<number>(1);
    const propertiesPerPage = 5;
    const indexOfLastProperties = currentPage * propertiesPerPage;
    const indexOfFirstConsultant = indexOfLastProperties - propertiesPerPage;
    const currentProperties = ListedProperties?.slice(indexOfFirstConsultant, indexOfLastProperties);

    const [isLoading, setIsLoading] = useState(true);
    // Loading delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);
        return () => clearTimeout(timer);

    }, []);
    return (
        <>
            <>
                {currentProperties?.length > 0 ? (
                    <>
                        <div className="lg:w-6/12 w-full">
                            <div className="flex justify-content-between">
                                <span className="text-sm leading-8">
                                    Showing Properties sorted by &nbsp;
                                </span>
                                <div className="border border-gray-400 rounded-md px-4 py-1.5 text-sm">
                                    <Popover className="relative">
                                        {({ open }) => (
                                            <>
                                                <Popover.Button
                                                    className={` ${open
                                                        ? "text-black focus:outline-none w-full flex justify-between"
                                                        : "flex justify-between w-full"
                                                        }`}
                                                >
                                                    <div className="flex justify-between w-full">
                                                        <button className="inline-block align-middle h-full">
                                                            Popular &nbsp;
                                                        </button>
                                                        <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                                                    </div>
                                                </Popover.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                >
                                                    <Popover.Panel className="absolute pb-2 bg-white dark:bg-neutral-800 z-50 mt-3 left-0 -ml-5 shadow-xl border w-36 rounded-md">
                                                        <div className="pt-2 px-2 relative flex flex-col">
                                                            <button className="border py-1.5 hover:bg-red-100">
                                                                Popular
                                                            </button>
                                                            <button className="border py-1.5 hover:bg-red-100">
                                                                Newest
                                                            </button>
                                                            <button className="border py-1.5 hover:bg-red-100">
                                                                Lowest Price
                                                            </button>
                                                            <button className="border py-1.5 hover:bg-red-100">
                                                                Highest Price
                                                            </button>
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Popover>
                                </div>
                            </div>
                        </div>
                        {currentProperties?.map((item: any) => (
                            <>
                                {loading || currentProperties?.length === 0 ? (
                                    <div role='status' className='grid grid-cols-1 gap-8'>
                                        <div className='col-span-1 p-4 animate-pulse md:p-6 mr-4'>
                                            <div className='grid grid-cols-2'>
                                                <div className='col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded'>
                                                    <svg
                                                        className='w-12 h-12 text-gray-200 dark:text-gray-600'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        aria-hidden='true'
                                                        fill='currentColor'
                                                        viewBox='0 0 640 512'
                                                    >
                                                        <path d='M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z' />
                                                    </svg>
                                                </div>
                                                <div className='col-span-1 flex flex-col justify-start'>
                                                    <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                                    <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                                    <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                                    <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                                    <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                                </div>
                                            </div>
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="pt-5">
                                            <div
                                                className='py-2.5'
                                                key={item.id}
                                                onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                                                onMouseLeave={() => setCurrentHoverID((_) => -1)}
                                            >
                                                <ListedPropertyCard image={image} name={name} email={email} phone={phone} data={item} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ))}
                        <PaginationCheck
                            currentPage={currentPage}
                            consultantsPerPage={propertiesPerPage}
                            totalConsultants={ListedProperties.length}
                            paginate={setCurrentPage}
                            text={'Properties'}
                        />
                    </>
                ) : !isLoading ? (
                    <div className="py-5 mt-5 flex justify-center">
                        <h2 className='pt-5 font-semibold text-[#345886] text-2xl italic dark:text-white'>No Property Yet Listed</h2>
                    </div>
                ) : (
                    <div className="py-5 mt-5 flex justify-center">
                        <Box
                            sx={{
                                height: "200px",
                                background: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                top: "30%",
                            }}
                        >
                            <Box zIndex="3" position="absolute" >
                                <CircularProgress style={{ color: '#263c67' }} />
                            </Box>
                        </Box>
                    </div>
                )}

            </>
        </>
    )
}

export default ConsultantPropertyListing