import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog, Popover } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { ClockIcon, HomeIcon, MapIcon } from "@heroicons/react/solid";
import TimeTypeOptionInput from "./TimeTypeoptionInput";

export interface CareerMobileFiltersProps {
    ButtonText: string;
    className: string;
}
const CareerPageMobileFilters: React.FC<CareerMobileFiltersProps> = ({ ButtonText, className, }) => {
    const [isVisable, setIsVisable] = useState(false);

    useEffect(() => {
        setIsVisable(false);
    }, [window.location.pathname]);

    const handleOpenMenu = () => setIsVisable(true);
    const handleCloseMenu = () => setIsVisable(false);
    const renderContent = () => {
        return (
            <Transition appear show={isVisable} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={handleCloseMenu}
                >
                    <div className="fixed left-0 top-0 bottom-0 w-full md:w-auto z-max outline-none focus:outline-none">
                        <React.Fragment>
                            <Transition.Child
                                as={Fragment}
                                enter="transition duration-950 transform"
                                enterFrom="opacity-0 translate-y-14"
                                enterTo="opacity-100 -translate-y-0"
                                leave="transition duration-350 transform"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-14"
                            >
                                <div className="z-50 relative w-full h-screen py-2 transition transform shadow-lg bg-white">
                                    <div className="container flex flex-col py-12">
                                        <div className="py-1">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><MapIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Enter Location</span></div>
                                            <input type="text" className="w-full rounded-md border-gray-400 focus:border-gray-400 ring-none outline-none focus:outline-none capitalize" placeholder="Location" />
                                        </div>
                                        <div className="pt-4 pb-2">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><HomeIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Department</span></div>
                                            <input type="text" className="w-full rounded-md border-gray-400 focus:border-gray-400 ring-none outline-none focus:outline-none capitalize" placeholder="Select Department" />
                                        </div>
                                        <div className="pt-4 pb-2">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><ClockIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Select Time Type</span></div>
                                            <div className="relative w-full rounded-md border border-gray-400 capitalize">
                                                <TimeTypeOptionInput />
                                            </div>
                                        </div>
                                        <div className="mt-7 flex justify-center">
                                            <button className="font-semibold text-base py-2.5 bg-gray-600 text-white text-center border w-full border-gray-400 rounded-md" onClick={handleCloseMenu}>Done</button>
                                        </div>
                                    </div>
                                    <span className="absolute right-2 top-2 p-1">
                                        <ButtonClose onClick={handleCloseMenu} />
                                    </span>
                                    <span className="absolute left-2 top-2 p-1">
                                        <button className="text-[#345886] font-semibold">Reset</button>
                                    </span>
                                </div>
                            </Transition.Child>

                            <Transition.Child
                                as={Fragment}
                                enter=" duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave=" duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
                            </Transition.Child>
                        </React.Fragment>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    return (
        <>
            <button onClick={handleOpenMenu} className={`text-white uppercase py-2 !h-12 w-full text-center text-sm bg-[#345886] hover:bg-[#1f345c] focus:outline-none rounded-md ${className}`}>{ButtonText}</button>
            {renderContent()}
        </>
    )
}

export { CareerPageMobileFilters }