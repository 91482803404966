import React, { useContext, useEffect, Fragment, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link, useHistory } from "react-router-dom";
import PriceRangeInput from "components/HeroSearchForm/PriceRangeInput";
import { useState } from "react";
import pinContext from "context/pin/pinContext";
import { ProjectContext } from "context/project/ProjectContext";
import { API } from "utils/config";
import ResidentialCommercialFilter from "components/HeroSearchForm/ResidentialCommercialFilter";
import SelectCityOptionInput from "components/HeroSearchForm/SelectCityOptionInput";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import ProjectNameInput from "../../components/HeroSearchForm/ProjectNameInput";
import DeveloperNameInput from "components/HeroSearchForm/DeveloperNameInput";
import SectionSliderNew from "./DeveloperSectionSlider/SectionSliderNewCategories";
import ProjectPagination from "./ProjectPagination/ProjectPagination";
import moment from "moment";
import { FeaturedCarousal, FeaturesCarousal } from "./NewSlider";
import NewProjectFilter from "./NewProjectFilter";
import DeveloperCard from "./DeveloperCard";
import { NewProjectsMobileFilters } from "./NewProjectsMobileFilters";
import AddProperties from "./ProjectPagination/SaveProperty";
import axios from "axios";
import { API as apiHost } from "utils/config";
import Login from "shared/Header/Login";
import LoginModal from "components/LoginModal/LoginModal";
export interface newProjectProps {
  project_id?: any;
  afterChange?: any;
}
const NewProject: FC<newProjectProps> = ({ afterChange }) => {
  const { userData, getAllAvailableAreaNames } = React.useContext(pinContext);
  const {
    getAllProjects,
    projects,
    projectsCount,
    loading,
    perPage,
    setPerPage,
    pageNumber,
    setPageNumber,
  }: any = useContext(ProjectContext);
  // useEffect(() => {
  //   const $body = document.querySelector("body");
  //   if (!$body) return;
  //   $body.classList.add("theme-cyan-blueGrey");
  //   return () => {
  //     $body.classList.remove("theme-cyan-blueGrey");
  //   };
  // }, []);
  useEffect(() => {
    getAllProjects();
  }, []);
  // console.log(properties, "Projects");
  const [showModal, setShowModal] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleSaveProperties = async (projectId: string) => {
    try {
      const tk = localStorage.getItem("authToken");
      const res = await axios.post(
        `${apiHost}/api/auth/saveProperties`,
        {
          saveProperty: projectId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tk}`,
          },
        }
      );
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
    localStorage.setItem("likedProjects", projectId);
  };
  return (
    <>
      <section className="relative table w-full py-32 lg:py-36 bg-[url('https://www.bayut.com/images/projects/newprojects_hero_bg.jpg?id=02c28162b00fdfd6b2dc')] bg-cover  bg-no-repeat bg-center">
        <div className="absolute inset-0 "></div>
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">
              Search for New Projects in Pakistan
            </h3>
          </div>
          {/* 
                // <!--end grid--> */}
        </div>
      </section>
      <div className="container max-w-3xl mx-auto max-w-6xl relative -mt-16 z-1">
        <div className="grid grid-cols-1">
          <div className="p-6 pt-3 bg-black bg-opacity-80 dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
            <div className="text-dark text-start lg:block md:block hidden">
              {/* <NewProjectFilter
                className="mt-2"
                resetClass="hidden"
                findBtnClass="col-span-2"
                resetClassMain="block text-white"
              /> */}
            </div>
            <div className="lg:hidden md:hidden flex justify-center">
              <div className="flex flex-col">
                <span className="text-white text-2xl font-medium tracking-wide py-1.5">
                  Find Project in Pakistan
                </span>
                <NewProjectsMobileFilters
                  className=""
                  ButtonText="Find New Projects"
                />
              </div>
            </div>
          </div>
          {/* <!--end form--> */}
        </div>
        {/* <!--end grid--> */}
      </div>
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid grid-cols-1 text-center mt-10">
            <h3 className="md:text-4xl text-3xl md:leading-normal dark:text-white leading-normal font-medium text-black mb-10">
              Featured Developers
            </h3>
          </div>
          <FeaturesCarousal
            categoryCardType="card6"
            itemPerRow={5}
            uniqueClassName="PageHome_s3"
          />
        </div>
        <div className="container py-5">
          <div className="text-center mt-10">
            <h3 className="md:text-4xl text-3xl dark:text-white md:leading-normal leading-normal font-medium text-black mb-10">
              Discover New Projects
            </h3>
          </div>

          <div className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-4 gap-8">
            {projects?.project?.map((item: any) => (
              <>
                {loading ? (
                  <div role="status" className="flex flex-wrap justify-center">
                    <div className="max-w-sm p-4 animate-pulse md:p-6 mr-4">
                      <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                        <svg
                          className="w-12 h-12 text-gray-200 dark:text-gray-600"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-1 md:col-span-2 lg:col-span-1">
                    <div className="relative bg-white border pb-3 border-t-0 overflow-hidden rounded-lg shadow-md dark:bg-gray-800 dark:border-none">
                      <Link to={`/project-detail/${item._id}`}>
                        <div className="overflow-hidden cursor-pointer w-full h-52">
                          <img
                            className="h-52 w-full object-cover rounded-t-lg transition-all duration-500 ease-in-out transform hover:scale-105"
                            src={`${API}/uploads/${item.images?.[0]}`}
                            alt=""
                          />
                        </div>

                        <div className="px-5 pt-4">
                          <a href="#">
                            <h5 className="text-xl font-normal tracking-tight text-gray-900 dark:text-white">
                              {item.projectName}
                            </h5>
                          </a>
                          <p className="mb-2 font-normal text-sm text-gray-700 dark:text-gray-400">
                            <i className="las la-map-marked px-1"></i>
                            {`${item.location.areaName}`}
                          </p>
                        </div>
                        <div className="px-5">
                          <p className="text-xs text-right py-1 text-[#4b5563]">
                            {moment(item.createdAt).format("LL")}
                          </p>
                        </div>
                        <div className="px-5 pb-2">
                          <div className="bg-slate-50 flex justify-between px-3 py-2 rounded">
                            <p className="text-sm font-semibold">
                              Developed By
                            </p>{" "}
                            <br />
                            <p className="text-sm">
                              {item?.developer?.name?.length > 12
                                ? item?.developer?.name?.substring(0, 15) +
                                "..."
                                : item?.developer?.name}
                            </p>
                          </div>
                        </div>
                      </Link>
                      <BtnLikeIcon
                        className="absolute right-3 top-3"
                        projectId={item._id}
                        handleSaveProperties={(projectId) => {
                          if (localStorage.getItem("authToken")) {
                            handleSaveProperties(projectId);
                          } else {
                            setIsPopoverOpen(true);
                            setShowModalLogin(true);
                          }
                        }}
                      />

                      <div className="px-5 -mt-5 hover:block">
                        <div>
                          <button
                            className="w-full text-white bg-[#223A64] hover:bg-[#e31414] focus:ring-none rounded focus:outline-none focus:ring-none font-semibold  text-md py-1.5 text-center dark:bg-[#223A64] mt-5 dark:hover:bg-[#e31414] dark:focus:ring-[#223A64]"
                            type="submit"
                            onClick={() => setShowModal(true)}
                          >
                            {" "}
                            Email{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>

        <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
          <div className="md:col-span-12  text-center md::col-span- lg:::col-span1-center">
            <nav>
              <ul className="inline-flex items-center -space-x-px">
                <li>
                  <button
                    onClick={() => setPageNumber(pageNumber - 1)}
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 border bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-[#223A64] dark:hover:border-[#223A64] hover:bg-[#e31414] dark:hover:bg-[#e31414]"
                    disabled={pageNumber === 0}
                  >
                    <i className="las la-angle-left text-xl"></i>
                  </button>
                </li>
                <ProjectPagination />
                <li>
                  <button
                    onClick={() => setPageNumber(pageNumber + 1)}
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 border bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-[#223A64] dark:hover:border-[#223A64] hover:bg-[#e31414] dark:hover:bg-[#e31414]"
                  >
                    <i className="las la-angle-right text-xl"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      {/* START EMAIL MODAL */}
      {showModal ? (
        <>
          <div className=" w-[1200px] z-50">
            <div className="fixed inset-0 bg-zinc-800 bg-opacity-90 transition-opacity"></div>
            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="w-auto z-40 my-6 mx-auto max-w-3xl">
                <div className="border-t-4 border-[#223A64] rounded-md shadow-lg relative flex flex-col w-80 bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                    <h3 className="text-3m font=semibold ml-12 font-bold dark:text-gray-600">
                      Make an Inquiry
                    </h3>
                    <button
                      className="bg-transparent border-0 text-black float-right"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="text-black opacity-7 h-8 w-8 text-xl block py-0 rounded-full">
                        <i className="las la-times-circle text-2xl"></i>
                      </span>
                    </button>
                  </div>
                  <div className="relative p-4 flex-auto">
                    <form className="rounded px-5 w-full">
                      <label className="block text-black text-sm mb-1">
                        NAME
                      </label>
                      <input
                        className="shadow appearance-none dark:bg-slate-800 dark:text-white bg-slate-50 border rounded w-full py-2 px-3 text-black"
                        value={userData.fname}
                      />
                      <label className="block text-black text-sm mb-1">
                        PHONE
                      </label>
                      <input
                        className="shadow appearance-none dark:bg-slate-800 dark:text-white bg-slate-50 border rounded w-full py-2 px-3 text-black"
                        value={userData.contact}
                      />
                      <label className="block text-black text-sm mb-1">
                        EMAIL
                      </label>
                      <input
                        className="shadow appearance-none dark:bg-slate-800 dark:text-white bg-slate-50 border rounded w-full py-2 px-3 text-black"
                        value={userData.email}
                      />
                      <label className="block text-black text-sm mb-1">
                        MESSAGE
                      </label>
                      <textarea
                        id="message"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-slate-800 rounded-md border border-gray-300 focus:ring-[#345886] focus:border-[#345886] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#345886] dark:focus:border-[#345886]"
                        placeholder="Your message..."
                      ></textarea>
                      <div className="px-0">
                        <button
                          className="w-full text-white bg-[#223A64] hover:bg-[#1f345c] focus:ring-4 rounded focus:outline-none focus:ring-red-300 font-semibold  text-md px-5 py-2.5 text-center dark:bg-[#223A64] mt-5 dark:hover:bg-[#e31414] dark:focus:ring-[#223A64]"
                          onClick={() => setShowModal(false)}
                          type="submit"
                        >
                          {" "}
                          Email{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {/* ENd EMAIL MODAL */}
    </>
  );
};
export default NewProject;
