import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import PriceRangeInput from "components/HeroSearchForm/PriceRangeInput";
import ResidentialCommercialFilter from "components/HeroSearchForm/ResidentialCommercialFilter";
import CompletionDate from "components/HeroSearchForm/CompletionDate";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ProjectContext } from "context/project/ProjectContext";
import Autocomplete from "components/HeroSearchForm/Autocomplete";

export interface NewProjectFilterProps {
  haveDefaultValue?: boolean;
  className?: any;
  resetClass?: any;
  resetClassMain?: any;
  findBtnClass?: any;
  onFilterChange: (filteredData: any[]) => void;
}
export interface Project {
  _id: string
  name: string
}

const NewProjectFilter: FC<NewProjectFilterProps> = ({
  haveDefaultValue = false,
  className,
  resetClass,
  findBtnClass,
  resetClassMain,
  onFilterChange,
}) => {
  const [selected, setSelected]: any = useState("");
  const [rangePrices, setRangePrices] = useState([0, 0]);

  const {
    getLocationsByCity,
    getAllProjectNames,
    getAllDevelopers,
    allDevelopers,
    getAllProjectsData,
    projectsData,
    filter,
  }: any = useContext(ProjectContext);
  // useEffect(() => {
  //   if (selected?._id !== undefined) {
  //     getLocationsByCity(selected._id);
  //     dispatchFilter({
  //       type: "updateProjectCity",
  //       value: selected.cityLocation,
  //     });
  //   }
  // }, [selected]);
  // useEffect(() => {
  //   getAllDevelopers();
  // }, [selected]);

  // const handleProjectType = (value: any) => {
  //   dispatchFilter({
  //     type: "updateProjectType",
  //     value: value,
  //   });
  // };

  // useEffect(() => {
  //   if (rangePrices[0] !== 0 || rangePrices[1] !== 0) {
  //     dispatchFilter({
  //       type: "updatePriceRange",
  //       value: rangePrices,
  //     });
  //   }
  // }, [rangePrices]);

  // const handleFind = () => {
  //   applyFilters();
  // };
  // const resetFilter = (e: any) => {
  //   e.preventDefault();

  //   dispatchFilter({
  //     type: "resetFilter",
  //   });
  // };

  const [selectedProject, setSelectedProject] = useState<{ _id: string; name: string } | null>(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [cityInput, setCityInput] = useState('');  // Manages the input for city
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [projectNameInput, setProjectNameInput] = useState(''); // Manages the input for project name
  const [selectedDeveloperName, setSelectedDeveloperName] = useState('');
  const [developerNameInput, setDeveloperNameInput] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [projectType, setProjectType] = useState('');
  const [resetFilter, setResetFilter] = useState(false);
  // ProjectName
  const [projectNameData, setProjectNameData] = useState([]);
  useEffect(() => {
    getAllProjectsData();
    getAllProjectNames();
  }, []);

  const handleSelectionChange = (project: { _id: string; name: string }) => {
    setSelectedProject(project);
  };

  // city input
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (projectsData && projectsData?.length > 0) {

      // project names
      const projectNamesData = projectsData.map((data: any) => ({
        _id: data?._id,
        name: data?.projectName?.projectName?.toLowerCase(),
      }));
      setProjectNameData(projectNamesData)

      // city input
      const cityData = projectsData?.map((data: any) => ({
        _id: data?._id,
        name: data?.city?.toLowerCase(),
      }));
      const uniqueCityNames = Array.from(new Map(cityData?.map((item: any) => [item?.name, item])).values()) as any;
      setCities(uniqueCityNames);


    }
  }, [projectsData]);

  // Filter UseEffect
  useEffect(() => {
    const filteredData = projectsData?.filter((project: any) => {
      return (!selectedCity || project.city === selectedCity) &&
        (!selectedProjectName || project.projectName === selectedProjectName) &&
        (!selectedDeveloperName || project.developer.name === selectedDeveloperName) &&
        (!priceRange[0] || (project.price >= priceRange[0] && project.price <= priceRange[1])) &&
        (!projectType || project.type === projectType);
    });

  }, [selectedCity, selectedProjectName, selectedDeveloperName, priceRange, projectType, projectsData]);

  const handleFilterChange = (filterType: any, value: any) => {
    switch (filterType) {
      case 'city':
        setSelectedCity(value);
        break;
      case 'projectName':
        setSelectedProjectName(value);
        break;
      case 'developer':
        setSelectedDeveloperName(value);
        break;
      case 'priceRange':
        setPriceRange(value);
        break;
      case 'projectType':
        setProjectType(value);
        break;
      default:
        break;
    }
  };

  // const applyFilters = () => {
  //   const filteredData1 = projectsData?.filter((project: any) => {
  //     return (!selectedCity || project?.city?.toLowerCase() === selectedCity) &&
  //       (!selectedProjectName || project?.projectName?.projectName?.toLowerCase() === selectedProjectName) &&
  //       (!selectedDeveloperName || project?.developer?.name?.toLowerCase() === selectedDeveloperName) &&
  //       (!priceRange[0] || (project?.price >= priceRange[0] && project?.price <= priceRange[1])) &&
  //       (!projectType || project?.type?.toLowerCase() === projectType);
  //   });
  //   const filteredData = projectsData?.filter((row: any) => row?.city?.toLowerCase() === selectedCity);
  //   onFilterChange(filteredData);
  // };
  useEffect(() => {
    if (selectedCity || selectedProjectName || selectedDeveloperName) {
      applyFilters();
    }
  }, [selectedCity, selectedProjectName, selectedDeveloperName]);

  // const handleProjectNameChange = (projectName: any) => setSelectedProjectName(projectName.name);
  // const handleDeveloperNameChange = (developer: any) => setSelectedDeveloperName(developer.name);
  // const handleResetFilterChange = () => setResetFilter(true);
  const handleCityChange = (city: any) => {
    setSelectedCity(city.name?.toLowerCase());
    setCityInput(city.name?.toLowerCase());
  };
  const handleProjectNameChange = (projectName: any) => {
    setSelectedProjectName(projectName.name?.toLowerCase());
    setProjectNameInput(projectName.name?.toLowerCase());
  };
  const handleDeveloperNameChange = (developer: any) => {
    setSelectedDeveloperName(developer?.name?.toLowerCase());
    setDeveloperNameInput(developer?.name?.toLowerCase());
  };

  const applyFilters = () => {
    // let filteredData = projectsData;
    // if (selectedCity) {
    //   filteredData = filteredData?.filter((project: any) => project?.city === selectedCity);
    // }
    // if (selectedProjectName) {
    //   filteredData = filteredData?.filter((project: any) => project?.projectName?.projectName === selectedProjectName);
    // }
    // if (selectedDeveloperName) {
    //   filteredData = filteredData?.filter((project: any) => project?.developer?.name === selectedDeveloperName);
    // }
    // if (resetFilter) {
    //   filteredData = projectsData
    // }
    const filteredData = projectsData.filter((project: any) => {
      return (!selectedCity || project.city?.toLowerCase() === selectedCity) &&
        (!selectedProjectName || project?.projectName?.projectName?.toLowerCase() === selectedProjectName) &&
        (!selectedDeveloperName || project?.developer?.name?.toLowerCase() === selectedDeveloperName);
    });
    onFilterChange(filteredData);
    // onFilterChange(filteredData);
  };
  // const handleCityChange = (city: Project) => {
  //   setSelectedCity(city?.name);
  //   applyFilters();
  // };

  const handlePriceRangeChange = (city: Project) => {
    // setPriceRange(city?.name);
    applyFilters();
  };
  const handleCompletionDateChange = (city: Project) => {
    setCompletionDate(city?.name);
    applyFilters();
  };
  const handleProjectTypeChange = (city: Project) => {
    setProjectType(city?.name);
    applyFilters();
  };

  const applyFilterReset = () => {
    onFilterChange(projectsData);
  }
  const resetFilters = () => {
    // Reset each filter state
    setSelectedCity('');
    setSelectedProjectName('');
    setSelectedDeveloperName('');
    setDeveloperNameInput('');
    setProjectNameInput('');
    setCityInput('');
    // applyFilters();
    onFilterChange(projectsData);
  };

  return (
    <>
      <div className={`mt-2 ${className} `}>
        <div className="">
          <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 lg:gap-2 md:gap-2 gap-0">
            <div className="col-span-1">
              <div className="relative mt-2 !h-12 w-full rounded-md bg-white dark:bg-slate-800">
                <Autocomplete value="" placeholder="City..." onInputChange={setCityInput} suggestions={cities} onSelectionChange={handleCityChange} />
              </div>
            </div>
            <div className="col-span-2">
              <div className="relative mt-2">
                <Autocomplete value="" placeholder="Project Name..." onInputChange={setProjectNameInput} suggestions={projectNameData} onSelectionChange={handleProjectNameChange} />
              </div>
            </div>
            <div className="relative col-span-1 mt-2">
              <div className="bg-white dark:bg-slate-800 rounded-md p-3 !h-12">
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={` ${open
                          ? "text-black focus:outline-none w-full flex justify-between"
                          : "flex justify-between w-full"
                          }`}
                      >
                        <div className="flex relative justify-between w-full">
                          <button className="inline-block align-middle h-full">
                            {filter?.projectType
                              ? filter?.projectType
                              : "Project Type"}
                          </button>
                          <ChevronDownIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute bg-white dark:bg-slate-800 overflow-hidden p-4 z-50 mt-5 lg:w-96 md:w-96 w-84 right-0 shadow-xl border rounded-md">
                          <ResidentialCommercialFilter
                          // handleProjectType={handleProjectType}
                          />
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 lg:gap-2 md:gap-2 gap-0">
            <div className="col-span-1">
              <div className="relative mt-2 !h-12 lg:bg-none md:bg-none rounded-md lg:pt-0 md:pt-0 pt-1.5">
                <PriceRangeInput
                  rangePrices={rangePrices}
                  setRangePrices={setRangePrices}
                // filter={filter}
                />
              </div>
            </div>
            <div className="col-span-1">
              <div className="relative bg-white dark:bg-slate-800 dark:border-0 !h-12 border rounded-md py-1 mt-2">
                <CompletionDate />
              </div>
            </div>
            <div className="col-span-1">
              <div className="relative filter-border mt-2">
                <Autocomplete value="" placeholder="Developer Name..." onInputChange={setDeveloperNameInput} suggestions={allDevelopers} onSelectionChange={handleDeveloperNameChange} />
              </div>
            </div>
            <div className="col-span-1 grid grid-cols-2 relative mt-2">
              <div className={`${findBtnClass}`}>
                <Link to="/projects" type="button">
                  <input
                    type="submit"
                    id="search-sell"
                    name="search"
                    className="btn bg-[#345886] hover:bg-[#223A64] cursor-pointer text-white w-full !h-12 rounded-md"
                    value="Find"
                  // onClick={() => handleFind()}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className={`pt-3 ${resetClassMain} `}>
            <div className="flex justify-end">
              <button
                onClick={resetFilters}
                className="text-white text-sm font-thin"
              >
                Reset Filters
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewProjectFilter;
