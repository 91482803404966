import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Check } from "@mui/icons-material";

type BuyRentEstimateFilterProps = {
  handleBuyRentTypeChange?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

const purpose = [
  {
    id: 1,
    name: "Buy",
  },
  {
    id: 2,
    name: "Rent",
  },
  {
    id: 3,
    name: "All",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function BuyRentEstimateFilter({
  handleBuyRentTypeChange,
}: BuyRentEstimateFilterProps) {
  const [selected, setSelected] = useState(purpose[2]);

  const handleSelectedOptionChange = (option: typeof purpose[number]) => {
    setSelected(option);
    handleBuyRentTypeChange &&
      handleBuyRentTypeChange({
        target: { value: option.name },
      } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <Listbox value={selected} onChange={handleSelectedOptionChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-pointer rounded-md border-gray-300 py-3 pl-3 pr-10 text-left focus:border-none focus:outline-none focus:ring-none sm:text-sm">
              <span className="flex items-center">
                <span className="block text-base truncate">
                  {selected.name}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 cursor-pointer max-h-56 w-full mt-4 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {purpose.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-white bg-[#223A64] text-lg"
                          : "text-gray-900",
                        "relative cursor-pointer select-none py-1 text-lg border-b pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 text-base block truncate py-1 cursor-pointer"
                            )}
                          >
                            {item.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-[#223A64]",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <Check
                              className="h-5 w-5 text-[#345886]"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
