import EmailFrom from "containers/ListingDetailPage/EmailFrom";
import pinContext from "context/pin/pinContext";
import React, { useContext, useEffect } from "react";
import AgencyProfile1 from "./AgencyProfile1";
const AgencyProfilePage = () => {
  const { userData, setShowBanner } = useContext(pinContext);
  useEffect(() => {
    setShowBanner(false);
  }, []);
  return (
    <div className="pt-5">
        <p className="text-center text-2xl font-semibold ">
          Welcome {userData?.fname}! Help us to get you going by completing your
          profile{" "}
        </p>
            <AgencyProfile1 />
          </div>

  );
};

export default AgencyProfilePage;
