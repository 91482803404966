import React from 'react'

const Servies = () => {
    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 gap-6 w-full">
                <div className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                    <img className="object-cover w-full h-90" src="https://wallpapercave.com/wp/wp3203383.jpg" alt="" />

                    <div className="absolute top-0 left-0 px-14 py-8">
                        <h4 className="mb-3 text-xl font-semibold tracking-tight text-center text-white ">Consultant</h4>
                        <p className="leading-normal text-white text-center">A consultant usually advises clients on investment activities in the market. Investment consultant is a genre in the property business whose job description is far piercer and extended than a simple realtor/property broker.</p>
                    </div>
                </div>



                <div className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                    <img className="object-cover w-full h-48" src="https://cdn.wallpapersafari.com/7/9/S3MpoK.jpg" alt="Flower and sky" />

                    <div className="absolute top-0 left-0 px-14 py-8">
                        <h4 className="mb-3 text-xl font-semibold tracking-tight text-center text-white">Realtor</h4>
                        <p className="leading-normal text-white text-center">A realtor helps clients buy or sell properties and follows the strategy and plan formulated and designed by the consultant.</p>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 mt-5 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-1 gap-6 w-full">
                <div className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
                    <img className="object-cover w-full h-48" src="https://media.istockphoto.com/photos/abstract-blue-background-minimal-geometric-background-for-use-in-picture-id1221025677?b=1&k=20&m=1221025677&s=170667a&w=0&h=7ifCf2jcgMPJEXQ_1VS09j3VbTq6Yu_lWUAbOnBHkDY=" alt="" />

                    <div className="absolute top-0 left-0 px-6 py-8">
                        <h4 className="mb-3 text-xl font-semibold tracking-tight text-white">Are you doing it the right way?</h4>
                        <p className="leading-normal text-gray-100">Imlaak is a one stop solution, we offer real estate consultancy and realtor/brokerage services. Real estate c onsultants and realtors play very different roles in the property market, though their duties and responsibilities might overlap at times.</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Servies