import React, { useState, useEffect, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

interface PropertyType {
    value: string;
    label: string;
}

interface MultiSelectPropertyTypesProps {
    options: PropertyType[];
    selectedOptions: string[];
    setSelectedOptions: (selected: string[]) => void;
    className?: string;
}

const MultiSelectPropertyTypes: React.FC<MultiSelectPropertyTypesProps> = ({ options, selectedOptions, setSelectedOptions, className }) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(selectedOptions);

    useEffect(() => {
        setSelectedValues(selectedOptions);
    }, [selectedOptions]);

    const handleChange = (selected: string[]) => {
        setSelectedValues(selected);
        setSelectedOptions(selected);
    };

    return (
        <Listbox as="div" className="space-y-1" value={selectedValues} onChange={handleChange} multiple>
            {({ open }) => (
                <>
                    <div className={`relative rounded-lg bg-white dark:bg-slate-800 border ${className} py-1.5 px-3`}>
                        <span className="inline-block w-full rounded-md shadow-sm">
                            <Listbox.Button className="cursor-pointer relative w-full bg-transparent dark:bg-slate-800 pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                <span className="block truncate">
                                    {selectedValues.length > 0 ? selectedValues.join(', ') : 'Select property types'}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>
                        </span>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute mt-2 w-full rounded-lg border border-gray-200 bg-white dark:bg-slate-800 shadow-lg z-10 ml-[-3%]">
                                <Listbox.Options static className="max-h-[40vh] rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
                                    {options.map(option => (
                                        <Listbox.Option key={option.value} value={option.value} as={Fragment}>
                                            {({ selected }) => (
                                                <li
                                                    className={`${selected ? 'text-white bg-[#345886]' : 'text-gray-900 dark:text-white'
                                                        } cursor-pointer select-none relative py-2 pl-10 pr-4 border-b border-[#fff] hover:text-white hover:bg-[#345886]`}
                                                >
                                                    <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate text-base`}>
                                                        {option.label}
                                                    </span>
                                                    {selected && (
                                                        <span
                                                            className="absolute inset-y-0 left-0 flex items-center pl-3"
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    )}
                                                </li>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </div>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};

export default MultiSelectPropertyTypes;
