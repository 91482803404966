import { CustomLink } from "data/types";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";
import { setQueryParams, getQueryParam, removeQueryParams } from "utils/queryManipulation";
import { valueToPercent } from "@mui/base";
import pinContext from "context/pin/pinContext";

export interface PaginationProps {
  className?: string;
}

const Pagination: FC<PaginationProps> = ({ className = "" }) => {
  const activeTab = getQueryParam('current')

  const [value, setValue] = useState(activeTab === 'activeTb');
  const [currentListing, setCurrentListing]: any = useState()

  const renderItem = ({ title, link }: any, index: number) => {
    if (index === pageNumber) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          onClick={() => setPageNumber(index)}
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {title}
        </span>
      );
    }


    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        // to={`/blog/${pageNumber}`}
        onClick={() => setPageNumber(index)}
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        to={link}
      >
        {title}
      </Link>
    );
  };
  const { blogsCount, perPage, setPerPage, pageNumber, setPageNumber, blogs, getBlog }: any = useContext(pinContext)
  const paginationData = blogsCount > 0 && blogs.length > 0 ? new Array(Math.ceil(blogsCount / perPage)).fill(null).map((_, index) => ({ title: index + 1, link: "#" })) : []
  useEffect(() => {
    setQueryParams("Page", pageNumber + 1)
  }, [pageNumber])
  useEffect(() => {
    return () => {
      removeQueryParams("Page")
    }
  }, [])

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {paginationData.map(renderItem)}
    </nav>
  );
};

export default Pagination;
