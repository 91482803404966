import React, { FC, useState, useContext, useEffect } from "react"
import "react-dates/initialize"
import ResidentialOptions from "./ResidentialOptions"
import CommercialOption from "./CommercialOption"
import { BuyRentContext } from "context/property/BuyRentContext"
export type SearchTab = "Residential" | "Commercial"

export interface ResidentialCommercialFilter {
  className?: string
  currentTab?: SearchTab
  currentPage?: "Residential" | "Commercial"
  handleProjectType?: any
}
export const propertyCategoryOptions = [
  {
    value: "Apartment",
    label: "Apartment",
    category: "Residential",
  },
  {
    value: "Serviced Apartments",
    label: "Serviced Apartments",
    category: "Residential",
  },
  {
    value: "Condominiums",
    label: "Condominiums",
    category: "Residential",
  },
  {
    value: "Hotel rooms",
    label: "Hotel rooms",
    category: "Residential",
  },
  {
    value: "Hotel Apartments ",
    label: "Hotel Apartments ",
    category: "Residential",
  },
  {
    value: "PentHouse",
    label: "PentHouse",
    category: "Residential",
  },
  {
    value: "Office",
    label: "Office",
    category: "Commercial",
  },
  {
    value: "Shop",
    label: "Shop",
    category: "Commercial",
  },
  {
    value: "Buildings",
    label: "Buildings",
    category: "Commercial",
  },
  {
    value: "Showroom",
    label: "Showroom",
    category: "Commercial",
  },
]

const ResidentialCommercialFilter: FC<ResidentialCommercialFilter> = ({
  className = "",
  currentTab = "Residential",
  currentPage,
  handleProjectType,
}) => {
  const {
    dispatchFilter,
    filter,
    getSubCategory,
    subCategory,
    applyFilters,
  }: any = useContext(BuyRentContext)
  const tabs: SearchTab[] = ["Residential", "Commercial"]
  const [tabActive, setTabActive] = useState<SearchTab>(filter?.category)
  // console.log(tabActive, "tabActive")
  useEffect(() => {
    dispatchFilter({
      type: "updatePropertyCategory",
      value: tabActive,
    })
  }, [tabActive])
  useEffect(() => {
    getSubCategory()
  }, [])

  const renderTab = () => {
    return (
      <ul
        className='w-full flex justify-between border-b-2 px-1 text-center dark:border-gray-800 mb-5'
        id='myTab'
        data-tabs-toggle='#StarterContent'
        role='tablist'
      >
        {tabs.map((tab) => {
          const active = tab === tabActive
          return (
            <li
              onClick={() => {
                setTabActive(tab)
                dispatchFilter({
                  type: "updatePropertyCategory",
                  value: tab,
                })
              }}
              className={`text-center cursor-pointer lg:text-base text-black text-sm ${active
                ? "text-[#223A64] dark:text-white dar:border-white border-b-2 border-[#345886] "
                : "text-black text-sm"
                } `}
              key={tab}
            >
              {active && <span className=''></span>}
              <span className=' px-3'>{tab}</span>
            </li>
          )
        })}
      </ul>
    )
  }

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab
    switch (tabActive) {
      case "Residential":
        return (
          <ResidentialOptions
            haveDefaultValue={isArchivePage}
            handleProjectType={handleProjectType}
            filter={filter}
            subCategory={propertyCategoryOptions.filter(
              ({ category }: any) => category === "Residential"
            )}
          />
        )
      case "Commercial":
        return (
          <CommercialOption
            haveDefaultValue={isArchivePage}
            handleProjectType={handleProjectType}
            filter={filter}
            subCategory={propertyCategoryOptions.filter(
              ({ category }: any) => category === "Commercial"
            )}
          />
        )

      default:
        return null
    }
  }

  return (
    <div
      className={`nc-HeroSearchForm max-w-3xl mx-auto max-w-6xl lg:py-0 ${className}`}
      data-nc-id='HeroSearchForm'
    >
      {renderTab()}
      {renderForm()}
    </div>
  )
}

export default ResidentialCommercialFilter
