import React, { useContext, useEffect, useState } from "react"
import { DEMO_STAY_LISTINGS } from "data/listings"
import { API } from "utils/config"
import { BuyRentContext } from "context/property/BuyRentContext"
import { useParams } from "react-router-dom"
import pinContext from "context/pin/pinContext"
import StayCardH from "components/StayCardH/StayCardH"

const DEMO_EXPERIENCES = DEMO_STAY_LISTINGS.filter((_, i) => i < 12)
export interface AgentDetailPageProps {
  agencyNameprops?: any
}

const PropertySection = () => {
  let { id } = useParams<any>()
  console.log(id, "agentid")

  const {
    singleAgent,
    getSingleAgentById,
    getAllApproveAgencies,
    approveAgencies,
    singleConsultant,
  } = useContext(pinContext)
  const { getMyProperties, properties }: any = useContext(BuyRentContext)
  useEffect(() => {
    getMyProperties(id)
  }, [id])

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1)
  useEffect(() => {
    getSingleAgentById(id)
  }, [id])

  console.log(singleAgent?.id, "singleAgentdetail")
  useEffect(() => {
    getAllApproveAgencies()
  }, [approveAgencies])
  console.log(approveAgencies?.data?.[0]._id, "approveAgencies")

  return (
    <>
      <div className='py-3'>
        {properties?.property?.map((item: any) => (
          <div
            key={item.id}
            onMouseEnter={() => setCurrentHoverID((_) => item.id)}
            onMouseLeave={() => setCurrentHoverID((_) => -1)}
          >
            <StayCardH data={item} />
          </div>
        ))}
      </div>
    </>
  )
}

export default PropertySection
