import React, { useState } from 'react'
import image from "../../images/consultant.svg"
import Imlaak from "../../images/logos/logo-white.png"

import ConsultantRegisterModal from './ConsultantRegisterModal';
import { Link } from 'react-router-dom';
const ConsultantSidebar = () => {

    const [showRegisterModal, setShowRegisterModal] = useState(false);
    return (
        <>
            <div className="w-full flex flex-col">
                <div className="border border-[#dedede] rounded-lg p-6 w-full">
                    <h3 className="text-base font-semibold text-center px-3 pb-5">How to Select a Consultant</h3>
                    <hr className='bg-black' />
                    <div className="">
                        <div className="flex justify-start py-1.5">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                            </span>
                            <li className='text-[14px] text-black dark:text-white list-none'>Find highly reviewed consultants with listings or add counts</li>
                        </div>
                        <div className="flex justify-start py-1.5">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                            </span>
                            <li className='text-[14px] text-black dark:text-white list-none'>Use the filters to sort consultant connect with experts in your area of choice</li>
                        </div>
                        <div className="flex justify-start py-1.5">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                            </span>
                            <li className='text-[14px] text-black dark:text-white list-none'>Select your other preferences to connect with the right consultant</li>
                        </div>
                        <div className="flex justify-start py-1.5">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                            </span>
                            <li className='text-[14px] text-black dark:text-white list-none'>Start the discussion to see if they match your requirements</li>
                        </div>
                    </div>
                </div>
                <div className="mt-5 rounded-lg border flex flex-col p-4 border-gray-300 w-full h-[15rem] bg-[url('https://www.bayut.com/assets/truValueSearchBackgroundAgency_noinline.9b6ec212acf732d111536e581478359c.svg')] image-wrap bg-cover bg-center">
                    <h3 className="text-[1.2rem] font-semibold text-center text-black py-5">Find the Best Consultant for Your Property</h3>
                    <div className="flex justify-center">
                        <Link to="/consultantsPage">
                            <button className="mt-10 bg-[#0c4a6e] text-white py-2 px-12 rounded-2xl shadow-lg hover:bg-[#083e5e] tracking-wider hover:font-semibold">Find</button>
                        </Link>
                    </div>
                </div>

                <div className='mb-5 sticky top-16'>
                    <div className='relative flex-grow mt-14 lg:mt-8'>
                        <div className='rounded-lg bg-[#345886] text-center pt-7 pb-6 shadow-md'>
                            <div className='text-2xl font-semibold flex justify-center items-center text-white'>
                                <img src={Imlaak} className='w-20' alt='' />
                            </div>
                            <form className='pt-5'>
                                <input
                                    type='text'
                                    className='bg-white rounded outline-none border-0 w-10/12'
                                    placeholder='Name'
                                />
                                <input
                                    type='email'
                                    className='mt-3 bg-white rounded outline-none border-0 w-10/12'
                                    placeholder='Email'
                                />
                                <button className='mt-5 bg-white shadow-lg text-black py-1.5 px-7 rounded-xl hover:bg-white hover:text-[#223A64] hover:font-semibold'>
                                    Subscribe
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ConsultantRegisterModal
                showRegisterModal={showRegisterModal}
                setShowRegisterModal={setShowRegisterModal}
            />
        </>
    )
}

export default ConsultantSidebar