import React, { FC, useContext, useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import pinContext from "context/pin/pinContext";
import { API } from "utils/config";

export interface AgencySliderCardProps {
  className?: string;
  taxonomy?: TaxonomyType;
  agencies?: TaxonomyType;
  get?: any;
}

const AgencySliderCard: FC<AgencySliderCardProps> = ({
  className = "",
  taxonomy,
  get,
}) => {
  return (
    <Link
      to={`/agency-detail/${get?._id}`}
      className={`nc-AgencySliderCard flex flex-col ${className}`}
      data-nc-id="AgencySliderCard"
    >
      <div
        className={`flex-shrink-0 relative w-full border-b aspect-w-4 aspect-h-2 h-0 overflow-hidden group`}
      >
        <NcImage
          src={`${API}/uploads/${get.contentImage}`}
          className="w-[50%] m-auto my-[1%]"
        />
        <span className="opacity-0 group-hover:opacity-100 bg-[#345886] absolute inset-0 bg-opacity-5 transition-opacity"></span>
      </div>
      {/* {agencies?.agencydata?.map((agencydata:any) => (  */}
      {/* {agencies?.data?.map((item:any) => */}
      <div className="text-black text-center py-3 px-5 dark:text-white">
        {/* <p className="text-lg font-semibold">Imlaak {item.manage}</p> */}
        <p className="text-sm mt-1">{get?.manage}</p>
        <p className="text-sm mt-1 capitalize">{get?.name}</p>
        <p className="text-sm mt-1">{get?.propertiesfor}</p>
      </div>
    </Link>
  );
};

export default AgencySliderCard;
