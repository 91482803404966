import React, { FC, Fragment, useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import Input from "shared/Input/Input";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Agency from "../../images/bg/agency.svg";
import Signup from "../../images/bg/signup.png";
import Swal from "sweetalert2";
import { API } from "utils/config";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import pinContext from "context/pin/pinContext";
import { Popover, Transition } from "@headlessui/react";

var data = require("../../data/jsons/citynames.json");
var provinceData = require("../../data/jsons/provincenames.json");

export interface AgencyPortalProps {
  className?: string;
  data?: any;
  searchTermProvince?: any;
  searchTermCity?: any;
}

const AgencyPortal: FC<AgencyPortalProps> = ({ className = "" }) => {
  const [Provincevalue, setProvinceValue] = useState("");
  const [Cityvalue, setCityValue] = useState("");

  const onChange = (event: any) => {
    if (event.target.name === "province") {
      setProvinceValue(event.target.value);
      setValue("province", event.target.value);
    } else if (event.target.name === "city") {
      setCityValue(event.target.value);
      setValue("city", event.target.value);
    }
  };
  const onSearch = (value: any) => {
    setValue("province", value);
    setProvinceValue(value);
  };
  const onSearchCity = (value: any) => {
    setValue("city", value);
    setCityValue(value);
  };

  const [showhide, setShowhide] = useState("no");
  const handleshow = (e: any) => {
    const getshow = e.target.value;
    setShowhide(getshow);
  };

  const [loader, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    control,
    // getValues,
    setValue,
    watch,
  } = useForm();

  console.log(useForm, "useFormdata");
  const { getMe }: any = React.useContext(pinContext);
  let history = useHistory();

  const [contact, setContact] = useState();

  console.log(setContact, "contactno");

  const onSubmit = async (data: any) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };
    console.log(data, "dataofreg");
    if (!data.password) {
      setTimeout(() => { }, 5000);
      return console.log("no data in signup");
    } else {
      setLoading(true);

      axios
        .post(
          `${API}/api/auth/register`,
          {
            agencyName: data.agencyName,
            email: data.email,
            password: data.password,
            contact: data.phone,
            role: data.role,
            country: data.country,
            province: data.province,
            city: data.city,
            searchTermProvince: data.searchTermProvince,
          },
          config
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("authToken", response.data.token);
            getMe();
            history.push("/");
          }
          setLoading(false);
          Swal.fire({
            title: "Welocome to Imlaak!",
            text: "Account successfully Login",
            icon: "success",
            confirmButtonColor: "#e74c3c",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    reset();
  };

  const { role } = watch();
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <>
      <div className="relative sm:flex-row flex-col overflow-hidden lg:h-[80vh]">
        <div className="sm:mx-7 mx-0 md:mx-0">
          <div className="relative z-10 bg-red-50 dark:bg-red-50 lg:h-[80vh] lg:rounded-l-xl md:rounded-l-xl lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-red-50 lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8"></div>
            <main className="mx-auto max-w-7xl px-4 lg:px-8 sm:py-10 py-5">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-bold tracking-tight text-slate-900 text-center">
                  <span className="block xl:inline">Agency Portal</span>{" "}
                </h1>
                <p className="mt-3 sm:pl-5 pl-0 mx-auto sm:mt-5 sm:max-w-xl md:mt-5">
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400 text-base text-justify">
                    Agency Portal is created for the agent so he can have an
                    overview of all his existing clients in a single dashboard.
                    Most of the information in the Agency Portal is pulled from
                    the Agency Management System (AMS) that the agent uses
                    including his Customers, Premiums, Carriers, etc. Agency
                    Portal has built in APIs, to fetch data to our Agency
                    Portal. You do not have to manually update the data in the
                    Agency Portal. There are several components to the Agency
                    Portal dashboard that are explained below in detail.
                  </span>
                  <div className="flex flex-wrap pt-5 sm:mx-auto sm:mb-2 ">
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="bg-red-300 rounded flex p-1 h-full items-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                          className="text-[#345886] w-4 h-4 flex-shrink-0 mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span className="title-font font-medium text-sm">
                          Dashboard
                        </span>
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="bg-red-300 rounded flex p-1 h-full items-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                          className="text-[#345886] w-4 h-4 flex-shrink-0 mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span className="title-font font-medium text-sm">
                          Widgets
                        </span>
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="bg-red-300 rounded flex p-1 h-full items-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                          className="text-[#345886] w-4 h-4 flex-shrink-0 mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span className="title-font font-medium text-sm">
                          Agent Management
                        </span>
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="bg-red-300 rounded flex p-1 h-full items-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                          className="text-[#345886] w-4 h-4 flex-shrink-0 mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span className="title-font font-medium text-sm">
                          Properties Management
                        </span>
                      </div>
                    </div>
                    <div className="p-2 sm:w-1/2 w-full">
                      <div className="bg-red-300 rounded flex p-1 h-full items-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="3"
                          className="text-[#345886] w-4 h-4 flex-shrink-0 mr-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                          <path d="M22 4L12 14.01l-3-3"></path>
                        </svg>
                        <span className="title-font font-medium text-sm">
                          User Feedback
                        </span>
                      </div>
                    </div>
                  </div>
                </p>
                <div className="mt-5 flex justify-center">
                  <div className="rounded-md shadow">
                    <button className="flex w-full py-2.5 items-center justify-center rounded-md border border-transparent bg-red-300 px-8 text-base font-medium text-white hover:bg-red-400 md:px-10">
                      More Detail
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 rounded-xl lg:right-0 mx-8 lg:block md:hidden hidden">
          <img
            className="h-56 w-64 object-cover rounded-xl sm:h-72 md:h-84 lg:h-full lg:w-full"
            src={Agency}
            alt=""
          />
        </div>
      </div>
      <div className="container pt-20 pb-10">
        <div className="rounded-lg grid lg:grid-cols-2 grid-cols-1">
          <div className="flex flex-col col-span-1 items-center">
            <div className="">
              <span className="capitalize font-semibold text-2xl text-[#345886]">
                List your properties on Tenmarla.com
              </span>
            </div>
            <img src={Signup} alt="" className="w-10/12" />
          </div>
          <div className="bg-red-50 rounded-lg col-span-1">
            <div className="sm:px-24 px-5 md:px-10 py-10">
              <form className="" onSubmit={handleSubmit(onSubmit)}>
                <Input
                  type="agencyName"
                  className={`bg-white rounded-md py-2 mt-3 border border-gray-300 w-full px-6 hover:border-b-none ${errors.agencyName && "invalid"
                    }`}
                  placeholder="Agency Name*"
                  {...register("agencyName", {
                    required: "First Name is required",
                  })}
                  onKeyUp={() => {
                    trigger("agencyName");
                  }}
                />
                {errors.agencyName && (
                  <small className="text-[#223A64]">
                    Agency Name is required
                  </small>
                )}
                <Input
                  type="email"
                  className={`bg-white border border-gray-300 text-sm rounded-md py-2 mt-6 w-full px-6 ${errors.email && "invalid"
                    }`}
                  placeholder="Email*"
                  {...register("email", {
                    required: "Email is Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  onKeyUp={() => {
                    trigger("email");
                  }}
                />
                {errors.email && (
                  <small className="text-[#223A64]">
                    Invalid email address
                  </small>
                )}
                <Input
                  type="password"
                  className={`bg-white border border-gray-300 text-sm rounded-md py-2 mt-6 w-full px-6  ${errors.password && "invalid"
                    }`}
                  placeholder="Password*"
                  {...register("password", {
                    required: "Password is required",
                  })}
                  onKeyUp={() => {
                    trigger("password");
                  }}
                />
                {errors.password && (
                  <small className="text-[#223A64]">Password is required</small>
                )}

                <div className="pt-2">
                  <Controller
                    name="phone-input"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        inputStyle={{
                          border: "1px solid #d1d5db",
                          width: "100%",
                          height: "43px",
                        }}
                        containerStyle={{
                          marginTop: "12px",
                        }}
                        country={"pk"}
                        value={value}
                        onChange={(phone) => {
                          console.log(phone);
                          setValue("phone", phone);
                          onChange(phone);
                        }}
                        inputProps={{
                          ref: register("phone-input", {
                            required: "Phone number is required.",
                          }),
                          onBlur: () => {
                            trigger("phone-input");
                          },
                        }}
                      />
                    )}
                  />
                  {errors["phone-input"] && (
                    <small className="text-[#223A64]">
                      Phone number is required
                    </small>
                  )}
                </div>

                {/* <Input
                                    type="country"
                                    className={`bg-white rounded-md py-2 mt-3 border border-gray-300 w-full px-6 ${errors.country && "invalid"}`}
                                    placeholder="Country*"
                                    {...register("country", { required: "First Name is required" })}
                                    onKeyUp={() => {
                                        trigger("country");
                                    }}
                                />
                                {errors.country && (
                                    <small className="text-[#223A64]">Country Name is required</small>
                                )} */}

                {/* Start province input */}
                <div className="col-span-2">
                  <div className="relative mt-2">
                    <div className="search-inner">
                      <input
                        type="province"
                        name="province"
                        placeholder="Province"
                        className={`bg-white rounded-md py-2.5 mt-3 border border-gray-300 w-full px-6 ${errors.province && "invalid"
                          }`}
                        value={Provincevalue}
                        onChange={onChange}
                      />

                      <button type="submit"></button>
                    </div>
                    <div className="absolute bg-white w-full z-40 shadow-xl  rounded-md mt-2 dropdown">
                      {provinceData
                        .filter((item: any) => {
                          const searchTermProvince =
                            Provincevalue.toLowerCase();
                          const fullName = item.provinceName.toLowerCase();

                          return (
                            searchTermProvince &&
                            fullName.startsWith(searchTermProvince) &&
                            fullName !== searchTermProvince
                          );
                        })
                        .slice(0, 10)
                        .map((item: any) => (
                          <div
                            onClick={() => onSearch(item.provinceName)}
                            className="dropdown-row px-3 border-b py-1.5"
                            key={item.provinceName}
                          >
                            {item.provinceName}
                          </div>
                        ))}
                    </div>
                  </div>
                  {errors.province && (
                    <small className="text-[#345886]">City is required</small>
                  )}
                </div>
                {/* End province input */}

                {/* Start city input */}
                <div className="col-span-2">
                  <div className="relative mt-2">
                    <div className="search-inner">
                      <input
                        type="city"
                        name="city"
                        placeholder="City"
                        className={`bg-white rounded-md py-2.5 mt-3 border border-gray-300 w-full px-6 ${errors.city && "invalid"
                          }`}
                        value={Cityvalue}
                        onChange={onChange}
                      />

                      <button type="submit"></button>
                    </div>
                    <div className="absolute bg-white w-full z-40 shadow-xl  rounded-md mt-2 dropdown">
                      {data
                        .filter((item: any) => {
                          const searchTermCity = Cityvalue.toLowerCase();
                          const fullName = item.cityName.toLowerCase();

                          return (
                            searchTermCity &&
                            fullName.startsWith(searchTermCity) &&
                            fullName !== searchTermCity
                          );
                        })
                        .slice(0, 10)
                        .map((item: any) => (
                          <div
                            onClick={() => onSearchCity(item.cityName)}
                            className="dropdown-row px-3 border-b py-1.5"
                            key={item.cityName}
                          >
                            {item.cityName}
                          </div>
                        ))}
                    </div>
                  </div>
                  {errors.city && (
                    <small className="text-[#345886]">City is required</small>
                  )}
                </div>

                {/* End city input */}

                {/* <Input
                                    type="province"
                                    className={`bg-white rounded-md py-2 mt-3 border border-gray-300 w-full px-6 ${errors.province && "invalid"}`}
                                    placeholder="Province*"
                                    {...register("province", { required: "Province Name is required" })}
                                    onKeyUp={() => {
                                        trigger("province");
                                    }}
                                />
                                {errors.province && (
                                    <small className="text-[#223A64]">Province Name is required</small>
                                )} */}

                {/* <Input
                                    type="city"
                                    className={`bg-white rounded-md py-2 mt-3 border border-gray-300 w-full px-6 ${errors.city && "invalid"}`}
                                    placeholder="City*"
                                    {...register("city", { required: "First Name is required" })}
                                    onKeyUp={() => {
                                        trigger("city");
                                    }}
                                />
                                {errors.city && (
                                    <small className="text-[#223A64]">City Name is required</small>
                                )} */}

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={` text-start bg-white rounded-md py-2.5 mt-3 border border-gray-300 w-full px-6 ${open ? "" : ""
                          }`}
                      >
                        <span className="capitalize text-gray-500">
                          {role ?? "Signing up as* "}
                        </span>

                        {/* <CogIcon className="w-8 h-8" /> */}
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          className="z-50 absolute rounded-md bg-white shadow mt-2 w-full"
                        // {...register("role", { required: "Role is required" })}
                        // onKeyUp={() => {
                        //     trigger("role")
                        // }}
                        >
                          <div className="pt-5 pb-5 px-3">
                            {/* <div className="pb-2 mt-3 border-b-2">Company</div> */}
                            {/* <div className="mt-2 px-4">
                                                            <input
                                                                type="radio"
                                                                value="owner"
                                                                name="role"
                                                                checked={role === "owner"}
                                                                onChange={({ target }) => {
                                                                    setValue("role", target.value);
                                                                }}
                                                                className="text-[#223A64] cursor-pointer"
                                                            />
                                                            <span className="text-sm">&nbsp; Owner</span>
                                                        </div> */}
                            <div className="mt-2 px-4">
                              <input
                                type="radio"
                                value="agency"
                                checked={role === "agency"}
                                name="role"
                                onChange={({ target }) => {
                                  setValue("role", target.value);
                                }}
                                className="text-[#223A64] cursor-pointer"
                              />
                              <span className="text-sm">&nbsp; Agency</span>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                {errors.role && (
                  <small className="text-[#223A64]">Role is required</small>
                )}
                <p className="mt-5">
                  <input
                    type="radio"
                    className="text-[#223A64] cursor-pointer"
                  />
                  <span className="px-2 text-sm text-black">
                    I am not a Robot
                  </span>
                </p>
                <p className="mt-5">
                  <input
                    type="radio"
                    className="text-[#223A64] cursor-pointer"
                    checked
                  />
                  <span className="px-2 text-sm text-black">
                    I have read and agreed to the Imlaak.com{" "}
                    <Link to="" className="text-blue-600">
                      Terms and Conditions
                    </Link>
                  </span>
                </p>
                <p className="mt-5 text-sm text-black">
                  <input
                    type="radio"
                    className="text-[#223A64] cursor-pointer"
                    checked
                  />
                  <span className="px-2">
                    I would like to receive notifications about promotions,
                    newsletters and updates.
                  </span>
                </p>
                <button
                  type="submit"
                  className="text-white text-xl cursor-pointer"
                  style={{ width: "100%" }}
                >
                  <div className="text-center bg-[#223A64] rounded py-2 mt-4 cursor-pointer">
                    {loader ? (
                      <CircularProgress
                        size={24}
                        color="error"
                        sx={{ zIndex: 1 }}
                      />
                    ) : (
                      "Register"
                    )}
                  </div>
                </button>

                {/* <div className="items-center text-center pt-7">
                                <button className="bg-[#345886] hover:bg-[#1f345c] text-white text-base rounded-md w-full py-2.5">Register Now</button>
                            </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyPortal;
