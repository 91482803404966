import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import EmailFrom from "containers/ListingDetailPage/EmailFrom";
import pinContext from "context/pin/pinContext";
import { ProjectContext } from "context/project/ProjectContext";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "shared/Logo/Logo";
import { API } from "utils/config";
import CheckIMage from "../../../images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import GeneralEmail from "containers/ListingDetailPage/GeneralEmail";

const DeveloperDetailPage = () => {
  type QuizParams = {
    id: any;
  };
  let { id } = useParams<any>();
  const { singleDeveloper, getsingleDeveloperById } = useContext(pinContext);
  useEffect(() => {
    getsingleDeveloperById(id);
  }, []);
  useEffect(() => {
    // console.log(singleDeveloper[0].name, "sigledeveloperdetail");
  }, [singleDeveloper]);
  const developerLogo = singleDeveloper[0]?.developerImage?.[0]?.url;
  const developer = singleDeveloper?.[0];
  const projects = singleDeveloper?.[0]?.projects

  return (
    <div className="pt-10">
      <div className="container flex justify-between">
        <p className="text-2xl font-semibold">Developer Information</p>
      </div>
      <hr />
      <div className="container grid lg:grid-cols-6 md:grid-cols-1 grid-cols-1 gap-4 sm:pb-2">
        <div className="lg:col-span-4 md:col-span-1 col-span-1">
          <div className="grid lg:grid-cols-6 grid-cols-1">
            <div className="lg:col-span-2 col-span-1 p-6 m-auto">
              <NcImage
                src={developerLogo}
                className="w-full"
                alt={developer?.name}
              />
            </div>
            {/* {developer?.map((item: any) => (
            ))} */}
            <div className="col-span-4">
              <div className="lg:py-10 text-sm font-semibold flex flex-col text-left mt-5 px-3 py-4 ">
                <div className="grid grid-cols-4 flex-row bg-gray-200 dark:bg-slate-800 dark:shadow-lg px-3 py-4 rounded">
                  <div className="col-span-1">
                    <p className="">Name :</p>
                  </div>
                  <div className="col-span-3">
                    <p className="font-normal capitalize">{developer?.name}</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 flex-row bg-white dark:bg-transparent px-3 py-4">
                  <div className="col-span-1">
                    <p className="">Location :</p>
                  </div>
                  <div className="col-span-3">
                    <p className="font-normal capitalize">
                      {developer?.developerAddress}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-4 flex-row bg-gray-200 dark:bg-slate-800 dark:shadow-lg px-3 py-4 rounded">
                  <div className="col-span-1">
                    <p className="">Contact :</p>
                  </div>
                  <div className="col-span-3">
                    <p className="font-normal">+{developer?.developerContact}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="listingSection__wrap">
            <h2 className="font-semibold">Description: </h2>
            <p className="text-sm text-justify pt-3">
              <div dangerouslySetInnerHTML={{ __html: developer?.description }} />
            </p>
          </div>

          <hr className="mt-5" />
          <div className="py-5">
            <h2 className="text-2xl font-medium">Projects</h2>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 md:gap-4 lg:pt-8 pt-5">
              {projects?.map((item: any) => (
                <div className="col-span-1 py-2">
                  <div className="relative bg-white border pb-3 border-t-0 overflow-hidden rounded-lg shadow-md dark:bg-gray-800 dark:border-none">
                    <Link to={`/${item.projectSlug}`}>
                      <div className="overflow-hidden cursor-pointer w-full h-52">
                        <img
                          className="h-52 w-full object-cover rounded-t-lg transition-all duration-500 ease-in-out transform hover:scale-105"
                          src={`${item?.mainImages?.[0]?.url}`}
                          alt=""
                        />
                      </div>

                      <div className="px-5 pt-4">
                        <a href="#">
                          <h5 className="text-xl font-normal tracking-tight text-gray-900 dark:text-white">
                            {item?.projectNameDetails?.projectName}
                          </h5>
                        </a>
                        <p className="mb-2 font-normal text-sm text-gray-700 dark:text-gray-400">
                          <i className="las la-map-marked px-1"></i>
                          {`${item.projectNameDetails?.city}, ${item.projectNameDetails?.country}`}
                        </p>
                      </div>
                      <div className="px-5">
                        <p className="text-xs text-right py-1  text-[#4b5563]">
                          {moment(item.createdAt).format("LL")}
                        </p>
                      </div>
                      <div className="px-5  pb-2">
                        <div className="bg-slate-50 flex justify-between px-3 py-2 rounded">
                          <p className="text-sm font-semibold">Developed By</p>{" "}
                          <br />
                          <p className="text-sm">{developer?.name}</p>
                        </div>
                      </div>
                    </Link>
                    {/* <BtnLikeIcon className="absolute right-3 top-3" /> */}
                    <div className="px-5 -mt-5 hover:block">
                      <Link
                        to={`/${item.projectSlug}`}
                        className="w-full text-white bg-[#345886] hover:bg-[#223A64] focus:ring-none rounded focus:outline-none focus:ring-none font-semibold  text-md py-1.5 text-center dark:bg-[#223A64] mt-5 dark:hover:bg-[#e31414] dark:focus:ring-[#223A64]"
                        type="submit"
                      >
                        {" "}
                        View Details{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="lg:col-span-2 md:col-span-1 col-span-1">
          <div className="sticky top-24 border border-gray-200 p-5 rounded-xl">
            <div className='flex justify-center text-center pb-4'>
              <Logo />
            </div>
            <GeneralEmail />
          </div>
        </div>

      </div>
    </div>
  );
};

export default DeveloperDetailPage;
