import React, { useContext, useEffect, useState } from "react"
import MapForm from "components/PlotListing/Map/MapForm"

import { CircularProgress } from "@mui/material"
import { Box, Typography } from "@mui/material"
import { PinContext } from "services/context/pin/PinContext"
import { allImage } from "components/PlotListing/allMaps"
import { API } from "utils/config"
// import LeafletMap from "components/PlotListing/components/Leaflet";

const Map = () => {
  const {
    loading,
    getCurrentMap,
    firstLoad,
    pageDetails,
    setLoading,
    setFirstLoad,
    currentPin,
    currentUser,
    detailsModalOpen,
    setCurrentUser,
    clickedMarker,
    setDetailsModalOpen,
  } = useContext(PinContext)
  useEffect(() => {
    getCurrentMap()
  }, [])
  return (
    <>
      <Box
        style={{
          filter: loading ? "opacity(0.3)" : "opacity(1)",
          pointerEvents: loading ? "none" : "all",
          height: "90vh",
        }}
      >
        {firstLoad ? null : (
          <MapForm
            mapImage={
              pageDetails.value ? `${API}/uploads/${pageDetails.image}` : ""
            }
            pageDetails={pageDetails}
            pinAdding={setLoading}
            currentPin={currentPin}
            currentUser={currentUser}
            detailsModalOpen={detailsModalOpen}
            setCurrentUser={setCurrentUser}
            clickedMarker={clickedMarker}
            setDetailsModalOpen={setDetailsModalOpen}
            setFirstLoad={setFirstLoad}
            // mapImageReload={loading}
          />
        )}
      </Box>

      {loading && (
        <Box
          zIndex='1'
          style={{ backgroundColor: "white" }}
          p={2}
          position='absolute'
          top={"48%"}
          left={"calc(calc(100% - 262px) / 2)"}
          display='flex'
          alignItems='center'
          flexDirection={"column"}
          justifyContent='center'
        >
          <CircularProgress />
          <ResponsiveTypography>
            Please hold on. Your smart Map is loading...{" "}
          </ResponsiveTypography>
        </Box>
      )}
    </>
  )
}

export const ResponsiveTypography = ({ children, ...rest }: any) => (
  <Typography
    sx={{ fontSize: { md: "1rem", xs: "13px", sm: "13px" } }}
    {...rest}
  >
    {children}
  </Typography>
)

export default Map
