import React, {
  Component,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// import ReactDOM from "react-dom";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import { TaxonomyType } from "data/types";
import Heading from "components/Heading/Heading";
import pinContext from "context/pin/pinContext";
import FeaturedCard from "./DeveloperSection/card";
import {
  CarouselProvider,
  Slider,
  // Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Box } from "@mui/system";
import { Grid, Grow, Typography, Slide, CircularProgress } from "@mui/material";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5" | "card6";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

// export const FeaturedCarousal: FC<SectionSliderNewCategoriesProps> = ({
//   heading = "",
//   subHeading = "",
//   className = "",
//   itemClassName = "",
//   itemPerRow = 4,
//   categoryCardType = "card3",
//   sliderStyle = "style1",
//   uniqueClassName,
// }) => {
//   const { developers, getAllDevelopers, loading, setLoading }: any =
//     useContext(pinContext);

//   useEffect(() => {
//     getAllDevelopers();
//   }, []);
//   return (
//     <>
//       <Heading
//         desc={subHeading}
//         hasNextPrev={sliderStyle === "style2"}
//         isCenter={sliderStyle === "style1"}
//       >
//         {heading}
//       </Heading>
//       {loading ? <>Loading ... </> : null}

//       <Carousel useKeyboardArrows showArrows centerMode >
//         {developers?.items?.map((item: any, index: any) => (
//           <li style={{ padding: "8px" }} key={item._id}>
//             <FeaturedCard get={item} />
//           </li>
//         ))}
//       </Carousel>
//     </>
//   );
// };

export const FeaturesCarousal = ({ heading = "", subHeading = "" }: any) => {

  const [index, setIndex] = useState(0);
  const { developers, getAllDevelopers, loading, setLoading }: any =
    useContext(pinContext);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Logic for slider mobile size
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  //End  Logic for slider mobile size


  useEffect(() => {
    getAllDevelopers();
  }, []);
  const toShow = useMemo(() => {
    if (index >= 0) {
      return {
        current: developers?.items?.[index],
        ...(index > 0 && index < developers?.items?.length
          ? {
            previous: developers?.items?.[index - 1],
            previousIndex: index - 1,
          }
          : null),
        ...(index < developers?.items?.length - 1
          ? { next: developers?.items?.[index + 1], nextIndex: index + 1 }
          : null),
        currentIndex: index,
      };
    }

    return null;
  }, [developers, index, loading]);

  useEffect(() => {
    switch (developers?.items?.length) {
      case 0:
        setIndex(1);
        return;
      case 1:
        setIndex(0);
        return;
      case 2:
        setIndex(1);
        return;
      case 3:
        setIndex(2);
        return;
      default:
        setIndex(1);

        return;
    }
  }, [developers]);

  const next = useCallback(() => {
    if (toShow?.nextIndex) setIndex(toShow.nextIndex);
  }, [toShow]);

  const previous = useCallback(() => {
    if (toShow?.previousIndex) setIndex(toShow.previousIndex);
  }, [toShow]);

  if (!developers || developers?.items?.length === 0 || !toShow) return null;
  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "red",
        }}
      ></Box>
      {loading ?
        <div className="relative bg-white rounded-lg overflow-hidden flex-center">
          <CircularProgress
            size={24}
            color="error"
            sx={{ zIndex: 1 }}
          /></div> : null}
      <Box
        display="flex"
        alignItems={"center"}
        my={6}
        justifyContent="space-evenly"
      >
        <ChevronLeftIcon
          width={42}
          height={42}
          onClick={previous}
          cursor="pointer"
        />
        {screenWidth >= 851 ? (
          <>
            {toShow?.previous ? (
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box sx={{ padding: "8px", minWidth: "325px" }}>
                  <FeaturedCard get={toShow.previous} />
                </Box>
              </Slide>
            ) : null}


            {toShow?.current ? (
              // <Box minWidth={"60%"}>
              <Slide
                timeout={3000}
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
              >
                <Box sx={{ padding: "8px", minWidth: "325px" }}>
                  <FeaturedCard get={toShow.current} />
                </Box>
              </Slide>
            ) :
              null}

            {toShow?.next ? (
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box sx={{ padding: "8px", minWidth: "325px" }}>
                  <FeaturedCard get={toShow.next} />
                </Box>
              </Slide>
            ) : null}
          </>
        ) : screenWidth >= 500 ? (
          <>
            {toShow?.previous ? (
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box sx={{ padding: "8px", minWidth: "325px" }}>
                  <FeaturedCard get={toShow.previous} />
                </Box>
              </Slide>
            ) : null}

            {toShow?.current ? (
              // <Box minWidth={"60%"}>
              <Slide
                timeout={3000}
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
              >
                <Box sx={{ padding: "8px", minWidth: "325px" }}>
                  <FeaturedCard get={toShow.current} />
                </Box>
              </Slide>
            ) :
              null}
          </>
        ) : (
          <>
            {toShow?.previous ? (
              <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                <Box sx={{ padding: "8px", minWidth: "325px" }}>
                  <FeaturedCard get={toShow.previous} />
                </Box>
              </Slide>
            ) : null}

          </>
        )
        }

        <ChevronRightIcon
          width={42}
          height={42}
          cursor="pointer"
          onClick={next}
        />
      </Box >
    </>
  );
};

export const FeaturedCarousal: FC<SectionSliderNewCategoriesProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  itemPerRow = 4,
  categoryCardType = "card3",
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const { developers, getAllDevelopers, loading, setLoading }: any =
    useContext(pinContext);

  useEffect(() => {
    getAllDevelopers();
  }, []);
  return (
    <>
      <Heading
        desc={subHeading}
        hasNextPrev={sliderStyle === "style2"}
        isCenter={sliderStyle === "style1"}
      >
        {heading}
      </Heading>
      {loading ? <>Loading ... </> : null}
      <Box width={"80vw"} sx={{ overflowX: "auto", display: "flex" }}>
        {developers?.items?.map((item: any, index: any) => (
          <Box sx={{ padding: "8px", minWidth: "325px" }} key={item._id}>
            <FeaturedCard get={item} />
          </Box>
        ))}
      </Box>
      {/* <CarouselProvider
        naturalSlideWidth={325}
        naturalSlideHeight={250}
        totalSlides={5}
        visibleSlides={3}
      >
        <Slider>
          {developers?.items?.map((item: any, index: any) => (
            <Slide index={index} style={{ padding: "8px" }} key={item._id}>
              <FeaturedCard get={item} />
            </Slide>
          ))}
        </Slider>
      </CarouselProvider> */}
    </>
  );
};
