import { Alert, Button, CircularProgress, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { LatLngBounds } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from 'react';
import { ImageOverlay, MapContainer, Marker, Popup, useMapEvents } from 'react-leaflet'
import './styles.css'
import L from "leaflet";

import mapIconRed from "../Map/icon-red.png";
import mapIconBlue from "../Map/marker-icon.png";
import shadowUrl from "../Map/shadow.png";
import { Box } from '@mui/material';
import { ResponsiveTypography } from '../';

const CustomPopUpBodyLatest = ({ details, deletePin, user }) => {
  const [requestSent, setRequestSent] = useState(false)
  const internalDeletePin = async (id) => {
    setRequestSent(true)
    await deletePin(id)
    setRequestSent(false)
  }

  return <Box>
    <Table aria-label="simple table" size="small" position='relative' sx={{ minWidth: 350 }}  >

      {requestSent ?
        <Box zIndex='6' backgroundColor='white' p={2} position="absolute" top={'48%'} left={'calc(calc(100% - 262px) / 2)'} display='flex' alignItems='center' flexDirection={'column'} justifyCenter='center' >
          <CircularProgress />
          <ResponsiveTypography >Please hold on. Your pin is deleting... </ResponsiveTypography>
        </Box>
        : null}
      <TableBody>
        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row">Plot No</TableCell>
            <TableCell component="th" align="left">{details?.plotno}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row">Plot Category </TableCell>
            <TableCell component="th" align="left">{details?.plotCat}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row">Plot Unit  </TableCell>
            <TableCell component="th" align="left">{details?.areaCat}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row">Plot Area  </TableCell>
            <TableCell component="th" align="left">{new Intl.NumberFormat().format(parseInt(details?.area || 0))}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row">Block No </TableCell>
            <TableCell component="th" align="left">{details?.blockNumber}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row">Price </TableCell>
            <TableCell component="th" align="left">{new Intl.NumberFormat().format(parseInt(details?.price || 0))}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row">Agent Detail </TableCell>
            <TableCell component="th" align="left">{details?.fname} {details?.lname}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row"> Mobile </TableCell>
            <TableCell component="th" align="left"> {details?.contact}</TableCell>
          </Typography>
        </TableRow>

        <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
          <Typography variant="subtitle1">
            <TableCell component="th" scope="row"> Email </TableCell>
            <TableCell component="th" align="left"> {details?.email}</TableCell>
          </Typography>
        </TableRow>
        <TableRow sx={{ border: "none" }}>
          <TableCell sx={{ border: "none" }}>
            {user?.email === details?.email ? (
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent={"flex-end"}
              >
                <Button
                  disabled={requestSent}
                  variant="contained"
                  color="error"
                  onClick={() => internalDeletePin(details?._id)}
                >
                  Delete Plot
                </Button>
              </Box>
            ) : null}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>


}


const CustomPopUpBody = ({ details, deletePin, user }) => {
  const [requestSent, setRequestSent] = useState(false)
  const internalDeletePin = async (id) => {
    setRequestSent(true)
    await deletePin(id)
    setRequestSent(false)
  }

  useEffect(() => {
    // const allTags = document.getElementsByClassName('leaflet-popup-close-button')
    // debugger
    // allTags.forEach(element => {
    //   console.log(element, 'element')
    //   element.href = ''
    // });
    document.querySelector('.leaflet-popup-close-button').removeAttribute('href')

  }, [])

  return <Box>
    <Table aria-label="simple table" size="small" position='relative' >

      {requestSent ?
        <Box zIndex='6' backgroundColor='white' p={2} position="absolute" top={'48%'} left={'calc(calc(100% - 262px) / 2)'} display='flex' alignItems='center' flexDirection={'column'} justifyCenter='center' >
          <CircularProgress />
          <ResponsiveTypography >Please hold on. Your pin is deleting... </ResponsiveTypography>
        </Box>
        : null}
      <TableBody>
        <tr style={{ border: '1px solid lightgray', }}>
          <th style={{ minWidth: 150 }}>Plot No</th>
          <td component="th" align="left">{details?.plotno}</td>
          <th style={{ minWidth: 150 }}>Plot Category </th>
          <td component="th" align="left">{details?.plotCat}</td>
        </tr>
        <tr style={{ border: '1px solid lightgray', }}>
          <th style={{ minWidth: 150 }}>Plot Unit  </th>
          <td component="th" align="left">{details?.areaCat}</td>
          <th style={{ minWidth: 150 }}>Plot Area  </th>
          <td component="th" align="left">{new Intl.NumberFormat().format(parseInt(details?.area || 0))}</td>
        </tr>
        <tr style={{ border: '1px solid lightgray', }}>
          <th style={{ minWidth: 150 }}>Block No </th>
          <td component="th" align="left">{details?.blockNumber}</td>
          <th style={{ minWidth: 150 }}>Price </th>
          <td component="th" align="left">{new Intl.NumberFormat().format(parseInt(details?.price || 0))}</td>
        </tr>
        <tr style={{ border: '1px solid lightgray', }}>
          <th style={{ minWidth: 150 }}>Agent Detail </th>
          <td component="th" align="left">{details?.fname} {details?.lname}</td>

          <th style={{ minWidth: 150 }}> Mobile </th>
          <td component="th" align="left"> {details?.contact}</td>
        </tr>

        <tr style={{ border: '1px solid lightgray', }}>
          <th style={{ minWidth: 150 }}> Email </th>
          <td component="th" align="left"> {details?.email}</td>
        </tr>

        <tr>
          <td colSpan={4} sx={{ border: "none" }}>
            {user?.email === details?.email ? (
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent={"center"}
              >
                <Button
                  color="secondary"
                  disabled={requestSent}
                  variant="contained"
                  onClick={() => internalDeletePin(details?._id)}
                >
                  Delete Plot
                </Button>
              </Box>
            ) : null}
          </td>
        </tr>
      </TableBody>
    </Table>
  </Box>


}

const CustomLocationMarker = ({ pinsData, currentUser, internalMarkerClick, clickedMarker, deletePin }) => {

  // const [position, setPosition] = useState(null)
  // const map = useMapEvents({
  //   click(e) {
  //     console.log(e, 'aaaa')
  //     internalMarkerClick()
  //     clickedMarker()
  //     // showForm(e)
  //   },
  // })
  // console.log(currentUser, 'currentUser')

  return pinsData?.map((data) => {
    const icon = (data?.email) === currentUser?.email
      ? mapIconRed
      : mapIconBlue;
    var markerIcon = L.icon({
      iconUrl: icon,
      iconSize: [30, 60],
      iconAnchor: [15, 60],
      popupAnchor: [-3, -76],
      shadowUrl: shadowUrl,
      shadowSize: [30, 60],
      shadowAnchor: [12, 60],
    });
    return (
      <Marker
        icon={markerIcon}
        position={data.coordinates}
        onClick={() => { }}>
        <Popup onClick={() => { }} style={{ width: 'auto !important' }}>
          <CustomPopUpBody user={currentUser} details={data} deletePin={deletePin} />
        </Popup>
      </Marker>
    )
  })
}

function Leaflet({ mapImage, mapPins, showForm, currentUser, clickedMarker, deletePin }) {
  const bounds = new LatLngBounds([400, -350], [-6000, 400])
  const [clickedOnMarker, setClickedOnMarker] = useState(null)
  console.log(mapImage, 'mapImage')
  function LocationMarker() {
    const [position, setPosition] = useState(null)

    const map = useMapEvents({
      click(e) {
        if (!clickedOnMarker) {
          showForm(e)
        }
        setClickedOnMarker(false)
      },
    })
    return null
  }

  return (
    <MapContainer center={[28, 0]} zoom={0}
      minZoom={2}
      maxZoom={8}
      doubleClickZoom={true}
    // whenReady={() => alert('hello map is ready')}
    >
      <ImageOverlay
        url={mapImage}
        bounds={bounds}
      />

      <CustomLocationMarker deletePin={deletePin} pinsData={mapPins} currentUser={currentUser} internalMarkerClick={() => setClickedOnMarker(true)} clickedMarker={clickedMarker} />
      <LocationMarker />
    </MapContainer>
  )
}

export default Leaflet


// import React, { useRef, useEffect } from "react";
// import { MapContainer } from "react-leaflet";
// import { CRS } from "leaflet";
// import "leaflet/dist/leaflet.css";
// import L from "leaflet";

// const LeafletMap = ({mapImage}) => {
//   const mapRef = useRef(null);

//   useEffect(() => {
//     const map = mapRef.current.leafletElement;
//     const bounds = [[-26.5, -25], [1021.5, 1023]];
//     const image = L.imageOverlay(
//       "mapImage",
//       bounds
//     ).addTo(map);

//     map.fitBounds(image.getBounds());
//   }, []);

//   return (
//     <>
//       <MapContainer
//         ref={mapRef}
//         minZoom={0}
//         crs={CRS.Simple}
//         maxBoundsViscosity={1.0}
//         boundsOptions={{ padding: [50, 50] }}
//         style={{ height: "100vh" }}
//       />
//     </>
//   );
// };

// export default LeafletMap