import React, { useEffect } from 'react'

export const useProfile = () => {
  const token = window.localStorage.getItem('authToken')
  const isLoggedIn = token ? true : false
  return {
    isLoggedIn
  }
}

