import React, {
  FC,
  useState,
  ReactNode,
  Fragment,
  useContext,
  useEffect,
} from "react"
import StayCardH from "components/StayCardH/StayCardH"
import { Popover, Transition } from "@headlessui/react"
import GoogleMapReact from "google-map-react"
import { DEMO_STAY_LISTINGS } from "data/listings"
import ButtonClose from "shared/ButtonClose/ButtonClose"
import HeaderFilter from "./HeaderFilter"
import { StayDataType } from "data/types"
import Imlaak from "../../images/logos/logo-white.png"
import { BuyRentContext } from "context/property/BuyRentContext"
import Pagination from "shared/Pagination/BuyRentPagination/BuyRentPagination"
import InstagaramFeeds from "components/HeroSearchForm/InstagaramFeeds"
import BuyPropertySearchForm from "components/HeroSearchForm/BuyPropertySearchForm"
import PaginationCheck from "shared/Pagination/PaginationCheck"
import { ProjectContext } from "context/project/ProjectContext"

export interface SalePageProps {
  stayListings?: StayDataType[]
  gridClass?: string
  heading?: ReactNode
  subHeading?: ReactNode
  headingIsCenter?: boolean
  tabs?: string[]
}

const SalePage: FC<SalePageProps> = ({
  gridClass = "",
  heading = "Properties for Sale",
  subHeading = "",
  headingIsCenter,
  tabs = ["All", "Furnished", "Unfurnished"],
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1)
  const [showFullMapFixed, setShowFullMapFixed] = useState(false)

  const {
    getMyProperties,
    loading,
    properties,
    perPage,
    setPerPage,
    pageNumber,
    setPageNumber,
    applyFilters,
    filter,
    dispatchFilter,
  }: any = useContext(BuyRentContext)
  useEffect(() => {
    applyFilters()
    getMyProperties()
  }, [])

  const [currentPage, setCurrentPage] = useState<number>(1);
  const propertiesPerPage = 10;
  const indexOfLastProperties = currentPage * propertiesPerPage;
  const indexOfFirstConsultant = indexOfLastProperties - propertiesPerPage;
  const currentProperties = properties?.slice(indexOfFirstConsultant, indexOfLastProperties);
  return (
    <>
      <div className='container my-2'>
        <BuyPropertySearchForm />
      </div>

      <main className='container relative mt-11 flex flex-col lg:flex-row '>
        {/* CONTENT */}
        <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
          <div className=''>
            <div className=' relative flex min-h-screen'>
              {/* CARDSSSS */}
              <div className='min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 '>
                {/* <Heading2 /> */}
                <div className='mb-8 lg:mb-3'>
                  <HeaderFilter
                    tabActive={"All"}
                    subHeading={subHeading}
                    tabs={tabs}
                    heading={heading}
                    onClickTab={() => { }}
                  />
                </div>

                <div className='grid grid-cols-1 gap-8'>
                  {currentProperties?.map((item: any) => (
                    <>
                      {loading ? (
                        <div role='status' className='grid grid-cols-1 gap-8'>
                          <div className='col-span-1 p-4 animate-pulse md:p-6 mr-4'>
                            <div className='grid grid-cols-2'>
                              <div className='col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded'>
                                <svg
                                  className='w-12 h-12 text-gray-200 dark:text-gray-600'
                                  xmlns='http://www.w3.org/2000/svg'
                                  aria-hidden='true'
                                  fill='currentColor'
                                  viewBox='0 0 640 512'
                                >
                                  <path d='M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z' />
                                </svg>
                              </div>
                              <div className='col-span-1 flex flex-col justify-start'>
                                <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                                <div className='h-2 bg-gray-200 rounded-full mt-7'></div>
                              </div>
                            </div>
                            <span className='sr-only'>Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={item.id}
                          onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                          onMouseLeave={() => setCurrentHoverID((_) => -1)}
                        >
                          <StayCardH data={item} />
                        </div>
                      )}
                    </>
                  ))}
                </div>
                <div className="py-2">
                  <PaginationCheck
                    currentPage={currentPage}
                    consultantsPerPage={propertiesPerPage}
                    totalConsultants={properties.length}
                    paginate={setCurrentPage}
                    text={'Properties'}
                  />
                </div>
                {/* <div className='flex mt-16 mb-10 justify-center items-center'>
                  <div className='md:col-span-12  text-center md::col-span- lg:::col-span1-center'>
                    <nav>
                      <ul className='inline-flex items-center -space-x-px'>
                        <li>
                          <button
                            onClick={() => setPageNumber(pageNumber - 1)}
                            className='w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 border bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-[#223A64] dark:hover:border-[#223A64] hover:bg-[#e31414] dark:hover:bg-[#e31414]'
                            disabled={pageNumber === 0}
                          >
                            <i className='las la-angle-left text-xl'></i>
                          </button>
                        </li>
                        <Pagination />
                        <li>
                          <button
                            onClick={() => setPageNumber(pageNumber + 1)}
                            className='w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 border bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-[#223A64] dark:hover:border-[#223A64] hover:bg-[#e31414] dark:hover:bg-[#e31414]'
                          >
                            <i className='las la-angle-right text-xl'></i>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div> */}
              </div>
              <div
                className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
                  }`}
              >
                {showFullMapFixed && (
                  <ButtonClose
                    onClick={() => setShowFullMapFixed(false)}
                    className='bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10'
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* SIDEBAR */}
        <div className='block px-5 flex-grow mt-14 lg:mt-0'>
          <div className='sticky top-24'>
            <div className='aspect-w-8 aspect-h-4 mb-5 w-full gap-4 h-96'>
              <InstagaramFeeds />
              <div className="hidden rounded-xl bg-[url('https://cdn4.vectorstock.com/i/1000x1000/50/68/house-background-vector-745068.jpg')] bg-cover  bg-wreap bg-center">
                <div className='rounded-xl'>
                  <a href='#' className='block p-6 max-w-sm rounded-md'>
                    <h5 className='mb-2 text-2xl font-bold text-white tracking-wide'>
                      Experience Search 2.0
                    </h5>
                    <p className='font-normal text-lg text-white py-10 mb-20'>
                      Find homes by commute time
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className='mb-5'>
              <div className='relative px-2 flex-grow mt-14 lg:mt-8'>
                <div className='top-24'>
                  <div className='rounded-lg bg-[#345886] text-center pt-7 pb-6 shadow-md'>
                    <div className='text-2xl font-semibold flex justify-center items-center text-white'>
                      <img src={Imlaak} className='w-20' alt='' />
                    </div>
                    <form className='pt-5'>
                      <input
                        type='text'
                        className='bg-white rounded outline-none border-0 w-10/12'
                        placeholder='Name'
                      />
                      <input
                        type='email'
                        className='mt-3 bg-white rounded outline-none border-0 w-10/12'
                        placeholder='Email'
                      />
                      <button className='mt-5 bg-white shadow-lg text-black py-1.5 px-7 rounded-xl hover:bg-white hover:text-[#223A64] hover:font-semibold'>
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default SalePage
