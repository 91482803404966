import React from 'react'
import Black from '../../images/black_square.png'
import White from '../../images/white_square.png'

const TransactionsPolicy = () => {
    return (
        <>
            <div className="container pt-5">
                <h2 className='bg-[#345886] text-white text-base font-semibold font-sans tracking-wide text-center rounded capitalize py-2'>Kindly read our transaction policy in detail before you invest through us</h2>
                <div className="border rounded-md pt-3 my-5 px-3 shadow-md">
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>All tokens and any money deposited for your transfer or paying of taxes/transfer fee must follow official imlaak channels and should only be deposited through cheque/pay order or cash.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>All tokens deposited with imlaak are conditional till seller agrees and approves the transaction.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>All tokens received against any kind of affidavit files are conditional till the intimation letter is received.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>File prices are volatile and may vary in hours therefore Imlaak is not responsible if the seller / investor of an affidavit file backs out due to any reason to give the file on the decided price and will only be liable to return the actual token amount back to the buyer.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>You must take a signed receipt from an authorized person when giving a token or against money deposited for your transfer or paying of taxes/transfer fee for any transaction, any receipt without signatures of the issuing authority will not be entertained.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>Token and any money deposited for your transfer or paying of taxes/transfer fee should only be handed over at our office or sent to our account directly.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>Please do not deposit any token money or any money deposited for your transfer or paying of taxes/transfer fee in any individual account as Imlaak.com will not be held responsible for any transaction done without following an official channel.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>Imlaak.com is not responsible for any fraud or mistake done by any Bank, society or institute involved in the transaction.</span>
                    </p>

                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>Imlaak.com will also not be held responsible for any token money withheld by the seller due to any reason.</span>
                    </p>
                </div>
                <div className="border rounded-md pt-3 my-5 px-3 shadow-md">
                    <h2 className='dark:text-white text-base font-bold font-sans tracking-wide text-center rounded capitalize py-2'>Authorized Persons for Token/Money Receipts</h2>
                    <p className="dark:text-white">People authorized to issue token receipts at imlaak are :</p>
                    <div className="lg:w-2/5 md:w-2/5 w-auto">
                        <div className="grid lg:grid-cols-2 grid-cols-1">
                            <div className="col-span-1">
                                <div className="dark:text-white flex justify-between">
                                    <p className="text-sm dark:text-white flex justify-start" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                                        <span className='pl-2 font-medium'>Mr. Shahnawaz Yaqub Bhatti</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-1 lg:mt-0 md:-mt-[5%] -mt-[5%] lg:pl-0 md:pl-3 pl-3">
                                <p className=""><a href='tel:+923331717170' className='font-semibold'> +92 333 1717170</a></p>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1">
                            <div className="col-span-1">
                                <div className="dark:text-white flex justify-between">
                                    <p className="text-sm dark:text-white flex justify-start" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                                        <span className='pl-2 font-medium'>Mr. Saim Qureshi</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-1 lg:mt-0 md:-mt-[5%] -mt-[5%] lg:pl-0 md:pl-3 pl-3">
                                <p className=""><a href='tel:+923214224878' className='font-semibold'> +92 321 4224878</a></p>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1">
                            <div className="col-span-1">
                                <div className="dark:text-white flex justify-between">
                                    <p className="text-sm dark:text-white flex justify-start" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                                        <span className='pl-2 font-medium'>Mr. Ishfaq Bhatti</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-1 lg:mt-0 md:-mt-[5%] -mt-[5%] lg:pl-0 md:pl-3 pl-3">
                                <p className=""><a href='tel:+923222237332' className='font-semibold'> +92 322 2237332</a></p>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1">
                            <div className="col-span-1">
                                <div className="dark:text-white flex justify-between">
                                    <p className="text-sm dark:text-white flex justify-start" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                                        <span className='pl-2 font-medium'>Mr. Muhammad Umair</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-span-1 lg:mt-0 md:-mt-[5%] -mt-[5%] lg:pl-0 md:pl-3 pl-3">
                                <p className=""><a href='tel:+923331616160' className='font-semibold'> +92 333 1616160</a></p>
                            </div>
                        </div>
                    </div>
                    <p className="text-sm dark:text-white flex justify-start py-2 lg:w-3/4 md:w-3/4 w-auto" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        Imlaak.com is not responsible for any tokens handed over to anyone except the persons mentioned above or deposited in any other bank account as mentioned below : Account Name: imlaak real estate Bank : Meezan Bank Account No : 0002080103018118 Swift code: MEZN PKKA Account No: Please contact the authorized persons for any further details.
                    </p>
                </div>
                <div className="border rounded-md pt-3 my-5 px-3 shadow-md">
                    <h2 className='dark:text-white text-base font-bold font-sans tracking-wide text-center rounded capitalize py-2'>Commission Policy</h2>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>At imlaak we believe in fair and transparent transactions and strictly adhere to 1% fixed service charges.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>There will be no discounts in our services charges.</span>
                    </p>
                    <p className="text-sm dark:text-white flex justify-start py-2" style={{ fontFamily: "Verdana, Geneva, sans-serif", textAlign: "justify" }}>
                        <img className='block dark:hidden w-2 h-2 mt-2' src={Black} alt="" />
                        <img className='dark:block hidden w-2 h-2 mt-2' src={White} alt="" />
                        <span className='pl-2'>If you find any person/agent of imlaak involved in charging you more than 1% without your prior consent or taking a margin please inform us immediately at +92 333 1616160 or +92 333 1717170 so that we can help you to get the refund.</span>
                    </p>
                </div>

                <div className="container py-5 flex justify-center">
                    <p className="text-center font-semibold tracking-wide">Please adhere to the above policy for the safety of your money and smooth conduct of transactions. Kindly read our transaction policy in detail before you invest through us.</p>
                </div>
            </div>
        </>
    )
}

export default TransactionsPolicy