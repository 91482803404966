import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";




export default function PinsPopupModal({
  details,
  open,
  setOpen,
  user,
  deletePin,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  console.log(details, 'aaa')
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      
      <DialogContent sx={{ minWidth: 350 }}>
        <DialogContentText id="alert-dialog-description">

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Plot Details</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      <Table aria-label="simple table" size="small"  sx={{ minWidth: 350 }}  >
        <TableBody>
          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row">Plot No</TableCell>
              <TableCell component="th" align="left">{details?.plotno}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row">Plot Category </TableCell>
              <TableCell component="th" align="left">{details?.plotCat}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row">Plot Unit  </TableCell>
              <TableCell component="th" align="left">{details?.areaCat}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row">Plot Area  </TableCell>
              <TableCell component="th" align="left">{details?.area}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row">Block No </TableCell>
              <TableCell component="th" align="left">{details?.blockNumber}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row">Price </TableCell>
              <TableCell component="th" align="left">{details?.price}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row">Agent Detail </TableCell>
              <TableCell component="th" align="left">{details?.fname} {details?.lname}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row"> Mobile </TableCell>
              <TableCell component="th" align="left"> {details?.contact}</TableCell>
            </Typography>
          </TableRow>

          <TableRow sx={{ "&:last-child th, &:last-child th": { border: 0 } }}>
            <Typography variant="subtitle1">
              <TableCell component="th" scope="row"> Email </TableCell>
              <TableCell component="th" align="left"> {details?.email}</TableCell>
            </Typography>
          </TableRow>
            <TableRow sx={{ border:"none" }}>
              <TableCell sx={{ border:"none" }}>
              {user?.email === details?.email ? (
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => deletePin(details?._id)}
              >
                Delete Plot
              </Button>
            </Box>
          ) : null}
              </TableCell>
            </TableRow>
        </TableBody>
      </Table>



          {/* <Typography variant="subtitle1">
            Plot No : {details?.plotno}
          </Typography>
          <Typography variant="subtitle1">
            Plot Category : {details?.plotCat}
          </Typography>
          <Typography variant="subtitle1">
            Plot Unit : {details?.areaCat}
          </Typography>
          <Typography variant="subtitle1">
            Plot Area : {details?.area}
          </Typography>
          <Typography variant="subtitle1">
            Block No : {details?.blockNumber}
          </Typography>
          <Typography variant="subtitle1">Price : {details?.price}</Typography>
          <Typography variant="subtitle1">
            Agent Detail : {details?.fname} {details?.lname}
          </Typography>
          <Typography variant="subtitle1">Contact: </Typography>
          <Typography variant="subtitle1">Mobile: {details?.contact}</Typography>
          <Typography variant="subtitle1">Email: {details?.email}</Typography>
          {user.email === details?.email ? (
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                color="error"
                onClick={() => deletePin(details?._id)}
              >
                Delete Plot
              </Button>
            </Box>
          ) : null} */}




        </DialogContentText>
      </DialogContent>
      

    </Dialog>
  );
}
