import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Slide } from "@mui/material";
import { Link } from "react-router-dom";
import { API } from "utils/config";

export default function FeaturedCard({ get }: any) {
  return (
    <>
      <Link
        to={`/developer-detail/${get?._id}`}
      >
        <div className="shadow-xl flex justify-center border lg:h-[25vh] md:h-[16vh] h-20vh w-full overflow-hidden align-middle rounded-2xl cursor-pointer">
          <div className="p-5 align-middle">
            <img src={`${API}/uploads/${get?.developer}`} className="align-middle" style={{ width: '250px', height: '150px' }} alt="" />
          </div>
        </div>
      </Link>
    </>
  );
}
