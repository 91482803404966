import React from "react";
import Agency from "../../images/bg/agency.svg";

const EventDetail = () => {
  return (
    <>
      {" "}
      <div className="relative sm:flex-row flex-col overflow-hidden lg:h-[60vh]">
        <div className="sm:mx-7 mx-0 md:mx-0">
          <div className="relative z-10 bg-red-50 dark:bg-red-50 lg:h-[80vh] lg:rounded-l-xl md:rounded-l-xl lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-red-50 lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8"></div>
            <main className="mx-auto max-w-7xl px-4 lg:px-8 sm:py-10 py-5">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-bold tracking-tight text-slate-900 text-center">
                  <span className="block xl:inline">Imlaak Event 2022</span>{" "}
                </h1>
                <p className="mt-3 sm:pl-5 pl-0 mx-auto sm:mt-5 sm:max-w-xl md:mt-5">
                  <span className="block mt-2 text-neutral-600 dark:text-neutral-400 text-base text-justify">
                    Nevertheless, Imlaak.pk is an all-way round in terms of real
                    estate concerns and needs, however, we do not merely
                    virtually exist rather our professional team of property
                    experts, real estate investment consultants, and experienced
                    builders are also there to get you going ahead with the real
                    estate projects and find a trusted real estate agent in the
                    single visual meeting.
                  </span>
                </p>
                <div className="mt-5 flex justify-center">
                  <div className="rounded-md shadow">
                    <button className="flex w-full py-2.5 items-center justify-center rounded-md border border-transparent bg-red-300 px-8 text-base font-medium text-white hover:bg-red-400 md:px-10">
                      More Detail
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 rounded-xl lg:right-0 mx-8 lg:block md:hidden hidden">
          <img
            className="h-56 w-64 object-cover rounded-xl sm:h-72 md:h-84 lg:h-full lg:w-full"
            src={Agency}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default EventDetail;
