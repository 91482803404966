import { Popover } from "@headlessui/react";
import React, { Fragment } from "react";
import { Transition } from "semantic-ui-react";
import AgentMoreFilter from "./AgentMoreFilter";
import BathEstimateFormInput from "./BathEstimateFormInput";
import BedsEstimateFormInput from "./BedsEstimateFormInput";
import BuyRentEstimateFilter from "./BuyRentEstimateFilter";
import PriceRangeInput from "./PriceRangeInput";
import ResidentialCommercialFilter from "./ResidentialCommercialFilter";

const AgentFilters = () => {
  return (
    <>
      <div className="grid lg:grid-cols-6 grid-cols-8 md:grid-cols-8 gap-2 lg:pt-5 pt-3 pb-3">
        <div className="lg:col-span-1 col-span-2">
          <div className="relative !h-12 border border-gray-300 p-3 rounded-md text-center">
            <BuyRentEstimateFilter />
          </div>
        </div>
        <div className="col-span-2 relative">
          <input
            className="placeholder:text-slate-800 block bg-white p-3 !h-12 w-full border border-gray-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-gray-500 focus:ring-gray-500 focus:ring-1 sm:text-sm"
            placeholder="Enter Location"
            type="text"
            name="search"
          />
        </div>
        <div className="lg:col-span-1 col-span-2">
          <div className="bg-white !h-12 rounded-md dark:bg-slate-800 pt-3 px-3 border border-gray-300 overflow-hidden">
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={` ${
                      open
                        ? "text-black focus:outline-none w-full flex justify-between"
                        : "flex justify-between w-full"
                    }`}
                  >
                    <div className="flex relative justify-between w-full">
                      <button className="inline-block align-middle h-full text-sm">
                        Property Type
                      </button>
                      <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                    </div>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute bg-white p-4 z-50 mt-5 shadow-xl border lg:-ml-3 md:-ml-3 -ml-52 w-96 rounded-md">
                      <ResidentialCommercialFilter />
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
        <div className="col-span-1 relative lg:block hidden">
          <div className="px-3 !h-12 bg-white py-3 dark:bg-slate-800 border border-gray-300 rounded-md overflow-hidden">
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={` ${
                      open
                        ? "text-black focus:outline-none w-full flex justify-between"
                        : "flex justify-between w-full"
                    }`}
                  >
                    <div className="flex justify-between w-full">
                      <button className="inline-block align-middle lg:text-base text-xs">
                        Beds & Baths
                      </button>
                      <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                    </div>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute pb-4 bg-white overflow-hidden z-50 lg:mt-5 mt-2 lg:w-96 w-72 right-0 shadow-xl border rounded-xl">
                      <div className="pt-2 px-2 relative">
                        <span className="px-4 font-semibold">Beds</span>
                        <BedsEstimateFormInput />
                      </div>
                      <div className="px-2 relative">
                        <span className="px-4 font-semibold">Baths</span>
                        <BathEstimateFormInput />
                      </div>
                      <div className="flex justify-between px-3 mt-2">
                        <button className="rounded-md bg-white text-center text-[#223A64] border-2 border-[#223A64] mx-1 w-6/12">
                          RESET
                        </button>
                        <button className="rounded-md bg-[#223A64] text-center mx-1 w-6/12 py-2 text-white">
                          DONE
                        </button>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-1 lg:block hidden relative ">
          <div className="rounded-md border border-gray-300 text-xs lg:text-base overflow-hidden">
            <PriceRangeInput />
          </div>
        </div>
        <div className="col-span-2 block lg:hidden relative">
          <AgentMoreFilter />
        </div>
      </div>
    </>
  );
};

export default AgentFilters;
