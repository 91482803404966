import { ProjectContext } from "context/project/ProjectContext"
import { BuyRentContext } from "context/property/BuyRentContext"
import { useContext, useEffect, useState } from "react"
// import "./styles.css";
var data = require("../../data/jsons/citynames.json");

export default function CityNameInput() {
    const { allCites } = useContext(ProjectContext)
    const { dispatchFilter } = useContext(BuyRentContext)
    const [value, setValue] = useState("")

    const onChange = (event) => {
        setValue(event.target.value)
    }

    const onSearch = (searchTerm) => {
        setValue(searchTerm)
        dispatchFilter({
            type: "updatePropertyCity",
            value: searchTerm,
        })
    }

    return (
        <>
            <div className=''>
                <div className='search-container'>
                    <div className='search-inner'>
                        <input
                            type='text'
                            placeholder='Enter City...'
                            className='dark:bg-slate-800 bg-white border-0 !h-12 w-full rounded-md'
                            value={value}
                            onChange={onChange}
                        />
                        <button onClick={() => onSearch(value)}></button>
                    </div>
                    <div className='absolute bg-white w-full z-40 shadow-xl  rounded-md mt-2 dropdown'>
                        {data
                            .filter((item) => {
                                const searchTerm = value.toLowerCase()
                                const fullName = item?.cityName?.toLowerCase()

                                return (
                                    searchTerm &&
                                    fullName.startsWith(searchTerm) &&
                                    fullName !== searchTerm
                                )
                            })
                            .slice(0, 10)
                            .map((item) => (
                                <div
                                    onClick={() => onSearch(item?.cityName)}
                                    className='dropdown-row px-3 border-b border-[#345886] cursor-pointer py-3'
                                    key={item?._id}
                                >
                                    {item?.cityName}
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}
