import React from "react";

const CallModal = ({ showCallModal, setShowCallModal, CallContent = '92 300 000000' }) => {
    return (
        <div>
            {
                showCallModal ? (
                    <>
                        <div className="w-[1200px] z-50 absolute">
                            <div className="fixed inset-0 bg-zinc-800 bg-opacity-90 transition-opacity"></div>
                            <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="w-auto my-6 mx-auto max-w-3xl">
                                    <div className="border-t-4 border-[#0c4e6c] rounded-md shadow-lg relative w-80 bg-white outline-none focus:outline-none">
                                        <button
                                            className="bg-transparent border-0 absolute top-0 right-0 m-0.5"
                                            onClick={() => setShowCallModal(false)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0c4e6c" class="w-6 h-6">
                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className="px-5 pt-3 pb-5">
                                            <div className="text-center py-4">
                                                <h3 className="font-semibold border-b-[1.8px] border-[#0c4e6c] text-[#0c4e6c]">Call Consultant</h3>
                                            </div>
                                            <div className="flex justify-start">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0c4e6c" class="w-6 text-[#0c4e6c] h-6">
                                                    <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
                                                </svg>
                                                <span className="pl-2"><a href={`tel:+${CallContent}`}>+{CallContent}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div>
    )
}

export default CallModal