import React, { useState } from 'react'
import CareerDescription from './CareerDescription';
import CareerForm from './CareerForm';

const CareerDetailPageTabs = (props) => {
    const { propsDescription } = props;
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <>
            <div className="text-center flex justify-center">
                <button
                    className={toggleState === 1 ? "text-[#223A64] border-b-2 border-red-400 px-5 mx-5" : "text-center border-b-2 border-gray-400 px-5 py-1.5 mx-5 cursor-pointer text-base text-black "}
                    onClick={() => toggleTab(1)}
                >
                    Overview
                </button>
                <button
                    className={toggleState === 2 ? "text-[#223A64] border-b-2 border-red-400 px-5 mx-5" : "text-center border-b-2 border-gray-400 px-5 py-1.5 mx-5 cursor-pointer text-base text-black "}
                    onClick={() => toggleTab(2)}
                >
                    Application Form
                </button>
            </div>
            <div className="container">
                <div className={toggleState === 1 ? "block text-left pt-7" : "hidden"} >
                    <CareerDescription propsDescription={propsDescription} onClickText={() => toggleTab(2)} />
                </div>

                <div className={toggleState === 2 ? "block text-left pt-7" : "hidden"} >
                    <CareerForm />
                </div>
            </div>
        </>
    )
}

export default CareerDetailPageTabs