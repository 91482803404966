import pinContext from 'context/pin/pinContext';
import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CareerDetailPageTabs from './CareerDetailPageTabs';

export interface CareerDetailPageProps {
    className?: string;
}



const CareerDetailPage: FC<CareerDetailPageProps> = ({ className, }) => {
    type QuizParams = {
        id: any;
    };
    let { id } = useParams<any>();
    const { singleJob, getByCareerId, loading, setLoading } = useContext(pinContext)
    useEffect(() => {
        getByCareerId(id)
    }, []);
    useEffect(() => {
    }, [singleJob])
    return (
        <>
            {singleJob?.id?.map((item: any, index: any) => (
                <div className="container py-5" key={index}>
                    <div className="text-center text-xl font-semibold capitalize">{item.jobTitle}</div>
                    <div className="text-center pt-3">
                        (<span className='text-base mx-3 font-medium capitalize'>{item.location} . </span>
                        <span className='text-base mx-3 font-medium capitalize'>{item.department} . </span>
                        <span className='text-base mx-3 font-medium capitalize'>{item.timeType}  </span>)
                    </div>
                </div>
            ))}
            <hr />
            <div className="pt-3 text-center">
                {singleJob?.id?.map((item: any, index: any) => (
                    <CareerDetailPageTabs key={index} propsDescription={item.description} />
                ))}
            </div>
        </>
    )
}

export default CareerDetailPage