import React, { FC, useState } from "react";

export interface BedAndBathProps {
  haveDefaultValue?: boolean;
  handleBedsChange?: any;
  dispatchFilter?: any;
  filter?: any;
}

const BedsEstimateFormInput: FC<BedAndBathProps> = ({
  haveDefaultValue,
  handleBedsChange,
  dispatchFilter,
  filter,
}) => {
  const [BedsInput, setBedsInput] = useState<
    "Studio" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "8+" | ""
  >(filter?.bedrooms);

  const handleBedsClick = (beds: any) => {
    setBedsInput(beds);
    if (handleBedsChange) {
      handleBedsChange(beds);
    }
    dispatchFilter({
      type: "updatePropertyBedrooms",
      value: beds,
    });
  };

  return (
    <div className="px-4 flex flex-row flex-wrap">
      <div
        className={`py-1 px-3 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "Studio"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("Studio")}
      >
        Studio
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "1"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("1")}
      >
        1
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "2"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("2")}
      >
        2
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "3"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("3")}
      >
        3
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "4"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("4")}
      >
        4
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "5"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("5")}
      >
        5
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "6"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("6")}
      >
        6
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "7"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("7")}
      >
        7
      </div>
      <div
        className={`py-1 px-5 flex items-center rounded-full font-medium text-sm cursor-pointer mr-1 my-1 ${
          BedsInput === "8+"
            ? "bg-[#fae1e1] text-[#223A64] text-white"
            : "border border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={(e) => handleBedsClick("8")}
      >
        8+
      </div>
    </div>
  );
};

export default BedsEstimateFormInput;
