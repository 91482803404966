import React, {
  FC,
  useState,
  ReactNode,
  Fragment,
  useContext,
  useEffect,
} from "react";
import StayCardH from "components/StayCardH/StayCardH";
import { Popover, Transition } from "@headlessui/react";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "../ListingStayPage/TabFiltersNew";
import HeaderFilter from "./HeaderFilter";
import { StayDataType } from "data/types";
import ResidentialCommercialFilter from "components/HeroSearchForm/ResidentialCommercialFilter";
import BedsEstimateFormInput from "components/HeroSearchForm/BedsEstimateFormInput";
import BathEstimateFormInput from "components/HeroSearchForm/BathEstimateFormInput";
import PriceRangeInput from "components/HeroSearchForm/PriceRangeInput";
import BuyRentEstimateFilter from "components/HeroSearchForm/BuyRentEstimateFilter";
import AgentMoreFilter from "components/HeroSearchForm/AgentMoreFilter";
import { BuyRentContext } from "context/property/BuyRentContext";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

export interface EstimationSearchProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const EstimationSearch: FC<EstimationSearchProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Properties listed in Pakistan",
  subHeading = "",
  headingIsCenter,
  tabs = ["All", "Furnished", "Unfurnished"],
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  const { getMyProperties, loading, properties }: any =
    useContext(BuyRentContext);
  useEffect(() => {
    // debugger
    getMyProperties();
  }, []);
  //   useEffect(() => {
  console.log(properties, "properties");
  //   }, [properties])

  return (
    <>
      <div className="container my-2">
        <div className="grid lg:grid-cols-5 md:grid-cols-5 grid-cols-4 gap-2">
          <div className="col-span-1">
            <div className="relative !h-12 border border-gray-300 p-3 rounded-md text-center">
              <BuyRentEstimateFilter />
            </div>
          </div>
          <div className="col-span-2">
            <div className="filter-search-form relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </div>
              <input
                className="placeholder:text-slate-800 block bg-white lg:py-3.5 md:py-3.5 py-3 w-full border border-gray-300 rounded-md pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-300 focus:ring-1 sm:text-sm"
                placeholder="Enter Keyword"
                type="text"
                name="search"
              />
            </div>
          </div>
          <div className="col-span-1 lg:block md:block hidden">
            <div className="bg-white !h-12 rounded-md py-3 px-3 border border-gray-300">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={` ${
                        open
                          ? "text-black focus:outline-none w-full flex justify-between"
                          : "flex justify-between w-full"
                      }`}
                    >
                      <div className="flex relative justify-between w-full">
                        <button className="inline-block align-middle h-full lg:text-base text-sm">
                          Property Type
                        </button>
                        <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute bg-white p-4 z-50 mt-5 -ml-3 shadow-xl border rounded-md">
                        <ResidentialCommercialFilter />
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
          <div className="col-span-1 !h-12 bg-white py-3 border border-gray-300 rounded-md">
            <div className="relative">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={` ${
                        open
                          ? "text-black focus:outline-none w-full flex justify-between"
                          : "flex justify-between w-full"
                      }`}
                    >
                      <div className="flex justify-between w-full px-3">
                        <button className="align-middle h-full lg:text-sm md:text-[14px] text-xs">
                          Beds & Baths
                        </button>
                        <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute pb-4 bg-white overflow-hidden z-40 mt-5 w-72 shadow-xl border right-0 rounded-md">
                        <div className="pt-2 px-2 relative">
                          Beds
                          <BedsEstimateFormInput />
                        </div>
                        <div className="px-2 relative">
                          Baths
                          <BathEstimateFormInput />
                        </div>
                        <div className="flex justify-between px-3 mt-2">
                          <button className="rounded-md bg-white text-center text-[#223A64] border-2 border-[#223A64] mx-1 w-6/12">
                            RESET
                          </button>
                          <button className="rounded-md bg-[#223A64] text-center mx-1 w-6/12 py-2 text-white">
                            DONE
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-3" />
      <main className="container relative z-10 mt-7 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          <div className="">
            <div className=" relative flex min-h-screen">
              {/* CARDSSSS */}
              <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
                {/* <Heading2 /> */}
                <div className="mb-8 lg:mb-11 ">
                  <HeaderFilter
                    tabActive={"All"}
                    subHeading={subHeading}
                    tabs={tabs}
                    heading={heading}
                    onClickTab={() => {}}
                  />
                </div>
                {loading ? (
                  <div role="status" className="grid grid-cols-1 gap-8">
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <div className="grid grid-cols-1 gap-8">
                  {properties?.property?.map((item: any) => (
                    <div
                      key={item.id}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <StayCardH data={item} />
                    </div>
                  ))}
                </div>
                <div className="flex mt-16 mb-10 justify-center items-center">
                  <Pagination />
                </div>
              </div>

              {/* {!showFullMapFixed && (
                <div
                  className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
                  onClick={() => setShowFullMapFixed(true)}
                >
                  <i className="text-lg las la-map"></i>
                  <span>Show map</span>
                </div>
              )} */}

              {/* MAPPPPP */}
              <div
                className={`xl:flex-grow xl:static xl:block ${
                  showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
                }`}
              >
                {showFullMapFixed && (
                  <ButtonClose
                    onClick={() => setShowFullMapFixed(false)}
                    className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="block px-5 flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">
            <div className="grid md:grid-cols-1 aspect-w-8 aspect-h-4 lg:grid-cols-1 mb-5 gap-4 ">
              <div className="rounded-md bg-[url('https://cdn4.vectorstock.com/i/1000x1000/50/68/house-background-vector-745068.jpg')] bg-cover bg-wreap bg-center">
                <div className="rounded-xl">
                  <a href="#" className="block p-6 max-w-sm rounded-md">
                    <h5 className="mb-2 text-2xl font-bold tracking-wide text-white">
                      Experience Search 2.0
                    </h5>
                    <p className="font-normal text-white py-10 mb-20">
                      Find new Properties in Pakistan
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-1 aspect-w-8 aspect-h-4 lg:grid-cols-1 mb-5  gap-4 ">
              <div className="rounded-md bg-[url('https://cdn4.vectorstock.com/i/1000x1000/50/68/house-background-vector-745068.jpg')] bg-cover bg-wreap bg-center">
                <div className="rounded-xl">
                  <a href="#" className="block p-6 max-w-sm rounded-md">
                    <h5 className="mb-2 text-2xl font-bold tracking-wide text-white">
                      Search for Projects
                    </h5>
                    <p className="font-normal text-white py-10 mb-20">
                      Find New Projects of Pakistan
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EstimationSearch;
