import React, { FC } from 'react'
import Input from 'shared/Input/Input';

export interface ForgetProps {
    onclickBtn: any;
    inputColor: any;
    buttonColor: any;
}
const Forget: FC<ForgetProps> = ({ onclickBtn, buttonColor, inputColor }) => {
    return (
        <>
            <div className="relative flex flex-col">
                <span className={`text-${inputColor} capitalize text-left pb-2`}>Please input your email</span>
                <form action="w-full" className="">
                    <Input type="text" className="bg-white rounded text-gray-600 w-full text-sm py-2.5 px-5 outline-hidden focus:border-gray-400" placeholder="Email*" />
                    <button className="py-2.5 mt-2 w-full uppercase text-white bg-[#345886] hover:bg-[#1f345c] rounded">Submit</button>
                </form>
            </div>
            <div className="pt-2 pb-0 text-right">
                <button className={`text-sm capitalize text-${buttonColor}`} onClick={onclickBtn}>back to login</button>
            </div>
        </>
    )
}

export default Forget