import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Social from "./Social";
import Svg from "./Svg";

export interface PageTeamProps {
  className?: string;
}
const PageTeam: FC<PageTeamProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Imlaak</title>
      </Helmet>
      <div className="mb-10 lg:mb-5">
        <div className="container max-w-7xl mx-auto">
          <section className="pt-5 lg:pt-[50px] pb-10 lg:pb-20">
            <div className="container">
              <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4">
                  <div className="text-center mx-auto mb-[60px] max-w-[510px]">
                    <span className="font-semibold text-lg text-primary mb-2 block">
                      Our Team
                    </span>
                    <h2 className=" font-bold text-3xl sm:text-4xl md:text-[40px] text-dark mb-4 ">
                      Our Awesome Team
                    </h2>
                    <p className="text-base text-body-color">
                      {/* There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form. */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-center z-0  reletive	 -mx-5">
                <div className="w-full md:w-1/2 xl:w-1/4 px-4 ">
                  <div className="max-w-[370px] w-full mx-auto mb-10">
                    <div className="relative rounded-lg shadow ">
                      <img
                        src="https://www.imlaak.com/wp-content/uploads/2021/11/PSX_20160504_125032-1024x770-1-150x150.jpg"
                        alt="image"
                        className="rounded-lg w-full "
                      />
                      <div className="absolute w-full bottom-[-40px] left-0  text-center">
                        <div className="  bg-white  relative  rounded-lg shadow  overflow-hidden  mx-[3px] py-2 px-3 dark:text-neutral-900 ">
                          <h3 className="text-base font-semibold text-dark">
                            Shahnawaz Bhatti
                          </h3>
                          <p className="text-sm text-body-color">
                            Founder & CEO
                            <Social />
                          </p>
                          <div>
                            <span className="absolute left-0 bottom-0">
                              <svg
                                width="61"
                                height="30"
                                viewBox="0 0 61 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="45"
                                  r="45"
                                  fill="#233A65"
                                  fill-opacity="1"
                                />
                              </svg>
                            </span>
                            <span className="absolute top-0 right-0">
                              <Svg />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 xl:w-1/4 px-4 ">
                  <div className="max-w-[370px] w-full mx-auto mb-10">
                    <div className="relative rounded-lg shadow ">
                      <img
                        src="https://www.imlaak.com/wp-content/uploads/elementor/thumbs/WhatsApp-Image-2021-09-08-at-2.52.18-PM-525x328-1-pjv7b611ces3k7djucsk8k5p4nhuystn1xjauqi44c.jpeg"
                        alt="image"
                        className="rounded-lg w-full "
                      />
                      <div className="absolute w-full bottom-[-40px] left-0  text-center">
                        <div className="  bg-white  relative  rounded-lg shadow  overflow-hidden  mx-[3px] py-2 px-3 dark:text-neutral-900 ">
                          <h3 className="text-base font-semibold text-dark">
                            Zainab Rabbani
                          </h3>
                          <p className="text-sm text-body-color">
                            Consultant and Marketing Manager
                            <Social />
                          </p>
                          <div>
                            <span className="absolute left-0 bottom-0">
                              <svg
                                width="61"
                                height="30"
                                viewBox="0 0 61 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="45"
                                  r="45"
                                  fill="#233A65"
                                  fill-opacity="1"
                                />
                              </svg>
                            </span>
                            <span className="absolute top-0 right-0">
                              <Svg />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 xl:w-1/4 px-4">
                  <div className="max-w-[370px] w-full mx-auto mb-10">
                    <div className="relative rounded-lg shadow  ">
                      <img
                        src="https://www.imlaak.com/wp-content/uploads/elementor/thumbs/pp-525x328-1-pjv4cov7610see1flbvysffyncb114492x57scdkyk.jpg"
                        alt="image"
                        className="w-full rounded-lg"
                      />
                      <div className="absolute w-full bottom-[-40px] left-0 text-center">
                        <div className="  bg-white  relative  rounded-lg shadow  overflow-hidden  mx-[3px] py-2 px-3 dark:text-neutral-900 ">
                          <h3 className="text-base font-semibold text-dark">
                            Ali Bukhari
                          </h3>
                          <p className="text-sm text-body-color">
                            Marketing Manager
                            <Social />
                          </p>
                          <div>
                            <span className="absolute left-0 bottom-0">
                              <svg
                                width="61"
                                height="30"
                                viewBox="0 0 61 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="45"
                                  r="45"
                                  fill="#233A65"
                                  fill-opacity="1"
                                />
                              </svg>
                            </span>
                            <span className="absolute top-0 right-0">
                              <Svg />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 xl:w-1/4 px-4">
                  <div className="max-w-[370px] w-full mx-auto mb-10">
                    <div className="relative rounded-lg shadow">
                      <img
                        src="https://www.imlaak.com/wp-content/uploads/elementor/thumbs/ur7-525x328-1-pjv3y5b3fb42rl5xijkrplk7rpzkxpdrexrrh7xv8c.jpg"
                        alt="image"
                        className="w-full rounded-lg"
                      />
                      <div className="absolute w-full bottom-[-40px] left-0 text-center">
                        <div
                          className="
                        bg-white
                        relative
                        rounded-lg
                        overflow-hidden
                        mx-[3px] shadow
                        py-2
                        px-3
                        dark:text-neutral-900
                        "
                        >
                          <h3 className="text-base font-semibold text-dark">
                            Umair Rajpoot
                          </h3>
                          <p className="text-sm text-body-color">
                            BDM (Brokerage)
                            <Social />
                          </p>
                          <div>
                            <span className="absolute left-0 bottom-0">
                              <svg
                                width="61"
                                height="30"
                                viewBox="0 0 61 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="45"
                                  r="45"
                                  fill="#233A65"
                                  fill-opacity="1"
                                />
                              </svg>
                            </span>
                            <span className="absolute top-0 right-0">
                              <Svg />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PageTeam;
