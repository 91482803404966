import { useEffect, useRef, useState } from "react";
var data = require("../../data/jsons/__languageList.json");

export default function LanguageInput({ lang }) {
    const [value, setValue] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1)
    const dropdownRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        }
        // Attach the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Remove the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const onChange = (event) => {
        setValue(event.target.value);
        if (event.target.value.trim() !== "") {
            setIsDropdownVisible(true);
            setHighlightedIndex(-1);
        } else {
            setIsDropdownVisible(false);
        }
    };

    const onSearch = (searchTerm) => {
        setValue(searchTerm)
        lang(searchTerm)
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "ArrowDown") {
                setIsDropdownVisible(true);
                setHighlightedIndex((prevIndex) =>
                    prevIndex < data.filter((item) => item.language_Name.toLowerCase().startsWith(value.toLowerCase())).length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === "ArrowUp") {
                setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
            } else if (e.key === "Enter" && highlightedIndex >= 0) {
                onSearch(data.filter((item) => item.language_Name.toLowerCase().startsWith(value.toLowerCase()))[highlightedIndex].language_Name);
                setHighlightedIndex(-1);
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [data, highlightedIndex, value]);
    return (
        <>
            <div className="" ref={dropdownRef}>
                <div className="search-container">
                    <div className="search-inner">
                        <div className="filter-search-form relative">
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                                </svg>
                            </div>
                            <input className="dark:bg-slate-800 bg-white text-base !h-12 w-full border border-slate-300 dark:border-0 dark:text-white rounded-md pl-5 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-0 sm:text-sm"
                                placeholder="Enter Language" type="text" value={value} onChange={onChange} onFocus={() => setIsDropdownVisible(true)} />
                            <button onClick={() => onSearch(value)}></button>
                        </div>
                    </div>
                    {isDropdownVisible && (
                        <div className="absolute bg-white w-full z-40 shadow-xl rounded-xl mt-2 dropdown">
                            {data
                                .filter((item) => {
                                    const searchTerm = value.toLowerCase();
                                    const fullName = item.language_Name.toLowerCase();

                                    return (
                                        searchTerm &&
                                        fullName.startsWith(searchTerm) &&
                                        fullName !== searchTerm
                                    );
                                })
                                .slice(0, 10)
                                .map((item, index) => (
                                    <div
                                        onClick={() => onSearch(item.language_Name)}
                                        className={`dropdown-row px-5 border-b-[2px] capitalize cursor-pointer dark:text-[#0c4a6e] border-[#0c4a6e] rounded-xl py-2 hover:bg-[#345886] hover:text-white${index === highlightedIndex ? ' text-white bg-[#345886]' : 'bg-white'}`}
                                        key={item.language_Name}
                                        onMouseEnter={() => setHighlightedIndex(index)}
                                    >
                                        {item.language_Name}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
