import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react'

function Defaulthideshow() {
    const [showhide, setShowhide] = useState("no");

    const handleshow = (e: any) => {
        const getshow = e.target.value;
        setShowhide(getshow);

    }
    return (
        <>
            <div className="container bg-red-100">
                <div className="row text-center">
                    <h3 className='mt-3'>Default Radio Button Checked and Show / Hide Div Content</h3>
                    <div className="col-md-5">
                        {showhide}
                        <form className="row g-3">

                            <div className="col-md-12">
                                <label className="form-label"> Username</label>
                                <div className="text-white">
                                    <input type="text" name='user' className='form-control' />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <label className="form-label"> Show Details</label>
                                <div className="text-white">
                                    Yes <input type="radio" name="userdetail" value="yes" onClick={handleshow} />
                                    No <input type="radio" name="userdetail" value="no" checked={showhide === 'no'} onClick={handleshow} />
                                </div>
                            </div>

                            {
                                showhide === 'yes' && (
                                    <div className='col-md-12'>
                                        <label className="form-label"> Address</label>
                                        <div className="text-white">
                                            <input type="text" name='address' className='form-control' />
                                        </div>
                                    </div>
                                )
                            }


                            <div className="col-md-12">
                                <label className="form-label"> </label>
                                <div className="text-dark">
                                    <button name='submit' className='btn btn-success'>Submit</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>



            <div className="container">
                <SectionSliderNewCategories
                    heading="Discover more about Pakistan real estate market"
                    categoryCardType="card7"
                    itemPerRow={5}
                    uniqueClassName="PageHome_s3"
                />
            </div>
        </>
    )
}

export default Defaulthideshow