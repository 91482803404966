import React from "react"
import MyRouter from "routers/index"
import PinProvider from "context/pin/PinState"
import ProjectProvider from "context/project/ProjectContext"
import BuyRentProvider from "context/property/BuyRentContext"

function App() {
  return (
    <div className='bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'>
      <PinProvider>
        <BuyRentProvider>
          <ProjectProvider>
            <MyRouter />
          </ProjectProvider>
        </BuyRentProvider>
      </PinProvider>
    </div>
  )
}

export default App
