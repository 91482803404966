import React, { FC, useContext, useEffect, useState } from "react"
import CommentListing from "components/CommentListing/CommentListing"
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate"
import useWindowSize from "hooks/useWindowResize"
import {
  FocusedInputShape,
} from "react-dates"
import Badge from "shared/Badge/Badge"
import ButtonCircle from "shared/Button/ButtonCircle"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import Input from "shared/Input/Input"
import NcImage from "shared/NcImage/NcImage"
import LikeSaveBtns from "./LikeSaveBtns"
import ModalPhotos from "./ModalPhotos"
import Logo from "shared/Logo/Logo"
import { Link, useParams } from "react-router-dom"
import { BuyRentContext } from "context/property/BuyRentContext"
import { API } from "utils/config"
import CurrencyFormat from "components/HeroSearchForm/CurrencyFormat"
import DummyMain from "../../images/bg/Dummy_property.jpg"
import GallerySlider2 from "components/GallerySlider/GallerySlider2"
import Email from "./Email"
import { ProjectContext } from "context/project/ProjectContext"
// import MobileFooterSticky from "./MobileFooterSticky";

export interface PropertyDetailPageProps {
  className?: string
  isPreviewMode?: boolean
}
function getOrdinalIndicator(n: number): string {
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;

  if (lastTwoDigits > 10 && lastTwoDigits < 20) {
    return 'th';
  }

  switch (lastDigit) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}
const PropertyDetailPage: FC<PropertyDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  type QuizParams = {
    id: any
  }
  let { id } = useParams<any>()

  const { getAllAmenities, amenities }: any = useContext(ProjectContext);
  const { getPropertyById, propertyDetails }: any = useContext(BuyRentContext)
  const [isOpen, setIsOpen] = useState(false)
  const [imageLinks, setImageLinks] = useState([])
  const [openFocusIndex, setOpenFocusIndex] = useState(0)
  const [features, setFeatures] = useState<any>([]);

  useEffect(() => {
    getPropertyById(id)
    getAllAmenities();
  }, [])

  // UseEffect Function to get Images Link after index 0
  useEffect(() => {
    const imageLinks = propertyDetails && propertyDetails.propertyImages
      ? propertyDetails.propertyImages.slice(1)
      : [];
    if (propertyDetails && propertyDetails?.propertyImages?.length > 2) {
      const getImageLinks = propertyDetails.propertyImages.slice(1);
      setImageLinks(getImageLinks)
    }

    const mainAmenitiesList = amenities?.map((amenity: any) => ({
      categories: amenity?.categories?.[0],
      titles: amenity?.list?.map((item: any) => item.title)
    }));
    const currentAmenitiesList = propertyDetails?.amenities?.map((item: any) => item?.label);

    const result = mainAmenitiesList?.map((amenity: any) => {
      const matchedLabels = amenity?.titles?.filter((title: any) => currentAmenitiesList?.includes(title));
      return { category: amenity?.categories, labels: matchedLabels };
    })?.filter((item: any) => item?.labels?.length > 0);

    setFeatures(result)
  }, [propertyDetails])
  const consultantEmail = propertyDetails?.landlordId?.consultant?.email
  const consultantData = propertyDetails?.landlordId?.consultant
  const windowSize = useWindowSize()

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34
    }
    if (windowSize.width <= 500) {
      return undefined
    }
    if (windowSize.width <= 1280) {
      return 56
    }
    return 48
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true)
    setOpenFocusIndex(index)
  }

  const handleCloseModal = () => setIsOpen(false)

  //Start dynamic image gallery
  const renderSection1 = () => {
    return (
      <div className='listingSection__wrap !space-y-6'>
        {/* {propertyDetails?.map((item: any) => ( */}
        <div className='flex flex-col items-left'>
          <div>
            <span className='text-[#223A64] font-bold dark:text-neutral-400 capitalize'>
              <span className='lg:text-2xl md:text-2xl text-lg text-black font-semibold capitalize ease-in-out duration-500 dark:text-white'>
                <br />
                {propertyDetails?.title} in&nbsp;
                {propertyDetails?.ProjectSelecting === 'Registered_project' ? (
                  <>
                    {propertyDetails?.projectName?.projectName}{" "}
                  </>
                ) : (
                  <>
                    {" "}
                  </>
                )}
                <br />
              </span>
              {/* By
                <span className="text-[#345886]">
                  {item.user.agencyName}
                </span> */}
            </span>
          </div>
          <br />
          <div className="grid grid-cols-2 bg-gray-300 py-2 rounded-md dark:bg-gray-700">
            <div className='pl-3 sm:col-span-1 col-span-1 flex items-center space-x-3 py-2 bg-gray-300 dark:bg-gray-700'>
              <i className=' las la-map-marked text-2xl'></i>
              <span className=''>
                <span className='inline-block capitalize text-sm font-semibold'>
                  {propertyDetails?.area} {"Sq. Ft"}
                </span>
              </span>
            </div>
            <div className='sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
              <i className=' las la-city text-2xl'></i>
              <span className=' '>
                <span className='inline-block capitalize text-sm font-semibold'>
                  Purpose for {propertyDetails?.purpose === "for_sale" ? "Sale" : "Rent"}
                </span>
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 bg-white py-2 rounded-md dark:bg-transparent">
            <div className='pl-3 sm:col-span-1 col-span-1 text-left flex justify-content-start mr-5 space-x-3 py-2'>
              <i className=' las la-donate text-2xl'></i>
              <span className=' '>
                <span className='inline-block capitalize text-sm font-semibold'>
                  PKR&nbsp;
                  <CurrencyFormat price={propertyDetails?.salePrice} />{" "}
                </span>
              </span>
            </div>
            <div className='sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
              <i className='las la-tools text-2xl'></i>
              <span className=' '>
                <span className='inline-block capitalize text-sm font-semibold'>
                  {propertyDetails?.category}
                </span>
              </span>
            </div>
          </div>
          {propertyDetails?.category === 'Residential' && (
            <>
              <div className="grid grid-cols-2 bg-gray-300 dark:bg-gray-700 py-2 rounded-md">
                <div className='pl-3 sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
                  <i className='las la-bed text-2xl'></i>
                  <span className=' '>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      Beds {propertyDetails?.bedrooms}
                    </span>
                  </span>
                </div>
                <div className='sm:col-span-1 col-span-1 flex items-center space-x-3 py-2 '>
                  <i className=' las la-bath text-2xl '></i>
                  <span className=''>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      Baths {propertyDetails?.bathrooms}
                    </span>
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-2 bg-white py-2 rounded-md dark:bg-transparent">
                <div className='pl-3 sm:col-span-1 col-span-1 text-left flex justify-content-start mr-5 space-x-3 py-2'>
                  <i className='las la-map-marked text-2xl'></i>
                  <span className=' '>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {" "}
                      {propertyDetails?.ProjectSelecting === 'Registered_project' ? (
                        <>
                          {propertyDetails?.projectName?.area}, {propertyDetails?.projectName?.city}, {propertyDetails?.projectName?.country}{" "}
                        </>
                      ) : (
                        <>
                          {propertyDetails?.city}, {propertyDetails?.country}{" "}
                        </>
                      )}
                    </span>
                  </span>
                </div>
                <div className='sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
                  <i className='las la-tools text-2xl'></i>
                  <span className=' '>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {propertyDetails?.subCategory}
                    </span>
                  </span>
                </div>
              </div>

              <div className="grid grid-cols-2 bg-gray-300 dark:bg-gray-700 py-2 rounded-md">
                <div className='pl-3 sm:col-span-1 col-span-1 flex items-center space-x-3 py-2 bg-gray-300 dark:bg-gray-700'>
                  <i className=' las la-chart-area text-2xl'></i>
                  <span className=''>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {propertyDetails?.floorNo}<sup className="font-semibold">{getOrdinalIndicator(propertyDetails?.floorNo)}</sup> {"Floor"}
                    </span>
                  </span>
                </div>
                <div className='sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
                  <i className=' las la-city text-2xl'></i>
                  <span className=' '>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {propertyDetails?.appartmentNo}
                    </span>
                  </span>
                </div>
              </div>
            </>
          )}
          {propertyDetails?.category === 'Commercial' && (
            <>
              <div className="grid grid-cols-2 bg-gray-300 dark:bg-gray-700 py-2 rounded-md">
                <div className='pl-3 sm:col-span-1 col-span-1 text-left flex justify-content-start mr-5 space-x-3 py-2'>
                  <i className='las la-map-marked text-2xl'></i>
                  <span className=' '>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {" "}
                      {propertyDetails?.ProjectSelecting === 'Registered_project' ? (
                        <>
                          {propertyDetails?.projectName?.area}, {propertyDetails?.projectName?.city}, {propertyDetails?.projectName?.country}{" "}
                        </>
                      ) : (
                        <>
                          {propertyDetails?.city}, {propertyDetails?.country}{" "}
                        </>
                      )}
                    </span>
                  </span>
                </div>
                <div className='sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
                  <i className='lar la-building text-2xl'></i>
                  <span className=' '>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {propertyDetails?.subCategory}
                    </span>
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-2 bg-white dark:bg-transparent py-2 rounded-md">
                <div className='pl-3 sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
                  <i className=' las la-chart-area text-2xl'></i>
                  <span className=''>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {propertyDetails?.floorNo}<sup className="font-semibold">{getOrdinalIndicator(propertyDetails?.floorNo)}</sup> {"Floor"}
                    </span>
                  </span>
                </div>
                <div className='sm:col-span-1 col-span-1 flex items-center space-x-3 py-2'>
                  <i className=' las la-city text-2xl'></i>
                  <span className=' '>
                    <span className='inline-block capitalize text-sm font-semibold'>
                      {propertyDetails?.appartmentNo}
                    </span>
                  </span>
                </div>
              </div>
            </>
          )}



        </div>

        {/* <div className='w-full border-b border-neutral-100 dark:border-neutral-700' /> */}
      </div>
    )
  }

  const renderSectionPhoto = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            <div className='relative'>
              <div
                className='relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
              >
                <NcImage
                  containerClassName='inset-0'
                  className='object-fill w-full h-[25vh] lg:h-[60vh] md:h-[40vh] rounded-md sm:rounded-xl'
                  src={``}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
            </div>
          </header>
        </>
      </div>
    )
  }

  const renderSectionPhoto1 = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            {/* {propertyDetails?.propertyImages?.length == "5" ?  */}
            <div className='relative grid grid-cols-1 sm:grid-cols-1 gap-1 sm:gap-2'>
              <div
                className='col-span-4 row-span-4 sm:row-span-4 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  key={id}
                  containerClassName='inset-0'
                  className='object-fill w-full h-[25vh] lg:h-[60vh] md:h-[40vh] rounded-md sm:rounded-xl'
                  src={`${propertyDetails?.propertyImages?.[0]}`}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
              {propertyDetails?.propertyImages?.filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                      className='object-cover w-full h-full rounded-md sm:rounded-xl'
                      src={`${item || ""}`}
                    />

                    {/* {/* OVERLAY */}
                    <div
                      className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={propertyDetails?.propertyImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName='nc-PropertyDetailPage-modalPhotos'
          />
        </>
      </div>
    )
  }

  const renderSectionPhoto2 = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            <div className='relative grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-2 pt-4'>
              <div
                className='col-span-2 row-span-2 sm:row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  key={id}
                  containerClassName='relative inset-0'
                  className='object-fill w-full h-[30vh] md:h-[40vh] lg:h-[60vh] rounded-md sm:rounded-xl'
                  src={`${propertyDetails?.propertyImages?.[0]}`}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
              {/* <GallerySlider
                galleryImgs={imageLinks}
                uniqueID={`StayCardH_${propertyDetails._id}`}
              /> */}
              {propertyDetails?.propertyImages?.filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                      className='object-fit w-full h-[20vh] md:h-[40vh] lg:h-[60vh] rounded-md sm:rounded-xl'
                      src={`${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={propertyDetails?.propertyImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName='nc-PropertyDetailPage-modalPhotos'
          />
        </>
      </div>
    )
  }

  const renderSectionPhoto3 = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            {/* {propertyDetails?.propertyImages?.length == "5" ?  */}
            <div className='relative grid grid-cols-1 md:grid-cols-1 lg:grid-cols-4 gap-1 sm:gap-2'>
              <div
                className='col-span-2 row-span-1 sm:row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  key={id}
                  containerClassName='inset-0'
                  className='object-fill w-full h-[20vh] md-[40vh] lg:h-[60vh] rounded-md sm:rounded-xl'
                  src={`${propertyDetails?.propertyImages?.[0]}`}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
              {propertyDetails?.propertyImages?.filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                      className='object-fill w-full h-[20vh] md-[40vh] lg:h-[60vh] rounded-md sm:rounded-xl'
                      src={`${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={propertyDetails?.propertyImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName='nc-PropertyDetailPage-modalPhotos'
          />
        </>
      </div>
    )
  }

  const renderSectionPhoto4 = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            <div className='relative grid grid-cols-3 sm:grid-cols-3 gap-1 sm:gap-2'>
              <div
                className='col-span-2 row-span-2 sm:row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  key={id}
                  containerClassName='absolute inset-0'
                  className='object-cover w-full h-full rounded-md sm:rounded-xl'
                  src={`${propertyDetails?.propertyImages?.[0]}`}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
              {propertyDetails?.propertyImages?.filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                      className='object-cover w-full h-full rounded-md sm:rounded-xl'
                      src={`${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={propertyDetails?.propertyImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName='nc-PropertyDetailPage-modalPhotos'
          />
        </>
      </div>
    )
  }

  const renderSectionPhoto5 = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            {/* {propertyDetails?.propertyImages?.length == "5" ?  */}
            <div className='relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2'>
              <div
                className='col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
                onClick={() => handleOpenModal(0)}
              >
                {/* {propertyDetails?.propertyImages.map((id:any, item:any))} */}
                <NcImage
                  key={id}
                  containerClassName='absolute inset-0'
                  className='object-cover w-full h-full rounded-md sm:rounded-xl'
                  src={`${propertyDetails?.propertyImages?.[0]}`}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
              {propertyDetails?.propertyImages?.filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName='aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5'
                      className='object-cover w-full h-full rounded-md sm:rounded-xl'
                      src={`${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={propertyDetails?.propertyImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName='nc-PropertyDetailPage-modalPhotos'
          />
        </>
      </div>
    )
  }

  const renderSliderPhotoSection = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            <div className='relative grid grid-cols-2 gap-2 sm:gap-2 pt-4'>
              <div
                className='col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
              >
                <GallerySlider2
                  galleryImgs={propertyDetails?.propertyImages}
                  uniqueID={`StayCardH_${propertyDetails._id}`}
                  imageClassCss="object-fit w-full h-[40vh] md:h-[50vh] lg:h-[70vh] rounded-md sm:rounded-xl"
                  ratioClass='relative inset-0'
                />
                {/* <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-80 transition-opacity'></div> */}
              </div>

              <div
                className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                  />
                </svg>
                <span className='ml-2 text-neutral-800 text-sm font-medium'>
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={propertyDetails?.propertyImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName='nc-PropertyDetailPage-modalPhotos'
          />
        </>
      </div>
    )
  }
  // end dynamic image galery

  const renderHostInfo = () => {
    return (
      <>
        <div className='listingSection__wrap relative'>
          {/* HEADING */}
          <h2 className='text-2xl font-semibold'>Consultant Information</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
          {/* Consultant */}
          <Link to={`/consultantProfile/${propertyDetails?.landlordId?.consultant?._id}`}>
            <div className='lg:flex md:flex hidden justify-between relative'>
              <div className="align-middle">
                <h2 className='text-xl font-medium capitalize flex justify-start' >
                  {propertyDetails?.landlordId?.consultant?.fname}
                  <sup><button className="bg-[#233a65] border-0 rounded-lg text-xs font-semibold text-white px-2 py-[1px]">View Profile</button></sup>
                </h2>
                <p className="pt-3">
                  {propertyDetails?.landlordId?.consultant?.description}
                </p>
              </div>
            </div>
            <div className='lg:hidden md:hidden grid grid-cols-1 gap-2'>
              <div className="col-span-1 flex justify-center">
                <NcImage
                  src={`${propertyDetails?.landlordId?.consultant?.userAvatar?.[0]?.url}`}
                  alt={propertyDetails?.landlordId?.consultant?.fname}
                  className="rounded-full border-2 border-[#263c67] w-[35vw] h-[35vw]"
                />
              </div>
              <div className="col-span-1 text-center">
                <h2 className='block text-xl font-medium capitalize pt-3' >
                  {propertyDetails?.landlordId?.consultant?.fname}
                </h2>
                <p className="pt-3">
                  {propertyDetails?.landlordId?.consultant?.description}
                </p>
              </div>
            </div>
          </Link>
          <div className="absolute top-5 right-5 lg:block md:block hidden">
            <NcImage
              src={`${propertyDetails?.landlordId?.consultant?.userAvatar?.[0]?.url}`}
              className="border-2 border-[#263c67] rounded-full w-[100px] h-[100px]"
              alt=''
            />
          </div>
        </div>

      </>
    )
  }

  const renderSection3 = () => {
    return (
      <div className='listingSection__wrap'>
        {/* {propertyDetails?.map((item: any) => (
          ))} */}
        <div>
          <h2 className='text-2xl font-semibold'>Description</h2>
          <span className='block mt-2 text-neutral-500 dark:text-neutral-400 text-justify first-letter:capitalize'>
            <div dangerouslySetInnerHTML={{ __html: propertyDetails?.description }} />
          </span>
        </div>
      </div>
    )
  }

  const renderSection4 = () => {
    return (
      <div className='listingSection__wrap'>
        <div className='dark:border-neutral-700'>
          <h2 className='text-2xl py-2 font-semibold'>Features</h2>
          <div className='pt-3 pb-2 w-50'>
            {/* <div className='py-2.5 hidden lg:grid-cols-2 md:grid-cols-2 grid-cols-1 bg-gray-300 rounded px-3 dark:bg-gray-700'>
              {propertyDetails?.amenities?.map((item: any) => (
                <div className='col-span-1 py-2.5'>
                  <div className='font-xs mx-2'>
                    <span className=' '>
                      <i className='las la-check-circle text-[20px] mx-1'></i>
                    </span>
                    <span className='font-semibold'>{item?.value} : </span>
                    <span className='font-normal '>
                      {item?.description}
                    </span>
                  </div>
                </div>
              ))}
            </div> */}

            {features?.map((item: any, index: any) => (
              <div key={index} className="mb-4">
                <div className="py-3">
                  <span className="text-lg font-semibold tracking-wide px-3">{item?.category}</span>
                </div>
                <div className="py-2.5 grid sm:grid-cols-2 grid-cols-1 gap-2">
                  {item.labels.map((label: any, labelIndex: any) => (
                    <div className="col-span-1 py-2.5 bg-gray-200 dark:bg-slate-800 rounded px-3" key={labelIndex}>
                      <span className="font-xs mx-2">
                        <span className=" ">
                          <i className="las la-check-circle text-[#233865] dark:text-white text-[20px] mx-2"></i>
                        </span>
                        <span className="capitalize font-normal">
                          {label}
                        </span>

                      </span>
                    </div>
                  ))}

                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    )
  }

  const renderSection5 = () => {
    return (
      <div className='listingSection__wrap overflow-hidden'>
        {/* HEADING */}
        <div>
          <h2 className='text-2xl font-semibold'>Availability</h2>
          <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
        {/* CONTENT */}

        <div className='listingSection__wrap__DayPickerRangeController flow-root'>
          <div className='-mx-4 sm:mx-auto xl:mx-[-22px]'></div>
        </div>
      </div>
    )
  }

  const renderSection6 = () => {
    return (
      <div className='listingSection__wrap'>
        {/* HEADING */}
        <h2 className='text-2xl font-semibold'>Reviews (23 reviews)</h2>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

        {/* Content */}
        <div className='space-y-5'>
          <FiveStartIconForRate iconClass='w-6 h-6' className='space-x-0.5' />
          <div className='relative'>
            <Input
              fontClass=''
              sizeClass='h-16 px-4 py-3'
              rounded='rounded-3xl'
              placeholder='Share your thoughts ...'
            />
            <ButtonCircle
              className='absolute right-2 top-1/2 transform -translate-y-1/2'
              size=' w-12 h-12 '
            >
              {/* <ArrowRightIcon className="w-5 h-5" /> */}
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className='divide-y divide-neutral-100 dark:divide-neutral-800'>
          <CommentListing className='py-8' />
          <CommentListing className='py-8' />
          <CommentListing className='py-8' />
          <CommentListing className='py-8' />
          <div className='pt-8'>
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    )
  }

  const renderSection7 = () => {
    const API_KEY = process.env.REACT_APP_MAP_API
    return (
      <div className='listingSection__wrap hover:transform'>
        <h2 className="text-2xl font-semibold">Property Location</h2>
        {/* {propertyDetails?.map((item: any) => (
          ))} */}
        <>
          <div className="flex justify-start" style={{ margin: '10px 0px 0px 0px' }}>
            <h4 className="font-semibold">Address:</h4>
            <p className="font-medium text-sm pl-2 m-0 leading-7"> {propertyDetails?.address}</p>
          </div>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-0" style={{ margin: '15px 0px 0px 0px' }}></div>
          {propertyDetails?.ProjectSelecting === 'Registered_project' ? (

            <iframe
              className='w-full h-[70vh] rounded-lg shadow-xl'
              src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=place_id:${propertyDetails?.projectName?.placeId}`}
              loading='lazy'
            ></iframe>
          ) : (
            <iframe
              className='w-full h-[70vh] rounded-lg shadow-xl'
              src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=place_id:${propertyDetails?.placeId}`}
              loading='lazy'
            ></iframe>
          )}
        </>
      </div>
    )
  }

  const renderSidebar = () => {
    return (
      <div className='listingSectionSidebar__wrap shadow-xl'>
        <div className='flex justify-center text-center'>
          <Logo />
        </div>
        <Email ReceiverEmail={consultantEmail} data={propertyDetails?._id} />
      </div>
    )
  }
  {
    if (propertyDetails?.propertyImages?.length > 0) {
      return (
        <div
          className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
          data-nc-id='PropertyDetailPage'
        >
          <div className='container'>{renderSliderPhotoSection()}</div>
          <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
            <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
              {renderSection1()}
              {renderSection3()}
              {renderSection4()}
              {renderSection7()}
              {renderHostInfo()}
            </div>
            {/* SIDEBAR */}
            <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
              <div className='sticky w-96 top-20'>{renderSidebar()}</div>
            </div>
          </main>
        </div>
      )
    }
    //   else if (propertyDetails?.propertyImages?.length === 1) {
    //     return (
    //       <div
    //         className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //         data-nc-id='PropertyDetailPage'
    //       >
    //         <div className='container'>{renderSectionPhoto1()}</div>
    //         <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
    //           <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
    //             {renderSection1()}
    //             {renderSection3()}
    //             {renderSection4()}
    //             {renderSection7()}
    //             {renderHostInfo()}
    //           </div>
    //           {/* SIDEBAR */}
    //           <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
    //             <div className='sticky w-96 top-20'>{renderSidebar()}</div>
    //           </div>
    //         </main>
    //       </div>
    //     )
    //   } else if (propertyDetails?.propertyImages?.length === 2) {
    //     return (
    //       <div
    //         className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //         data-nc-id='PropertyDetailPage'
    //       >
    //         <div className='container'>{renderSectionPhoto2()}</div>
    //         <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
    //           <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
    //             {renderSection1()}
    //             {renderSection3()}
    //             {renderSection4()}
    //             {renderSection7()}
    //             {renderHostInfo()}
    //           </div>
    //           {/* SIDEBAR */}
    //           <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
    //             <div className='sticky w-96 top-20'>{renderSidebar()}</div>
    //           </div>
    //         </main>
    //       </div>
    //     )
    //   }else if (propertyDetails?.propertyImages?.length === 3) {
    //   return (
    //     <div
    //       className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //       data-nc-id='PropertyDetailPage'
    //     >
    //       <div className='container'>{renderSectionPhoto3()}</div>
    //       <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
    //         <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
    //           {renderSection1()}
    //           {renderSection3()}
    //           {renderSection4()}
    //           {renderSection7()}
    //           {renderHostInfo()}
    //         </div>
    //         {/* SIDEBAR */}
    //         <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
    //           <div className='sticky w-96 top-20'>{renderSidebar()}</div>
    //         </div>
    //       </main>
    //     </div>
    //   )
    // } else if (propertyDetails?.propertyImages?.length === 4) {
    //   return (
    //     <div
    //       className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //       data-nc-id='PropertyDetailPage'
    //     >
    //       <div className='container'>{renderSectionPhoto4()}</div>
    //       <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
    //         <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
    //           {renderSection1()}
    //           {renderSection3()}
    //           {renderSection4()}
    //           {renderSection7()}
    //           {renderHostInfo()}
    //         </div>
    //         {/* SIDEBAR */}
    //         <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
    //           <div className='sticky w-96 top-20'>{renderSidebar()}</div>
    //         </div>
    //       </main>
    //     </div>
    //   )
    // } else if (propertyDetails?.propertyImages?.length === 5) {
    //   return (
    //     <div
    //       className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //       data-nc-id='PropertyDetailPage'
    //     >
    //       <div className='container'>{renderSectionPhoto5()}</div>
    //       <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
    //         <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
    //           {renderSection1()}
    //           {renderSection3()}
    //           {renderSection4()}
    //           {renderSection7()}
    //           {renderHostInfo()}
    //         </div>
    //         {/* SIDEBAR */}
    //         <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
    //           <div className='sticky w-96 top-20'>{renderSidebar()}</div>
    //         </div>
    //       </main>
    //     </div>
    //   )
    // } else if (propertyDetails?.propertyImages?.length === 6) {
    //   ; <div
    //     className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //     data-nc-id='PropertyDetailPage'
    //   >
    //     <div className='container'>{renderSectionPhoto5()}</div>
    //     <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
    //       <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
    //         {renderSection1()}
    //         {renderSection3()}
    //         {renderSection4()}
    //         {renderSection7()}
    //         {renderHostInfo()}
    //       </div>
    //       {/* SIDEBAR */}
    //       <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
    //         <div className='sticky w-96 top-20'>{renderSidebar()}</div>
    //       </div>
    //     </main>
    //   </div>
    // }
  }

  return (
    <div
      className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
      data-nc-id='PropertyDetailPage'
    >
      <div className='container'>{renderSectionPhoto()}</div>
      {/* Images */}
      <main className='container relative z-10 mt-11 flex flex-col lg:flex-row'>
        <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10'>
          {renderSection1()}
          {renderSection3()}
          {renderSection4()}
          {renderSection7()}
          {renderHostInfo()}
        </div>
        {/* SIDEBAR */}
        <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
          <div className='sticky w-96 top-20'>{renderSidebar()}</div>
        </div>
      </main>
    </div>
  )
}

export default PropertyDetailPage
