import { CustomLink } from "data/types"
import React, { FC, useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import twFocusClass from "utils/twFocusClass"
// import { string } from "yup/lib/locale";
import {
  setQueryParams,
  getQueryParam,
  removeQueryParams,
} from "utils/queryManipulation"
import { valueToPercent } from "@mui/base"
// import pinContext from "context/pin/pinContext";
import { BuyRentContext } from "context/property/BuyRentContext"

export interface PaginationProps {
  className?: string
}

const Pagination: FC<PaginationProps> = ({ className = "" }) => {
  const {
    getMyProperties,
    properties,
    propertiesCount,
    perPage,
    setPerPage,
    pageNumber,
    setPageNumber,
  }: any = useContext(BuyRentContext)
  useEffect(() => {
    getMyProperties()
  }, [])
  // console.log(properties,'allmy project')
  // debugger
  const activeTab = getQueryParam("current")

  const [value, setValue] = useState(activeTab === "activeTb")
  const [currentListing, setCurrentListing]: any = useState()

  // const [value,setValue] = useState(0);

  const renderItem = ({ title, link }: any, index: number) => {
    if (index === pageNumber) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          onClick={() => setPageNumber(index)}
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-[#345886] text-white ${twFocusClass()}`}
        >
          {title}
        </span>
      )
    }

    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        onClick={() => setPageNumber(index)}
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900  dark:border-neutral-700 ${twFocusClass()}`}
        to={link}
      >
        {title}
      </Link>
    )
  }
  const paginationData =
    propertiesCount > 0 && properties?.property?.length > 0
      ? new Array(Math.ceil(propertiesCount / perPage))
        .fill(null)
        .map((_, index) => ({ title: index + 1, link: "#" }))
      : []
  useEffect(() => {
    setQueryParams("Page", pageNumber + 1)
  }, [pageNumber])
  useEffect(() => {
    return () => {
      removeQueryParams("Page")
    }
  }, [])

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {paginationData.map(renderItem)}
    </nav>
  )
}

export default Pagination
