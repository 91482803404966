import React, { FC, useState, Fragment, useContext, useEffect } from "react";
import Image from "../../images/avatars/Image-26.png";
import { Popover, Transition } from "@headlessui/react";
import Pagination from "shared/Pagination/Pagination";
import ConsultantProfileTab from "./ConsultantProfileTab";
import ConsultantPropertyListing from "./ConsultantPropertyListing";
import CallModal from "components/HeroSearchForm/CallModal";
import EmailModal from "components/HeroSearchForm/EmailModal";
import { useParams } from "react-router-dom";
import pinContext from "context/pin/pinContext";
import { API } from "utils/config";
import NcImage from "shared/NcImage/NcImage";

export interface ConsultantProps {
  className?: any;
}

const ConsultantProfile: FC<ConsultantProps> = () => {
  type QuizParams = {
    id: any;
  };
  let { id } = useParams<any>();

  const { singleConsultant, getByConsultantId, getConsultantListedProperties, consultantListedProperties } = useContext(pinContext);
  const consultantData = singleConsultant
  useEffect(() => {
    getByConsultantId(id);
    getConsultantListedProperties(id);
  }, [id]);

  // TabsShifting Start
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index: any) => {
    setToggleState(index);
  };
  const [showCallModal, setShowCallModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const image = consultantData?.userAvatar?.[0]?.url
  return (
    <>
      <div className="bg-blue-50 dark:bg-blue-200 lg:py-10 md:py-10 py-14">
        <div className="lg:px-20 md:px-10 px-5">
          <div className="lg:grid md:grid grid-cols-3 hidden">
            <div className="col-span-1">
              <div className="rounded-full">
                <NcImage
                  src={`${image}`}
                  alt={consultantData?.fname}
                  className="rounded-full lg:w-[10vw] lg:h-[22vh] md:[30vw] md:h-[20vh] w-[20vh] h-48 border border-[#0c4a6e] shadow-lg"
                />
              </div>
            </div>
            <div className="col-span-2 lg:px-0 px-5 lg:py-8 md:py-10 py-5">
              <div className="flex flex-col items-start">
                <span className="capitalize px-5 bg-[#0c4a6e] text-white text-lg font-semibold py-1 tracking-wide rounded-md my-1.5 text-center">
                  {consultantData?.fname}
                </span>
                <span className="capitalize px-5 bg-[#0c4a6e] text-white py-1 rounded-md my-1.5 text-center">
                  {singleConsultant?.landlordCount} Landlords
                </span>
                <span className="capitalize px-5 bg-[#0c4a6e] text-white py-1 rounded-md my-1.5 text-center">
                  {singleConsultant?.propertiesCount} Properties
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MObile Section */}
      <div className="lg:hidden md:hidden block">
        <div className="relative -mt-20 z-1 flex justify-center">
          <NcImage
            src={`${image}`}
            alt={consultantData?.fname}
            className="rounded-full w-[35vw] h-[35vw]"
          />
        </div>
        <div className="flex flex-col">
          <span className="capitalize text-xl font-semibold pb-1 text-center text-[#263c67] dark:text-white">
            {consultantData?.fname}
          </span>
          <div className="flex justify-center">
            <span className="capitalize text-sm text-center text-white bg-[#345886] py-2 px-5 rounded-lg">
              Working with {singleConsultant?.landlordCount} Landlords
            </span>
          </div>
        </div>
        <div className="px-5 py-3 flex justify-center">
          <button className="bg-blue-100 dark:bg-blue-200 hover:bg-[#0c4a6e] rounded-md lg:text-md text-xs text-center text-[#0c4a6e] hover:text-white py-2.5 px-3 mx-2 capitalize">
            <i className="las la-share"></i> Share Profile
          </button>
          <button
            className="bg-[#0c4a6e] hover:bg-[#083e5e] text-white rounded-md lg:text-md text-xs text-center py-2.5 px-3 mx-2 capitalize"
            onClick={() => setShowEmailModal(true)}
          >
            <i className="las la-envelope"></i> Email Consultant
          </button>
          <button
            className="bg-[#0c4a6e] hover:bg-[#083e5e] text-white rounded-md lg:text-md text-xs text-center py-2.5 px-3 mx-2 capitalize"
            onClick={() => setShowCallModal(true)}
          >
            <i className="las la-phone"></i> Call Consultant
          </button>
        </div>

        <div className="lg:px-20 md:px-10 px-5 flex justify-start">
          <button
            className={
              toggleState === 1
                ? "capitalize text-[#0c4a6e] dark:text-white border-b-[3px] py-2.5 dark:border-[#fff] border-[#0c4a6e] lg:hidden block"
                : "capitalize text-[#0c4a6e] dark:text-white py-2.5 lg:hidden block"
            }
            onClick={() => toggleTab(1)}
            value="Profile"
            autoFocus
          >
            Profile
          </button>
          <button
            className={
              toggleState === 2
                ? "capitalize text-[#0c4a6e] dark:text-white border-b-[3px] py-2.5 dark:border-[#fff] border-[#0c4a6e] lg:hidden block mx-5"
                : "capitalize text-[#0c4a6e] dark:text-white py-2.5 lg:hidden block mx-5"
            }
            onClick={() => toggleTab(2)}
            value="Active Listings"
          >
            Active Listings
          </button>
        </div>
        <hr />
      </div>
      {/* End of Mobile Section */}

      <div className="lg:px-20 md:px-10 px-5 border-b-2 border-gray-300 lg:grid md:grid grid-cols-2 hidden">
        <div className="col-span-1 md:flex justify-start lg:block hidden">
          <button className="capitalize text-[#0c4a6e] dark:text-white align-bottom border-b-[3px] py-2.5 border-[#0c4a6e] lg:block hidden">
            Active listings
          </button>
          <button
            className={
              toggleState === 1
                ? "capitalize text-[#0c4a6e] dark:text-white border-b-[3px] py-2.5 border-[#0c4a6e] lg:hidden block"
                : "capitalize text-[#0c4a6e] dark:text-white py-2.5 lg:hidden block"
            }
            onClick={() => toggleTab(1)}
            value="Profile"
            autoFocus
          >
            Profile
          </button>
          <button
            className={
              toggleState === 2
                ? "capitalize text-[#0c4a6e] dark:text-white border-b-[3px] py-2.5 border-[#0c4a6e] lg:hidden block mx-5"
                : "capitalize text-[#0c4a6e] dark:text-white py-2.5 lg:hidden block mx-5"
            }
            onClick={() => toggleTab(2)}
            value="Active Listings"
          >
            Active Listings
          </button>
        </div>
        <div className="col-span-1 flex justify-end">
          <button className="bg-blue-100 dark:bg-blue-200 hover:bg-[#0c4a6e] rounded-md lg:text-sm text-xs text-center text-[#0c4a6e] dark:text-white hover:text-white py-2 px-3 m-1 capitalize">
            <i className="las la-share"></i> Share Profile
          </button>
          <button
            className="bg-[#345886] hover:bg-[#083e5e] text-white rounded-md lg:text-sm text-xs text-center py-2 px-3 m-1 capitalize"
            onClick={() => setShowEmailModal(true)}
          >
            <i className="las la-envelope"></i> Email Consultant
          </button>
          <button
            className="bg-[#345886] hover:bg-[#083e5e] text-white rounded-md lg:text-sm text-xs text-center py-2 px-3 m-1 capitalize lg:block md:block hidden"
            onClick={() => setShowCallModal(true)}
          >
            <i className="las la-phone"></i> Call Consultant
          </button>
        </div>
      </div>

      <div className="lg:px-20 md:px-10 px-5 lg:grid lg:grid-cols-8 md:grid-cols-8 grid-cols-1 gap-4 hidden">
        <div className="lg:col-span-6 col-span-6 md:col-span-4 pt-5">
          {/* <AgentFilters /> */}
          <div className="lg:w-6/12 w-full hidden">
            <div className="flex justify-content-between">
              <span className="text-sm leading-8">
                Showing Properties sorted by &nbsp;
              </span>
              <div className="border border-gray-400 rounded-md px-4 py-1.5 text-sm">
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={` ${open
                          ? "text-black focus:outline-none w-full flex justify-between"
                          : "flex justify-between w-full"
                          }`}
                      >
                        <div className="flex justify-between w-full">
                          <button className="inline-block align-middle h-full">
                            Popular &nbsp;
                          </button>
                          <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute pb-2 bg-white dark:bg-neutral-800 z-50 mt-3 left-0 -ml-5 shadow-xl border w-36 rounded-md">
                          <div className="pt-2 px-2 relative flex flex-col">
                            <button className="border py-1.5 hover:bg-red-100">
                              Popular
                            </button>
                            <button className="border py-1.5 hover:bg-red-100">
                              Newest
                            </button>
                            <button className="border py-1.5 hover:bg-red-100">
                              Lowest Price
                            </button>
                            <button className="border py-1.5 hover:bg-red-100">
                              Highest Price
                            </button>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
          </div>
          <div className={toggleState === 1 ? "block" : "hidden"}>
            <ConsultantPropertyListing name={consultantData?.fname} image={image} email={consultantData?.email} phone={consultantData?.contact} consultant={consultantListedProperties} id={""} />
          </div>
        </div>
        <div className="lg:col-span-2 col-span-2 md:col-span-4 text-left pt-4">
          <div className="pt-5 text-left">
            <ConsultantProfileTab consultant={singleConsultant} id={""} />
          </div>
        </div>
      </div>

      <div className="lg:px-20 md:px-10 px-5 lg:hidden block">
        <div className={toggleState === 1 ? "block py-5" : "hidden"}>
          <ConsultantProfileTab consultant={singleConsultant} id={""} />
        </div>
        <div className={toggleState === 2 ? "block" : "hidden"}>
          <ConsultantPropertyListing name={consultantData?.fname} image={image} email={consultantData?.email} phone={consultantData?.phone} consultant={consultantListedProperties} id={""} />
        </div>
      </div>
      <CallModal
        showCallModal={showCallModal}
        setShowCallModal={setShowCallModal}
        CallContent={consultantData?.contact}
      />
      <EmailModal
        ReceiverEmail={consultantData?.email}
        showEmailModal={showEmailModal}
        setShowEmailModal={setShowEmailModal}
      />
    </>
  );
};

export default ConsultantProfile;
