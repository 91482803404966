import React, { useState } from 'react';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";

type DataItem = {
    label: string;
    value: string;
    desc: string;
};

export function TabsDefault() {
    const data: DataItem[] = [
        {
            label: "Phase 1-4 Overview",
            value: "overview",
            desc: `Defence Housing Authority Lahore ph 1 , 2, 3 and 4 are considered as older phases of DHA. 
            The prices of plots and houses are very diverse, while ph 1 and 2 offer the cheapest plots available, phase 3 turns out to be most expensive.
             These 4 phases have a bigger market for used houses and Fewer plots available for sale. `,
        },
        {
            label: "React",
            value: "react",
            desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
        },
        {
            label: "Vue",
            value: "vue",
            desc: `We're not always in the position that we want to be at.
      We're constantly growing. We're constantly making mistakes. We're
      constantly trying to express ourselves and actualize our dreams.`,
        },
        {
            label: "Angular",
            value: "angular",
            desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
        },
        {
            label: "Svelte",
            value: "svelte",
            desc: `We're not always in the position that we want to be at.
      We're constantly growing. We're constantly making mistakes. We're
      constantly trying to express ourselves and actualize our dreams.`,
        },
    ];
    const [activeTab, setActiveTab] = useState('overview');

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index: any) => {
        setToggleState(index);
    };

    return (
        <>
            <div className="container">
                <div className="lg:px-12 md:px-10 px-0">
                    <div className="p-5">
                        <Tabs value="overview" className="flex flex-row">
                            <TabsHeader className="flex flex-col" placeholder=''>
                                <Tab
                                    placeholder=''
                                    value="Phase 1-4 Overview"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 1 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(1)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Phase 1-4 Overview
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Residential Plots"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 2 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(2)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Residential Plots
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Commercial Plots"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 3 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(3)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Commercial Plots
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Files in Phase 1-4"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 4 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(4)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Files in Phase 1-4
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Houses in Phase 1-4"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 5 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(5)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Houses in Phase 1-4
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Height Limitations"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 6 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(6)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Height Limitations
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Dimensions"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 7 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(7)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Dimensions
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Projects"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 8 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(8)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Projects
                                </Tab>
                                <Tab
                                    placeholder=''
                                    value="Development Update"
                                    className={`mb-2 -mr-3 py-4 px-4 rounded-md font-[700] text-[14px] border border-r-0 ${toggleState === 9 ? 'border-gray-300 text-[#bda588]' : 'border-transparent text-[#233c65]'} whitespace-nowrap`}
                                    onClick={() => toggleTab(9)}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Development Update
                                </Tab>
                                {/* {data.map(({ label, value }) => (
                                ))} */}
                            </TabsHeader>
                            <TabsBody placeholder='' className='border border-gray-300 rounded-md'>
                                <TabPanel
                                    value="Phase 1-4 Overview"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] px-4 ${toggleState === 1 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    <strong className='text-[#233c65]'>Defence Housing Authority Lahore</strong> ph 1 , 2, 3 and 4 are considered as older phases of DHA.
                                    The prices of plots and houses are very diverse, while ph 1 and 2 offer the cheapest plots available, phase 3 turns out to be most expensive.
                                    These 4 phases have a bigger market for used houses and Fewer plots available for sale.
                                </TabPanel>
                                <TabPanel
                                    value="Residential Plots"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] pt-5 px-4 ${toggleState === 2 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    There are basically 4 categories of Defence Housing Authority Lahore residential plots in Phase 1-4 as per size:
                                    <ul className='px-5 mt-3'>
                                        <li className="py-1 list-disc">5 Marla plots</li>
                                        <li className="py-1 list-disc">10 Marla Plots</li>
                                        <li className="py-1 list-disc">1 Kanal Plots</li>
                                        <li className="py-1 list-disc">2 Kanal Plots</li>
                                    </ul>
                                </TabPanel>
                                <TabPanel
                                    value="Commercial Plots"
                                    className={`border-transparent text-[#7a7a7a] pt-5 text-[16px] font-[400] px-4 ${toggleState === 3 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    There are 5 Categories of commercial plots available in Defence Housing Authority Lahore Phase 8
                                    <ul className='px-5 mt-3'>
                                        <li className="py-1 list-disc">2 Marla commercial shops</li>
                                        <li className="py-1 list-disc">4 Marla commercial</li>
                                        <li className="py-1 list-disc">8 Marla commercial</li>
                                    </ul>
                                </TabPanel>
                                <TabPanel
                                    value="Files in Phase 1-4"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] px-4 ${toggleState === 4 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    No files are available as the area is fully developed.
                                </TabPanel>
                                <TabPanel
                                    value="Houses in Phase 1-4"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] px-4 ${toggleState === 5 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Defence Housing Authority Lahore Ph 1-4 offer a lot of options to buy used and new houses in DHA Lahore. Follwing categories are available for sale.
                                    <ul className='px-5 mt-3'>
                                        <li className="py-1 list-disc">5 Marla</li>
                                        <li className="py-1 list-disc">10 Marla</li>
                                        <li className="py-1 list-disc">1 Kanal</li>
                                        <li className="py-1 list-disc">2 Kanal</li>
                                    </ul>
                                </TabPanel>
                                <TabPanel
                                    value="Height Limitations"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] px-4 ${toggleState === 6 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    <p className='font-bold text-[#222] text-[16px]'>Residential Plots Height Limit </p>

                                    All residential plots Defence Housing Authority Lahore in Phases 1 , 2 , 3 and 4 are only allowed a maximum of 3 floors as under :
                                    <ul className="px-5 mt-3">
                                        <li className="py-1 list-disc">Ground Floor</li>
                                        <li className="py-1 list-disc">First Floor</li>
                                        <li className="py-1 list-disc">Basement</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <p className='text-[#222] font-bold text-[16px]'>Commercial Plots Height Limit</p>
                                    <br />
                                    Defence Housing Authority Lahore Ph 1 , 2 , 3 and 4 commercials are allowed 5 floors as under:
                                    <ul className='px-5 mt-3'>
                                        <li className="py-1 list-disc">Ground Floor</li>
                                        <li className="py-1 list-disc">Mezzanine Floor</li>
                                        <li className="py-1 list-disc"> First Floor</li>
                                        <li className="py-1 list-disc">Second Floor</li>
                                        <li className="py-1 list-disc">Third Floor</li>
                                        <li className="py-1 list-disc"> Fourth Floor</li>
                                        <li className="py-1 list-disc"> Basement</li>
                                    </ul>
                                </TabPanel>
                                <TabPanel
                                    value="Dimensions"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] px-4 ${toggleState === 7 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    <p className='font-bold text-[#222] text-[16px]'>Dimensions of Residential Plots in DHA Lahore Ph 1,2,3 & 4 ( 1 Marla = 225 Sqft)</p>
                                    <br />
                                    5 Marla Plot :  25 X 45
                                    <br />
                                    25 Width 45 Length

                                    <br />
                                    <br />
                                    10 Marla Plot : 35 X 65
                                    <br />
                                    35 Width 65 Length

                                    <br />
                                    <br />
                                    1 Kanal Plot : 50 X 90
                                    <br />
                                    50 Width 90 Length

                                    <br />
                                    <br />
                                    2 Kanal Plot : 75 X 120
                                    <br />
                                    75 Width 120 Length

                                    <p className='font-bold text-[#222] pt-3 text-[16px]'>Dimensions of Commercial Plots in DHA Lahore Ph 1,2,3 & 4 ( 1 Marla = 225 Sqft)</p>

                                    <br />
                                    4 Marla Commercial Plot : 30 X 30
                                    <br />
                                    30 Width 30 Length

                                    <br />
                                    <br />
                                    8 Marla Commercial Plot : 30 X 60
                                    <br />
                                    30 Width 60 Length
                                </TabPanel>
                                <TabPanel
                                    value="Projects"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] px-4 ${toggleState === 8 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    Significant projects in Defence Housing Authority Lahore Ph 1, 2, 3 & 4 are :
                                    <ul className='px-5 mt-3'>
                                        <li className="py-1 list-disc">Haly Tower Ph 2</li>
                                        <li className="py-1 list-disc">Gold Crest Ph 4</li>
                                    </ul>
                                </TabPanel>
                                <TabPanel
                                    value="Development Update"
                                    className={`border-transparent text-[#7a7a7a] text-[16px] font-[400] px-4 ${toggleState === 9 ? 'block' : 'hidden'}`}
                                    style={{ fontFamily: '"Nunito Sans", Sans-serif' }}
                                >
                                    <p className='font-bold text-[#222] pt-3 text-[16px]'>Full Developed</p>
                                    <ul className='px-5 mt-3'>
                                        <li className="py-1 list-disc">These phases are fully developed and no more development work is being under taken here.</li>
                                    </ul>
                                </TabPanel>
                                {/* {data.map(({ value, desc }) => (
                                ))} */}
                            </TabsBody>
                        </Tabs>
                    </div>
                </div>
            </div >
        </>
    );
}
