import React, { FC } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import heroRightImage from "images/hero-right-car.png";
import SectionDowloadApp from "containers/PageHome/SectionDowloadApp";

export interface ListingCarMapPageProps {
  className?: string;
}

const ListingCarMapPage: FC<ListingCarMapPageProps> = ({ className = "" }) => {
  return (
    <>
      <div className="relative overflow-hidden  lg:h-[87vh] ">
        <div className="mx-7 max-w-7xl ">
          <div className="relative z-10 bg-sky-50 dark:bg-gray-700 lg:h-[87vh] lg:rounded-l-md  lg:rounded-l-xl  pb-5 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-sky-50 dark:text-gray-700  lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative px-4 pt-6 sm:px-6 lg:px-8"></div>

            <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900  dark:text-gray-100 sm:text-5xl md:text-6xl">
                  <span className="block   xl:inline">IMLAAK </span>
                  <span className="block text-sky-900 dark:text-gray-400  xl:inline">
                    CONSTRUCTIONS LAHORE
                  </span>
                </h1>

                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <button className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-900 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 md:py-4 md:px-10 md:text-lg">
                      Contact Us
                    </button>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <button className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-100 px-8 py-3 text-base font-medium text-sky-900 hover:bg-sky-200 md:py-4 md:px-10 md:text-lg">
                      <i className="las la-play-circle text-2xl"></i>
                      Watch VIdeo
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 rounded-xl lg:right-0 mx-8">
          <img
            className="h-56 w-full object-cover rounded-xl sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="http://erp.apexgroup.org/store/blogs/40891DHA-Pic-1170x0-c-center.jpg"
            alt=""
          />
        </div>
      </div>
      <div
        className={`px-2 sm:px-20 nc-ListingCarMapPage relative ${className}`}
        data-nc-id="ListingCarMapPage"
      >
        <Helmet>
          <title>IMLAAK CONSTRUCTIONS LAHORE</title>
        </Helmet>
        <BgGlassmorphism />

        {/* SECTION HERO */}

        {/* SECTION */}
        <div className="container  pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">
          <SectionGridHasMap />
        </div>

        <div className="container overflow-hidden">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName="ListingCarMapPage"
            />
          </div>
        </div>
        <SectionDowloadApp />
      </div>
    </>
  );
};

export default ListingCarMapPage;
