import React, { FC, useEffect, useState, useContext } from "react"
import Heading from "shared/Heading/Heading"
import Nav from "shared/Nav/Nav"
import NavItem from "shared/NavItem/NavItem"
import ButtonSecondary from "shared/Button/ButtonSecondary"
import { ReactNode } from "react"
import { BuyRentContext } from "context/property/BuyRentContext"

export interface HeaderFilterProps {
  tabActive: string
  tabs: string[]
  heading: ReactNode
  subHeading?: ReactNode
  onClickTab: (item: string) => void
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "🎈 Latest Articles",
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive)
  const { dispatchFilter, filter, applyFilters }: any =
    useContext(BuyRentContext)

  useEffect(() => {
    setTabActiveState(tabActive)
  }, [tabActive])

  const handleClickTab = (item: string) => {
    onClickTab && onClickTab(item)
    setTabActiveState(item)
    dispatchFilter({
      type: "updatePropertyFurnishingStatus",
      value: item.toLowerCase(),
    })
    let filtersToApply = {
      ...filter,
      furnishingStatus: item.toLowerCase(),
    }

    applyFilters(filtersToApply)
  }
  const [showDropdown, setShowDropdown] = useState(false)
  const handleSort = (value: any) => {
    // dispatchFilter({
    //   type: "sortBy",
    //   value,
    // })
    let filtersToApply = {
      ...filter,
      sort: value,
    }

    applyFilters(filtersToApply)
  }
  return (
    <div className='flex flex-col mb-3 relative'>
      <Heading desc={subHeading}>{heading}</Heading>
      <div className='flex items-center justify-between'>
        <Nav
          className='sm:space-x-1'
          containerClassName='relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar'
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item}
              onClick={() => handleClickTab(item)}
            >
              {item}
            </NavItem>
          ))}
        </Nav>
        <span className='hidden sm:block flex-shrink-0'>
          <ButtonSecondary
            onClick={() => setShowDropdown(!showDropdown)}
            className='!leading-none'
          >
            <button
              onClick={() => setShowDropdown(!showDropdown)}
              data-dropdown-toggle='dropdownDivider'
              className=''
              type='button'
            >
              Popular
            </button>

            <div
              className={`z-10 w-44 bg-white absolute mt-[210px] rounded shadow-md border divide-y focus:ring-none focus:outline-none divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 ${
                showDropdown ? "" : "hidden"
              }`}
            >
              <ul
                className='py-1 text-sm text-gray-700 dark:text-gray-200'
                aria-labelledby='dropdownDividerButton'
              >
                <li className='px-2'>
                  <button
                    // href='#'
                    onClick={() => handleSort("newest")}
                    className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                  >
                    Newest
                  </button>
                </li>
                <li className='px-2'>
                  <button
                    // href='#'
                    onClick={() => handleSort("lowest")}
                    className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                  >
                    Lowest Price
                  </button>
                </li>
                <li className='px-2'>
                  <button
                    // href='#'
                    onClick={() => handleSort("highest")}
                    className='block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                  >
                    Highest Price
                  </button>
                </li>
              </ul>
              {/* <div className="py-1">
                <a href="#" className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Separated link</a>
              </div> */}
            </div>

            <i className='ml-3 las la-arrow-right text-xl'></i>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  )
}

export default HeaderFilter
