import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Login from "./Login";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { TabsDefault } from "./TabsDefault";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  },
  {
    title: "💌 EMAIL",
    desc: "nc.example@example.com",
  },
  {
    title: "☎ PHONE",
    desc: "000-123-456-7890",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      {/* <!-- ====== Contact Section Start --> */}
      <div className="container-fluid relative">
        <div className="grid grid-cols-1">
          <div className="w-full leading-[0] border-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16154.761633148864!2d74.41639969607357!3d31.464781224070258!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391906116ea342c7%3A0xe3cb0f0af677476e!2sImlaak.com!5e0!3m2!1sen!2sus!4v1698130192113!5m2!1sen!2sus"
              style={{ border: 0 }}
              className="w-full h-[500px]"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <section className=" item-center rounded-lg max-w-3xl mx-auto max-w-20xl py-20 -mt-20 lg:py-[40px] overflow-hidden relative z-10">
        <div className="  ">
          <div className=" lg:justify-between">
            <div className="w-full md:px-20">
              <div className="bg-white border-t-4 border-[#223A64]  relative rounded-md p-8 sm:p-12 shadow-lg">
                <form action="" method="POST" className="py-6 px-9">
                  <div className="mb-2">
                    <label
                      htmlFor="name"
                      className="mb-1 block text-base font-medium text-[#07074D]"
                    >
                      Your Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter your name"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="mb-1 block text-base font-medium text-[#07074D]"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@domain.com"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="text"
                      className="mb-1 block text-base font-medium text-[#07074D]"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      placeholder="Subject"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>

                  <div className="mb-2">
                    <label
                      htmlFor="message"
                      className="mb-1 block text-base font-medium text-[#07074D]"
                    >
                      Message
                    </label>
                    <textarea
                      rows={4}
                      name="message"
                      id="message"
                      placeholder="Explain your queries"
                      className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    ></textarea>
                  </div>
                  <div>
                    <button className="hover:shadow-form w-full rounded-md bg-[#223A64] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                      Send Email
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <Login/> */}
      </section>

      <div className="my-24 hidden">
        <TabsDefault />
      </div>
      {/* <!-- ====== Contact Section End --> */}
    </div>
  );
};

export default PageContact;
