import React, { FC, useState } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import imagePng from "images/finder.png";
import SectionPlotFinder, { SearchTab2 } from "components/HeroSearchForm/ViewMapForm";
import Map from "components/PlotListing";
import PlotListingTable from 'components/PlotListing/plotListingTable'
export interface PlotFinderPageProps {
  className?: string;
}
const PlotFinderPage: FC<PlotFinderPageProps> = ({ className = "" }) => {
  const [tabActive, setTabActive] = useState<SearchTab2>('View Maps');

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />
      <div className={`nc-SectionHero px-20 flex flex-col-reverse lg:flex-col relative ${className}`}
        data-nc-id="SectionHero"
      >
        {/* <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-0 " /> */}

        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
            <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
              Plot Finder
            </h2>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              Pakistan's Leading Web Application For Plot
              Listing On Societies Map
            </span>
            <ButtonPrimary>Start your search</ButtonPrimary>
          </div>
          <div className="flex-grow">
            <img className="w-full" src='/assets/finder.png' alt="hero" />
          </div>
        </div>


        <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
          <SectionPlotFinder tabActive={tabActive} setTabActive={setTabActive} />
        </div>
      </div>


      <div className="container px-20  relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <div className="relative py-16">
          {
            tabActive === 'View Maps' ?
              <Map />
              : <PlotListingTable />
          }
          {/* <img className="w-full" src={imagePng2} alt="hero" /> */}
        </div>
      </div>
      <div className="container px-20  relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        <div className="relative py-16">
          <SectionSubscribe2 />
        </div>
      </div>
    </div>
  )
}

export default PlotFinderPage