import React, { FC, useState, Fragment, useContext, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import Image from "../../images/avatars/download.png";
import { Popover, Transition } from "@headlessui/react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import PropertyCardH from "components/PropertyCardH/PropertyCardH";
import Pagination from "shared/Pagination/Pagination";
import { BuyRentContext } from "context/property/BuyRentContext";
import { useParams } from "react-router-dom";
import pinContext from "context/pin/pinContext";
import { API } from "utils/config";
import AgentMoreFilter from "components/HeroSearchForm/AgentMoreFilter";
import AgentFilters from "components/HeroSearchForm/AgentFilters";
import PropertySection from "./PropertySection";
import AgentActiveListingTab from "./AgentActiveListingTab";
import AgentProfileTab from "./AgentProfileTab";

const DEMO_EXPERIENCES = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);
export interface AgentDetailPageProps {
  agencyNameProps?: any;
}

const AgentDetailPage: FC<AgentDetailPageProps> = () => {
  // TabsShifting Start
  const [showhide, setShowhide] = useState("Profile");
  const handleshow = (e: any) => {
    const getshow = e.target.value;
    setShowhide(getshow);
  };
  // TabsShifting End

  type QuizParams = {
    id: any;
  };
  let { id } = useParams<any>();
  console.log(id, "agentid");

  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  const {
    singleAgent,
    getSingleAgentById,
    getAllApproveAgencies,
    approveAgencies,
    singleConsultant,
  } = useContext(pinContext);
  const { getMyProperties, properties }: any = useContext(BuyRentContext);
  useEffect(() => {
    // debugger
    getMyProperties(id);
  }, [id]);
  // console.log(singleConsultant?.id[0]?.fname,"singleConsultant")
  // console.log(properties, 'properties')

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  // const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  useEffect(() => {
    getSingleAgentById(id);
  }, [id]);

  console.log(singleAgent?.id, "singleAgentdetail");
  useEffect(() => {
    getAllApproveAgencies();
  }, [approveAgencies]);
  console.log(approveAgencies?.data?.[0]._id, "approveAgencies");

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className="bg-red-50 lg:py-10 lg:py-10 py-14">
        <div className="container">
          {singleAgent?.id?.map((item: any) => (
            <div className="lg:grid md:grid grid-cols-3 hidden">
              <div className="col-span-1">
                <div className="rounded-full">
                  <img
                    src={
                      item?.userImage
                        ? `${API}/uploads/${item?.userImage}`
                        : Image
                    }
                    alt=""
                    className="rounded-full lg:w-[15vw] lg:h-[35vh] md:[30vw] md:h-[20vh] w-[20vh] h-48 border border-[#223A64] shadow-lg"
                  />
                </div>
              </div>
              <div className="col-span-2 lg:px-0 px-5 lg:py-8 md:py-10 py-5">
                <div className="flex flex-col items-start">
                  <span className="capitalize px-5 bg-red-300 text-white text-xl font-semibold py-1 rounded-md my-1.5 text-center">
                    {item?.fname}
                  </span>
                  <span className="capitalize px-5 bg-red-300 text-white py-1 rounded-md my-1.5 text-center">
                    {singleConsultant?.id[0]?.agencyName}
                  </span>
                  <span className="capitalize px-5 bg-red-300 text-white py-1 rounded-md my-1.5 text-center">
                    No of Properties
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* MObile Section */}
      {singleAgent?.id?.map((item: any) => (
        <div className="lg:hidden md:hidden block">
          <div className="relative -mt-16 z-1 flex justify-center">
            <img
              src={
                item?.userImage ? `${API}/uploads/${item?.userImage}` : Image
              }
              alt=""
              className="rounded-full w-[30vw] h-[15vh]"
            />
          </div>
          <div className="flex flex-col">
            <span className="capitalize text-xl font-semibold pb-1 text-center">
              {item?.fname}
            </span>
            <span className="capitalize text-base pb-1 text-center">
              {singleConsultant?.id[0]?.agencyName}
            </span>
            <span className="capitalize text-sm pb-1 text-center">
              (No of Properties)
            </span>
          </div>
          <div className="px-5 py-3 flex justify-center">
            <button className="bg-red-100 hover:bg-red-300 rounded-md lg:text-md text-xs text-center text-[#223A64] hover:text-white py-2.5 px-3 mx-2 capitalize">
              <i className="las la-share"></i> Share Profile
            </button>
            <button className="bg-[#223A64] hover:bg-[#1f345c] text-white rounded-md lg:text-md text-xs text-center py-2.5 px-3 mx-2 capitalize">
              <i className="las la-envelope"></i> Email Agent
            </button>
            <button className="bg-[#223A64] hover:bg-[#1f345c] text-white rounded-md lg:text-md text-xs text-center py-2.5 px-3 mx-2 capitalize">
              <i className="las la-phone"></i> Call Agent
            </button>
          </div>

          <div className="container flex justify-start">
            <button
              className="capitalize text-[#223A64] focus:border-b-[3px] py-2.5 focus:border-[#223A64] lg:hidden block"
              onClick={handleshow}
              value="Profile"
              autoFocus
            >
              Profile
            </button>
            <button
              className="capitalize text-[#223A64] focus:border-b-[3px] py-2.5 focus:border-[#223A64] lg:hidden block mx-5"
              onClick={handleshow}
              value="Active Listings"
            >
              Active Listings
            </button>
          </div>
          <hr />
        </div>
      ))}
      {/* End of Mobile Section */}

      <div className="container border-b-2 border-gray-300 lg:grid md:grid grid-cols-2 hidden">
        <div className="col-span-1 md:flex justify-start lg:block hidden">
          <button className="capitalize text-[#223A64] align-bottom border-b-[3px] py-2.5 border-[#223A64] lg:block hidden">
            Active listings
          </button>
          <button
            className="capitalize text-[#223A64] focus:border-b-[3px] py-2.5 focus:border-[#223A64] lg:hidden block"
            onClick={handleshow}
            value="Profile"
            autoFocus
          >
            Profile
          </button>
          <button
            className="capitalize text-[#223A64] focus:border-b-[3px] py-2.5 focus:border-[#223A64] lg:hidden block mx-5"
            onClick={handleshow}
            value="Active Listings"
          >
            Active Listings
          </button>
        </div>
        <div className="col-span-1 flex justify-end">
          <button className="bg-red-100 hover:bg-red-300 rounded-md lg:text-sm text-xs text-center text-[#223A64] hover:text-white py-2 px-3 m-1 capitalize">
            <i className="las la-share"></i> Share Profile
          </button>
          <button className="bg-[#223A64] hover:bg-[#1f345c] text-white rounded-md lg:text-sm text-xs text-center py-2 px-3 m-1 capitalize">
            <i className="las la-envelope"></i> Email Agent
          </button>
          <button className="bg-[#223A64] hover:bg-[#1f345c] text-white rounded-md lg:text-sm text-xs text-center py-2 px-3 m-1 capitalize lg:block md:block hidden">
            <i className="las la-phone"></i> Call Agent
          </button>
        </div>
      </div>

      <div className="container lg:grid lg:grid-cols-8 md:grid-cols-8 grid-cols-1 gap-4 hidden">
        <div className="lg:col-span-6 col-span-6 md:col-span-4">
          <AgentFilters />
          <div className="lg:w-6/12 w-full">
            <div className="flex justify-content-between">
              <span className="text-sm leading-8">
                Showing Properties sorted by &nbsp;
              </span>
              <div className="border border-gray-400 rounded-md px-4 py-1.5 text-sm">
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={` ${open
                          ? "text-black focus:outline-none w-full flex justify-between"
                          : "flex justify-between w-full"
                          }`}
                      >
                        <div className="flex justify-between w-full">
                          <button className="inline-block align-middle h-full">
                            Popular &nbsp;
                          </button>
                          <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                        </div>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute pb-2 bg-white dark:bg-neutral-800 z-50 mt-3 left-0 -ml-5 shadow-xl border w-36 rounded-md">
                          <div className="pt-2 px-2 relative flex flex-col">
                            <button className="border py-1.5 hover:bg-red-100">
                              Popular
                            </button>
                            <button className="border py-1.5 hover:bg-red-100">
                              Newest
                            </button>
                            <button className="border py-1.5 hover:bg-red-100">
                              Lowest Price
                            </button>
                            <button className="border py-1.5 hover:bg-red-100">
                              Highest Price
                            </button>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
          </div>
          <div className="">
            <PropertySection />
          </div>
          <div className="flex flex-col mt-16 mb-10 justify-center items-center">
            <Pagination />
            <button className="border-2 border-[#345886] text-[#345886] hover:bg-red-100 rounded-3xl px-5 py-2 my-4 capitalize">
              Show More
            </button>
          </div>
        </div>
        <div className="lg:col-span-2 col-span-2 md:col-span-4 text-left pt-4">
          <AgentProfileTab />
        </div>
      </div>

      <div className="container lg:hidden block">
        {showhide === "Profile" && (
          <div className="py-5">
            <AgentProfileTab />
          </div>
        )}
        {showhide === "Active Listings" && (
          <div className="">
            <AgentActiveListingTab />
          </div>
        )}
      </div>
    </>
  );
};

export default AgentDetailPage;
