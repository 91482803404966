import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

interface Project {
    _id: string;
    name: string;
}

interface AutocompleteProps {
    suggestions: Project[];
    placeholder: string;
    onInputChange: any;
    value: string;
    onSelectionChange: (selectedProject: Project) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ suggestions, onSelectionChange, placeholder, onInputChange, value }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState<Project[]>([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [input, setInput] = useState<string>(value);
    const autocompleteRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setInput(value);
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target as Node)) {
                setShowSuggestions(false); // Close suggestions if click is outside the component
            }
        };
        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const userInput = e.target.value;
        onInputChange(userInput);
        setInput(userInput);
        const filtered = suggestions?.filter(
            suggestion => suggestion?.name?.toLowerCase().includes(userInput?.toLowerCase())
        );
        setFilteredSuggestions(filtered);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
    };

    const onFocus = () => {
        setFilteredSuggestions(suggestions);
        setShowSuggestions(true);
    };

    const onClick = (suggestion: Project) => {
        setFilteredSuggestions([]);
        setInput(suggestion.name);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
        onSelectionChange(suggestion);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && showSuggestions) {
            const selectedSuggestion = filteredSuggestions[activeSuggestionIndex];
            setInput(selectedSuggestion?.name);
            setShowSuggestions(false);
            onSelectionChange(selectedSuggestion); // Notify the parent component about the selection
            e.preventDefault();
        } else if (e.key === "ArrowUp") {
            if (activeSuggestionIndex === 0) return;
            setActiveSuggestionIndex(activeSuggestionIndex - 1);
            e.preventDefault();
        } else if (e.key === "ArrowDown") {
            if (activeSuggestionIndex + 1 >= filteredSuggestions.length) return;
            setActiveSuggestionIndex(activeSuggestionIndex + 1);
            e.preventDefault();
        }
    };

    const SuggestionsListComponent = () => {
        if (showSuggestions) {
            if (filteredSuggestions.length > 0) {
                return (
                    <ul className="absolute z-10 w-full pb-5 mt-2 rounded-lg bg-white dark:bg-slate-800 shadow-md max-h-40 overflow-auto">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className = "p-2 px-3 cursor-pointer dark:text-black hover:bg-[#345886] hover:text-white";
                            if (index === activeSuggestionIndex) {
                                className += " bg-gray-200"; // Highlight the active suggestion
                            }
                            // Split the suggestion for highlighting matching text
                            const parts = suggestion?.name?.split(new RegExp(`(${input})`, 'gi'));
                            return (
                                <li className={`${className} tracking-wide`} key={suggestion._id} onClick={() => onClick(suggestion)}>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.toLowerCase() === input.toLowerCase() ? 'bold' : 'normal' }}>
                                            {part}
                                        </span>
                                    ))}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div className="absolute z-10 w-full py-20 text-center text-base font-semibold mt-2.5 rounded-lg bg-white dark:bg-slate-800 p-2 text-gray-700 shadow-md">
                        <div className="text-center">
                            <p className='text-center dark:text-white flex justify-center'><FaInfoCircle className='w-12 h-12' /></p>
                            <br />
                            <em className='dark:text-white'>No data available.</em>
                        </div>
                    </div>
                );
            }
        }
        return null;
    };


    return (
        <div className="relative w-full" ref={autocompleteRef}>
            <input
                type="text"
                onChange={onChange}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                value={input}
                className="w-full py-3 px-4 form-control dark:bg-slate-800 border-0 rounded-md focus:outline-none focus:ring-2 dark:placeholder-white focus:ring-none focus:border-transparent"
                placeholder={placeholder}
            />
            {SuggestionsListComponent()}
        </div>
    );
};

export default Autocomplete;
