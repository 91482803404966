import React, { useEffect, useState, Fragment } from "react";
import { FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import BuyRentEstimateFilter from "./BuyRentEstimateFilter";
import PropertyTypeEstimateInput from "./PropertyTypeEstimateInput";
import BedsEstimateFormInput from "./BedsEstimateFormInput";
import BathEstimateFormInput from "./BathEstimateFormInput";
import ResidentialCommercialFilter from "./ResidentialCommercialFilter";
import axios from "axios";
import ResidentialCommercialDropDown from "./ResidentialCommercialDropDown";
import Swal from "sweetalert2";
import LoginModal from "components/LoginModal/LoginModal";

export interface GetEstimateFormProps {
  haveDefaultValue?: boolean;
  afterChange?: any;
}

const GetEstimateForm: FC<GetEstimateFormProps> = ({
  haveDefaultValue,
  afterChange,
}) => {
  const apiUrl = "http://api.emporiontest.com/api/estimationValue/create";

  const [showModalLogin, setShowModalLogin] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [areaName, setAreaName] = useState("");
  const [buyRent, setBuyRent] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [beds, setBeds] = useState("");
  const [baths, setBaths] = useState("");
  const [sqft, setSqft] = useState("");

  const handleAreaNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAreaName(event.target.value);
  };

  const handleBuyRentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBuyRent(event.target.value);
  };

  const handlePropertyTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPropertyType(event.target.value);
  };

  const handleBedsChange = (beds: string) => {
    setBeds(beds);
  };

  const handleBathsChange = (baths: string) => {
    setBaths(baths);
  };

  const handleSqftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSqft(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (localStorage.getItem("authToken")) {
      const data = {
        areaName: areaName,
        purpose: buyRent,
        propertyType: propertyType,
        beds: beds,
        baths: baths,
        sqft: sqft,
      };
      const tk = localStorage.getItem("authToken");
      axios
        .post(apiUrl, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tk}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            Swal.fire({
              title: "Thank you for sharing your thoughts!",
              text: "We have recorded your responses to come up with a new and improved version of EstimationValue™. Stay tuned!",
              icon: "success",
              confirmButtonColor: "#e74c3c",
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setShowModalLogin(true);
    }
  };

  const renderForm = () => {
    return (
      <>
        <form
          onSubmit={handleSubmit}
          className="pb-3 px-4 pt-3 bg-zinc-900 bg-opacity-80 dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700"
        >
          <div className="registration-form text-dark text-start">
            <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 lg:gap-3 md:gap-2">
              <div className="col-span-1">
                <div className="relative mt-2 !h-12 bg-white rounded-lg">
                  <BuyRentEstimateFilter
                    handleBuyRentTypeChange={handleBuyRentTypeChange}
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="relative filter-border mt-2">
                  <input
                    name="name"
                    type="text"
                    value={areaName}
                    onChange={handleAreaNameChange}
                    id="job-keyword"
                    className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0 !h-12 w-full rounded-lg"
                    placeholder="Enter Location"
                    required
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="relative">
                  {/* <div className="mt-2 bg-white rounded-lg z-2 py-3 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3">
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={` ${open ? "text-black focus:outline-none w-full flex justify-between" : "flex justify-between w-full"
                            }`}
                        >
                          <div className="flex justify-between w-full">

                            <button className="inline-block align-middle h-full capitalize">Property Type</button>
                            <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-40 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 mt-5 py-5 sm:py-6 px-4 sm:px-8 -ml-3 rounded-xl shadow-xl">
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div> */}
                  <div className="relative mt-2 !h-12 bg-white rounded-lg">
                    <ResidentialCommercialDropDown
                      handlePropertyTypeChange={handlePropertyTypeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 lg:gap-3 md:gap-2 lg:mt-1">
              <div className="relative col-span-1">
                <div className="mt-2 bg-white rounded-lg z-2 py-3 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3">
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={` ${open
                            ? "text-black focus:outline-none w-full flex justify-between"
                            : "flex justify-between w-full"
                            }`}
                        >
                          <div className="flex justify-between w-full">
                            <button className="inline-block align-middle h-full">
                              Beds
                            </button>
                            <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute pb-4 bg-white dark:bg-neutral-800 overflow-hidden z-10 mt-6 sm:mt-5 shadow lg:w-72 w-72 -ml-3 rounded-lg">
                            <div className="pt-2 px-4">Beds</div>
                            <BedsEstimateFormInput
                              handleBedsChange={handleBedsChange}
                            />
                            {/* <div className="flex justify-between px-3 mt-2">
                            <button className="rounded-md bg-white text-center text-[#223A64] border-2 border-[#223A64] mx-1 w-6/12">RESET</button>
                            <button className="rounded-md bg-[#223A64] text-center mx-1 w-6/12 py-2 text-white">DONE</button>
                          </div> */}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </div>

              <div className="col-span-1">
                <div className="mt-2 bg-white rounded-lg z-2 py-3 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3">
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={` ${open
                            ? "text-black focus:outline-none w-full flex justify-between"
                            : "flex justify-between w-full"
                            }`}
                        >
                          <div className="flex justify-between w-full">
                            <button className="inline-block align-middle h-full">
                              Baths
                            </button>
                            <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute pb-4 bg-white dark:bg-neutral-800 overflow-hidden z-10 mt-6 sm:mt-5 shadow lg:w-72 w-84 lg:-ml-3 md:-ml-4 -ml-3 rounded-lg">
                            <div className="px-4 pt-2">Baths</div>
                            <BathEstimateFormInput
                              handleBathsChange={handleBathsChange}
                            />
                            {/* <div className="flex justify-between px-3 mt-2">
                            <button className="rounded-md bg-white text-center text-[#223A64] border-2 border-[#223A64] mx-1 w-6/12">RESET</button>
                            <button className="rounded-md bg-[#223A64] text-center mx-1 w-6/12 py-2 text-white">DONE</button>
                          </div> */}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </div>

              <div className="col-span-1">
                <div className="relative filter-border lg:mt-2 md:mt-2 mt-2">
                  <input
                    name="name"
                    type="text"
                    value={sqft}
                    onChange={handleSqftChange}
                    className="form-input bg-gray-50 dark:bg-slate-800 border-0 !h-12 w-full rounded-lg"
                    placeholder="e.g. 1078 sqft"
                    required
                  />
                </div>
              </div>

              <div className="col-span-1 relative filter-border cursor-pointer lg:mt-2 mt-2 md:mt-2">
                <input
                  type="submit"
                  id="search-sell"
                  name="search"
                  className="cursor-pointer btn bg-[#223A64] hover:bg-[#c91212] text-white submit-btn w-full !h-12 rounded-lg"
                  value="Get Estimate"
                />
              </div>
            </div>
            <div className="pt-3">
              <div className="flex justify-end">
                <button className="text-white text-sm font-thin">
                  Reset Search
                </button>
              </div>
            </div>
            {/* <!--end grid--> */}
          </div>
        </form>

        <LoginModal
          showModalLogin={showModalLogin}
          setShowModalLogin={setShowModalLogin}
          afterChange={afterChange}
        />
      </>
    );
  };

  return renderForm();
};

export default GetEstimateForm;
