
import __plotListing from "./jsons/__plotListing.json"
import __stayListing from "./jsons/__stayListing.json"
import __buyListing from "./jsons/__buyListing.json"
import __carsListing from "./jsons/__carsListing.json"
import __experiencesListing from "./jsons/__experiencesListing.json"
import { DEMO_STAY_CATEGORIES, DEMO_EXPERIENCES_CATEGORIES, DEMO_BUY_CATEGORIES } from "./taxonomies"
import { BuyDataType, CarDataType, ExperiencesDataType, StayDataType } from "./types"
import { DEMO_AUTHORS } from "./authors"
import car1 from "images/cars/1.png"
import car2 from "images/cars/2.png"
import car3 from "images/cars/3.png"
import car4 from "images/cars/4.png"
import car5 from "images/cars/5.png"
import car6 from "images/cars/6.png"
import car7 from "images/cars/7.png"
import car8 from "images/cars/8.png"
import car9 from "images/cars/9.png"
import car10 from "images/cars/10.png"
import car11 from "images/cars/11.png"
import car12 from "images/cars/12.png"
import car13 from "images/cars/13.png"
import car14 from "images/cars/14.png"
import car15 from "images/cars/15.png"
import car16 from "images/cars/16.png"
import plotimg from "images/plot.svg"
import { useContext } from "react"
import { BuyRentContext } from "context/property/BuyRentContext"
const carsImgs = [
  car1,
  car2,
  car3,
  car4,
  car5,
  car6,
  car7,
  car8,
  car9,
  car10,
  car11,
  car12,
  car13,
  car14,
  car15,
  car16,
]
export const plotImgs = [
  plotimg,
  plotimg,
  plotimg,
  plotimg,

];
// const {getMyProperties,properties }:any = useContext(BuyRentContext)

// useEffect(() => {
//    getMyProperties()
// },[])
// let property = properties?.property?.[0] || {};
// console.log(properties?.property?.[0],"buyrentproperties")

const DEMO_STAY_LISTINGS = __stayListing.map(
  (post: any, index: any): StayDataType => {
    //  ##########  GET CATEGORY BY CAT ID ######## //
    const category = DEMO_STAY_CATEGORIES.filter(
      (taxonomy) => taxonomy.id === post.listingCategoryId
    )[0]

    return {
      ...post,
      id: `stayListing_${index}_`,
      saleOff: !index ? "-20% today" : post.saleOff,
      isAds: !index ? true : post.isAds,
      author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
      listingCategory: category,
    }
  }
)

const DEMO_EXPERIENCES_LISTINGS = __experiencesListing.map(
  (post, index): ExperiencesDataType => {
    //  ##########  GET CATEGORY BY CAT ID ######## //
    const category = DEMO_EXPERIENCES_CATEGORIES.filter(
      (taxonomy) => taxonomy.id === post.listingCategoryId
    )[0]

    return {
      ...post,
      id: `experiencesListing_${index}_`,
      saleOff: !index ? "-20% today" : post.saleOff,
      isAds: !index ? true : post.isAds,
      author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
      listingCategory: category,
    }
  }
)

const DEMO_CAR_LISTINGS = __carsListing.map((post, index): CarDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_EXPERIENCES_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0]

  return {
    ...post,
    id: `carsListing_${index}_`,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
    featuredImage: carsImgs[index],
  }
})


const DEMO_Plot_LISTINGS = __plotListing.map((post, index): CarDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_EXPERIENCES_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `carsListing_${index}_`,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
    featuredImage: plotImgs[index],
  };
});
const DEMO_BUY_LISTINGS = __buyListing.map((post, index): BuyDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_BUY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListing_${index}_`,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});
export {
  DEMO_STAY_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_CAR_LISTINGS,
  carsImgs,
  DEMO_Plot_LISTINGS,
  DEMO_BUY_LISTINGS,
}
function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.")
}
