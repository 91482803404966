import React, { FC, useState, Fragment, useContext, useEffect } from "react";
import pinContext from "context/pin/pinContext";

const AgentProfileTab = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const {
    singleAgent,
    getSingleAgentById,
    getAllApproveAgencies,
    approveAgencies,
    singleConsultant,
  } = useContext(pinContext);
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      {singleAgent?.id?.map((item: any) => (
        <div className="text-left">
          <div className="text-xl font-semibold">About</div>
          <div className="py-1.5">
            <span className="font-semibold text-sm">Languages: </span>
            <span className="text-sm capitalize"> {item.languages}</span>
          </div>
          <div className=" py-1.5">
            <span className="font-semibold text-sm">Specialities: </span>
            <span className="text-sm capitalize"> {item.specialities}</span>
          </div>
          <div className="py-1.5">
            <span className="font-semibold text-sm">Service Areas: </span>
            <span className="text-sm capitalize"> {item.serviceArea}</span>
          </div>
          <div className="py-1.5">
            <span className="font-semibold text-sm">Properties : </span>
            <span className="text-sm capitalize"> {item.propertiesfor} </span>
          </div>
          <div className="py-1.5">
            <span className="font-semibold text-sm">Description : </span>
            <span className="text-sm capitalize line-clamp-2">
              {item.description}{" "}
            </span>
          </div>
          <div className="py-1.5">
            <span className="font-semibold text-sm">Experience : </span>
            <span className="text-sm text-justify">
              {expanded
                ? item.description
                : item.description.slice(0, 150) + "..."}
            </span>
            <button
              className="text-[#223A64]"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Read Less" : "Read More"}
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default AgentProfileTab;
