import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import { Range } from "rc-slider";
import millify from "millify";

export interface NewPriceRangeInputProps {
    onChange?: (data: any) => void;
    fieldClassName?: string;
    rangePrices?: [number, number];
    setRangePrices?: (range: [number, number]) => void;
    filter?: { priceRange: [number, number] };
}

const NewPriceRangeInput: FC<NewPriceRangeInputProps> = ({
    onChange,
    fieldClassName = "[ overflow-hidden ]",
    rangePrices = [10000000, 90000000000],
    setRangePrices,
    filter,
}) => {
    const [localRange, setLocalRange] = useState<[number, number]>(rangePrices);

    useEffect(() => {
        if (filter?.priceRange) {
            setLocalRange(filter.priceRange);
        }
    }, [filter?.priceRange]);

    const handleRangeChange = (newRange: [number, number]) => {
        setLocalRange(newRange);
        if (setRangePrices) {
            setRangePrices(newRange);
        }
    };

    return (
        <Popover className='bg-white rounded-md z-2 lg:py-3 md:py-1 sm:py-2 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3'>
            {({ open, close }) => (
                <>
                    <Popover.Button
                        className={`flex text-left w-full items-center ${fieldClassName} focus:outline-none cursor-pointer ${open ? "" : ""}`}
                    >
                        <div className='flex-row'>
                            <span className='inline-block text-md'>
                                {`Rs ${millify(localRange[0])} - Rs ${millify(localRange[1])}`}
                            </span>
                            <span className='block -mt-2 text-[10px] font-semibold text-gray-500'>
                                (Choose Price Range)
                            </span>
                        </div>
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                    >
                        <Popover.Panel className='absolute left-0 lg:-ml-3 md:-ml-40 ml-0 lg:right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-2xl shadow-xl border'>
                            <div className='relative flex flex-col space-y-8'>
                                <div className='space-y-5'>
                                    <span className='font-medium'>Price Range</span>
                                    <Range
                                        className='text-[#345886]'
                                        min={9000000}
                                        max={99000000000}
                                        value={localRange}
                                        allowCross={false}
                                        step={900000}
                                        onChange={(value) => handleRangeChange([value[0], value[1]])}
                                    />
                                </div>

                                <div className='flex justify-between space-x-3'>
                                    <div>
                                        <label
                                            htmlFor='minPrice'
                                            className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
                                        >
                                            Min price
                                        </label>
                                        <div className='mt-1 relative rounded-md'>
                                            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                                <span className='text-neutral-500 sm:text-sm'>Rs </span>
                                            </div>
                                            <input
                                                type='text'
                                                disabled
                                                name='minPrice'
                                                id='minPrice'
                                                className='focus:ring-[#345886] focus:border-[#345886] block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                                                value={millify(localRange[0])}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor='maxPrice'
                                            className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
                                        >
                                            Max price
                                        </label>
                                        <div className='mt-1 relative rounded-md'>
                                            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                                <span className='text-neutral-500 sm:text-sm'>Rs </span>
                                            </div>
                                            <input
                                                disabled
                                                type='text'
                                                name='maxPrice'
                                                id='maxPrice'
                                                className='focus:ring-primary-500 focus:border-priring-[#345886] block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                                                value={millify(localRange[1])}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};

export default NewPriceRangeInput;