import React, { FC, useEffect, useMemo, useRef } from "react";
import { Dialog } from "@headlessui/react";
import NextPrev from "shared/NextPrev/NextPrev";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Glide from "@glidejs/glide";
import NcImage from "shared/NcImage/NcImage";
import useNcId from "hooks/useNcId";

export interface SingleModalPhotoProps {
    img: string;
    onClose: () => void;
    isOpen: boolean;
    uniqueClassName: string;
}

const SingleModalPhoto: FC<SingleModalPhotoProps> = ({
    img,
    isOpen,
    onClose,
    uniqueClassName = "",
}) => {
    const UNIQUE_CLASS =
        `ModalPhotos_single_glide_${uniqueClassName}` + useNcId();
    let completeButtonRef = useRef(null);

    let MY_GLIDEJS = useMemo(() => {
        return new Glide(`.${UNIQUE_CLASS}`, {
            gap: 10,
            perView: 1,
            startAt: 0,
        });
    }, [UNIQUE_CLASS]);

    useEffect(() => {
        if (!isOpen) return;
        setTimeout(() => {
            MY_GLIDEJS.mount();
        }, 10);
    }, [MY_GLIDEJS, isOpen, UNIQUE_CLASS]);

    const renderSlider = () => {
        return (
            <div
                className={`modalPhotos-single-gallery ${UNIQUE_CLASS} group relative flex flex-col z-50 w-full h-full`}
            >
                <div
                    className="controls_nav glide__bullets mt-8 mb-5"
                    data-glide-el="controls[nav]"
                >
                    <div className="text-center hidden">
                        <span className="text-3xl font-semibold">1</span>
                        <span> / 1</span>
                    </div>
                </div>
                <div
                    className="glide__track max-h-full h-full relative z-50"
                    data-glide-el="track"
                >
                    <ul className="glide__slides h-full">
                        <li className="glide__slide relative h-full">
                            <NcImage
                                src={img}
                                containerClassName="w-full h-full"
                                className="absolute object-contain w-full max-h-screen"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const renderModalPhotos = () => {
        return (
            <Dialog
                initialFocus={completeButtonRef}
                as="div"
                className="fixed inset-0 z-max overflow-y-auto dark bg-neutral-800 text-neutral-200 hiddenScrollbar"
                onClose={onClose}
                open={isOpen}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-white dark:bg-neutral-800" />
                    <div
                        ref={completeButtonRef}
                        className="absolute left-2 top-2 md:top-4 md:left-4 z-max"
                    >
                        <ButtonClose className="sm:w-11 sm:h-11" onClick={onClose} />
                    </div>
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>

                    <div className="relative inline-block w-full max-w-5xl py-8 h-screen align-middle mx-auto">
                        {renderSlider()}
                    </div>
                </div>
            </Dialog>
        );
    };

    return renderModalPhotos();
};

export default SingleModalPhoto;
