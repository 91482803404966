import React, { useContext } from 'react';
import pinContext from "context/pin/pinContext";
import Autocomplete from 'components/HeroSearchForm/Autocomplete';

interface Project {
    _id: string;
    name: string;
}

interface ConsultantProps {
    id: number;
    fname: string;
    city: string;
    languages: string[];
}
const FilterPage: React.FC = () => {
    const { consultantsData, setFilters } = useContext(pinContext);

    const nameSuggestions = consultantsData?.map((consultant: any) => ({
        _id: consultant?._id?.toString(),
        name: consultant?.fname
    }));

    const citySuggestions = consultantsData?.map((consultant: any) => ({
        _id: consultant?._id?.toString(),
        name: consultant?.city
    }));


    const languageSuggestions: Project[] = Array.from(new Set(consultantsData?.flatMap((consultant: ConsultantProps) => consultant?.languages) as string[]))
        .map((language: string, index: number) => ({
            _id: index?.toString(),
            name: language
        }));

    const handleSelectionChange = (filterType: string) => (project: Project) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: project?.name
        }));
    };

    const handleInputChange = (filterType: string) => (value: string) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: value
        }));
    };

    return (
        <div className='grid grid-cols-3 gap-4'>

            <div className="col-span-1 border rounded-lg">
                <Autocomplete
                    value=''
                    suggestions={nameSuggestions}
                    placeholder="Search by name"
                    onInputChange={(value: any) => handleInputChange('name')(value)}
                    onSelectionChange={handleSelectionChange('name')}
                />
            </div>

            <div className="col-span-1 border rounded-lg">
                <Autocomplete
                    value=''
                    suggestions={citySuggestions}
                    placeholder="Search by city"
                    onInputChange={(value: any) => handleInputChange('city')(value)}
                    onSelectionChange={handleSelectionChange('city')}
                />
            </div>

            <div className="col-span-1 border rounded-lg">
                <Autocomplete
                    suggestions={languageSuggestions}
                    value=''
                    placeholder="Search by language"
                    onInputChange={(value: any) => handleInputChange('languages')(value)}
                    onSelectionChange={handleSelectionChange('languages')}
                />
            </div>
        </div>
    );
};

export default FilterPage;
