import React from "react";
import { GoogleLogin } from "react-google-login";

const GoogleLoginButton = () => {
  const responseGoogle = (response: any) => {
    console.log(response.name);
    console.log(response.email);
  };

  return (
    <div className="">
      <GoogleLogin
        clientId="885794475045-o1r637kjaikh2j24re3polga1faiprst.apps.googleusercontent.com"
        render={(renderProps) => (
          <button
            className="btn bg-white hover:bg-gray-100 flex justify-center w-full text-center border py-2.5 rounded dark:text-black"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
              <path
                fill="#4285f4"
                d="M23.52 12.27a13.79 13.79 0 0 0-.22-2.46H12v4.64h6.46a5.52 5.52 0 0 1-2.4 3.62v3.02h3.88a11.7 11.7 0 0 0 3.58-8.82z"
              ></path>
              <path
                fill="#34a853"
                d="M12 24a11.46 11.46 0 0 0 7.94-2.9l-3.88-3.02a7.24 7.24 0 0 1-10.78-3.8h-4v3.11A12 12 0 0 0 12 24z"
              ></path>
              <path
                fill="#fbbc05"
                d="M5.29 14.28a7.1 7.1 0 0 1 0-4.56v-3.1H1.28a12.01 12.01 0 0 0 0 10.77z"
              ></path>
              <path
                fill="#ea4335"
                d="M12 4.77a6.48 6.48 0 0 1 4.59 1.8l3.44-3.44A11.53 11.53 0 0 0 12 0 12 12 0 0 0 1.27 6.61l4.01 3.11A7.15 7.15 0 0 1 12 4.77z"
              ></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
            </svg>
            &nbsp; Continue with Google
          </button>
        )}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        className=""
      />
    </div>
  );
};

export default GoogleLoginButton;
