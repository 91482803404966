import SectionHero from "components/SectionHero/SectionHeromain"
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2"
// import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionOurFinance from "components/SectionOurFeatures/SectionOurFinance"
import SectionAutomation from "components/SectionOurFeatures/SectionAutomation"
import SectionTenants from "components/SectionOurFeatures/SectionTenants"
import SectionScreenTenant from "components/SectionOurFeatures/SectionScreenTenant"
// import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork2"
import BackgroundSection from "components/BackgroundSection/BackgroundSection"
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism"
// import { TaxonomyType, TaxonomyTypeB } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox"
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox"
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor"
import SectionVideos from "./SectionVideos"
import SectionClientSay from "components/SectionClientSay/SectionClientSay"
import { useContext, useEffect } from "react"
import { BuyRentContext } from "context/property/BuyRentContext"
import Heading from "components/Heading/Heading"
import { useLocation } from "react-router-dom"
// const DEMO_CATS: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "/listing-stay",
//     name: "Indigo Heights",
//     taxonomy: "category",
//     desc:"PKR 3.5 cr - 12.5 cr",
//     count: 30,
//     thumbnail:
//       "https://media.zameen.com/thumbnails/76712216-400x300.jpeg",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "SIXTY6 GULBERG",
//     taxonomy: "category",
//     desc:"PKR 5.5 cr - 18.5 cr",
//     count: 35,

//     thumbnail:
//       "https://saibanproperties.com/wp-content/uploads/2021/06/sixty6-builing-893x1024.jpeg",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "DownTown",
//     taxonomy: "category",
//     desc:"PKR 62 lac - 12.5 cr",
//     count: 35,
//     thumbnail:
//       "https://www.redrealestate.com.pk/wp-content/uploads/2020/01/downtown-mall-liberty-645x430.png",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Goldcrest Views",
//     taxonomy: "category",
//     desc:"PKR 3.5 cr - 12.5 cr",
//     count: 25,
//     thumbnail:
//       "https://irealprojects.com/wp-content/uploads/2021/08/Goldcrest-Views-40-stories-residential-tower.jpeg",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Defence Raya",
//     taxonomy: "category",
//     desc:"PKR 3.5 cr - 12.5 cr",
//     count: 35,
//     thumbnail:
//       "https://media.zameen.com/thumbnails/151625731-400x300.jpeg",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: " Goldcrest Mall & Residency",
//     taxonomy: "category",
//     desc:"PKR 3.5 cr - 12.5 cr",
//     count: 50,
//     thumbnail:
//       "https://vairt.com/realtor//application/views/admin/listings/photos/goldcrest_mall_residency_24609.jpg",
//   },
// ];

// const DEMO_CATS_2: TaxonomyTypeB[] = [
//   {
//     id: "1",
//     href: "/blog",
//     name: "Malamjaba Ski Resort",
//     taxonomy: "category",
//     des: "Malam Jabba is a ski resort in the Hindu Kush mountain range nearly 40 km from Saidu Sharif in Swat Valley,",

//     thumbnail:
//       "https://www.chalchalen.com/images/event/5fc933b664d76-2-days-trip-to-malam-jabba-swat-valley.png?v=1607021494",
//   },
//   {
//     id: "222",
//     href: "/blog",
//     name: "Arang Kel Kashmir",
//     taxonomy: "category",
//     des: "Nature Adventure Club trip to Kel Arang kel  Neelum Valley ",
//     thumbnail:
//       "https://upload.wikimedia.org/wikipedia/commons/e/eb/Arang_Kel%2C_Azad_Kashmir%2C_Pakistan.jpg",
//   },
//   {
//     id: "3",
//     href: "/blog",
//     name: "Shahibagh Kalam",
//     taxonomy: "category",
//     des: "Swat pakistan new tourist family spot",

//     thumbnail:
//       "https://i.pinimg.com/originals/1c/09/9a/1c099afb517ef16366b36afe2cb3c549.jpg",
//   },
//   {
//     id: "4",
//     href: "/blog",
//     name: "Bhurban Murree",
//     taxonomy: "category",
//     des: "5 Star Resort hotel Bhurban Murree",

//     thumbnail:
//       "https://i.ytimg.com/vi/4wJLH6dSAgk/maxresdefault.jpg",
//   },
//   {
//     id: "5",
//     href: "/blog",
//     name: "Gwadar",
//     taxonomy: "category",
//     des: "Gwadar Land & History: Gwadar Investment Opportunities",

//     thumbnail:
//       "https://i0.wp.com/www.opindia.com/wp-content/uploads/2020/07/EcyLlI8X0AACaxc.jpg?fit=1200%2C673&ssl=1",
//   },
// ];

function PageHome() {
  const {
    getMyProperties,
    loading,
    properties,
    perPage,
    setPerPage,
    pageNumber,
    setPageNumber,
    applyFilters,
    dispatchFilter,
  }: any = useContext(BuyRentContext)
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  if (token) {
    localStorage.setItem("authToken", token);
  }

  useEffect(() => {
    if (localStorage.getItem("category")) {
      dispatchFilter({
        type: "updatePropertyCategory",
        value: localStorage.getItem("category"),
      })
    } else {
      dispatchFilter({
        type: "updatePropertyCategory",
        value: "Residential",
      })
      localStorage.setItem("category", "Residential")
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("purpose")) {
      dispatchFilter({
        type: "updatePropertyPurpose",
        value: localStorage.getItem("purpose"),
      })
    } else {
      dispatchFilter({
        type: "updatePropertyPurpose",
        value: "Buy",
      })
      localStorage.setItem("purpose", "Buy")
    }
  }, [])

  return (
    <div className='nc-PageHome relative overflow-hidden'>
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}

      <SectionHero className='dark:bg-slate-800 dark:bg-opacity-20 bg-gradient-to-bl from-[#345886] via-[#345886] to-[#263c67] lg:pt-5 pb-16' />

      <div className='container relative space-y-10 mb-7 lg:space-y-12 lg:mb-15'>
        <div className='relative py-16'>{/* <SectionOurFeatures /> */}</div>
        <div className='relative py-16'>
          {/* <BackgroundSection /> */}

          <SectionOurFinance />
        </div>

        {/* <SectionAutomation /> */}

        <div className='relative py-16 hidden'>
          <BackgroundSection />
          <SectionTenants />
        </div>

        {/* <SectionScreenTenant /> */}
        {/* SECTION 1 */}

        <div className='relative py-16'>
          <BackgroundSection />
          <SectionHowItWork />
        </div>

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection  />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places in pakistan to visit that Imlaak recommend for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection  />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}
        <Heading
          desc="Visit the video and get to know how to login/signup and access our Landlord Portal."
        >
          Demo Video to Login/SignUp
        </Heading>
        <div className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px] will-change-transform">
          <iframe
            // src={`${vidurl}?autoplay=1`}
            src={`https://www.youtube.com/embed/hfGnkXqvGJM`}
            // title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}
      </div>
    </div>
  )
}

export default PageHome
