import { NavItemType } from "shared/Navigation2/NavigationItem2";
import { APP_PORTAL, LANDLORD_PORTAL } from "utils/config";
import ncNanoId from "utils/ncNanoId";
var token = window.localStorage.getItem("authToken");
const aboutChildrenMenus: NavItemType[] = [
    { id: ncNanoId(), href: "https://www.imlaak.com/contact-us", name: "Contact Us", targetBlank: true, },
    { id: ncNanoId(), href: "https://www.imlaak.com/privacy-policy/", name: "Privacy Policy", targetBlank: true, },
];
const blogChildrenMenus: NavItemType[] = [

    //
    { id: ncNanoId(), href: "/discussion-forum", name: "Discussion Forum" },
    { id: ncNanoId(), href: "/blog", name: "Blogs" },
    //
    { id: ncNanoId(), href: "/property-news", name: "News" },

];

export const NAVIGATION_Mobile: NavItemType[] = [
    // {
    //   id: ncNanoId(),
    //   href: "/",
    //   name: "Buy",
    //   type: "dropdown",
    //   children: demoChildMenus,
    //   isNew: true,
    // },
    {
        id: ncNanoId(),
        href: '/salePage',
        name: "Buy",
        type: "none",
        targetBlank: false
    },
    //  {
    //     id: ncNanoId(),
    //     href: '/rentPage',
    //     name: "Rent",
    //     type: "none",
    //     targetBlank: false
    // },
    {
        id: ncNanoId(),
        href: '/projects',
        name: "Projects",
        type: "none",
        targetBlank: false
    }, {
        id: ncNanoId(),
        href: "/developers",
        name: "Developers",
        type: "none",
    },
    // {
    //     id: ncNanoId(),
    //     href: '/',
    //     name: "Plot Finder",
    //     type: "none",
    //     targetBlank: false
    // },
    // {
    //     id: ncNanoId(),
    //     href: LANDLORD_PORTAL,
    //     name: "Landlord Portal",
    //     type: "none",
    //     targetBlank: true
    // },
    {
        id: ncNanoId(),
        href: '/consultantsPage',
        name: "Consultants",
        type: "none",
        targetBlank: false
    },
    {
        id: ncNanoId(),
        href: "https://www.imlaak.com/blogs-2/",
        name: "Blogs",
        type: "none",
        isNew: false,
        targetBlank: true,
    },
    {
        id: ncNanoId(),
        href: "/",
        name: "About Us",
        type: "dropdown",
        children: aboutChildrenMenus,
        isNew: false,
    },
    {
        id: ncNanoId(),
        href: '/login',
        name: "SignIn",
        type: "none",
        targetBlank: false
    },

    // {
    //   id: ncNanoId(),
    //   href: token ? undefined : 'Login',
    //   name: token ? "Logout" : 'SignIn',
    //   type: "none",
    // },
];
