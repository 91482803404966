
import React from "react";
import { Helmet } from "react-helmet";
import VerifiedSvg from "images/verified.svg";
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const EmailVerified: React.FC = () => (
    <div className="nc-Page404">
        <Helmet>
            <title>FSBO | Imlaak</title>
        </Helmet>
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
            {/* HEADER */}
            <header className="text-center max-w-2xl mx-auto space-y-2">
                <div className="flex justify-center">
                    <NcImage src={VerifiedSvg} className="w-80" />
                </div>
                <p className="block text-base text-neutral-800 sm:text-2xl font-mono dark:text-neutral-200 tracking-wider font-semibold">
                    Congratulations your email has been verified .{" "}
                    <br />
                    PLease go to Login Page
                </p>
                <div className="pt-8">
                    <ButtonPrimary href="/login">Login</ButtonPrimary>
                </div>
            </header>
        </div>
    </div>
);

export default EmailVerified;
