import React, { useEffect, useState, Fragment, useContext } from "react"
import { Popover, Transition } from "@headlessui/react"
import { BuyRentContext } from "context/property/BuyRentContext"

const AreaRange = ({ areaCategory }: any) => {
  const {
    dispatchFilter,
    filter,
    setPropertyMinArea,
    setPropertyMaxArea,
    propertyMinArea,
    propertyMaxArea,
  }: any = useContext(BuyRentContext)

  // const [areaType, setAreaType] = useState("Marla")
  const [isOpen, setOpen] = useState(false)

  const handleDropDown = () => {
    setOpen(!isOpen)
  }

  const handleMaxArea = (e: any) => {
    setPropertyMaxArea(e.target.value)

    dispatchFilter({
      type: "updatePropertyMaxArea",
      value: e.target.value,
    })
  }
  const handleMinArea = (e: any) => {
    setPropertyMinArea(e.target.value)

    dispatchFilter({
      type: "updatePropertyMinArea",
      value: e.target.value,
    })
  }
  return (
    <>
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button
              className={` ${
                open
                  ? "text-black focus:outline-none w-full flex justify-between"
                  : "flex justify-between w-full"
              }`}
            >
              <div className='flex justify-between w-full'>
                <button className='inline-block align-middle h-full'>
                  Area ({"Sq. Ft."})
                </button>
                <i className='las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full'></i>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute bg-white dark:bg-neutral-800 pb-4  z-40 lg:mt-6 mt-5 shadow-lg -ml-3 lg:w-[24vw] md:w-96 w-84 rounded-lg border shadow-xl'>
                <div className='flex justify-between pt-4 px-4'>
                  <div className='w-6/12 pr-3'>
                    <p className='text-gray-500 font-medium mb-2'>Min</p>
                    <input
                      type='text'
                      className='rounded-xl w-full'
                      placeholder='0'
                      value={propertyMinArea}
                      onChange={(e) => handleMinArea(e)}
                    />
                  </div>
                  <div className='w-6/12'>
                    <p className='text-gray-500 font-medium mb-2'>Max</p>
                    <input
                      type='text'
                      className='rounded-xl w-full'
                      placeholder='Any'
                      value={propertyMaxArea}
                      onChange={(e) => handleMaxArea(e)}
                    />
                  </div>
                </div>
                {/* <div className='flex justify-between px-5 mt-5'>
                  <button className='rounded-md bg-white text-center text-[#223A64] border-2 border-[#223A64] mx-1 w-6/12'>
                    RESET
                  </button>
                  <button className='rounded-md bg-[#223A64] text-center mx-1 w-6/12 py-2 text-white'>
                    DONE
                  </button>
                </div> */}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  )
}

export default AreaRange
