import React, { FC } from "react";
import rightImgPng from "images/tenants.png";
import NcImage from "shared/NcImage/NcImage";
// import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}
const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-0",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 mx-5 lg:mx-20 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-5" : "lg:pr-5"
        }`}
      >
       
        <h2 className="font-semibold text-left  text-4xl mt-5">Find the perfect tenants or buyer</h2>
        <span className="block mt-5 text-justify text-neutral-500 dark:text-neutral-400">
        List your properties on top marketpla, 
        and manage applications in one place. Includes customized pre-screening questions to save you time.
        </span>
        <ul className="space-y-10 mt-4">
          
        
          <li className="space-y-4">
          <ButtonPrimary >DISCOVER LISTINGS</ButtonPrimary>
          </li>
        </ul>
      </div>
      
    </div>
  );
};
export default SectionOurFeatures;