import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

export interface CareerCardProps {
    className?: string;
    data: {
        _id?: any;
        jobTitle: string;
        location: string;
        department: string;
        timeType: string;

    };
}

const CareerCard: FC<CareerCardProps> = ({ className = "", data }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <Link to={`/career_detail/${data?._id}`}>
                <div
                    className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white overflow-hidden border-b hover:shadow-lg transition-shadow space-y-6 ${className}`}
                    data-nc-id="FlightCard"
                >
                    <div
                        className={`sm:pr-20 relative lg:block md:block hidden ${className}`} data-nc-id="FlightCard">
                        <div className="grid grid-cols-5 gap-2">

                            {/*Name */}
                            <div className="col-span-2">
                                <div className="text-lg capitalize text-neutral-800 font-medium">
                                    {data.jobTitle.charAt(0).toUpperCase() + data.jobTitle.slice(1)}
                                </div>
                            </div>

                            {/* Location */}
                            <div className="col-span-1">
                                <div className="text-base capitalize text-sm">
                                    {data.location.charAt(0).toUpperCase() + data.location.slice(1)}
                                </div>
                            </div>

                            {/* Department */}
                            <div className="col-span-1">
                                <div className="text-base capitalize text-sm">
                                    {data.department.charAt(0).toUpperCase() + data.department.slice(1)}
                                </div>
                            </div>

                            {/* Time Type */}
                            <div className="col-span-1 text-right">
                                <span className="text-base font-semibold text-red-300">
                                    {data.timeType.charAt(0).toUpperCase() + data.timeType.slice(1)}
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* For Mobile Size */}
                    <div className={`sm:pr-20 relative lg:hidden md:hidden block ${className}`} data-nc-id="FlightCard">
                        <div className="grid grid-cols-1 gap-2">

                            {/*Name */}
                            <div className="col-span-1">
                                <div className="text-lg capitalize text-neutral-800 font-medium">
                                    {data.jobTitle.charAt(0).toUpperCase() + data.jobTitle.slice(1)}
                                </div>
                            </div>
                            {/* Department */}
                            <div className="col-span-1">
                                <div className="text-base capitalize text-sm">
                                    {data.department.charAt(0).toUpperCase() + data.department.slice(1)}
                                </div>
                            </div>

                            <div className="col-span-1 grid grid-cols-2 gap-2">
                                {/* Location */}
                                <div className="col-span-1">
                                    <div className="text-base capitalize text-sm">
                                        {data.location.charAt(0).toUpperCase() + data.location.slice(1)}
                                    </div>
                                </div>

                                {/* Time Type */}
                                <div className="col-span-2">
                                    <div className="text-lg capitalize text-neutral-800 font-medium">
                                        {data.jobTitle.charAt(0).toUpperCase() + data.jobTitle.slice(1)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default CareerCard;
