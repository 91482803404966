import React, { FC, useState } from "react";
import { InstagramEmbed } from "react-social-media-embed";

const InstagaramFeeds = () => {
  return (
    <div className="overflow-hidden w-full pl-3">
      <div
        className="w-full text-center py-1.5 text-white rounded"
        style={{
          background:
            "linear-gradient(45deg, #f9ed32 0%, #ee2a7b 62%, #002aff 100%)",
        }}
      >
        INSTAGRAM
      </div>
      {/* instagramkey */}
      {/* IGQVJXMDZAkVmpESXF4WFRpRGNWT3JLa2liOVlnbjVTM1I3MmVhcENFT2FLNGZALZATR5U3FrZAzlnUVJPenpOZADlsd0lFVDhjYml5UmVHdVY5ZAFpGWXhPZA3JrMVo3bHowdlFkZADdUYmk4Y1JINjVER01FTQZDZD */}
      {/* <InstagramEmbed
                url='https://instagram.com/salmanzamankhel?igshid=YmMyMTA2M2Y='
                clientAccessToken='IGQVJXMDZAkVmpESXF4WFRpRGNWT3JLa2liOVlnbjVTM1I3MmVhcENFT2FLNGZALZATR5U3FrZAzlnUVJPenpOZADlsd0lFVDhjYml5UmVHdVY5ZAFpGWXhPZA3JrMVo3bHowdlFkZADdUYmk4Y1JINjVER01FTQZDZD'
                maxWidth={320}
                hideCaption={false}
                containerTagName='div'
                protocol=''
                injectScript
                onLoading={() => { }}
                onSuccess={() => { }}
                onAfterRender={() => { }}
                onFailure={() => { }}
                x
            /> */}
      <div className="py-4 rounded-md w-full">
        <InstagramEmbed url="https://www.instagram.com/p/CqZ4qd-okvD/?utm_source=ig_web_copy_link" />
      </div>

      <div className="text-center mt-3">
        <a
          href="https://www.instagram.com/p/CqZ4qd-okvD/?utm_source=ig_web_copy_link"
          className="bg-[#c32e92] px-3 py-1.5 rounded text-white"
        >
          <i className="lab la-instagram text-lg pr-2"></i>Follow Me
        </a>
      </div>
    </div>
  );
};

export default InstagaramFeeds;
