import { Fragment, useState, useEffect, useContext } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Check } from "@mui/icons-material";
import { ProjectContext } from "context/project/ProjectContext";
const city = [
  {
    id: 1,
    name: "Completed By",
  },
  {
    id: 2,
    name: "2020",
  },
  {
    id: 3,
    name: "2021",
  },
  {
    id: 4,
    name: "2022",
  },
  {
    id: 5,
    name: "2023",
  },
  {
    id: 6,
    name: "2024",
  },
  {
    id: 7,
    name: "2025",
  },
  {
    id: 8,
    name: "2026",
  },
  {
    id: 9,
    name: "2027",
  },
  {
    id: 9,
    name: "2028 or Later",
  },
];

function generateArrayOfYears() {
  let max = new Date().getFullYear();
  let min = max + 4;
  let years = [];

  for (var i = max; i <= min; i++) {
    let value;
    if (i === min) {
      value = {
        label: `By ${i} or later`,
        value: i,
      };
    } else {
      value = {
        label: `By ${i}`,
        value: i,
      };
    }
    years.push(value);
  }
  years.unshift({
    label: "Completed",
    value: "Completed",
  });
  return years;
}

let years = generateArrayOfYears();

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export default function CompletionDate() {
  const { dispatchFilter, filter }: any = useContext(ProjectContext);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (selected) {
      dispatchFilter({
        type: "updateCompletedDate",
        value: selected,
      });
    }
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative px-4">
            <Listbox.Button className="relative w-full cursor-pointer py-2 text-left sm:text-sm">
              <span className="flex items-center">
                <span className="block text-base truncate dark:text-white">
                  {filter?.completedDate
                    ? filter?.completedDate
                    : "Completed By"}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center">
                {/* <ChevronDownIcon
                  className='h-6 w-6 text-gray-400'
                  aria-hidden='true'
                /> */}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 cursor-pointer max-h-56 w-full mt-2 -ml-4 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {years.map((person) => (
                  <Listbox.Option
                    key={person.label}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-white bg-[#223A64] text-lg"
                          : "text-gray-900",
                        "relative cursor-pointer select-none py-1 text-lg border-b pl-3 pr-9"
                      )
                    }
                    value={person.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "text-base block truncate py-1 cursor-pointer"
                            )}
                          >
                            {person.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-[#223A64]",
                              "absolute inset-y-0 cursor-pointer right-0 flex items-center"
                            )}
                          >
                            <Check
                              className="h-5 w-5 text-[#345886]"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
