import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useMemo } from "react";
import NcImage from "shared/NcImage/NcImage";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import NextPrev from "shared/NextPrev/NextPrev";
import { API } from "utils/config";
export interface GallerySliderProps {
  className?: string;
  imageClassCss?: string;
  ratioClass?: string;
  uniqueID: string;
  galleryImgs: string[];
}
const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  imageClassCss = "",
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();
  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs?.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden rounded-md`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {galleryImgs?.length > 0 ? (
              <>
                {galleryImgs?.map((item, index) => (
                  <li key={index} className="glide__slide">
                    <div className={ratioClass}>
                      <NcImage src={`${item}`} imageClass={imageClassCss} className={`object-contain`} />
                    </div>
                  </li>
                ))}
              </>
            ) : (
              <>
                <li key={uniqueID} className="">
                  <div
                    className={`${ratioClass} flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}
                  >
                    <div className="h-2/4 flex justify-center align-middle items-center self-center">
                      <PlaceIcon />
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* DOTS */}
        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
        {renderDots()}

        {/* NAV */}
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
