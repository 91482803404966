// ConsultantsPage2.tsx
import React, { useContext, useEffect, useState } from 'react';
import pinContext from "context/pin/pinContext";
import ConsultantCard from './ConsultantCard';

interface Consultant {
    id: number;
    name: string;
    city: string;
    languages: string[];
}

const ConsultantsPage2: React.FC = () => {
    const { consultantsData, filters } = useContext(pinContext);
    const [filteredConsultants, setFilteredConsultants] = useState([]);

    useEffect(() => {
        const filtered = consultantsData.filter((consultant: any) => {
            const nameMatch = !filters?.name || consultant?.fname?.toLowerCase().includes(filters?.name?.toLowerCase());
            const cityMatch = !filters?.city || consultant?.city?.toLowerCase().includes(filters?.city?.toLowerCase());
            // const languageMatch = !filters?.languages?.length || filters?.languages?.some((lang: any) => consultant?.languages?.includes(lang));
            const languageMatch = !Array.isArray(filters.languages) || filters?.languages?.length === 0 || filters?.languages?.some((lang: any) => consultant?.languages?.includes(lang));
            return nameMatch && cityMatch && languageMatch;
            // const languageMatch = !filters?.languages?.length || filters?.consultant.languages.some((lang: any) => filters.languages.includes(lang)) : true;
            //     return nameMatch && cityMatch && languageMatch;
        });
        setFilteredConsultants(filtered);
    }, [consultantsData, filters]);
    return (
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 pb-10 pt-10">
            {filteredConsultants.length > 0 ? filteredConsultants.map((consultant: any) => (
                <ConsultantCard consultant={consultant} id={""} />

            )) : (
                <p>No consultants match your filters.</p>
            )}
        </div>
    );
};

export default ConsultantsPage2;
