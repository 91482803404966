import React, { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface AgencyProfile1Props {}

const AgencyProfile1: FC<AgencyProfile1Props> = () => {
  return (
    
    <CommonLayout
      index="01"
      backtHref="/agency-profile1"
      nextHref="/agency-profile2"
    >
      <>
        <h2 className="text-2xl font-semibold">Choosing Agency categories</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Agency name"
          >
            <Input />
          </FormItem>

          <FormItem
            label="Properties"
            desc="Such as : 3243"
          >
            <Input  />
          </FormItem>
          
          <FormItem
            label="Service Area"
            desc="Such as : Lahore +  Burewala Chakwal + Faisalabad  + Islamabad"
          >
            <Input  />
          </FormItem>

          
        </div>
      </>
    </CommonLayout>
  );
};

export default AgencyProfile1;
