import React, { FC } from "react";

export interface PageConstructionsProps {
  className?: string;
}
const PageConstructions: FC<PageConstructionsProps> = ({ className = "" }) => {
  return (
    <>
      <div className="relative overflow-hidden  lg:h-[87vh] ">
        <div className="mx-7 max-w-7xl ">
          <div className="relative z-10 bg-sky-50 dark:bg-gray-700 lg:h-[87vh] lg:rounded-l-md  lg:rounded-l-xl  pb-5 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-sky-50 dark:text-gray-700  lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative px-4 pt-6 sm:px-6 lg:px-8"></div>

            <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900  dark:text-gray-100 sm:text-5xl md:text-5xl">
                  <span className="block   xl:inline">IMLAAK </span>
                  <span className="block text-sky-900 dark:text-gray-400  xl:inline">
                    CONSTRUCTIONS LAHORE
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 dark:text-gray-400 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-sm lg:mx-0">
                  Imlaak Constructions is a leader in providing value-added
                  construction services to our customers by creating a
                  successful partnership with them throughout the construction
                  process. DHA Lahore home construction offered by imlaak pledge
                  to establish lasting relationships with our customers by
                  exceeding their expectations and gaining their trust through
                  exceptional performance by every member of the construction
                  team. We believe in an educated customer and have thus
                  undertaken to educate our clients and the general public about
                  the construction process. This helps us in achieving a more
                  meaningful and robust dialogue about the process ensuring a
                  fulfilling experience. Our home Constructions’ team has a long
                  and distinguished history of undertaking and completing
                  diversified and complex residential and commercial
                  construction projects.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <button className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-900 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 md:py-4 md:px-10 md:text-lg">
                      Contact Us
                    </button>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <button className="flex w-full items-center justify-center rounded-md border border-transparent bg-sky-100 px-8 py-3 text-base font-medium text-sky-900 hover:bg-sky-200 md:py-4 md:px-10 md:text-lg">
                      <i className="las la-play-circle text-2xl"></i>
                      Watch VIdeo
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 rounded-xl lg:right-0 mx-8">
          <img
            className="h-56 w-full object-cover rounded-xl sm:h-72 md:h-96 lg:h-full lg:w-full"
            src="https://www.teahub.io/photos/full/277-2774312_building-construction.jpg"
            alt=""
          />
        </div>
      </div>
      <div
        className={`nc-PageConstructions overflow-hidden sm-px-20 px-10 ${className}`}
        data-nc-id="PageConstructions"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 p-6">
          <div className="cursor-pointer rounded-xl bg-white p-3 shadow-lg hover:shadow-xl">
            <div className="relative flex items-end overflow-hidden rounded-xl">
              <img
                src="https://www.imlaak.com/wp-content/uploads/2022/02/camera-1-1024x691-1-768x662.jpg"
                alt="wallpaper"
              />

              <div className="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-yellow-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>

                <span className="ml-1 text-sm text-slate-400">4.9</span>
              </div>
            </div>

            <div className="mt-1 p-2">
              <div className="mt-3 flex items-end justify-between">
                <h2 className="text-slate-700">5 & 10 Marla Categories</h2>
                <p className="mt-1 text-sm text-slate-400">Lahore, Pakistan</p>
                <div className="group inline-flex rounded-xl bg-sky-100 p-2 hover:bg-orange-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-[#233a65] group-hover:text-orange-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="cursor-pointer rounded-xl bg-white p-3 shadow-lg hover:shadow-xl">
            <div className="relative flex items-end overflow-hidden rounded-xl">
              <img
                className=""
                src="https://www.imlaak.com/wp-content/uploads/2022/02/house_nice_4-1024x682-1-768x662.jpg"
                alt="wallpaper"
              />

              <div className="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-yellow-400 "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>

                <span className="ml-1 text-sm text-slate-400">4.9</span>
              </div>
            </div>

            <div className="mt-1 p-2">
              <div className="mt-3 flex items-end justify-between">
                <h2 className="text-slate-700"> 1 & 2 kanal Categories</h2>
                <p className="mt-1 text-sm text-slate-400">Lahore, Pakistan</p>

                <div className="group  inline-flex rounded-xl bg-sky-100 p-2 hover:bg-orange-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-[#233a65] group-hover:text-orange-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full mt-5 sm-px-20 px-10 mb-10">
          <div className="flex flex-col xl:flex-row rounded-xl  bg-white p-3 shadow-lg hover:shadow-xl overflow-hidden cursor-pointer">
            <img
              className="object-cover w-[100px] h-48"
              src="https://bsmedia.business-standard.com/_media/bs/img/about-page/thumb/464_464/1600045201.jpg"
              alt="ENGINEERS"
            />

            <div className="relative p-4">
              <h3 className="text-base md:text-xl font-medium text-gray-800">
                ENGINEERS
              </h3>

              <p className="mt-4 text-base md:text-lg text-gray-600">
                Our competency as engineers makes us the best choice, we are
                unconventional and wizards of this field.
              </p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row rounded-xl  bg-white p-3 shadow-lg hover:shadow-xl overflow-hidden cursor-pointer">
            <img
              className="object-cover w-[100px] h-48"
              src="https://www.surachanaarchitects.com/img/why-us/specalization-min.jpg"
              alt=" ARCHITECTS"
            />

            <div className="relative p-4">
              <h3 className="text-base md:text-xl font-medium text-gray-800">
                ARCHITECTS
              </h3>

              <p className="mt-4 text-base md:text-lg text-gray-600">
                Our panel of architects are always around, rendering all
                services 3D designs, site visits and more.{" "}
              </p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row rounded-xl  bg-white p-3 shadow-lg hover:shadow-xl overflow-hidden cursor-pointer">
            <img
              className="object-cover  w-[100px] h-48"
              src="https://foyr.com/learn/wp-content/uploads/2021/09/essential-tools-for-interior-designers.jpg"
              alt="INTERIOR DESIGNERS"
            />

            <div className="relative p-4">
              <h3 className="text-base md:text-xl font-medium text-gray-800">
                INTERIOR DESIGNERS
              </h3>

              <p className="mt-4 text-base md:text-lg text-gray-600">
                We have the top interior designers on our panel, now help is
                just one call away.
              </p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row rounded-xl  bg-white p-3 shadow-lg hover:shadow-xl overflow-hidden cursor-pointer">
            <img
              className="object-cover  w-[100px] h-48"
              src="https://www.tatapower.com/images/credit-rating-revision-header800.jpg"
              alt="LOOK UP TO STARS"
            />

            <div className="relative p-4">
              <h3 className="text-base md:text-xl font-medium text-gray-800">
                LOOK UP TO STARS
              </h3>

              <p className="mt-4 text-base md:text-lg text-gray-600">
                Stars are the new limit, we will set your standards so high that
                you will never look below.{" "}
              </p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row rounded-xl  bg-white p-3 shadow-lg hover:shadow-xl overflow-hidden cursor-pointer">
            <img
              className="object-cover  w-[100px] h-48"
              src="https://www.tatapower.com/images/credit-rating-revision-header800.jpg"
              alt="FOLLOW YOUR HEART"
            />

            <div className="relative p-4">
              <h3 className="text-base md:text-xl font-medium text-gray-800">
                FOLLOW YOUR HEART
              </h3>

              <p className="mt-4 text-base md:text-lg text-gray-600">
                Lets break the ordinary, we love new challenges and are happy to
                help achieving your dreams.{" "}
              </p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row rounded-xl  bg-white p-3 shadow-lg hover:shadow-xl overflow-hidden cursor-pointer">
            <img
              className="object-cover  w-[100px] h-48"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzP16Ic0zuiVfAkdAcdKF5_wtK23-CgXj0Vg&usqp=CAU"
              alt="TOUCH THE SKY"
            />

            <div className="relative p-4">
              <h3 className="text-base md:text-xl font-medium text-gray-800">
                TOUCH THE SKY
              </h3>

              <p className="mt-4 text-base md:text-lg text-gray-600">
                YOU DREAM, WE BUILD. Nothing is impossible when we are at your
                side, we want you to dream of touching the sky.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageConstructions;
