import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import PropertyDetailPage from "containers/ListingDetailPage/PropertyDetailPage";
import ProjectsDetailPage from "containers/ListingDetailPage/ProjectsDetailPage";
import PropertyValueSearch from "containers/ListingDetailPage/PropertyValueSearch";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import NewProject from "containers/NewProject/NewProject";
import FindAgency from "containers/FindAgency/FindAgency";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/signup";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import AgencyDetailPage from "containers/FindAgency/AgencyDetailPage";
import AgencyProfilePage from "containers/AgencyPorfile/AgencyProfile";
import AgencyProfilePage1 from "containers/AgencyPorfile/AgencyProfile1";
import AgencyProfilePage2 from "containers/AgencyPorfile/AgencyProfile2";
import AgencyProfilePage3 from "containers/AgencyPorfile/AgencyProfile3";
import DeveloperDetailPage from "containers/NewProject/DeveloperPage/DeveloperDetailPage";
import RentPage from "containers/ListingDetailPage/RentPage";
import SalePage from "containers/ListingDetailPage/SalePage";
import PropertyDetailPage2 from "containers/ListingDetailPage/PropertyDetailPage2";
import AgentDetailPage from "containers/FindAgency/AgentDetailPage";
import AgencyPortal from "containers/FindAgency/AgencyPortal";
import Agencies from "containers/FindAgency/Agencies";
import CareerPage from "containers/CareerPage/CareerPage";
import CareerDetailPage from "containers/CareerPage/CareerDetailPage";
import Defaulthideshow from "containers/PageSignUp/Defaulthideshow";
import Events from "containers/Events/Events";
import EventDetail from "containers/Events/EventsDetail";
import PageLogin from "containers/PageLogin/PageLogin";
import ConsultantsPage from "containers/ConsultantsPage/ConsultantsPage";
import ConsultantProfile from "containers/ConsultantsPage/ConsultantProfile";
import PageForget from "containers/PageForget/PageForget";
import Consultants from "containers/ConsultantsPage/Consultants";
import EmailVerified from "shared/Header/EmailVerified";
import BlogSingle from "containers/BlogPage/BlogSingle";
import BlogPage from "containers/BlogPage/BlogPage";
import Blogs from "containers/BlogPage/Blogs";

import PageContact from "containers/PageContact/PageContact";
import WhyUs from "containers/PageContact/WhyUs";
import PageServices from "containers/PageServices/PageServices";
import PageConstructions from "containers/PageConstructions/PageConstructions";
import PageLandlord from "containers/PageLandlord/PageLandlord";
import ResidentialPlots from "containers/Plots&File/ResidentialPlots/ResidentialPlots";
import ResidentialFiles from "containers/Plots&File/ResidentialFiles/ResidentialFiles";
import ResidentialCommercial from "containers/Plots&File/ResidentialCommercial/ResidentialCommercial";
import CommercialPlots from "containers/Commercial/CommercialPlots";
import CommercialFiles from "containers/Commercial/CommerialFiles";
import CommercialOther from "containers/Commercial/Other";
import ApartmentsHouses from "containers/Projects/ApartmentsHouses/Sixty6Gulberg";
import Goldcrest from "containers/Projects/ApartmentsHouses/Goldcrest";
import DownTown from "containers/Projects/ApartmentsHouses/DownTown";
import Manor from "containers/Projects/ApartmentsHouses/Manor";
import FairwaysDefence from "containers/Projects/Commerical/FairwaysDefence";
import IndigoHeights from "containers/Projects/Commerical/IndigoHeights";
import DreamGardens from "containers/Projects/Other/DreamGardens";
import PhaseSeven from "containers/RealtyPages/DhaLahore/PhaseOne";
import PhaseEight from "containers/RealtyPages/DhaLahore/PhaseEight";
import PhaseNine from "containers/RealtyPages/DhaLahore/PhaseNine";
import SangarHousing from "containers/RealtyPages/Gwadar/SangarHousingScheme";
import JinnahAvenue from "containers/RealtyPages/Gwadar/JinnahAvenue";
import AirportRoad from "containers/RealtyPages/Gwadar/AirportRoad";
import ApartmentsLahore from "containers/RealtyPages/Other/ApartmentsLahore";
import DhaMultan from "containers/RealtyPages/Other/DhaMultan";
import DubaiRealEstate from "containers/RealtyPages/Other/DubaiRealEstate";
import PageTeam from "containers/PageTeam/PageTeam";
import SinglePost from "containers/BlogPage/SinglePost";
import EstimationSearch from "containers/ListingDetailPage/EstimationSearch";
import PrivacyPolicy from "containers/Pages/PrivacyPolicy";
import TransactionsPolicy from "containers/Pages/TransactionsPolicy";
import Projects from "containers/NewProject/Projects";
import DhaLahoreMaps from "containers/Pages/DhaLahoreMaps";
import PlotFinderPage from "containers/PlotFinder/PlotFinderPage";
import ConsultantServices from "containers/Pages/ConsultantServices";
import DHAPhase1to4 from "containers/Plots&File/ResidentialFiles/DHAPhase1to4";
import DHAPhase5 from "containers/RealtyPages/Other/DHAPhase5";
import Developers from "containers/NewProject/Developers";
import ConsultantsPage2 from "containers/ConsultantsPage/ConsultantsPage2";
import MainProjectPage from "containers/NewProject/MainProjectPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-2", component: PageHome2 },
  //
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-stay-map", component: ListingStayMapPage },
  { path: "/property_detail/:id", component: PropertyDetailPage },
  // { path: "/project-detail/:id", component: ProjectsDetailPage },

  { path: "/try-value", component: PropertyValueSearch },
  { path: "/RentPage", component: RentPage },
  { path: "/SalePage", component: SalePage },
  { path: "/Property_Page", component: PropertyDetailPage2 },
  { path: "/estimation", component: EstimationSearch },

  { path: "/contact-us", component: PageContact },
  { path: "/why-choose-us/", component: WhyUs },
  { path: "/real-estate-consultancy-services", component: PageServices },
  { path: "/constructions", component: PageConstructions },
  { path: "/landlord", component: PageLandlord },
  // {
  //   path: "/dha-lahore/residential-plots-dha-lahore",
  //   component: ResidentialPlots,
  // },
  // {
  //   path: "/dha-lahore/dha-lahore-residential-files",
  //   component: ResidentialFiles,
  // },
  // { path: "/dha-lahore/dha-lahore-phase-5", component: DHAPhase5, },
  // { path: "/dha-lahore/dha-lahore-phase-1-to-4", component: DHAPhase1to4, },
  // { path: "/residential-plots-dha-multan", component: ResidentialCommercial },
  // {
  //   path: "/dha-lahore/commercial-plots-dha-lahore",
  //   component: CommercialPlots,
  // },
  // { path: "/commercial-files-dha-lahore", component: CommercialFiles },
  // { path: "/other", component: CommercialOther },
  // { path: "/sixty6-gulberg", component: ApartmentsHouses },
  // { path: "/61-manor", component: Manor },
  // { path: "/goldcrest-dha-lahore-mall-residency", component: Goldcrest },
  // { path: "/downtown-residences", component: DownTown },
  // {
  //   path: "/fairways-defence-raya-commercial-plazas",
  //   component: FairwaysDefence,
  // },
  // { path: "/indigo-heights-lahore", component: IndigoHeights },
  // { path: "/dha-lahore/dha-lahore-phase-7", component: PhaseSeven },
  // { path: "/dha-lahore/dha-lahore-phase-8/", component: PhaseEight },
  // { path: "/dha-lahore/dha-lahore-phase-9-prism/", component: PhaseNine },
  // {
  //   path: "/sangar-housing-scheme-2",
  //   component: SangarHousing,
  // },
  // { path: "/jinnah-avenue-commercial-3", component: JinnahAvenue },
  // { path: "/airport-road-commercial-3", component: AirportRoad },
  // { path: "/apartments-in-lahore", component: ApartmentsLahore },
  // { path: "/dha-multan/", component: DhaMultan },
  // { path: "/dubai-real-estate/", component: DubaiRealEstate },
  // { path: "/dream-gardens-lahore", component: DreamGardens },
  { path: "/the-team", component: PageTeam },
  { path: "/about", component: PageAbout },

  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  }, {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //

  { path: "/new-projects", component: NewProject },
  { path: "/newProjects", component: Projects },
  { path: "/projects", component: MainProjectPage },

  //
  // { path: "/find-agency", component: FindAgency },
  // { path: "/Agencies/:province", component: Agencies },

  //
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/EmailVerified", component: EmailVerified },

  { path: "/subscription", component: PageSubcription },
  { path: "/agency-detail/:id", component: AgencyDetailPage },
  { path: "/developer-detail/:id", component: DeveloperDetailPage },
  { path: "/agency-profile", component: AgencyProfilePage },
  { path: "/agency-profile1", component: AgencyProfilePage1 },
  { path: "/agency-profile2", component: AgencyProfilePage2 },
  { path: "/agency-profile3", component: AgencyProfilePage3 },
  { path: "/Agent/:id", component: AgentDetailPage },
  { path: "/agency-portal", component: AgencyPortal },
  { path: "/career", component: CareerPage },
  { path: "/career_detail/:id", component: CareerDetailPage },

  { path: "/HideShow", component: Defaulthideshow },
  { path: "/Forget", component: PageForget },
  //
  { path: "/events", component: Events },
  { path: "/event-detail/:id", component: EventDetail },
  { path: "/login", component: PageLogin },

  { path: "/consultantsPage", component: ConsultantsPage },
  { path: "/consultantsPage2", component: ConsultantsPage2 },
  { path: "/consultants", component: Consultants },
  { path: "/consultantProfile/:id", component: ConsultantProfile },
  { path: "/developers", component: Developers },

  // Maps
  { path: "/plot-finder", component: PlotFinderPage },

  // Other Pages
  { path: "/privacy-policy", component: PrivacyPolicy },
  // { path: "/real-estate-consultancy-services", component: ConsultantServices },
  { path: "/transactions-policy", component: TransactionsPolicy },
  // { path: "/dha-lahore-maps", component: DhaLahoreMaps },

  //Blogs
  // { path: "/blog", component: BlogPage },
  // { path: "/blogPage", component: Blogs },
  // { path: "/BlogDetail/:id", component: BlogSingle },
  // { path: "/:slug", component: SinglePost },
  { path: "/developer/:id", component: DeveloperDetailPage },
  { path: "/:projectSlug", component: ProjectsDetailPage },
];

const Routes = () => {
  return (
    <BrowserRouter basename="">
      <ScrollToTop />
      <SiteHeader />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;

