import React, { FC, useState, Fragment, useContext, useEffect } from "react"
import pinContext from "context/pin/pinContext"
import PropertySection from "./PropertySection"
import AgentFilters from "components/HeroSearchForm/AgentFilters"
import { Popover, Transition } from "@headlessui/react"
import { DEMO_STAY_LISTINGS } from "data/listings"
import { useParams } from "react-router-dom"
import { BuyRentContext } from "context/property/BuyRentContext"

const DEMO_EXPERIENCES = DEMO_STAY_LISTINGS.filter((_, i) => i < 12)
export interface AgentActiveListingTabProps {
  agencyNameprops?: any
}

const AgentActiveListingTab: FC<AgentActiveListingTabProps> = () => {
  type QuizParams = {
    id: any
  }
  let { id } = useParams<any>()
  console.log(id, "agentid")

  const [showFullMapFixed, setShowFullMapFixed] = useState(false)

  const {
    singleAgent,
    getSingleAgentById,
    getAllApproveAgencies,
    approveAgencies,
    singleConsultant,
  } = useContext(pinContext)
  const { getMyProperties, properties }: any = useContext(BuyRentContext)
  useEffect(() => {
    // debugger
    getMyProperties(id)
  }, [id])

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1)
  useEffect(() => {
    getSingleAgentById(id)
  }, [id])

  console.log(singleAgent?.id, "singleAgentdetail")
  useEffect(() => {
    getAllApproveAgencies()
  }, [approveAgencies])
  console.log(approveAgencies?.data?.[0]._id, "approveAgencies")
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <div className=''>
        <AgentFilters />
        <div className='lg:w-6/12 w-full'>
          <div className='flex justify-content-between'>
            <span className='text-sm leading-8'>
              Showing Properties sorted by &nbsp;
            </span>
            <div className='border border-gray-400 rounded-md px-4 py-1.5 text-sm'>
              <Popover className='relative'>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={` ${open ? "text-black focus:outline-none w-full" : "w-full"
                        }`}
                    >
                      <div className='flex justify-between w-full'>
                        <button className='text-center lg:text-base md:text-sm text-xs'>
                          Popular
                        </button>
                        <i className='las la-caret-down lg:mt-1 md:mt-1 sm:mt-2 inline-block align-middle h-full'></i>
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 translate-y-1'
                    >
                      <Popover.Panel className='absolute pb-2 bg-white dark:bg-neutral-800 z-50 mt-3 left-0 -ml-5 shadow-xl border w-36 rounded-md'>
                        <div className='pt-2 px-2 relative flex flex-col'>
                          <button className='border py-1.5 hover:bg-red-100'>
                            Popular
                          </button>
                          <button className='border py-1.5 hover:bg-red-100'>
                            Newest
                          </button>
                          <button className='border py-1.5 hover:bg-red-100'>
                            Lowest Price
                          </button>
                          <button className='border py-1.5 hover:bg-red-100'>
                            Highest Price
                          </button>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
        </div>
        <PropertySection />
      </div>
    </>
  )
}

export default AgentActiveListingTab
