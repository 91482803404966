import React, { FC, useContext, useEffect, Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { API } from "utils/config";
import { TaxonomyType } from "data/types";
import { Popover } from "@headlessui/react";
import { API as apiHost } from "utils/config";
import { Transition } from "semantic-ui-react";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { NewProjectsMobileFilters } from "./NewProjectsMobileFilters";
import { ProjectContext } from "context/project/ProjectContext";
import moment from "moment";
import axios from "axios";
import LoginModal from "components/LoginModal/LoginModal";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SelectCityOptionInput from "components/HeroSearchForm/SelectCityOptionInput";
import NewProjectFilter from "./NewProjectFilter";
import Autocomplete from "components/HeroSearchForm/Autocomplete";
import PaginationCheck from "shared/Pagination/PaginationCheck";
import ConsultantsNameInput from "components/HeroSearchForm/ConsultantsNameInput";
import CityInput from "components/HeroSearchForm/CityInput";

export interface DevelopersProps {
    className?: string;
    taxonomy?: TaxonomyType;
    afterChange?: any;
    get?: any;
}
const Developers: FC<DevelopersProps> = ({ className = "", get, afterChange }) => {
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected]: any = useState({});
    const [rangePrices, setRangePrices] = useState([0, 0]);
    const {
        getAllProjects,
        projects,
        getAllDevelopersData,
        developers,
        loading,
        getLocationsByCity,
        cityLocations,
        getAllDevelopers,
        allDevelopers,
        dispatchFilter,
        filter,
        applyFilters,
        projectNames,
        getAllProjectNames,
        getAllProjectsData,
        projectsData,
    }: any = useContext(ProjectContext);
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const handleSaveProperties = async (projectId: string) => {
        try {
            const tk = localStorage.getItem("authToken");
            const res = await axios.post(
                `${apiHost}/api/auth/saveProperties`,
                {
                    saveProperty: projectId,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${tk}`,
                    },
                }
            );
            // console.log(res.data);
        } catch (err) {
            console.error(err);
        }
        localStorage.setItem("likedProjects", projectId);
    };
    const [filteredProjects, setFilteredProjects] = useState([]);
    useEffect(() => {
        getAllProjects();
        getAllProjectNames();
        getAllProjectsData();
        getAllDevelopersData();
    }, []);

    const [projectNameData, setProjectNameData] = useState([]);
    const [selectedProject, setSelectedProject] = useState<{ _id: string; name: string } | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [allProjectsData, setAllProjectsData] = useState<any>([]);
    const developersPerPage = 10;
    const allProjects = projects?.project;
    const indexOfLastDeveloper = currentPage * developersPerPage;
    const indexOfFirstDeveloper = indexOfLastDeveloper - developersPerPage;

    const [selectedDeveloperName, setSelectedDeveloperName] = useState('');
    const [developerNameInput, setDeveloperNameInput] = useState('');
    const [cityInput, setCityInput] = useState('');  // Manages the input for city
    const [selectedCity, setSelectedCity] = useState('');

    const handleSelectionChange = (project: { _id: string; name: string }) => {
        setSelectedProject(project);
    };
    const handleFilterChange = (filteredData: any) => {
        setFilteredProjects(filteredData);
    };
    // city input
    const [cities, setCities] = useState([]);

    useEffect(() => {
        if (projectsData && projectsData?.length > 0) {

            // project names
            const projectNamesData = projectsData.map((data: any) => ({
                _id: data?._id,
                name: data?.projectName?.projectName?.toLowerCase(),
            }));
            setProjectNameData(projectNamesData)

            // city input
            const cityData = developers?.map((data: any) => ({
                _id: data?._id,
                name: data?.developerAddress?.toLowerCase(),
            }));
            const uniqueCityNames = Array.from(new Map(cityData?.map((item: any) => [item?.name, item])).values()) as any;
            setCities(uniqueCityNames);

            if (filteredProjects?.length > 0) {
                setAllProjectsData(filteredProjects)
            }
            else {
                setAllProjectsData(projectsData)
            }

        }
    }, [projectsData]);

    const showFirst20Letters = (text: string): string => {
        if (text?.length > 18) {
            return text.slice(0, 18) + '...';
        }
        return text;
    };
    const showFirst15Letters = (text: string): string => {
        if (text?.length > 15) {
            return text.slice(0, 15) + '...';
        }
        return text;
    };

    useEffect(() => {
        if (selected?._id !== undefined) {
            getLocationsByCity(selected._id);
            dispatchFilter({
                type: "updateProjectCity",
                value: selected.cityLocation,
            });
        }
    }, [selected]);
    useEffect(() => {
        getAllDevelopers();
    }, [selected]);
    const handleProjectType = (value: any) => {
        dispatchFilter({
            type: "updateProjectType",
            value: value,
        });
    };

    useEffect(() => {
        if (rangePrices[0] !== 0 || rangePrices[1] !== 0) {
            dispatchFilter({
                type: "updatePriceRange",
                value: rangePrices,
            });
        }
    }, [rangePrices]);

    const handleFind = () => {
        applyFilters();
    };
    const resetFilter = (e: any) => {
        e.preventDefault();

        dispatchFilter({
            type: "resetFilter",
        });
    };

    const handleSort = (value: any) => {

        let filteredData
        if (value === 'newest') {
            filteredData = projectsData?.sort((a: any, b: any) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            });
        }
        if (value === 'lowest') {
            filteredData = projectsData?.sort((a: any, b: any) => {
                return a.minpriceRange - b.minpriceRange;
            });
        }
        if (value === 'highest') {
            filteredData = projectsData?.sort((a: any, b: any) => {
                return a.maxpriceRange - b.maxpriceRange;
            });
        }
        setFilteredProjects(filteredData);
    };

    const handleCityLocation = (value: any) => {
        dispatchFilter({
            type: "updateProjectLocation",
            value: value,
        });
    };


    const currentProjects = developers?.slice(indexOfFirstDeveloper, indexOfLastDeveloper);

    const handleDeveloperNameChange = (developer: any) => {
        setSelectedDeveloperName(developer?.name?.toLowerCase());
        setDeveloperNameInput(developer?.name?.toLowerCase());
    };
    const handleCityChange = (developer: any) => {
        setSelectedCity(developer?.developerAddress?.toLowerCase());
        setCityInput(developer?.developerAddress?.toLowerCase());
    };
    return (
        <>
            <div className="container py-7 hidden">
                <div className="grid-cols-4 grid gap-6">
                    <div className="col-span-2 relative rounded-md">
                        <div className="relative w-full rounded-md bg-white dark:bg-slate-800  border border-gray-400">
                            <Autocomplete value="" placeholder="Search by Name..." onInputChange={setDeveloperNameInput} suggestions={developers} onSelectionChange={handleDeveloperNameChange} />
                        </div>
                    </div>
                    <div className="col-span-2 relative rounded-md">
                        <div className="relative w-full rounded-md bg-white dark:bg-slate-800  border border-gray-400">
                            <Autocomplete value="" placeholder="Search by City..." onInputChange={setCityInput} suggestions={cities} onSelectionChange={handleCityChange} />
                        </div>
                    </div>
                </div>
                <div className='pt-3 w-full flex justify-end'>
                    <button
                        className="text-black dark:text-white text-sm font-semibold"
                    >
                        Reset Filters
                    </button>
                </div>
            </div>
            <div className="grid lg:hidden md:hidden grid-cols-2 gap-2 py-2 px-2">
                <div className="col-span-1">
                    <div className="relative !h-12 w-full rounded-md bg-white border  dark:bg-slate-800   border-gray-400">
                        <Autocomplete value="" placeholder="Search by Name..." onInputChange={setDeveloperNameInput} suggestions={developers} onSelectionChange={handleDeveloperNameChange} />
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="relative w-full rounded-md bg-white dark:bg-slate-800  border border-gray-400">
                        <Autocomplete value="" placeholder="Search by City..." onInputChange={setCityInput} suggestions={cities} onSelectionChange={handleCityChange} />
                    </div>
                </div>
            </div>
            <div className="py-5 border-t">
                <div className="container">

                    <div className="lg:w-6/12 w-full pb-3 pt-1">
                        <div className="flex justify-content-between">
                            <span className="text-2xl font-semibold leading-8">
                                {/* Showing  */}
                                Developers
                                {/* sorted by &nbsp; */}
                            </span>
                            <div className="hidden border border-gray-400 rounded-md px-4 py-1.5 text-sm">
                                <Popover className="relative">
                                    {({ open }) => (
                                        <>
                                            <Popover.Button
                                                className={` ${open
                                                    ? "text-black focus:outline-none w-full flex justify-between"
                                                    : "flex justify-between w-full"
                                                    }`}
                                            >
                                                <div className="flex justify-between w-full">
                                                    <button className="inline-block align-middle h-full">
                                                        Popular &nbsp;
                                                    </button>
                                                    <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                                                </div>
                                            </Popover.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel className="absolute pb-2 bg-white dark:bg-neutral-800 z-50 mt-3 left-0 -ml-5 shadow-xl border w-36 rounded-md">
                                                    <div className="pt-2 px-2 relative flex flex-col">
                                                        <button
                                                            onClick={() => handleSort("newest")}
                                                            className="border py-1.5 hover:bg-red-100"
                                                        >
                                                            Most Projects
                                                        </button>
                                                        <button
                                                            onClick={() => handleSort("lowest")}
                                                            className="border py-1.5 hover:bg-red-100"
                                                        >
                                                            Least Projects
                                                        </button>
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {loading ? (
                    <div role="status" className="flex flex-wrap justify-center">
                        <div className="max-w-sm p-4 animate-pulse md:p-6 mr-4">
                            <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                <svg
                                    className="w-12 h-12 text-gray-200 dark:text-gray-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 640 512"
                                >
                                    <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                                </svg>
                            </div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            <span className="sr-only">Loading...</span>
                        </div>

                        <div className="max-w-sm p-4 animate-pulse md:p-6 mr-4">
                            <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                <svg
                                    className="w-12 h-12 text-gray-200 dark:text-gray-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 640 512"
                                >
                                    <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                                </svg>
                            </div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            <span className="sr-only">Loading...</span>
                        </div>

                        <div className="max-w-sm p-4 animate-pulse md:p-6 mr-4">
                            <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                <svg
                                    className="w-12 h-12 text-gray-200 dark:text-gray-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 640 512"
                                >
                                    <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                                </svg>
                            </div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            <span className="sr-only">Loading...</span>
                        </div>

                        <div className="max-w-sm p-4 animate-pulse md:p-6 mr-4">
                            <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                                <svg
                                    className="w-12 h-12 text-gray-200 dark:text-gray-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 640 512"
                                >
                                    <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                                </svg>
                            </div>
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) :
                    <div className="grid lg:grid-cols-3 grid-cols-1 md:grid-cols-3 gap-8 pb-5">
                        {currentProjects?.map((item: any, index: any) => (
                            <div className="col-span-1 md:col-span-2 lg:col-span-1 relative w-full h-[50vh]" key={index}>
                                <div className="relative bg-white border pb-3 border-t-0 overflow-hidden rounded-lg shadow-md dark:bg-gray-800 dark:border-none">
                                    <Link to={`/developer/${item._id}`}>
                                        <div className="overflow-hidden flex justify-center align-middle cursor-pointer w-full h-[30vh] border border-b-2 py-2">
                                            <NcImage
                                                className="rounded-t-lg transition-all flex justify-center duration-500 ease-in-out transform hover:scale-105"
                                                src={`${item?.developerImage?.[0]?.url}`}
                                                alt=""
                                                imageClass="object-contain w-80 h-full"
                                                containerClassName='flex justify-center align-middle'
                                            />
                                        </div>

                                        <div className="px-5 py-4 ">
                                            <a href="#">
                                                <h5 className="text-xl font-normal tracking-tight text-gray-900 dark:text-white capitalize">
                                                    {showFirst20Letters(item?.name)}
                                                </h5>
                                            </a>
                                            <p className="mb-2 mt-1 font-normal text-sm text-gray-700 dark:text-white capitalize">
                                                <i className="las la-map-marked capitalize px-1"></i>
                                                {`${item?.developerAddress}`}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="px-5 -mt-5 hover:block">
                                        <Link to={`/developer/${item._id}`}>
                                            <button
                                                className="w-full text-white bg-[#345886] hover:bg-[#1f345c] focus:ring-none tracking-wide rounded focus:outline-none focus:ring-none font-semibold  text-md py-2.5 text-center dark:bg-[#223A64] mt-5 dark:hover:bg-[#1f345c] dark:focus:ring-[#223A64]"
                                                type="submit"
                                            >
                                                {" "}
                                                View Details{" "}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
            <div className="py-3">
                <PaginationCheck
                    currentPage={currentPage}
                    consultantsPerPage={developersPerPage}
                    totalConsultants={projectsData?.length}
                    paginate={setCurrentPage}
                    text={'Projects'}
                />
            </div>

        </>
    );
};

export default Developers;
