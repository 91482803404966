import React from 'react'

const Svg = () => {
  return (
    <>
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg" >
     <circle cx="0.706257" cy="24.3533" r="0.646687" transform="rotate(-90 0.706257 24.3533)" fill="#233A65" />
     <circle cx="6.39669" cy="24.3533" r="0.646687" transform="rotate(-90 6.39669 24.3533)" fill="#233A65" />
     <circle cx="12.0881" cy="24.3533" r="0.646687" transform="rotate(-90 12.0881 24.3533)" fill="#233A65" />
     <circle cx="17.7785" cy="24.3533" r="0.646687" transform="rotate(-90 17.7785 24.3533)" fill="#233A65" />
     <circle cx="0.706257" cy="18.6624" r="0.646687" transform="rotate(-90 0.706257 18.6624)" fill="#233A65"/>
     <circle cx="6.39669" cy="18.6624" r="0.646687" transform="rotate(-90 6.39669 18.6624)" fill="#233A65"/>
     <circle  cx="12.0881"  cy="18.6624" r="0.646687" transform="rotate(-90 12.0881 18.6624)" fill="#233A65" />
     <circle cx="17.7785" cy="18.6624" r="0.646687" transform="rotate(-90 17.7785 18.6624)" fill="#233A65" />
     <circle cx="0.706257" cy="12.9717" r="0.646687" transform="rotate(-90 0.706257 12.9717)" fill="#233A65" />
     <circle cx="6.39669" cy="12.9717" r="0.646687" transform="rotate(-90 6.39669 12.9717)" fill="#233A65" />
     <circle cx="12.0881"  cy="12.9717" r="0.646687" transform="rotate(-90 12.0881 12.9717)" fill="#233A65"/>
     <circle cx="17.7785" cy="12.9717" r="0.646687" transform="rotate(-90 17.7785 12.9717)" fill="#233A65" />
     <circle cx="0.706257" cy="7.28077"  r="0.646687" transform="rotate(-90 0.706257 7.28077)" fill="#233A65" />
     <circle cx="6.39669" cy="7.28077" r="0.646687" transform="rotate(-90 6.39669 7.28077)" fill="#233A65" />
     <circle cx="12.0881" cy="7.28077" r="0.646687" transform="rotate(-90 12.0881 7.28077)" fill="#233A65" />
     <circle  cx="17.7785"  cy="7.28077"  r="0.646687"  transform="rotate(-90 17.7785 7.28077)" fill="#233A65" />
     <circle cx="0.706257" cy="1.58989" r="0.646687" transform="rotate(-90 0.706257 1.58989)"  fill="#233A65" />
     <circle cx="6.39669" cy="1.58989" r="0.646687" transform="rotate(-90 6.39669 1.58989)" fill="#233A65" />
     <circle cx="12.0881" cy="1.58989" r="0.646687" transform="rotate(-90 12.0881 1.58989)" fill="#233A65" />
     <circle  cx="17.7785"  cy="1.58989"  r="0.646687" transform="rotate(-90 17.7785 1.58989)"  fill="#233A65" />
</svg>
    </>
  )
}

export default Svg