import { Popover } from "@headlessui/react";
import React, { Fragment } from "react";
import { Transition } from "semantic-ui-react";
import BathEstimateFormInput from "./BathEstimateFormInput";
import BedsEstimateFormInput from "./BedsEstimateFormInput";
import ResidentialCommercialFilter from "./ResidentialCommercialFilter";

const AgentMoreFilter = () => {
  return (
    <>
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button
              className={` ${
                open
                  ? "focus:outline-none w-full bg-red-300 !h-12 py-2 rounded-md text-white"
                  : "w-full bg-red-100 hover:bg-red-300 rounded-md text-center !h-12 py-2"
              }`}
            >
              <div className="w-full">
                <button className="inline-block align-middle text-sm text-center text-[#223A64] hover:text-white font-semibold tracking-wide">
                  More Filters
                </button>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute bg-white p-4 z-50 mt-2 shadow-xl border w-96 right-0 rounded-md">
                <div className="px-2 relative">
                  <span className="px-1 font-semibold">Beds</span>
                  <BedsEstimateFormInput />
                </div>
                <div className="px-2 relative">
                  <span className="px-1 font-semibold">Baths</span>
                  <BathEstimateFormInput />
                </div>
                <div className="p-2">
                  <h3 className="font-semibold tracking-wide">Price (PKR)</h3>
                  <div className="flex justify-between py-2">
                    <div className="px-2">
                      <p className="pb-1">Minimum</p>
                      <input
                        type="text"
                        className="rounded-xl border-gray-300 focus:outline-none focus:ring-none w-full"
                        placeholder="0"
                      />
                    </div>
                    <div className="px-2">
                      <p className="pb-1">Maximum</p>
                      <input
                        type="text"
                        className="rounded-xl border-gray-300 focus:outline-none focus:ring-none w-full"
                        placeholder="any"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between px-5 mt-2">
                  <button className="rounded-md bg-white text-center text-[#223A64] border-2 border-[#223A64] mx-1 w-6/12">
                    RESET
                  </button>
                  <button className="rounded-md bg-[#223A64] text-center mx-1 w-6/12 py-2 text-white">
                    DONE
                  </button>
                </div>
                {/* <ResidentialCommercialFilter /> */}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default AgentMoreFilter;
