import { useContext, useEffect, useRef, useState } from "react";
import { BuyRentContext } from "context/property/BuyRentContext"
import { City } from "country-state-city";
var data = require("../../data/jsons/__cityNames.json");

export default function CityInput({ city }) {
    const [value, setValue] = useState("");
    const [cityData, setCityData] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1)
    const dropdownRef = useRef(null);

    useEffect(() => {
        const allCities = City.getAllCities();
        setCityData(allCities);
    }, []);
    // close dropdown
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        }
        // Attach the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Remove the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const { dispatchFilter, setCityName, applyFilters } = useContext(BuyRentContext)

    const onChange = (event) => {
        setValue(event.target.value);
        if (event.target.value.trim() !== "") {
            setIsDropdownVisible(true);
            setHighlightedIndex(-1);
        } else {
            setIsDropdownVisible(false);
        }
    };

    const onSearch = (searchTerm) => {
        setValue(searchTerm)
        setCityName(searchTerm)
        city(searchTerm)
        dispatchFilter({
            type: "updatePropertyCity",
            value: searchTerm,
        })
        setIsDropdownVisible(false);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "ArrowDown") {
                setIsDropdownVisible(true);
                setHighlightedIndex((prevIndex) =>
                    prevIndex < cityData.filter((item) => item.name.toLowerCase().startsWith(value.toLowerCase())).length - 1 ? prevIndex + 1 : prevIndex
                );
            } else if (e.key === "ArrowUp") {
                setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
            } else if (e.key === "Enter" && highlightedIndex >= 0) {
                onSearch(cityData.filter((item) => item.name.toLowerCase().startsWith(value.toLowerCase()))[highlightedIndex].name);
                setHighlightedIndex(-1);
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [cityData, highlightedIndex, value]);

    return (
        <>
            <div className="" ref={dropdownRef}>
                <div className="search-container">
                    <div className="search-inner">
                        <div className="filter-search-form relative">
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" className="w-5 h-7">
                                    <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input className="dark:bg-slate-800 bg-white text-base !h-12 w-full border border-slate-300 dark:border-0 dark:text-white rounded-md pl-5 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-0 sm:text-sm"
                                placeholder="Enter Location" type="text" value={value} onChange={onChange} onFocus={() => setIsDropdownVisible(true)} />
                            <button onClick={() => onSearch(value)}></button>
                        </div>
                    </div>
                    {isDropdownVisible && (
                        <div className="absolute bg-white w-full z-40 shadow-xl rounded-xl mt-2 dropdown">
                            {cityData
                                .filter((item) => {
                                    const searchTerm = value.toLowerCase();
                                    const fullName = item.name.toLowerCase();

                                    return (
                                        searchTerm &&
                                        fullName.startsWith(searchTerm) &&
                                        fullName !== searchTerm
                                    );
                                })
                                .slice(0, 10)
                                .map((item, index) => (
                                    <div
                                        onClick={() => onSearch(item?.name)}
                                        className={`dropdown-row px-5 border-b-[2px] capitalize cursor-pointer dark:text-[#0c4a6e] border-[#0c4a6e] rounded-xl py-2 hover:bg-[#345886] hover:text-white${index === highlightedIndex ? ' text-white bg-[#345886]' : 'bg-white'}`}
                                        key={item?.name}
                                        onMouseEnter={() => setHighlightedIndex(index)}
                                    >
                                        {item?.name}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
