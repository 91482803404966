// import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/relax2.svg";
import HIW2img from "images/checklist.png";
import HIW3img from "images/smartpay.png";
// import VectorImg from "images/VectorHIW.svg";
import rightImgPng from "images/mob.png";

export interface SectionHowItWorkProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}
// const DEMO_DATA: SectionHowItWorkProps["data"] = [
//   {
//     id: 1,
//     img: HIW1img,
//     title: "Email Reminders",
//     desc: "Let each stay be an inspirational journey, each room a peaceful space",
//   },
//   {
//     id: 2,
//     img: HIW2img,
//     title: "Smart checklist",
//     desc: "Let each stay be an inspirational journey, each room a peaceful space",
//   },
//   {
//     id: 3,
//     img: HIW3img,
//     title: "Smart Payment",
//     desc: "Let each stay be an inspirational journey, each room a peaceful space",
//   },
// ];
const SectionHowItWork2: FC<SectionHowItWorkProps> = ({
  className = "",
  type = "type1",
  rightImg = rightImgPng,
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >

      <div
        className={`max-w-2xl flex-shrink-0 text-left mt-10 lg:mt-0 lg:w-2/6 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >

        <ul className="space-y-10 mt-4">
          <li className="space-y-4">
            {/* <Badge name="Advertising" /> */}
            <span className="block text-xl font-semibold">
              <i className=" las la-envelope text-4xl "></i>  Email Reminders
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Send automated tenant communications and set personal reminders.
            </span>
          </li>
          <li className="space-y-4">
            {/* <Badge color="green" name="Exposure " /> */}
            <span className="block text-xl font-semibold">
              <i className=" las la-user text-4xl "></i> Asset Management
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Store and manage active and historical leases for complete visibility.
            </span>
          </li>
          <li className="space-y-4">
            <span className="block text-xl font-semibold">
              <i className=" las la-camera text-4xl "></i>  Secure Document Storage
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Stay organized by uploading documents to store against each property or lease.
            </span>

          </li>
        </ul>
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 text-left mt-10 lg:mt-0 lg:w-2/6 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`} >

        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 text-left mt-10 lg:mt-0 lg:w-2/6 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`} >

        <ul className="space-y-10 mt-4">
          <li className="space-y-4">
            {/* <Badge name="Advertising" /> */}
            <span className="block text-xl font-semibold">
              <i className=" las la-mobile text-4xl "></i>  Supplier Directory
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Keep all your property-related contacts in one handy place for easy access, saving you time.
            </span>
          </li>
          <li className="space-y-4">
            {/* <Badge color="green" name="Exposure " /> */}
            <span className="block text-xl font-semibold">
              <i className=" las la-map text-4xl "></i>  Payment Invoicing
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              You can easily email and text payment receipts to tenants for their records
            </span>
          </li>
          <li className="space-y-4">
            <span className="block text-xl font-semibold">
              <i className=" las la-home text-4xl "></i> Entity Management
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              If you manage properties in multiple entities, you can easily add and link entities to properties for reporting.
            </span>

          </li>
        </ul>
      </div>
      {/* <div className="flex-grow">
        <NcImage src={rightImg} />
      </div> */}
    </div>
    // <div
    //   className={`nc-SectionHowItWork  ${className}`}
    //   data-nc-id="SectionHowItWork">
    //   <div className="grid grid-rows-3 grid-flow-col gap-4">
    //     <div className="row-start row-span-2">

    //       <h2 className="font-semibold text-left text-2xl mt-5"><i className=" las la-envelope text-4xl "></i>  Email Reminders</h2>
    //       <span className="block mt-5 text-left text-neutral-500 dark:text-neutral-400">
    //         Send automated tenant communications and set personal reminders.
    //       </span>
    //       <h2 className="font-semibold text-left text-2xl mt-5"><i className=" las la-user text-4xl "></i>  Tenancy Management</h2>
    //       <span className="block mt-5 text-left text-neutral-500 dark:text-neutral-400">
    //         Store and manage active and historical leases for complete visibility
    //       </span>

    //       <h2 className="font-semibold text-left text-2xl mt-5"><i className=" las la-camera text-4xl "></i>  Secure Document Storage</h2>
    //       <span className="block mt-5 text-left text-neutral-500 dark:text-neutral-400">
    //         Stay organized by uploading documents to store against each property or lease.
    //       </span>

    //     </div>
    //     <div className="row-end-3 row-span-2">
    //     <NcImage src={rightImg} />

    //       </div>
    //     <div className="row-start-1 row-end-4">
    //       <h2 className="font-semibold text-left text-3xl mt-5">Automated online rent collection</h2>
    //       <span className="block mt-5 text-left text-neutral-500 dark:text-neutral-400">
    //         Make late rent a problem of the past.
    //         Your tenants can easily and securely pay rent directly into your account, and even set up automated payments.
    //       </span>
    //     </div>
    //   </div>
    // </div>
  );
};
export default SectionHowItWork2;