// import MUIDataTable from "mui-datatables";
import React, { useContext, useEffect, useState } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { PinContext } from "../../../services/context/pin/PinContext";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  table: {
    '& tr': {
      color: localStorage.theme === "light" ? "black" : "white"
    },
    // color: localStorage.theme === "light" ? "black" : "white"
  },
  typo: {
    color: localStorage.theme === "light" ? "black" : "white"
  }
}))

export const DataTable = () => {
  const classes = useStyles()
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [printBtn, setPrintBtn] = useState(false);
  const [viewColumnBtn, setViewColumnBtn] = useState(false);
  const [filterBtn, setFilterBtn] = useState(true);

  const columns = [
    {
      name: "phase",
      label: "Phase #",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "blockNumber",
      label: "Block #",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "plotno",
      label: "Plot Number",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "plotCat",
      label: "Plot Category",
      options: {
        filter: true,
        sort: true,
      }
    },

    {
      name: "areaCat",
      label: "Area Category",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "area",
      label: "Area",
      options: {
        filter: true,
        sort: true,
      }
    },


  ];

  const { getPins, pins, filter } = useContext(PinContext);
  // const [data, setData] = useState([])

  useEffect(() => {
    const fetchPins = async () => {
      await getPins({ filter })
      // setData(res.data)
    };
    fetchPins();
  }, [])



  return (
    // <CacheProvider value={muiCache}>
    <div style={{ overflowX: 'auto' }}>

      <Table aria-label="simple table" size="small" style={{ minWidth: 350, }} className={classes.table}  >
        <TableBody>
          <TableRow>
            {
              columns.map((column) =>
                <TableCell key={column.label} component="th" scope="row">
                  <Typography variant="h5" className={classes.typo} style={{ fontWeight: 800, fontSize: 18 }}>{column.label}</Typography>
                </TableCell>
              )
            }
          </TableRow>
          {
            pins?.map((item: any) => {
              return (
                <TableRow>
                  {
                    columns.map((column) =>
                      <TableCell key={column.label} component="th" scope="row">
                        <Typography className={classes.typo} variant="subtitle1">{item[column.name]}</Typography>
                      </TableCell>
                    )
                  }
                </TableRow>
              )
            })

          }
        </TableBody>
      </Table>
    </div>

    // <MUIDataTable
    //   title={"IMLAAK PLOT LIST DATA"}
    //   data={data}
    //   columns={columns}
    //   options={{
    //     search: searchBtn,
    //     download: downloadBtn,
    //     print: printBtn,
    //     viewColumns: viewColumnBtn,
    //     filter: filterBtn,
    //     filterType: "dropdown",
    //     tableBodyHeight,
    //     tableBodyMaxHeight,

    //     onTableChange: (action: any, state: any) => {
    //       console.log(action);
    //       console.dir(state);
    //     }
    //   }}
    // />
    // </CacheProvider>
  );
}


export default DataTable
