import { PlayIcon, RewindIcon } from '@heroicons/react/solid';
import { Play } from 'heroicons-react';
import React from 'react'

interface PaginationCheckProps {
    currentPage: number;
    text: string;
    consultantsPerPage: number;
    totalConsultants: number;
    paginate: (pageNumber: number) => void;
}

const PaginationCheck: React.FC<PaginationCheckProps> = ({ currentPage, consultantsPerPage, totalConsultants, paginate, text }) => {
    const totalPageCount = Math.ceil(totalConsultants / consultantsPerPage);

    const hasPrevious = currentPage > 1;
    const hasNext = currentPage < totalPageCount;

    // Logic to display page numbers according to the specified format
    let pagesToDisplay: number[] = [];
    if (currentPage === 1) {
        // Handle first page case
        pagesToDisplay = [1, 2, 3].filter(page => page <= totalPageCount);
    } else if (currentPage === totalPageCount && totalPageCount > 3) {
        // Handle last page case
        pagesToDisplay = [totalPageCount - 2, totalPageCount - 1, totalPageCount];
    } else if (totalPageCount > 3) {
        // Handle general case for middle pages
        pagesToDisplay = [currentPage - 1, currentPage, currentPage + 1].filter(page => page <= totalPageCount);
    } else {
        // If total pages are 3 or less, show all
        pagesToDisplay = Array.from({ length: totalPageCount }, (_, i) => i + 1);
    }

    return (
        <>
            <div className='flex justify-center'>
                <ul className='flex flex-row'>
                    {hasPrevious && (
                        <>
                            <li onClick={() => paginate(1)} className="shadow rounded-md mx-2 py-2 text-sm align-middle px-4 cursor-pointer bg-gray-100">
                                <abbr title="First Page">
                                    <button onClick={() => paginate(1)} className="">
                                        <RewindIcon className='h-5 w-5 dark:text-[#345886]' />
                                    </button>
                                </abbr>
                            </li>
                            <li onClick={() => paginate(currentPage - 1)} className="shadow rounded-md mx-2 py-2 text-sm align-middle px-4 cursor-pointer bg-gray-100">
                                <abbr title="Previous">
                                    <button onClick={() => paginate(currentPage - 1)} className="">
                                        <svg className='w-5 h-5' fill='#000' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 19V5l-11 7z" />
                                        </svg>
                                    </button>
                                </abbr>
                            </li>
                        </>
                    )}
                    {pagesToDisplay.map(number => (
                        <li key={number} onClick={() => paginate(number)} className={`shadow rounded-md mx-2 py-2 text-sm align-middle px-4 dark:text-gray-500 cursor-pointer ${currentPage === number ? 'active bg-blue-50 py-2 px-4 dark:bg-blue-200 dark:text-gray-500' : 'bg-gray-100'}`}>
                            <button onClick={() => paginate(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                    {hasNext && (
                        <li onClick={() => paginate(currentPage + 1)} className="shadow rounded-md mx-2 py-2 text-sm align-middle px-4 cursor-pointer bg-gray-100">
                            <abbr title="Next">
                                <button onClick={() => paginate(currentPage + 1)} className="page-link">
                                    <svg className='w-5 h-5' fill='#000' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 5v14l11-7z" />
                                    </svg>
                                </button>
                            </abbr>
                        </li>
                    )}
                </ul>
            </div>
            <div className='flex justify-center text-xs font-medium tracking-wide text-gray-400 mt-3'>
                (Showing {(currentPage - 1) * consultantsPerPage + 1} - {Math.min(currentPage * consultantsPerPage, totalConsultants)} of {totalConsultants} {text})
            </div>
        </>
    )
}

export default PaginationCheck
