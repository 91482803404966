import React, { useEffect, useState, Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import GuestsInput, { GuestsInputProps } from "./GuestsInput"
import { FC } from "react"

export interface CommercialOptionProps {
  haveDefaultValue?: boolean
}
export interface CommercialOption {
  tabs?: string[]
  haveDefaultValue?: boolean
  handleProjectType?: any
  filter?: any
  subCategory?: any
}

const CommercialOption: FC<CommercialOption> = ({
  haveDefaultValue,
  handleProjectType,
  filter,
  subCategory,
}) => {
  const [CommercialInput, setCommercialInput] = useState<any>(
    filter?.subCategory
  )
  const renderCommercialInput = () => {
    return (
      <div className='pb-2 grid grid-cols-2 gap-2'>
        {subCategory.map(({ value, label }: any) => (
          <div
            className={`w-full py-1 col-span-1 px-0 rounded-full text-center text-xs cursor-pointer my-1 ${CommercialInput === value
                ? "bg-[#223A64] font-semibold text-white"
                : "border border-neutral-300 dark:border-neutral-700 text-[#223A64]"
              }`}
            onClick={(e) => {
              setCommercialInput(value)
              handleProjectType(value)
            }}
          >
            {label}
          </div>
        ))}
      </div>
    )
  }
  const renderForm = () => {
    return (
      <div className='px-1 -mt-2 font-medium'>{renderCommercialInput()}</div>
    )
  }

  return renderForm()
}

export default CommercialOption
