import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { ArrowRightIcon } from "@heroicons/react/24/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StartRating from "components/StartRating/StartRating";
import { Helmet } from "react-helmet";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import EmailFrom from "./EmailFrom";
import { ProjectContext } from "context/project/ProjectContext";
import SectionVideos from "containers/PageHome/SectionVideos";
import Logo from "shared/Logo/Logo";
import { Link, useParams } from "react-router-dom";
import { isTemplateExpression } from "typescript";
import { upload } from "@testing-library/user-event/dist/upload";
import { API } from "utils/config";
import CurrencyFormat from "components/HeroSearchForm/CurrencyFormat";
import Navigation from "./Navigation";
import FloorModalPhotos from "./FloorModalPhotos";
import { MapIcon } from "@heroicons/react/solid";
import DummyMain from "../../images/bg/Dummy_property.jpg"
import GallerySlider2 from "components/GallerySlider/GallerySlider2";
import GeneralEmail from "./GeneralEmail";
import SingleModalPhoto from "./SingleModalPhoto";
export interface ProjectsDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

interface PaymentPlan {
  floorTitle: string;
  paymentImage: string;
}

interface FloorPlan {
  floorTitle: string;
  minArea: number;
  maxArea: number;
  minPrice: number;
  maxPrice: number;
  floorImage: string;
  category: string;
}

interface mainImages {
  url: string;
}
interface ProjectNameDetail {
  _id: string;
  longitude: number;
  latitude: number;
  placeId: string;
}
interface FloorPlanProps {
  floorPlans?: FloorPlan[];
  paymentPlans: PaymentPlan[];
}

const PHOTOS: string[] = ["", "", "", "", ""];

const ProjectsDetailPage: FC<ProjectsDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  type QuizParams = {
    id: any;
  };

  const { getAllProjects, projects, projectNames, getAllProjectNames, projectDetails, getProjectBySlug, getAllAmenities, amenities }: any = useContext(ProjectContext);
  let { projectSlug } = useParams<any>();
  useEffect(() => {
    getProjectBySlug(projectSlug)
    getAllProjects();
    getAllProjectNames();
    getAllAmenities();
  }, []);

  // Getting ProjectNames
  const [projectLookup, setProjectLookup] = useState<any>({});
  const [longitudeValue, setLongitudeValue] = useState<any>({});
  const [latitudeValue, setLatitudeValue] = useState<any>({});

  useEffect(() => {
    // Create a lookup object for projectName id's to get Name and Coordinates
    const lookup: any = {};
    const valueLng: any = {};
    const valueLtd: any = {};
    projectNames.forEach((row: any) => {
      lookup[row?._id] = row?.projectName;
      valueLng[row?._id] = row?.longitude;
      valueLtd[row?._id] = row?.latitude;
    });
    setProjectLookup(lookup);
    setLongitudeValue(valueLtd);
    setLatitudeValue(valueLng);
  }, [projectNames]);

  // const projectDetails = projects?.project?.filter((row: any) => row?.projectSlug === projectSlug)
  const projectNameId = projectDetails?.[0]?.projectName;
  const projectCoordinates = projectNames?.filter((row: any) => row?._id === projectNameId)
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [features, setFeatures] = useState<any>([]);

  // useEffect(() => {

  // }, [amenitiesList])

  const floorPlans: FloorPlan[] | undefined = projectDetails?.[0]?.floorPlans;
  const [floorPlanDetails, setFloorPlanDetails] = useState<FloorPlan[]>([]);

  useEffect(() => {
    if (Array.isArray(floorPlans)) {
      // Extracting floorTitle, minArea & maxArea, minPrice & maxPrice
      const extractedData = floorPlans.map(({ floorTitle, minArea, maxArea, minPrice, maxPrice, floorImage, category }: FloorPlan) => ({
        floorTitle,
        minArea,
        maxArea,
        minPrice,
        maxPrice,
        floorImage,
        category,
      }));
      extractedData.sort((a, b) => {
        const titleA = a.floorTitle.toUpperCase();
        const titleB = b.floorTitle.toUpperCase();
        const isNumA = !isNaN(Number(titleA));
        const isNumB = !isNaN(Number(titleB));

        if (!isNumA && !isNumB) {
          // Both are non-numerical
          return titleA.localeCompare(titleB);
        } else if (!isNumA) {
          // titleA is non-numerical and should come first
          return -1;
        } else if (!isNumB) {
          // titleB is non-numerical and should come first
          return 1;
        }
        // Both are numerical
        return Number(titleA) - Number(titleB);
      });
      setFloorPlanDetails(extractedData);
    }
  }, [floorPlans]);


  const paymentPlans: PaymentPlan[] | undefined = projectDetails?.[0]?.paymentPlans;
  const [paymentPlanDetails, setPaymentPlanDetails] = useState<PaymentPlan[]>([]);

  useEffect(() => {
    if (Array.isArray(paymentPlans)) {
      // Extracting floorTitle, minArea & maxArea, minPrice & maxPrice
      const paymentPlanExtractedData = paymentPlans.map(({ floorTitle, paymentImage }: PaymentPlan) => ({
        floorTitle,
        paymentImage,
      }));
      paymentPlanExtractedData.sort((a, b) => {
        const titleA = a.floorTitle.toUpperCase();
        const titleB = b.floorTitle.toUpperCase();
        const isNumA = !isNaN(Number(titleA));
        const isNumB = !isNaN(Number(titleB));

        if (!isNumA && !isNumB) {
          // Both are non-numerical
          return titleA.localeCompare(titleB);
        } else if (!isNumA) {
          // titleA is non-numerical and should come first
          return -1;
        } else if (!isNumB) {
          // titleB is non-numerical and should come first
          return 1;
        }
        // Both are numerical
        return Number(titleA) - Number(titleB);
      });
      setPaymentPlanDetails(paymentPlanExtractedData);
    }
  }, [paymentPlans]);

  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");


  const [projectImages, setProjectImages] = useState<any>([]);

  useEffect(() => {
    const images = projectDetails?.[0]?.mainImages ?? [];

    const projectMainImages = projectDetails?.[0]?.mainImages?.map((row: mainImages) => row?.url)
    setProjectImages(projectMainImages)

    const mainAmenitiesList = amenities?.map((amenity: any) => ({
      categories: amenity?.categories?.[0],
      titles: amenity?.list?.map((item: any) => item.title)
    }));

    const currentAmenitiesList = projectDetails?.[0]?.amenities?.map((item: any) => item?.label);

    const result = mainAmenitiesList?.map((amenity: any) => {
      const matchedLabels = amenity?.titles?.filter((title: any) => currentAmenitiesList?.includes(title));
      return { category: amenity?.categories, labels: matchedLabels };
    })?.filter((item: any) => item?.labels?.length > 0);

    setFeatures(result)

  }, [projectDetails]);

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };
  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
  const handleOpenModal2 = () => {
    setIsOpen2(true);
  };
  const handleCloseModal = () => setIsOpen(false);
  const handleCloseModal2 = () => setIsOpen2(false);

  // Floor Images Slider
  const [isFloorImagesOpen, setFloorImagesIsOpen] = useState(false);
  const [openFloorImagesFocusIndex, setOpenFloorImagesFocusIndex] = useState(0);

  const [visibleCount, setVisibleCount] = useState(5);
  const [visibleCount2, setVisibleCount2] = useState(6);

  const handleShowMore = () => {
    setVisibleCount((prevCount: any) => prevCount + 5);
  };

  const handleShowLess = () => {
    setVisibleCount((prevCount) => (prevCount - 5 < 5 ? 5 : prevCount - 5));
  };

  const handleShowMore2 = () => {
    setVisibleCount2((prevCount: any) => prevCount + 6);
  };

  const handleShowLess2 = () => {
    setVisibleCount2((prevCount) => (prevCount - 6 < 6 ? 6 : prevCount - 6));
  };

  const formatCategory = (category: string[] | string) => {
    if (Array.isArray(category)) {
      return category.join(", ");
    }
    return category;
  };


  const handleFloorImagesOpenModal = (index: number) => {
    setFloorImagesIsOpen(true);
    setOpenFloorImagesFocusIndex(index);
  };

  const handleFloorImagesCloseModal = () => setFloorImagesIsOpen(false);

  const renderSliderPhotoSection = () => {
    return (
      <div className=''>
        <>
          <div className='relative pt-4'>
            <div className="grid grid-cols-7 gap-4">
              <div
                className='col-span-7 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
              >
                <GallerySlider2
                  galleryImgs={projectImages}
                  uniqueID={`StayCardH_${projectDetails?.[0]?._id}`}
                  imageClassCss="object-contain w-full h-full rounded-md sm:rounded-xl"
                  ratioClass='relative inset-0'
                />
                {/* <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-80 transition-opacity'></div> */}
              </div>
              {/* <div className="col-span-2">
                <GallerySlider2
                  galleryImgs={projectImages}
                  uniqueID={`StayCardH_${projectDetails?.[0]?._id}`}
                  imageClassCss="object-contain w-full h-full rounded-md sm:rounded-xl"
                  ratioClass='relative inset-0'
                />
              </div> */}
            </div>

            <div
              className='absolute flex items-center justify-center align-middle sm:left-3 left-1 sm:bottom-3 bottom-1 sm:px-4 px-2 sm:py-2 py-0 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='sm:h-5 sm:w-5 h-3 w-3'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                />
              </svg>
              <span className='ml-2 text-neutral-800 sm:text-sm text-[0.5rem] font-medium'>
                Show all photos
              </span>
            </div>
          </div>
          <ModalPhotos
            imgs={projectImages}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName='nc-PropertyDetailPage-modalPhotos'
          />
        </>
      </div>
    )
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap section !space-y-6" id="overview">
        {projectDetails[0] && (
          <div className="flex flex-col items-left">
            {/* <Avatar  sizeClass="h-10 w-10" /> */}
            {/* <img src="https://www.lahoresmartcitypk.com/assets/img/web/logo.png" alt="" width={200} /> */}
            <div>
              <span className="text-[#223A64] font-bold dark:text-neutral-400 capitalize">
                <span className="text-2xl text-black dark:text-white font-semibold ease-in-out duration-500 capitalize">
                  <br />
                  {projectLookup[projectDetails?.[0]?.projectName]}
                  <br />
                </span>
                By {projectDetails?.[0]?.developer?.name}
              </span>
            </div>
            <br />
            <div className="flex items-center justify-between grid grid-cols-1 xl:justify-start text-sm text-neutral-700 dark:text-neutral-300">
              <div className="grid grid-cols-3 flex-row bg-gray-200 dark:bg-slate-800 dark:shadow-lg px-3 py-3 rounded">
                <div className="col-span-1">
                  <p className="font-semibold">
                    <i className="las la-map-marked text-xl"></i> Location :
                  </p>
                </div>
                <div className="col-span-2 leading-7">
                  <span className="capitalize">
                    {/* {projectDetails?.[0]?.location?.areaName} , */}
                    {projectDetails?.[0]?.city}, {projectDetails?.[0]?.province}, {projectDetails?.[0]?.country}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-3 flex-row bg-white dark:bg-transparent px-3 py-4 rounded">
                <div className="col-span-1 pr-2 sm:pr-0">
                  <p className="font-semibold">
                    {" "}
                    <i className=" las la-city text-2xl"></i> Project Type{" "}
                  </p>
                </div>
                <div className="col-span-2 leading-7">
                  <span className="capitalize">
                    {projectDetails?.[0]?.projectPropertyTypes?.join(", ")}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-3 flex-row bg-gray-200 dark:bg-slate-800 dark:shadow-lg px-3 py-4 rounded">
                <div className="col-span-1 pr-2 sm:pr-0">
                  <p className="font-semibold">
                    {" "}
                    <i className=" las la-donate text-2xl"></i> Project Price{" "}
                  </p>
                </div>
                <div className="col-span-2 leading-7">
                  <span className="capitalize">
                    PKR : <CurrencyFormat price={projectDetails?.[0]?.minpriceRange} />
                    {" - "}
                    <CurrencyFormat price={projectDetails?.[0]?.maxpriceRange} />
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-3 flex-row bg-white dark:bg-transparent px-3 py-4 rounded">
                <div className="col-span-1">
                  <p className="font-semibold">
                    {" "}
                    <i className="las la-tools text-2xl"></i>Developer{" "}
                  </p>
                </div>
                <div className="col-span-2 leading-7">
                  <span className="capitalize">{projectDetails?.[0]?.developer?.name}</span>
                </div>
              </div>
            </div>
            {/* <div className="flex items-center justify-between grid grid-cols-2 xl:justify-start text-sm text-neutral-700 dark:text-neutral-300">
              <div className="sm:col-span-1 col-span-1 flex items-center space-x-3 py-2 ">
                <i className=" las la-map-marked text-2xl "></i>
                <span className="">
                  <span className="capitalize">
                    {item.location.areaName}
                    {item.location.state}
                  </span>
                </span>
              </div>
              <div className="sm:col-span-1 col-span-1 flex items-center space-x-3 py-2">
                <i className=" las la-city text-2xl"></i>
                <span className=" ">
                  <span className="inline-block capitalize">
                    {item.projectType}
                  </span>
                </span>
              </div>
              <div className="sm:col-span-1 col-span-1 text-left flex justify-content-start mr-5 space-x-3 py-2">
                <i className=" las la-donate text-2xl"></i>
                <span className=" ">
                  <span className="inline-block capitalize">PKR {item.priceRange.max}</span>
                </span>
              </div>
              <div className="sm:col-span-1 col-span-1 flex items-center space-x-3 py-2">
                <i className="las la-tools text-2xl"></i>
                <span className=" ">
                  <span className="inline-block capitalize">
                    {item.developer.name}
                  </span>
                </span>
              </div>
            </div> */}
          </div>
        )}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
      </div>
    );
  };

  const renderSectionPhoto1 = () => {
    return (
      <div className="">
        <>
          <header className="">
            {/* {projectDetails?.[0]?.images?.length == "5" ?  */}
            <div className="relative grid grid-cols-1 sm:grid-cols-1 gap-1 sm:gap-2">
              <div
                className="col-span-4 row-span-4 sm:row-span-4 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  key={projectSlug}
                  containerClassName="inset-0"
                  className="object-fill w-full h-[25vh] lg:h-[60vh] md:h-[25vh] rounded-md sm:rounded-xl"
                  src={`${API}/uploads/${projectDetails?.[0].images?.[0]}`}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {projectDetails?.[0].images
                .filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName=""
                      className="object-fill w-full h-full rounded-md sm:rounded-xl"
                      src={`${API}/uploads/${item || ""}`}
                    />

                    {/* {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={projectDetails?.[0].images}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-PropertyDetailPage-modalPhotos"
          />
        </>
      </div>
    );
  };

  const renderSectionPhoto2 = () => {
    return (
      <div className="">
        <>
          <header className="">
            <div className="relative grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-2 pt-4">
              <div
                className="col-span-2 row-span-2 sm:row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  key={projectSlug}
                  containerClassName=""
                  className="object-fill w-full h-[30vh] md:h-[25vh] lg:h-[60vh] rounded-md sm:rounded-xl"
                  src={`${API}/uploads/${projectDetails?.[0].images?.[0]}`}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {projectDetails?.[0].images
                .filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 relative aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-fill w-full h-[30vh] md:h-[25vh] lg:h-[60vh] rounded-md sm:rounded-xl"
                      src={`${API}/uploads/${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={projectDetails?.[0].images}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-PropertyDetailPage-modalPhotos"
          />
        </>
      </div>
    );
  };

  const renderSectionPhoto3 = () => {
    return (
      <div className="">
        <>
          <header className="">
            {/* {projectDetails?.[0]?.images?.length == "5" ?  */}
            <div className="relative grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-2">
              <div
                className="col-span-2 row-span-1 sm:row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <NcImage
                  key={projectSlug}
                  containerClassName="inset-0"
                  className="object-fill w-full h-[20vh] md:h-[25vh] lg:h-[60vh] rounded-md sm:rounded-xl"
                  src={`${API}/uploads/${projectDetails?.[0].images?.[0]}`}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {projectDetails?.[0].images
                .filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-fill w-full h-[20vh] md:h-[25vh] lg:h-[60vh] rounded-md sm:rounded-xl"
                      src={`${API}/uploads/${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={projectDetails?.[0].images}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-PropertyDetailPage-modalPhotos"
          />
        </>
      </div>
    );
  };

  const renderSectionPhoto4 = () => {
    return (
      <div className="">
        <>
          <header className="">
            {/* {projectDetails?.[0]?.images?.length == "5" ?  */}
            <div className="relative grid grid-cols-3 sm:grid-cols-3 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-2 sm:row-span-1 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                {/* {projectDetails?.[0]?.images?.map((id:any, item:any))} */}
                <NcImage
                  key={projectSlug}
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={`${API}/uploads/${projectDetails?.[0].images?.[0]}`}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {projectDetails?.[0].images
                .filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={`${API}/uploads/${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={projectDetails?.[0].images}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-PropertyDetailPage-modalPhotos"
          />
        </>
      </div>
    );
  };

  const renderSectionPhoto5 = () => {
    return (
      <div className="">
        <>
          <header className="">
            {/* {projectDetails?.[0]?.images?.length == "5" ?  */}
            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                {/* {projectDetails?.[0]?.images?.map((id:any, item:any))} */}
                <NcImage
                  key={projectSlug}
                  containerClassName="absolute inset-0"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl"
                  src={`${API}/uploads/${projectDetails?.[0].images?.[0]}`}
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {projectDetails?.[0].images
                .filter((_: any, i: any) => i >= 1 && i < 5)
                .map((item: any, index: any) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                      }`}
                  >
                    <NcImage
                      containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={`${API}/uploads/${item || ""}`}
                    />

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </div>
            </div>
          </header>
          <ModalPhotos
            imgs={projectDetails?.[0].images}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
            uniqueClassName="nc-PropertyDetailPage-modalPhotos"
          />
        </>
      </div>
    );
  };
  //end dynamic image gallery

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap section" id="overview">
        {projectDetails[0] && (
          <div>
            <h2 className="text-2xl relative font-semibold pb-5 underlineBefore">Description</h2>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400 text-justify">
              <div dangerouslySetInnerHTML={{ __html: projectDetails?.[0]?.description }} />
              {/* {projectDetails?.[0]?.description} */}
            </span>
          </div>
        )}
      </div>
    );
  };

  const renderFeatures = () => {
    return (
      <div className="listingSection__wrap section" id="features">
        <div className="dark:border-neutral-700">
          <h2 className="text-2xl py-2 font-semibold">Features</h2>
          <div className="pt-3 pb-2 w-50">
            {/* <div className="py-3">
              <span className="text-lg font-medium px-3">Main Features</span>
            </div> */}
            <div className="py-2.5 hidden sm:grid-cols-2 grid-cols-1 gap-2">
              {projectDetails?.[0]?.amenities.map((item: any) => (
                <div className="col-span-1 py-2.5 bg-gray-200 dark:bg-slate-800 rounded px-3">
                  <span className="font-xs mx-2">
                    <span className=" ">
                      <i className="las la-check-circle text-[#233865] dark:text-white text-[20px] mx-2"></i>
                    </span>
                    <span className="capitalize font-normal">
                      {item?.value}
                    </span>

                  </span>
                </div>
              ))}
            </div>

            {features?.map((item: any, index: any) => (
              <div key={index} className="mb-4">
                <div className="py-3">
                  <span className="text-lg font-semibold tracking-wide px-3">{item?.category}</span>
                </div>
                <div className="py-2.5 grid sm:grid-cols-2 grid-cols-1 gap-2">
                  {item.labels.map((label: any, labelIndex: any) => (
                    <div className="col-span-1 py-2.5 bg-gray-200 dark:bg-slate-800 rounded px-3">
                      <span className="font-xs mx-2">
                        <span className=" ">
                          <i className="las la-check-circle text-[#233865] dark:text-white text-[20px] mx-2"></i>
                        </span>
                        <span className="capitalize font-normal" key={labelIndex}>
                          {label}
                        </span>

                      </span>
                    </div>
                  ))}

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderPropertyType = () => {
    return (
      <div className="listingSection__wrap section" id="propertyType">
        <div className="dark:border-neutral-700">
          <h2 className="text-2xl py-2 font-semibold">Property Types</h2>
          <div>
            {floorPlanDetails.slice(0, visibleCount).map((floorPlan, index) => (
              <div className="pt-3 pb-2 w-50" key={index}>
                <div className="py-3">
                  <span className="text-md font-medium">
                    {floorPlan?.floorTitle} Floor
                    {floorPlan?.category && ` (${formatCategory(floorPlan?.category)})`}
                  </span>
                </div>
                <div className="py-2 grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1 bg-gray-200 dark:bg-slate-800 dark:shadow-lg rounded-md px-3">
                  <div className="col-span-1 py-1 lg:border-0 md:border-0 border-b border-white">
                    <span className="font-xs mx-2 flex justify-start">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                          />
                        </svg>
                      </span>
                      <span className="capitalize font-semibold">Area: &nbsp;</span>
                      <span className="capitalize font-normal text-[90%]">
                        {floorPlan.minArea}-{floorPlan.maxArea} <strong>sq.Ft</strong>
                      </span>
                    </span>
                  </div>
                  <div className="col-span-1 py-1">
                    <span className="font-xs mx-2">
                      <span>
                        <i className="las la-donate text-[20px] mx-1"></i>
                      </span>
                      <span className="capitalize font-semibold">Price: &nbsp;</span>
                      <span className="capitalize font-normal text-[90%]">
                        <CurrencyFormat price={floorPlan.minPrice} /> - <CurrencyFormat price={floorPlan.maxPrice} />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-center">
              {visibleCount < floorPlanDetails?.length && (
                <button onClick={handleShowMore} className="mt-4 p-2 mx-2 rounded-md bg-[#233a65] hover:bg-[#263c65] text-white px-5">
                  Show More
                </button>
              )}
              {visibleCount > 5 && (
                <button onClick={handleShowLess} className="mt-4 p-2 mx-2 rounded-md bg-[#233a65] hover:bg-[#263c65] text-white px-5">
                  Show Less
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Floor Plan Images
  const renderFloorPlan = () => {
    return (
      <>
        <div className="listingSection__wrap section" id="floor">
          <div className="dark:border-neutral-700">
            <h2 className="text-2xl py-2 font-semibold">Floor Plans</h2>
            <div className="pt-3 pb-2 w-50">
              <div className="py-2 grid sm:grid-cols-3 grid-cols-1 gap-4 rounded-md px-3">
                {projectDetails?.[0]?.floorPlans.slice(0, visibleCount2).map((item: any) => (
                  <>
                    <div className="col-span-1 p-2 rounded border border-gray-300 cursor-pointer" key={item._id} onClick={handleOpenModal2}>
                      <div className="flex flex-col">
                        <div className="flex justify-center border-b">
                          <NcImage src={`${item?.floorImage?.[0]?.url}`} className="h-48 object-contain" alt="" />
                        </div>
                        <div className="flex pt-2 justify-center">
                          <h2 className="text-base font-semibold">{item?.floorTitle} Floor</h2>
                        </div>
                      </div>
                    </div>
                    <SingleModalPhoto
                      img={`${item?.floorImage?.[0]?.url}`}
                      isOpen={isOpen2}
                      onClose={handleCloseModal2}
                      uniqueClassName='nc-PropertyDetailPage-modalPhotos'
                    />
                  </>
                ))}
              </div>
              <div className="flex justify-center">
                {visibleCount2 < projectDetails?.[0]?.floorPlans?.length && (
                  <button onClick={handleShowMore2} className="mt-4 p-2 mx-2 rounded-md bg-[#233a65] hover:bg-[#263c65] text-white px-5">
                    Show More
                  </button>
                )}
                {visibleCount2 > 6 && (
                  <button onClick={handleShowLess2} className="mt-4 p-2 mx-2 rounded-md bg-[#233a65] hover:bg-[#263c65] text-white px-5">
                    Show Less
                  </button>
                )}
              </div>
            </div>

          </div>
        </div>

        {/* <FloorModalPhotos
          imgs={projectDetails?.[0].images}
          isOpen={isFloorImagesOpen}
          onClose={handleFloorImagesCloseModal}
          uniqueClassName={""} /> */}
      </>
    );
  };

  // Payment Plan Images
  const renderPaymentPlan = () => {
    return (
      <>
        {projectDetails?.[0]?.paymentPlans?.length > 0 && (
          <div className="listingSection__wrap section" id="payment">
            <div className="dark:border-neutral-700">
              <h2 className="text-2xl py-2 font-semibold">Payment Plan</h2>
              <div className="pt-3 pb-2 w-50">
                <div className="py-2 grid sm:grid-cols-3 grid-cols-1 gap-4 rounded-md px-3">

                  {projectDetails?.[0]?.paymentPlans.map((item: any) => (
                    <div className="col-span-1 p-2 rounded border border-gray-300" key={item?._id}>
                      <div className="flex flex-col">
                        <div className="flex justify-center border-b">
                          <img src={`${item?.paymentImage?.[0]?.url}`} className="h-48 object-contain" alt="" />
                        </div>
                        <div className="flex pt-2 justify-center">
                          <h2 className="text-base font-semibold">{item?.floorTitle} Floor</h2>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // Youtube Vide
  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap section">
        {/* {projectDetails?.project[0].videoUrl map((item:any) =>{ */}
        <div className="bg-">
          <SectionVideos vidurl={projectDetails?.[0]?.videoUrl} />
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]"></div>
        </div>
      </div>
    );
  };

  // Developer Section
  const renderHostInfo = () => {
    const developerInfo = projectDetails?.[0]?.developer
    return (
      <>
        <div className="section" id='developer'>
          <div className='listingSection__wrap relative'>
            {/* HEADING */}
            <h2 className='text-2xl font-semibold'>Developer Information</h2>
            <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
            {/* Consultant */}
            <Link to={`/developer/${developerInfo?._id}`}>
              <div className='lg:flex md:flex hidden justify-between relative'>
                <div className="align-middle">
                  <h2 className='text-xl font-medium capitalize flex justify-start' >
                    {developerInfo?.name}
                    <sup><button className="bg-[#233a65] border-0 rounded-lg text-xs font-semibold text-white px-2 py-[1px]">View Details</button></sup>
                  </h2>
                  <p className="pt-3 line-clamp-6">
                    <div dangerouslySetInnerHTML={{ __html: developerInfo?.description }} />
                  </p>
                </div>
              </div>
              <div className='lg:hidden md:hidden grid grid-cols-1 gap-2'>
                <div className="col-span-1 flex justify-center">
                  <NcImage
                    src={`${developerInfo?.developerImage?.[0]?.url}`}
                    alt={developerInfo?.name}
                    className="w-[45vw] h-[45vw]"
                  />
                </div>
                <div className="col-span-1 text-center">
                  <h2 className='block text-xl font-medium capitalize pt-3' >
                    {developerInfo?.name}
                  </h2>
                  <p className="pt-3 line-clamp-6">
                    <div dangerouslySetInnerHTML={{ __html: developerInfo?.description }} />
                  </p>
                </div>
              </div>
            </Link>
            <div className="absolute top-5 right-5 lg:block md:block hidden">
              <NcImage
                src={`${developerInfo?.developerImage?.[0]?.url}`}
                className="w-[100px] h-[100px]"
                alt=''
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              {/* <ArrowRightIcon className="w-5 h-5" /> */}
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderTabSection = () => {
    return (
      <>
        <Navigation />
      </>
    );
  };

  const renderSection7 = () => {
    const API_KEY = process.env.REACT_APP_MAP_API;
    return (
      <>

        <div className="listingSection__wrap hover:transform section" id="map">
          <h2 className="text-2xl font-semibold">Project Location</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          {projectCoordinates.map((item: ProjectNameDetail) => (
            <iframe
              key={item._id}
              title="Location Map"
              className="w-full h-[70vh] rounded-lg shadow-xl"
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=place_id:${item?.placeId}`}
            />
          ))}
        </div>
      </>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        <div className="flex justify-center text-center">
          <Logo />
        </div>
        <GeneralEmail />
      </div>
    );
  };

  const renderSectionPhoto = () => {
    return (
      <div className=''>
        <>
          <header className=''>
            <div className='relative'>
              <div
                className='relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
              >
                <NcImage
                  containerClassName='inset-0'
                  className='object-fill w-full h-[25vh] lg:h-[60vh] md:h-[40vh] rounded-md sm:rounded-xl'
                  src={``}
                />
                <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
              </div>
            </div>
          </header>
        </>
      </div>
    )
  }

  {
    if (projectImages?.length > 0) {
      return (
        <div
          className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
          data-nc-id="PropertyDetailPage"
        >
          <Helmet>
            <title> {`${projectLookup[projectDetails?.[0]?.projectName]}`} | Imlaak </title>
          </Helmet>
          <div className="container">{renderSliderPhotoSection()}</div>
          <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
              {renderTabSection()}
              {renderSection1()}
              {renderSection3()}
              {renderFeatures()}
              {renderPropertyType()}
              {renderFloorPlan()}
              {renderPaymentPlan()}
              {renderSection4()}
              {renderSection7()}
              {renderHostInfo()}
            </div>
            {/* SIDEBAR */}
            <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
              <div className="sticky w-96 top-20">{renderSidebar()}</div>
            </div>
          </main>
        </div>
      );
    }
    // else if (projectDetails?.[0]?.images?.length == "1") {
    //   return (
    //     <div
    //       className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //       data-nc-id="PropertyDetailPage"
    //     >
    //       <div className="container">{renderSectionPhoto1()}</div>
    //       <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
    //         <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
    //           {renderTabSection()}
    //           {renderSection1()}
    //           {renderSection3()}
    //           {renderFeatures()}
    //           {renderPropertyType()}
    //           {renderFloorPlan()}
    //           {renderPaymentPlan()}
    //           {renderSection4()}
    //           {renderSection7()}
    //           {renderHostInfo()}
    //         </div>
    //         {/* SIDEBAR */}
    //         <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
    //           <div className="sticky w-96 top-20">{renderSidebar()}</div>
    //         </div>
    //       </main>
    //     </div>
    //   );
    // } else if (projectDetails?.[0]?.images?.length == "2") {
    //   return (
    //     <div
    //       className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //       data-nc-id="PropertyDetailPage"
    //     >
    //       <div className="container">{renderSectionPhoto2()}</div>
    //       <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
    //         <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
    //           {renderTabSection()}
    //           {renderSection1()}
    //           {renderSection3()}
    //           {renderFeatures()}
    //           {renderPropertyType()}
    //           {renderFloorPlan()}
    //           {renderPaymentPlan()}
    //           {renderSection4()}
    //           {renderSection7()}
    //           {renderHostInfo()}
    //         </div>
    //         {/* SIDEBAR */}
    //         <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
    //           <div className="sticky w-96 top-20">{renderSidebar()}</div>
    //         </div>
    //       </main>
    //     </div>
    //   );
    // } else if (projectDetails?.[0]?.images?.length == "3") {
    //   return (
    //     <div
    //       className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //       data-nc-id="PropertyDetailPage"
    //     >
    //       <div className="container">{renderSectionPhoto3()}</div>
    //       <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
    //         <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
    //           {renderTabSection()}
    //           {renderSection1()}
    //           {renderSection3()}
    //           {renderFeatures()}
    //           {renderPropertyType()}
    //           {renderFloorPlan()}
    //           {renderPaymentPlan()}
    //           {renderSection4()}
    //           {renderSection7()}
    //           {renderHostInfo()}
    //         </div>
    //         {/* SIDEBAR */}
    //         <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
    //           <div className="sticky w-96 top-20">{renderSidebar()}</div>
    //         </div>
    //       </main>
    //     </div>
    //   );
    // } else if (projectDetails?.[0]?.images?.length == "4") {
    //   return (
    //     <div
    //       className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //       data-nc-id="PropertyDetailPage"
    //     >
    //       <div className="container">{renderSectionPhoto4()}</div>
    //       <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
    //         <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">

    //           {renderTabSection()}
    //           {renderSection1()}
    //           {renderSection3()}
    //           {renderFeatures()}
    //           {renderPropertyType()}
    //           {renderFloorPlan()}
    //           {renderPaymentPlan()}
    //           {renderSection4()}
    //           {renderSection7()}
    //           {renderHostInfo()}
    //         </div>
    //         {/* SIDEBAR */}
    //         <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
    //           <div className="sticky w-96 top-20">{renderSidebar()}</div>
    //         </div>
    //       </main>
    //     </div>
    //   );
    // } else if (projectDetails?.[0]?.images?.length == "6") {
    //   <div
    //     className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
    //     data-nc-id="PropertyDetailPage"
    //   >
    //     <div className="container">{renderSectionPhoto5()}</div>
    //     <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
    //       <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
    //         {renderTabSection()}
    //         {renderSection1()}
    //         {renderSection3()}
    //         {renderFeatures()}
    //         {renderPropertyType()}
    //         {renderFloorPlan()}
    //         {renderPaymentPlan()}
    //         {renderSection4()}
    //         {renderSection7()}
    //         {renderHostInfo()}
    //       </div>
    //       {/* SIDEBAR */}
    //       <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
    //         <div className="sticky w-96 top-20">{renderSidebar()}</div>
    //       </div>
    //     </main>
    //   </div>;
    // }
  }

  return (
    <>

      <div
        className={`ListingDetailPage pb-10 nc-PropertyDetailPage ${className}`}
        data-nc-id="PropertyDetailPage"
      >
        <Helmet>
          <title> {`${projectLookup[projectDetails?.[0]?.projectName]}`} | Imlaak </title>
        </Helmet>
        <div className="container">{renderSectionPhoto()}</div>
        {/* Images */}

        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row">
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            {renderTabSection()}
            {renderSection1()}
            {renderSection3()}
            {renderFeatures()}
            {renderPropertyType()}
            {renderFloorPlan()}
            {renderPaymentPlan()}
            {renderSection4()}
            {renderSection7()}
            {renderHostInfo()}
          </div>
          {/* SIDEBAR */}
          <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky w-96 top-20">{renderSidebar()}</div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ProjectsDetailPage;
function getQueryParam(arg0: string) {
  throw new Error("Function not implemented.");
}
