import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import logoLightImg from "../../images/logos/logo-white.png";
import { Link } from "react-router-dom";
import { Input } from "@mui/material";
import GooglePlay from "../../images/googlePlay.png"
import GooglePlayWhite from "../../images/googlePlay_white.png"
import AppleStore from "../../images/appStore.png"
import AppleStoreWhite from "../../images/appStore_white.png"
import Winner from "../../images/Winner.png"

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
  img?: string;
}

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <footer className="bg-white dark:bg-neutral-900 py-5">
        <div className="py-5 mt-5">
          <div className="container border-t-2 border-b-2 py-3">
            <ul className="flex flex-wrap items-center justify-center py-2 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a target="_blank" href="https://www.imlaak.com/contact-us/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold ">
                  Contact Us
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/why-choose-us/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold">
                  Why Choose Us
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/privacy-policy/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/transactions-policy/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Transactions Policy
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/blogs-2/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Blogs
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/property-news/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  News
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/constructions/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Constructions
                </a>
              </li>
            </ul>
            <ul className="flex flex-wrap items-center justify-center py-2 text-sm text-gray-500 mt-[-5%] lg:mt-2 md:mt-0 dark:text-gray-400">
              <li>
                <a target="_blank" href="https://www.imlaak.com/property-tax-calculator/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Property Tax Calculator
                </a>
              </li>
              <li>
                <a target="_blank" href="https://landlord.imlaak.com" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Landlord
                </a>
              </li>
              <li>
                <a target="_blank" href="contact" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Marketing
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/property-management-2/tenant/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Tenant
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.imlaak.com/real-estate-consultancy/" className="px-2 font-thin text-[#54595f] dark:text-white dark:hover:text-white hover:text-[#345886] hover:text-semibold" >
                  Services
                </a>
              </li>
            </ul>
          </div>
          <div className="container pt-5">
            <p className="text-center text-[10px] text-[#817F7F] dark:text-white dark:hover:text-white">
              We at Imlaak are continuously working to improve the accessibility of our web experience for everyone, and we welcome feedback and suggestion. Please let us know if you wish to report a problem or send us a suggestion.
              <a href="" className="">let us know</a>
            </p>
            <p className="text-center pt-5 text-[10px] text-[#817F7F] dark:text-white dark:hover:text-white">
              Here at ‘Imlaak’, we believe in change for the better, and are constantly challenging tradition. We have a strong desire to improve the way our industry operates and do business differently to give you the edge.
              If you choose to use ‘Imlaak’ as your personal investment manager, our experienced staff will help you maintain your property portfolios online and ensure that you are always a click away from the latest evaluation of your assets. Besides that,
              we will formulate special investment plans and custom plans on special requests depending upon your portfolios.
            </p>
            <p className="text-center py-5">
              <a href="" className="text-center border-b border-[#233A65] text-base text-[#817F7F] dark:text-white dark:hover:text-white">Download Amaarat App By Imlaak</a>
            </p>

            <div className="lg:hidden md:hidden flex pt-2">
              <a className="px-4">
                <img className="w-48 dark:hidden" src={GooglePlay} alt="Logo" />
                <img className="max-h-12 dark:block hidden" src={GooglePlayWhite} alt="Logo" />
              </a>
              <a className="px-4">
                <img src={GooglePlay} alt="" className="w-48 dark:hidden" />
                <img className="max-h-12 dark:block hidden" src={AppleStoreWhite} alt="Logo" />
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1">
            <div className="col-span-1 py-5 flex justify-center">
              <div className="w-96">
                <h2 className="text-base pb-5 font-semibold text-center  dark:text-white text-[#2c4781]">Signup & Subscribe</h2>
                <form action="">
                  <input className="form-control w-full rounded border-[1px] border-[#ccc] p-2.5" placeholder="Your Email Address" />
                  <br />
                  <button className="mt-3 py-3 rounded bg-[#bda588] w-full text-white tracking-wide">Sign Up</button>
                </form>
              </div>
            </div>

            <div className="lg:col-span-2 md:col-span-1 col-span-1 lg:py-5 md:py-5 py-0 flex justify-center">
              <div>
                <div className="hidden md:flex lg:flex">
                  <a className="px-4">
                    <img src={GooglePlay} alt="" className="w-48 dark:hidden" />
                    <img className="max-h-12 dark:block hidden" src={GooglePlayWhite} alt="Logo" />
                  </a>
                  <a className="px-4">
                    <img src={AppleStore} alt="" className="w-48 dark:hidden" />
                    <img className="max-h-12 dark:block hidden" src={AppleStoreWhite} alt="Logo" />
                  </a>
                </div>
                <div className="py-5 flex justify-center">
                  <h2 className="font-semibold text-xl text-[#233A65]  dark:text-white">Follow Us:</h2>
                  <SocialsList1 className="flex " />
                </div>
                <div className="py-2 flex justify-between">
                  <a href="tel:+923111777555" className="text-[0.9rem] font-semibold text-[#233A65]  dark:text-white">
                    UAN Number :+92 311 1777 555
                  </a>
                  <a href="tel:+923111777555" className="text-[0.9rem] font-semibold text-[#233A65]  dark:text-white">
                    WhatsApp :+92 333 1717 170
                  </a>
                </div>
                <div className="flex justify-center">
                  <h2 className="font-semibold text-xl text-[#233A65]  dark:text-white">Address:</h2>
                  <address className="text-[#233A65]  dark:text-white pl-3 text-[0.9rem] not-italic">147, CCA, Phase 5, DHA Lahore,<br /> Punjab, Pakistan-54000</address>
                </div>
              </div>
            </div>

            <div className="col-span-1 lg:flex md:hidden flex">
              <div className="py-5 flex justify-center">
                <img src={Winner} alt="" className="w-[50%]" />
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4">
          <p className="text-[#7a7a7a] dark:text-white">
            © 2021 – {new Date().getFullYear()} Imlaak. All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
