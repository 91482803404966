import axios from "axios";
import pinContext from "context/pin/pinContext";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { API as apiHost } from "../../utils/config";

export interface GeneralEmailProps {
    data?: any;
}

const GeneralEmail: FC<GeneralEmailProps> = ({ data }) => {
    const { handleSubmit, control, formState: { errors }, reset, trigger, } = useForm();
    const [formData, setFormData] = useState<any | null>(null);
    const [consultantData, setConsultantData] = useState<any | null>(null);
    useEffect(() => {
        setConsultantData(data)
    }, [data])
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            receiver: 'info@imlaak.com',
            message: '',
        },
        onSubmit: (values) => {
            setFormData(values)
            formFunction(values)
        },
    });

    const formFunction = async (values: any) => {
        formik.resetForm()
        try {
            const response = await axios.post(`${apiHost}/api/auth/generalEmail`, values);
            if (response.status === 200) {
                console.log('Successfully sent');
                Swal.fire({
                    title: 'Email Sent',
                    text: "Your inquiry has been sent. Imlaak Management Team will contact you within 24 hours.",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "OK",
                })
                formik.resetForm();
            } else {
                console.log('Received non-200 status:', response.status);
            }
        } catch (error: any) {
            console.error('Error sending data to API:', error.message);
        }
    };



    return (
        <div className="flex flex-col w-full">
            <div className="">
                <h3 className="text-center text-black font-semibold text-base dark:text-white">
                    To Create Wealth Through Real Estate Management
                </h3>
            </div>
            <form className="rounded w-full" onSubmit={formik.handleSubmit}>
                <div className="py-1">
                    <label
                        htmlFor="name"
                        className="block pb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Name<span className="text-red-600"> *</span>
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="name"
                        required
                    />
                </div>
                <div className="py-2 block dark:hidden">
                    <label
                        htmlFor="subject"
                        className="block pb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Phone<span className="text-red-600"> *</span>
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        rules={{ required: "Phone number is required." }}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                value={formik.values.phone}
                                onChange={(phone: any) =>
                                    formik.setFieldValue("phone", phone)
                                }
                                inputStyle={{
                                    border: "1px solid rgb(209 213 219)",
                                    width: "100%",
                                    height: "43px",
                                }}
                                containerStyle={{
                                    marginTop: "0px",
                                }}
                                country={"pk"}
                                inputProps={{
                                    onBlur: () => {
                                        trigger("");
                                    },
                                }}
                            />
                        )}
                    />
                    {errors.phone && (
                        <small className="text-[#ff1616]">Phone number is required</small>
                    )}
                </div>
                <div className="py-2 dark:block hidden">
                    <label
                        htmlFor="subject"
                        className="block pb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Phone<span className="text-red-600"> *</span>
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        rules={{ required: "Phone number is required." }}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                value={formik.values.phone}
                                onChange={(phone: any) =>
                                    formik.setFieldValue("phone", phone)
                                }
                                inputStyle={{
                                    border: "1px solid rgb(75 85 99)",
                                    width: "100%",
                                    height: "43px",
                                    background: "rgb(55 65 81)",
                                }}
                                containerStyle={{
                                    marginTop: "0px",
                                    background: "rgb(55 65 81)",
                                }}
                                country={"pk"}
                                inputProps={{
                                    onBlur: () => {
                                        trigger("");
                                    },
                                }}
                            />
                        )}
                    />
                    {errors.phone && (
                        <small className="text-[#ff1616]">Phone number is required</small>
                    )}
                </div>
                <div className="py-1">
                    <label
                        htmlFor="subject"
                        className="block pb-1 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Email<span className="text-red-600"> *</span>
                    </label>
                    <input
                        id="email"
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="user@example.com"
                        required
                    />
                </div>
                <div className="sm:col-span-2 py-2">
                    <label
                        htmlFor="message"
                        className="block pb-1 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                        Your message
                    </label>
                    <textarea
                        id="message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        rows={5}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Leave a message..."
                    ></textarea>
                </div>
                <button
                    className="w-full rounded-lg mt-2 text-white bg-[#345886] hover:bg-[#1f345c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium  text-sm px-5 py-2.5 text-center dark:bg-sky-800  dark:hover:bg-sky-800 dark:focus:ring-sky-800"
                    type="submit"
                >
                    Send
                </button>
            </form>
        </div>
    );
};

export default GeneralEmail;
