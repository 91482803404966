import { useEffect, useReducer, useState } from "react"
import axios from "axios"
import { API as apiHost } from "utils/config"
import Swal from "sweetalert2"
import { createContext } from "react"
// import {
//   bahawalpurMapOptions,
//   gujrawalaMapOptions,
//   lahoreMapOptions,
//   multanMapOptions,
// } from 'components/PlotListing/mapOptions'

const updateCurrentUrl = ({ value }: any) => {
  const nextURL = `/plot-finder/?openedMap=${value}`
  const nextTitle = ""
  const nextState = { additionalInformation: "" }
  // This will create a new entry in the browser's history, without reloading
  window.history.pushState(nextState, nextTitle, nextURL)
}
const initialFilters = {
  projectName: '',
  city: '',
  developer: '',
  priceRange: [0, 9999999999],
  propertyType: '',
  completionDate: '',
};
interface IProjectContext {
  projects: any
  getAllProjects: Function
  loading: boolean
  getAllProjectsData: Function
  getAllProjectNames: Function
  setLoading: Function
  getProjectById: any
  getProjectBySlug: any
  dispatchFilter: any
  filter: any
  applyFilters: any
  projectNames: any
}

const ProjectContextInitial = {
  projects: {},
  getAllProjects: () => { },
  projectDetails: {},
  setProjectDetails: () => { },
  loading: false,
  setLoading: () => { },
  getProjectById: () => { },
  getProjectBySlug: () => { },
  filter: {},
  dispatchFilter: () => { },
  applyFilters: () => { },
  getAllProjectNames: () => { },
  projectNames: () => { },
  getAllProjectsData: () => { },
}

export const ProjectContext = createContext<IProjectContext>(
  ProjectContextInitial
)
const initialState: any = {
  priceRange: [0, 0],
}
function reducer(state: any, action: any,) {
  switch (action.type) {
    case "updateProjectCity":
      return { ...state, city: action.value }
    case "updateProjectLocation":
      return { ...state, community: action.value }
    case "updateProjectType":
      return { ...state, projectType: action.value }
    case "updateCompletedDate":
      return { ...state, completedDate: action.value }
    case "updateProjectDeveloper":
      return { ...state, developerName: action.value }
    case "updatePriceRange":
      return { ...state, priceRange: action.value }
    case "resetFilter":
      return {
        ...state,
        priceRange: [0, 0],
        city: "",
        selected: "",
        setSelected: "",
        community: "",
        projectType: "",
        completedDate: "",
        developer: "",
      }
    // case "sortBy":
    //   return {
    //     ...state,
    //     sort: action.value,
    //   }

    case "priceRangeReset":
      return { ...state, priceRange: [0, 0] }
    default:
      throw new Error()
  }
}

const ProjectProvider = (props: any) => {
  const token = localStorage.getItem("authToken")
  // const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [developers, setDevelopers] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [projectDetails, setProjectDetails] = useState({})
  const [userData, setUser] = useState("")
  const [filter, dispatchFilter] = useReducer(reducer, initialState)
  const [pageNumber, setPageNumber] = useState(0)
  const [perPage, setPerPage] = useState(999)
  const [projectsCount, setProjectsCount] = useState(0)
  const [count, setCount] = useState(0)
  const [cities, setCities] = useState([])
  const [cityLocations, setCityLocations] = useState([])
  const [allDevelopers, setAllDevelopers] = useState([])
  const [projectNames, setProjectNames] = useState([])
  const [amenities, setAmenities] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [filters, setFilters] = useState(initialFilters);

  const getAllDevelopersData = async () => {
    const response = await axios.get(`${apiHost}/api/developer/getAllDevelopers`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const allDevelopers = await response.data
    setDevelopers(allDevelopers.items)
    setCount(allDevelopers?.length)
    return response
  }

  // get all amenities
  const getAllAmenities = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/amenities/getAllAmenities`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response?.data;
      setAmenities(responseData?.items);
    } catch (err) {
      console.log(err);
    }
  };

  // map changing logic
  const [pageDetails, setPageDetails] = useState({
    text: "next Plot Listing Site",
    value: "",
    mapName: "Phase 1 and 2",
  })

  const [loading, setLoading] = useState(false)
  const applyFilters = (newFilter: any = {}) => {
    getAllProjects(Object.keys(newFilter).length ? newFilter : filter)
  }

  const getAllProjects = async (query: any) => {

    if (!loading || !Boolean(projects)) {
      setLoading(true)
      const filters = JSON.stringify(query)
      const response = await axios.get(
        `${apiHost}/api/project/getAllListedProjects`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: { filters: filters, offset: pageNumber, limit: perPage },
        }
      )
      const allProject = await response.data
      setProjects(allProject)
      setProjectsCount(allProject.count)
      setLoading(false)

      return response
    }
    return
  }

  const getAllProjectsData = async () => {
    setLoading(true)
    const response = await axios.get(`${apiHost}/api/project/getAllProjectsData`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    const allProject = await response?.data
    const sortedProjects = allProject?.project?.sort((a: any, b: any) =>
      new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
    );
    if (allProject?.count > 0) {
      setProjectsData(sortedProjects)
      setFilteredProjects(sortedProjects)
    }
    setProjectsCount(response.data.count)
    setLoading(false)

    return response
  }
  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const setNewFilters = (filters: any) => {
    const filtered = projectsData?.filter(project => {
      return true;
    });
    setFilteredProjects(filtered);
  };

  const getProjectById = async (id: any) => {
    const response = await axios.get(
      `${apiHost}/api/project/getProjectById/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        // params: { id },
      }
    )
    setProjectDetails(response.data)
    return response
  }

  const getProjectBySlug = async (projectSlug: any) => {
    const response = await axios.get(
      `${apiHost}/api/project/getProjectBySlug/${projectSlug}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setProjectDetails(response?.data?.project)
    return response
  }

  const getAllCityOfPakistan = async (id: any) => {
    const response = await axios.get(`${apiHost}/api/cityLocation/getAllCityOfPakistan`, {
      headers: {
        "Content-Type": "application/json",
      },
    }
    )
    setCities(response.data?.userCityLocation)
  }

  const getLocationsByCity = async (id: any) => {
    const response = await axios.get(
      `${apiHost}/api/communityLocation/getLocationsByCityId/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setCityLocations(response?.data?.userCommunityLocation)
    // return response
  }

  const getAllDevelopers = async () => {
    //todo api call
    //API CALL
    // const filters = JSON.stringify(query)
    const response = await axios.get(
      `${apiHost}/api/developer/getAllDevelopers`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setAllDevelopers(response?.data?.items)
    // return response
  }

  const getAllProjectNames = async () => {
    const response = await axios.get(
      `${apiHost}/api/projectName/getAllProjectnames`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setProjectNames(response?.data?.items)
  }

  //
  const getMe = async () => {
    setLoading(true)
    const tk = localStorage.getItem("authToken")
    if (tk) {
      try {
        const response = await axios.get(`${apiHost}/api/auth/finduser`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tk}`,
          },
        })

        if (response.status === 404) {
          localStorage.removeItem("authToken")
          setUser("")
          setLoading(false)
        } else if (response.status === 200) {
          const user = await response.data
          setUser(user)
          setLoading(false)
          return user
        }
      } catch (e) {
        setLoading(false)
      }
    }
  }

  const [users, setusers] = useState([])

  const value = {
    projects,
    getAllProjects,
    projectDetails,
    setProjectDetails,
    getMe,
    loading,
    setLoading,
    getProjectById,
    filter,
    dispatchFilter,
    applyFilters,
    perPage,
    setPerPage,
    pageNumber,
    setPageNumber,
    projectsCount,
    setProjectsCount,
    getAllCityOfPakistan,
    getAllProjectsData,
    getAllDevelopersData,
    developers,
    count,
    projectsData,
    cities,
    getLocationsByCity,
    cityLocations,
    getAllDevelopers,
    allDevelopers,
    projectNames,
    getAllProjectNames,
    getProjectBySlug,
    getAllAmenities,
    amenities,
    filters,
    setFilters,
    filteredProjects,
    setFilteredProjects,
    setNewFilters,
    resetFilters,
  }

  return (
    <ProjectContext.Provider value={value}>
      {props.children}
    </ProjectContext.Provider>
  )
}
export default ProjectProvider
