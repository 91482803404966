import React from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import Input from "shared/Input/Input";

const ConsultantRegisterModal = ({ showRegisterModal, setShowRegisterModal }) => {
    const {
        trigger,
        control,
        setValue,
        register,
    } = useForm();

    return (
        <div>
            {
                showRegisterModal ? (
                    <>
                        <div className="w-[1200px] z-50 absolute">
                            <div className="fixed inset-0 bg-zinc-800 bg-opacity-90 transition-opacity"></div>
                            <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="w-auto my-6 mx-auto max-w-3xl">
                                    <div className="border-t-4 border-[#0c4a6e] rounded-md shadow-lg relative w-96 bg-white outline-none focus:outline-none">
                                        <button
                                            className="bg-transparent border-0 absolute top-0 right-0 m-0.5"
                                            onClick={() => setShowRegisterModal(false)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0c4a6e" class="w-6 h-6">
                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                                            </svg>

                                            {/* <span className="text-black opacity-7 h-6 w-6 text-xl block py-0">
                                                <i className="las la-times text-red-500 text-xl"></i>
                                            </span> */}
                                        </button>
                                        <div className="px-5 pt-3 pb-5">
                                            <div className="text-center py-4">
                                                <h3 className="font-semibold text-xl text-[#0c4a6e] tracking-wide border-b-[1.8px]">Signup as a Consultant</h3>
                                            </div>
                                            <form>
                                                <Input
                                                    type="text"
                                                    className={`bg-white rounded-md py-2 mt-3 border border-gray-400 w-full px-6 hover:border-b-none `}
                                                    placeholder="Name*" />
                                                <Input
                                                    type="text"
                                                    className={`bg-white rounded-md py-2 mt-3 border border-gray-400 w-full px-6 hover:border-b-none `}
                                                    placeholder="Email*" />
                                                <Input
                                                    type="text"
                                                    className={`bg-white rounded-md py-2 mt-3 border border-gray-400 w-full px-6 hover:border-b-none `}
                                                    placeholder="Phone*" />
                                                <Input
                                                    type="password"
                                                    className={`bg-white rounded-md py-2 mt-3 border border-gray-400 w-full px-6 hover:border-b-none `}
                                                    placeholder="Password*" />
                                                <p className="mt-5 flex justify-start">
                                                    <input type="radio" className="text-[#0c4a6e] focus:ring-0 cursor-pointer" />
                                                    <span className="px-2 text-sm text-black">
                                                        I am not a Robot
                                                    </span>
                                                </p>
                                                <p className="mt-5 flex justify-start">
                                                    <input type="radio" className="text-[#0c4a6e] cursor-pointer" checked />
                                                    <p className="px-2 text-sm text-black">
                                                        <span>I have read and agreed to the Imlaak.com{" "}</span>
                                                        <Link to="" className="text-blue-600">
                                                            Terms and Conditions
                                                        </Link>
                                                    </p>
                                                </p>
                                                <p className="mt-5 text-sm text-black flex justify-start">
                                                    <input type="radio" className="text-[#0c4a6e] cursor-pointer" checked />
                                                    <span className="px-2">
                                                        I would like to receive notifications about promotions,
                                                        newsletters and updates.
                                                    </span>
                                                </p>
                                                <div className="text-center bg-[#0c4a6e] hover:bg-[#093d5c] w-full rounded py-2 mt-4 cursor-pointer">
                                                    <button type="submit" className="text-white w-full text-xl cursor-pointer">
                                                        SignUp
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div>
    )
}

export default ConsultantRegisterModal