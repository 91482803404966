import React, { FC, useState } from "react";
import "react-dates/initialize";
import PropertySearchForm from "./BuyPropertySearchForm";
import GetEstimateForm from "./GetEstimateForm";
import ProjectsForm from "./ProjectsForm";
import NewProjectFilter from "containers/NewProject/NewProjectFilter";

export type SearchTab = "Property Search" | "Projects" | "EstimationValue";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Property Search" | "Projects" | "EstimationValue";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Property Search",
  currentPage,
}) => {
  const tabs: SearchTab[] = ["Property Search", "Projects", "EstimationValue"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderTab = () => {
    return (
      <ul
        className=" mx-auto sm:w-fit w-full flex-wrap justify-center text-center rounded p-2 bg-zinc-900 bg-opacity-80 dark:bg-slate-900/80 backdrop-blur-sm dark:border-gray-800 mt-10 mb-5"
        id="myTab"
        data-tabs-toggle="#StarterContent"
        role="tablist"
      >
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={` flex items-center inline-flex align-middle px-5 cursor-pointer text-sm lg:text-base font-medium ${active
                ? "rounded-md bg-neutral-100 py-2 "
                : "text-neutral-100 dark:hover:text-neutral-400"
                } `}
              key={tab}
            >
              {active && (
                <span className="bg-neutral-100 dark:bg-neutral-100 mr-2" />
              )}
              <span className=" rounded-md">{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    switch (tabActive) {
      case "Property Search":
        return (
          <PropertySearchForm
            advanceSearch={true}
            haveDefaultValue={isArchivePage}
          />
        );
      case "Projects":
        return (
          <>
            {/* <NewProjectFilter
             className="bg-zinc-900 bg-opacity-80 rounded-xl shadow-md px-4 pt-3 pb-5"
             haveDefaultValue={isArchivePage}
             findBtnClass="col-span-2"
           /> */}
          </>
        );
      case "EstimationValue":
        return <GetEstimateForm haveDefaultValue={isArchivePage} />;

      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm max-w-3xl mx-auto max-w-6xl lg:py-0 ${className}`}
      data-nc-id="HeroSearchForm"
    >
      <div className="lg:block md:block hidden">
        {renderTab()}
        {renderForm()}
      </div>
    </div>
  );
};

export default HeroSearchForm;
