import React from "react";
import NavigationItem from "./NavigationItem2";
import { NAVIGATION_DEMO2 } from "data/navigation2";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

function Navigation() {
  var token = window.localStorage.getItem("authToken");

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {NAVIGATION_DEMO2.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
      <li className="menu-item">
        {/* <Box
          component={token ? 'button' : 'a'}
          href='/login'
          onClick={() => { if (token) window.localStorage.removeItem('authToken') }}
          className="inline-flex items-center text-sm xl:text-base font-normal text-neutral-700 dark:text-neutral-300 py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
        >
          {
            token ?
              "Logout"
              :
              "SignIn"
          }
        </Box> */}
      </li>
    </ul>
  );
}

export default Navigation;
