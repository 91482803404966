import React from 'react'
export interface CareerDescriptionProps {
    onClickText: any;
    propsDescription?: any;
}



const CareerDescription: React.FC<CareerDescriptionProps> = ({ onClickText, propsDescription }) => {

    const descToPlainText = (
        <div dangerouslySetInnerHTML={{ __html: propsDescription }} />
    )

    return (
        <>
            <div className="container">
                <span className="text-2xl text-black hover:text-green-600 font-semibold ease-in-out duration-500 capitalize">
                    Description
                </span>
                <p className="text-lg font-medium pt-2 uppercase">Job Purpose:</p>
                <p className="block mt-2 text-neutral-500 dark:text-neutral-400 text-justify">
                    {descToPlainText}
                </p>
                <div className="text-center py-5">
                    <button className="text-base capitalize bg-[#345886] hover:bg-[#1f345c] py-2 text-white font-semibold lg:w-96 w-72 rounded-lg" onClick={onClickText}>Apply for this job</button>
                </div>
            </div>
        </>
    )
}

export default CareerDescription