import Email from "containers/ListingDetailPage/Email";
import React from "react";

const EmailModal = ({ showEmailModal, setShowEmailModal, ReceiverEmail }) => {
    return (
        <div>
            {
                showEmailModal ? (
                    <>
                        <div className='w-[1200px] z-50'>
                            <div className='fixed inset-0 bg-zinc-800 bg-opacity-90 transition-opacity'></div>
                            <div className='flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none'>
                                <div className='w-auto z-40 my-6 mx-auto max-w-3xl'>
                                    <div className='border-t-4 border-[#0c4e6c] rounded-md shadow-lg relative flex flex-col w-80 bg-white dark:bg-slate-800 dark:border-slate-300 outline-none focus:outline-none'>
                                        <button
                                            className='bg-transparent border-0 absolute top-0 right-0 m-0.5'
                                            onClick={() => setShowEmailModal(false)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#0c4e6c" class="w-6 h-6 dark:bg-white dark:rounded-full">
                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clip-rule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className='flex items-start justify-center p-5 border-b border-solid border-gray-300 rounded-t '>
                                            <h3 className='text-3m font-bold tracking-wider text-center text-[#0c4e6c] dark:text-white'>
                                                Make an Inquiry
                                            </h3>
                                        </div>
                                        <div className='relative p-4 flex-auto'>
                                            <Email ReceiverEmail={ReceiverEmail} />
                                            {/* <form className='rounded px-5 w-full'>
                                                <label className='block text-black text-sm mb-1'>
                                                    NAME
                                                </label>
                                                <input
                                                    className='shadow appearance-none dark:bg-slate-800 dark:text-white bg-slate-50 border rounded w-full py-2 px-3 text-black'
                                                    value=''
                                                />
                                                <label className='block text-black text-sm mb-1'>
                                                    PHONE
                                                </label>
                                                <input
                                                    className='shadow appearance-none dark:bg-slate-800 dark:text-white bg-slate-50 border rounded w-full py-2 px-3 text-black'
                                                    value=''
                                                />
                                                <label className='block text-black text-sm mb-1'>
                                                    EMAIL
                                                </label>
                                                <input
                                                    className='shadow appearance-none dark:bg-slate-800 dark:text-white bg-slate-50 border rounded w-full py-2 px-3 text-black'
                                                    value=''
                                                />
                                                <label className='block text-black text-sm mb-1'>
                                                    MESSAGE
                                                </label>
                                                <textarea
                                                    id='message'
                                                    className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-slate-800 rounded-md border border-gray-300 focus:ring-[#0c4e6c] focus:border-[#0c4e6c] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#0c4e6c] dark:focus:border-[#0c4e6c]'
                                                    placeholder='Your message...'
                                                ></textarea>
                                                <div className='px-0'>
                                                    <button
                                                        className='w-full text-white bg-[#0c4e6c] rounded focus:outline-none focus:ring-0 font-semibold  text-md px-5 py-2.5 text-center dark:bg-[#0c4e6c] mt-5'
                                                        onClick={() => setShowEmailModal(false)}
                                                        type='submit'
                                                    >
                                                        {" "}
                                                        Email{" "}
                                                    </button>
                                                </div>
                                            </form> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div>
    )
}

export default EmailModal