import { NavItemType } from "shared/Navigation2/NavigationItem2";
import { APP_PORTAL, LANDLORD_PORTAL } from "utils/config";
import ncNanoId from "utils/ncNanoId";
var token = window.localStorage.getItem("authToken");
const aboutChildrenMenus: NavItemType[] = [

  //
  { id: ncNanoId(), href: "/contact-us", name: "Contact Us" },
  { id: ncNanoId(), href: "/privacy-policy", name: "Privacy Policy" },
  //
  // { id: ncNanoId(), href: "/the-team", name: "The Team" },

];
const aboutUsChildrenMenus: NavItemType[] = [

  { id: ncNanoId(), href: "https://www.imlaak.com/contact-us", name: "Contact Us", targetBlank: true, },
  { id: ncNanoId(), href: "https://www.imlaak.com/privacy-policy/", name: "Privacy Policy", targetBlank: true, },
];

export const NAVIGATION_DEMO2: NavItemType[] = [
  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Discussion/Blog",
  //   type: "dropdown",
  //   children: blogChildrenMenus,
  // },
  // {
  //   id: ncNanoId(),
  //   href: LANDLORD_PORTAL,
  //   name: "Landlord",
  //   type: "none",
  //   targetBlank: true
  // },
  {
    id: ncNanoId(),
    href: "#",
    name: "About Us",
    type: "dropdown",
    children: aboutUsChildrenMenus,
  },
  {
    id: ncNanoId(),
    href: "https://www.imlaak.com/blogs-2/",
    name: "Blogs",
    type: "none",
    isNew: false,
    targetBlank: true,
  },
  {
    id: ncNanoId(),
    href: '/consultantsPage',
    name: "Consultants",
    type: "none",
    targetBlank: false
  },
  {
    id: ncNanoId(),
    href: "https://www.imlaak.com",
    name: "Back To Main",
    type: "none",
    isNew: false,
    targetBlank: true,
  },
];
