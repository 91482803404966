// FilterPage.js
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Transition, Dialog, Popover } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { CalendarIcon, HomeIcon, MapIcon, OfficeBuildingIcon, TagIcon } from "@heroicons/react/solid";
import Autocomplete from 'components/HeroSearchForm/Autocomplete';
import { ProjectContext } from 'context/project/ProjectContext';
import ProjectCompletionDate from 'components/HeroSearchForm/ProjectCompletionDate';
import NewPriceRangeInput from 'components/HeroSearchForm/NewPriceRangeInput';
import MultiSelectPropertyTypes from './MultiSelectPropertyTypes';
import { NewProjectsMobileFilters } from './NewProjectsMobileFilters';

interface Project {
    _id: string;
    name: string;
}
const propertyTypeOptions = [
    { value: 'residential', label: 'Residential' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Serviced Apartments', label: 'Serviced Apartments' },
    { value: 'Hotel Rooms', label: 'Hotel Rooms' },
    { value: 'PentHouse', label: 'PentHouse' },
    { value: 'Hotel Apartments', label: 'Hotel Apartments' },
    { value: 'Condominiums', label: 'Condominiums' },
    { value: 'commercial', label: 'Commercial' },
    { value: 'Office', label: 'Office' },
    { value: 'Shop', label: 'Shop' },
    { value: 'Buildings', label: 'Buildings' },
    { value: 'Showroom', label: 'Showroom' },
];
const ProjectMobileFilters: React.FC = () => {
    const { getAllProjectsData, projectsData, setFilters, resetFilters, filters }: any = useContext(ProjectContext);
    const [rangePrices, setRangePrices] = useState<[number, number]>(filters?.priceRange || [0, 990000000]);
    const [propertyType, setPropertyType] = useState<string[]>(filters.propertyType || []);
    const [cityInput, setCityInput] = useState('');
    const [projectNameInput, setProjectNameInput] = useState('');
    const [developerInput, setDeveloperInput] = useState('');
    useEffect(() => {
        getAllProjectsData();
    }, []);

    const nameSuggestions = projectsData?.map((project: any) => ({
        _id: project?._id?.toString(),
        name: project?.projectName?.projectName
    }));

    const developerSet = new Set<string>();
    const developerSuggestions: Project[] = [];
    projectsData.forEach((project: any, index: any) => {
        if (!developerSet.has(project?.developer?.name)) {
            developerSet.add(project?.developer?.name);
            developerSuggestions.push({ _id: index.toString(), name: project?.developer?.name });
        }
    });

    const citySet = new Set<string>();
    const citySuggestions: Project[] = [];
    projectsData.forEach((project: any, index: any) => {
        if (!citySet.has(project?.city)) {
            citySet.add(project?.city);
            citySuggestions.push({ _id: index, name: project?.city });
        }
    });

    const handlePriceChange = (newRange: [number, number]) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            priceRange: newRange
        }));
    };

    const handleCompletionChange = (completionDate: string) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            completionDate
        }));
    };

    const handlePropertyTypeChange = (selectedTypes: string[]) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            propertyType: selectedTypes
        }));
    };

    const handleSelectionChange = (filterType: string, project: Project) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: project.name
        }));
    };

    const handleInputChange = (filterType: string, value: string) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: value
        }));
    };

    const handleResetFilters = () => {
        setRangePrices([0, 99000000000]);
        setCityInput('');
        setProjectNameInput('');
        setDeveloperInput('');
        setPropertyType([]);
        resetFilters();
    };

    const [isVisable, setIsVisable] = useState(false);

    useEffect(() => {
        setIsVisable(false);
    }, [window.location.pathname]);

    const handleOpenMenu = () => setIsVisable(true);
    const handleCloseMenu = () => setIsVisable(false);
    const renderContent = () => {
        return (
            <Transition appear show={isVisable} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={handleCloseMenu}
                >
                    <div className="fixed left-0 top-0 bottom-0 w-full md:w-auto z-max outline-none focus:outline-none">
                        <React.Fragment>
                            <Transition.Child
                                as={Fragment}
                                enter="transition duration-450 transform"
                                enterFrom="opacity-0 translate-y-14"
                                enterTo="opacity-100 -translate-y-0"
                                leave="transition duration-350 transform"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-14"
                            >
                                <div className="z-50 relative w-full h-screen py-2 transition transform shadow-lg bg-white">
                                    <div className="container flex flex-col py-12">
                                        <div className="pt-3">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><OfficeBuildingIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Project Title</span></div>
                                            <div className="w-full rounded-md border border-gray-400">
                                                <Autocomplete
                                                    suggestions={nameSuggestions}
                                                    placeholder="Search by name..."
                                                    value={filters.projectName}
                                                    onInputChange={(value: any) => handleInputChange('projectName', value)}
                                                    onSelectionChange={(project) => handleSelectionChange('projectName', project)}
                                                />
                                            </div>
                                        </div>
                                        <div className="pt-4 pb-2">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><HomeIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2"> Property Type</span></div>
                                            <MultiSelectPropertyTypes
                                                className='border-gray-400'
                                                options={propertyTypeOptions}
                                                selectedOptions={propertyType}
                                                setSelectedOptions={handlePropertyTypeChange}
                                            />
                                        </div>

                                        <div className="pt-3">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#4b5563" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                                </svg>

                                                <span className="font-semibold text-base text-gray-600 pl-2">Developer Title</span></div>
                                            <div className="w-full rounded-md border border-gray-400">
                                                <Autocomplete
                                                    suggestions={developerSuggestions}
                                                    placeholder="Developer Name..."
                                                    value={filters.developer}
                                                    onInputChange={(value: any) => handleInputChange('developer', value)}
                                                    onSelectionChange={(project) => handleSelectionChange('developer', project)}
                                                />
                                            </div>                                        </div>
                                        <div className="pt-4 pb-1">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start"><TagIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Price Range (PKR)</span></div>
                                            <div className="col-span-1 border border-gray-400 py-1 rounded-lg relative">
                                                <NewPriceRangeInput
                                                    rangePrices={rangePrices}
                                                    setRangePrices={handlePriceChange}
                                                    filter={{ priceRange: rangePrices }}
                                                />
                                            </div>
                                        </div>
                                        <div className="pt-4">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><CalendarIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Completion Date</span></div>
                                            <div className="w-full rounded-md border border-gray-400">
                                                <ProjectCompletionDate onCompletionChange={handleCompletionChange} />
                                            </div>
                                        </div>
                                        <div className="mt-7 flex justify-center">
                                            <button className="font-semibold text-base py-2.5 bg-gray-600 text-white text-center border w-full border-gray-400 rounded-md" onClick={handleCloseMenu}>Find Projects</button>
                                        </div>
                                    </div>
                                    <span className="absolute right-2 top-2 p-1">
                                        <ButtonClose onClick={handleCloseMenu} />
                                    </span>
                                    <span className="absolute left-2 top-2 p-1">
                                        <button className="text-[#345886] font-semibold" onClick={handleResetFilters}>Reset</button>
                                    </span>
                                </div>
                            </Transition.Child>

                            <Transition.Child
                                as={Fragment}
                                enter=" duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave=" duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
                            </Transition.Child>
                        </React.Fragment>
                    </div>
                </Dialog>
            </Transition>
        );
    };
    return (
        <>
            <div className=" py-5 px-3">
                <div className='grid grid-cols-5 gap-2'>
                    <div className="col-span-3 border border-gray-400 rounded-lg overflow-hidden">
                        <Autocomplete
                            suggestions={citySuggestions}
                            placeholder="City..."
                            value={filters.city}
                            onInputChange={(value: any) => handleInputChange('city', value)}
                            onSelectionChange={(project) => handleSelectionChange('city', project)}
                        />
                    </div>
                    <div className="col-span-2">
                        <button onClick={handleOpenMenu} className={`text-white uppercase w-full flex justify-center align-middle py-3 px-3 bg-[#345886] hover:bg-[#1f345c] focus:outline-none rounded-md`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-6 pr-2">
                                <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                            </svg>
                            Filters
                        </button>
                        {renderContent()}
                        {/* <NewProjectsMobileFilters ButtonText={"Filters"} className={""} /> */}
                    </div>
                </div>
                <div className="flex justify-end pt-2">
                    <button className="bg-transparent border-0 text-sm text-black" onClick={handleResetFilters}>Reset Filters</button>
                </div>
            </div>
        </>
    );
};

export default ProjectMobileFilters;