import React, { useEffect, useState, Fragment, useContext } from "react"
import { Popover, Transition } from "@headlessui/react"
import { FocusedInputShape } from "react-dates"
import PriceRangeInput from "./PriceRangeInput"
import moment from "moment"
import { FC } from "react"
import { Link } from "react-router-dom"
import ResidentialCommercialFilter from "./ResidentialCommercialFilter"
import AreaRange from "./AreaRange"
import BedsBathsInput from "./BedsBathsInput"
import BuyRentFilter from "./BuyRentfilter"
import BedsEstimateFormInput from "./BedsEstimateFormInput"
import BathEstimateFormInput from "./BathEstimateFormInput"
import SelectCityOptionInput from "./SelectCityOptionInput"
import { ProjectContext } from "context/project/ProjectContext"
import { BuyRentContext } from "context/property/BuyRentContext"
import CityNameInput from "./CityNameInput"
import Autocomplete from "./Autocomplete"
export interface DateRage {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

export interface BuyPropertySearchFormProps {
  haveDefaultValue?: boolean
  advanceSearch?: any
  resetClassMain?: any
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "Lahore, Peshawar"
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, "days"),
}

const BuyPropertySearchForm: FC<BuyPropertySearchFormProps> = ({
  haveDefaultValue = false,
  advanceSearch,
  resetClassMain,
}) => {
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  })

  const { getLocationsByCity, cityLocations, getAllCityOfPakistan }: any =
    useContext(ProjectContext)
  const { dispatchFilter, filter, applyFilters, getAreaType, areaType, cities }: any =
    useContext(BuyRentContext)
  const [rangePrices, setRangePrices] = useState([0, 0])
  const [selected, setSelected]: any = useState("")
  const [selectedType, setSelectedType]: any = useState("")
  const [locationInputValue, setLocationInputValue] = useState("")
  const [guestValue, setGuestValue] = useState({})

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(null)
  useEffect(() => {
    getAllCityOfPakistan()
  }, [])
  // useEffect(() => {
  //   getAreaType()
  // }, [])

  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue(defaultDateRange)
      setLocationInputValue(defaultLocationValue)
    }
    // applyFilters()

  }, [])
  useEffect(() => {
    if (selected?._id !== undefined) {
      getLocationsByCity(selected._id)
      dispatchFilter({
        type: "updatePropertyCity",
        value: selected.cityLocation,
      })
    }
    // applyFilters()

  }, [selected])

  const handleCityLocation = (value: any) => {
    dispatchFilter({
      type: "updatePropertyLocation",
      value: value,
    })
  }
  const handleProjectType = (value: any) => {
    setSelectedType(value)
    dispatchFilter({
      type: "updatePropertySubCategory",
      value: value,
    })
  }

  useEffect(() => {
    if (rangePrices[0] !== 0 || rangePrices[1] !== 0) {
      dispatchFilter({
        type: "updatePropertyRange",
        value: rangePrices,
      })
    }
  }, [rangePrices])

  const resetFilter = (e: any) => {
    e.preventDefault();

    dispatchFilter({
      type: "resetFilter",
    });
  };

  const handleFind = () => {
    applyFilters()
  }

  const [showDropdown, setShowDropdown] = useState(false)

  const [selectedCity, setSelectedCity] = useState('');
  const [cityInput, setCityInput] = useState('');

  const handleCityChange = (city: any) => {
    setSelectedCity(city.name?.toLowerCase());
    setCityInput(city.name?.toLowerCase());
  };

  const renderForm = () => {
    return (
      <div className='px-4 pt-2 pb-4 bg-zinc-900 bg-opacity-80 dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700'>
        <div className='grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 gap-4'>
          {/* <div className='col-span-1'>
            <div className='mt-2 bg-white rounded-lg z-2 py-3 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3'>
              <input
                type='text'
                value='Buy'
                className='bg-transparent -mt-2 border-0'
                disabled
              />
            </div>
          </div> */}
          <div className='col-span-3'>
            <div className="relative mt-2 !h-12 w-full rounded-md bg-white dark:bg-slate-800">
              <Autocomplete value="" placeholder="City..." onInputChange={setCityInput} suggestions={cities} onSelectionChange={handleCityChange} />
            </div>
            <div className='relative mt-2 hidden'>
              <CityNameInput />
            </div>
          </div>

          <div className='col-span-1'>
            <div className="mt-2 bg-white rounded-lg z-2 py-3 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={` ${open
                        ? "text-black focus:outline-none w-full flex justify-between"
                        : "flex justify-between w-full"
                        }`}
                    >
                      <div className="flex justify-between w-full">
                        <button className="inline-block align-middle h-full" value={selectedType}>
                          {selectedType === "" ? " Property Type" : selectedType}
                        </button>
                        <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute bg-white dark:bg-neutral-800 overflow-hidden p-4 z-40 lg:mt-6 mt-5 shadow-xl lg:w-[24.5vw] md:w-96 w-full -ml-3 rounded-lg">
                        <ResidentialCommercialFilter
                          handleProjectType={handleProjectType}
                        />
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
        </div>
        <div className='grid lg:grid-cols-4 md:grid-cols-4 grid-cols-1 gap-4 mt-1'>
          {filter.subCategory !== "Office" &&
            filter.subCategory !== "Shop" &&
            filter.subCategory !== "Buildings" &&
            filter.subCategory !== "Showroom" &&
            filter.subCategory !== "Other Commercial" && (
              <div className='col-span-1'>
                <div className='relative mt-2 bg-white rounded-lg py-3 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3'>
                  <Popover>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={` ${open
                            ? "text-black focus:outline-none w-full flex justify-between"
                            : "flex justify-between w-full"
                            }`}
                        >
                          <div className='flex justify-between w-full'>
                            <button className='inline-block align-middle h-full'>
                              {!filter?.bedrooms &&
                                !filter?.bathrooms &&
                                "Beds & Baths"}
                              {filter?.bedrooms &&
                                (filter?.bedrooms == "8"
                                  ? filter?.bedrooms + "+Beds"
                                  : filter?.bedrooms + "Beds")}
                              {/* {filter?.bedrooms && filter?.bedrooms + " Beds"} */}
                              {filter?.bathrooms && filter?.bedrooms && "/"}
                              {filter?.bathrooms &&
                                (filter?.bathrooms == "6"
                                  ? filter?.bathrooms + "+Baths"
                                  : filter?.bathrooms + "Baths")}
                            </button>
                            <i className='las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full'></i>
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-200'
                          enterFrom='opacity-0 translate-y-1'
                          enterTo='opacity-100 translate-y-0'
                          leave='transition ease-in duration-150'
                          leaveFrom='opacity-100 translate-y-0'
                          leaveTo='opacity-0 translate-y-1'
                        >
                          <Popover.Panel className='absolute pb-4 bg-white dark:bg-neutral-800 overflow-hidden z-10 lg:mt-6 mt-5 shadow lg:w-[25.5vw] md:w-96 w-full -ml-3 rounded-lg shadow-xl border'>
                            <div className='pt-2 px-2'>
                              <span className='px-5'>Beds</span>
                              <BedsEstimateFormInput
                                dispatchFilter={dispatchFilter}
                                filter={filter}
                              />
                            </div>
                            <div className='px-2'>
                              <span className='px-5'>Baths</span>
                              <BathEstimateFormInput
                                dispatchFilter={dispatchFilter}
                                filter={filter}
                              />
                            </div>
                            {/* <div className='flex justify-between px-3 mt-2'>
                                <button className='rounded-md bg-white text-center text-[#263c76] border-2 border-[#263c76] mx-1 w-6/12'>
                                  RESET
                                </button>
                                <button className='rounded-md bg-[#263c76] text-center mx-1 w-6/12 py-2 text-white'>
                                  DONE
                                </button>
                              </div> */}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </div>
            )}

          <div className='col-span-1'>
            <div className='relative mt-2 bg-white rounded-lg z-2 py-3 dark:bg-slate-800 border-0 lg:w-full lg:!h-12 px-3'>
              <AreaRange />
            </div>
          </div>

          {/* <div className='col-span-1 lg:pr-2 md:pr-2'> */}
          <div
            className={`${filter?.subCategory === "Office" ||
              filter?.subCategory === "Shop" ||
              filter?.subCategory === "Buildings" ||
              filter?.subCategory === "Showroom" ||
              filter?.subCategory === "Other Commercial"
              ? "col-span-2"
              : "col-span-1"
              }  `}
          >
            <div className='relative mt-2'>
              <PriceRangeInput
                rangePrices={rangePrices}
                setRangePrices={setRangePrices}
                filter={filter}
              />
            </div>
          </div>

          <div className='col-span-1 relative mt-2'>
            <Link to='/salePage' type='button'>
              <input
                type='submit'
                id='search-sell'
                name='search'
                className='cursor-pointer btn bg-[#263c76] text-white rounded-lg submit-btn w-full !h-12'
                value='Find'
                onClick={() => handleFind()}
              />
            </Link>
          </div>
        </div>
        <div className={`pt-3 ${resetClassMain} `}>
          <div className="flex justify-end">
            <button
              onClick={(e) => resetFilter(e)}
              className="text-white text-sm font-thin"
            >
              Reset Filters
            </button>
          </div>
        </div>
      </div>
    )
  }

  return renderForm()
}

export default BuyPropertySearchForm
