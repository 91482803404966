import React, { FC, useState } from "react"
import "react-dates/initialize"
// import AddListingForm from "./AddListingForm"
// import ViewMapsSearchForm from "./ViewMapsSearchForm"

export type SearchTab2 = "View Maps" | "View Listing"

export interface ViewMapFormProps {
  className?: string
  currentTab?: SearchTab2
  currentPage?: "View Maps" | "View Listing"
  tabActive?: any
  setTabActive?: any
}

const ViewMapForm: FC<ViewMapFormProps> = ({
  className = "",
  currentTab = "View Maps",
  currentPage,
  tabActive,
  setTabActive,
}) => {
  const tabs: SearchTab2[] = ["View Maps", "View Listing"]

  const renderTab = () => {
    return (
      <ul className='ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar'>
        {tabs.map((tab) => {
          const active = tab === tabActive
          return (
            <li
              onClick={() => setTabActive(tab)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className='block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2' />
              )}
              <span>{tab}</span>
            </li>
          )
        })}
      </ul>
    )
  }

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab
    switch (tabActive) {
      case "View Maps":
        // return <ViewMapsSearchForm haveDefaultValue={isArchivePage} />
      case "View Listing":
        // return <AddListingForm haveDefaultValue={isArchivePage} />

      default:
        return null
    }
  }

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id='HeroSearchForm'
    >
      {renderTab()}
      {renderForm()}
    </div>
  )
}

export default ViewMapForm
