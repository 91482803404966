import React from 'react';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import { API } from "utils/config"

const WhatsAppButton = ({ id, agencyName, contentImage, contact }) => {
  return (
    <div className="z-50 top-1 right-0">
      <WhatsAppWidget
        phoneNo={contact}
        position="right"
        widgetWidthMobile="260px"
        autoOpenTimer={5000}
        messageBox={true}
        messageBoxTxt={`Hi, I am interested in your property on Imlaak Reference no: ${id}?`}
        iconSize="40"
        iconColor="white"
        iconBgColor="#128C7E"
        headerIcon={`${(contentImage)}`}
        HeaderIconSize="10"
        headerIconColor="#128C7E"
        headerIconOverFlow="hidden"
        headerTxtColor="#fff"
        headerBgColor="#128C7E"
        headerTitle={`${agencyName}`}
        headerCaption="Online"
        bodyBgColor="#EDF8F5"
        chatPersonName="Support"
        chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
        footerBgColor='#128C7E'
      />
    </div>
  );
};

export default WhatsAppButton;

