import React, {
  FC,
  useState,
  ReactNode,
  Fragment,
  useContext,
  useEffect,
} from "react";
import StayCardH from "components/StayCardH/StayCardH";
import { Popover, Transition } from "@headlessui/react";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "../ListingStayPage/TabFiltersNew";
import HeaderFilter from "./HeaderFilter";
import { StayDataType } from "data/types";
import ResidentialCommercialFilter from "components/HeroSearchForm/ResidentialCommercialFilter";
import BedsEstimateFormInput from "components/HeroSearchForm/BedsEstimateFormInput";
import BathEstimateFormInput from "components/HeroSearchForm/BathEstimateFormInput";
import PriceRangeInput from "components/HeroSearchForm/PriceRangeInput";
import BuyRentEstimateFilter from "components/HeroSearchForm/BuyRentEstimateFilter";
import { BuyRentContext } from "context/property/BuyRentContext";
import AgentMoreFilter from "components/HeroSearchForm/AgentMoreFilter";
import PropertySearchForm from "components/HeroSearchForm/BuyPropertySearchForm";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

export interface PropertyValueSearchProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const PropertyValueSearch: FC<PropertyValueSearchProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Properties listed in Pakistan",
  subHeading = "",
  headingIsCenter,
  tabs = ["All", "Furnished", "Unfurnished"],
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  const { getMyProperties, loading, properties, filter }: any =
    useContext(BuyRentContext);
  useEffect(() => {
    getMyProperties();
  }, []);
  //   useEffect(() => {
  console.log(properties, "properties");
  //   }, [properties])

  return (
    <>
      <div className="container my-2">
        <PropertySearchForm advanceSearch={false} />
      </div>
      <hr className="mt-3" />
      <main className="container relative mt-7 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          <div className="">
            <div className=" relative flex min-h-screen">
              {/* CARDSSSS */}
              <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
                {/* <Heading2 /> */}
                <div className="mb-8 lg:mb-11 ">
                  <HeaderFilter
                    tabActive={"All"}
                    subHeading={subHeading}
                    tabs={tabs}
                    heading={heading}
                    onClickTab={() => {}}
                  />
                </div>
                {loading ? (
                  <div role="status" className="grid grid-cols-1 gap-8">
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                      <div className="grid grid-cols-2">
                        <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                          <svg
                            className="w-12 h-12 text-gray-200 dark:text-gray-600"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        </div>
                        <div className="col-span-1 flex flex-col justify-start">
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                          <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                        </div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <div className="grid grid-cols-1 gap-8">
                  {properties?.property?.map((item: any) => (
                    <div
                      key={item.id}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <StayCardH filterAreaType={filter.areaType} data={item} />
                    </div>
                  ))}
                </div>
                <div className="flex mt-16 mb-10 justify-center items-center">
                  <Pagination />
                </div>
              </div>

              {/* {!showFullMapFixed && (
                <div
                  className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
                  onClick={() => setShowFullMapFixed(true)}
                >
                  <i className="text-lg las la-map"></i>
                  <span>Show map</span>
                </div>
              )} */}

              {/* MAPPPPP */}
              <div
                className={`xl:flex-grow xl:static xl:block ${
                  showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
                }`}
              >
                {showFullMapFixed && (
                  <ButtonClose
                    onClick={() => setShowFullMapFixed(false)}
                    className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="block px-5 flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">
            <div className="grid md:grid-cols-1 aspect-w-8 aspect-h-4 lg:grid-cols-1 mb-5 gap-4 ">
              <div className="rounded-md bg-[url('https://cdn4.vectorstock.com/i/1000x1000/50/68/house-background-vector-745068.jpg')] bg-cover bg-wreap bg-center">
                <div className="rounded-xl">
                  <a href="#" className="block p-6 max-w-sm rounded-md">
                    <h5 className="mb-2 text-2xl font-bold tracking-wide text-white">
                      Experience Search 2.0
                    </h5>
                    <p className="font-normal text-white py-10 mb-20">
                      Find new Properties in Pakistan
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-1 aspect-w-8 aspect-h-4 lg:grid-cols-1 mb-5  gap-4 ">
              <div className="rounded-md bg-[url('https://cdn4.vectorstock.com/i/1000x1000/50/68/house-background-vector-745068.jpg')] bg-cover bg-wreap bg-center">
                <div className="rounded-xl">
                  <a href="#" className="block p-6 max-w-sm rounded-md">
                    <h5 className="mb-2 text-2xl font-bold tracking-wide text-white">
                      Search for Projects
                    </h5>
                    <p className="font-normal text-white py-10 mb-20">
                      Find New Projects of Pakistan
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PropertyValueSearch;
