import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS, carsImgs } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { API } from "utils/config";

export interface PropertyCardHProps {
  className?: string;
  data: any;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PropertyCardH: FC<PropertyCardHProps> = ({ className = "", data }) => {
  const {
    name,
    like,
    isAds = true,
    _id,
    bedrooms,
    bathrooms,
    areaType,
    propertyType,
    sellingDetails,
    area,
    images,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full sm:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5 rounded-xl"
          galleryImgs={images}
          uniqueID={`PropertyCardH_${_id}`}
          // images={images}
          // galleryImgs={`${API}/uploads/${images?.[0]}`}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-3">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bed text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {bedrooms} beds
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {bathrooms} baths
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-th-large text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {area} {areaType}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">Ads</span>
                </div>
              }
            />
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-user-friends"></i>
                  <span className="ml-1">{propertyType}</span>
                </div>
              }
              color="yellow"
            />
          </div>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{name}</span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <span className="flex items-center justify-center px-3 rounded leading-none text-base font-medium">
              PKR {`${sellingDetails?.sellingPrice}`}
            </span>
          </div>
          <div className="flex justify-between  items-end">
            <button className="bg-[#d10f0f] text-white font-base py-1 px-4 rounded inline-flex items-center hover:bg-[#223A64]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="#fff"
                className="e6b01fef mr-1"
              >
                <path d="M13.3 10.3A7.6 7.6 0 0 1 11 10a.7.7 0 0 0-.7.1l-1 1.4a10.1 10.1 0 0 1-4.6-4.6L6 5.7A.7.7 0 0 0 6 5a7.4 7.4 0 0 1-.3-2.3A.7.7 0 0 0 5 2H2.8c-.4 0-.8.2-.8.7A11.4 11.4 0 0 0 13.3 14a.7.7 0 0 0 .7-.8V11a.7.7 0 0 0-.7-.6z"></path>
              </svg>{" "}
              <span>Call</span>
            </button>
            <button className="bg-[#d10f0f] ml-2 mr-5  text-white font-base py-1 px-4 rounded inline-flex items-center hover:bg-[#223A64]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                className="e6b01fef mr-1"
              >
                <path d="M13.3 2.7H2.7A1.3 1.3 0 0 0 1.3 4v8a1.3 1.3 0 0 0 1.4 1.3h10.6a1.3 1.3 0 0 0 1.4-1.3V4a1.3 1.3 0 0 0-1.4-1.3zm0 2.6L8 8.7 2.7 5.3V4L8 7.3 13.3 4z"></path>
                <path fill="none" d="M0 0h16v16H0z"></path>
              </svg>
              <span>Email</span>
            </button>
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
            {/* <span className="text-base font-semibold text-secondary-500">
  {price}

  <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
    /Yearly
  </span>
 
</span> */}
            <span className="flex justify-between  items-end ml-20">
              {/* <img src={logo} width={80} /> */}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="PropertyCardH"
    >
      <div className="flex flex-col  sm:flex-row sm:items-center">
        <span className="rounded-xl">{renderSliderGallery()}</span>

        <Link to={`/property_detail/${_id}`}>{renderContent()}</Link>
      </div>
    </div>
  );
};

export default PropertyCardH;
