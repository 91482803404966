import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import Input from "shared/Input/Input";
import Swal from "sweetalert2";
import { API } from "utils/config";
import { ChangeEvent, DragEvent, useState } from "react";
import React from "react";
import { ImageUploader } from "./imageSelector";
import { ResumeUploader } from "./resumeSelector";
import { Button } from "@mui/material";

const CareerForm = () => {
  const [showhide, setShowhide] = useState("no");

  const handleshow = (e: any) => {
    const getshow = e.target.value;
    setShowhide(getshow);
  };

  const [loader, setLoading] = useState(false);

  // const onSubmit = async (data: any) => {
  //   const formData = new FormData();
  //   formData.append("displayPicture", data.displayPicture);
  //   formData.append("resume", data.resume);

  //   // const res = await uploadImage({ params: formData });

  //   // console.log(res?.data?.image[0], "abcdef");

  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${API}/api/employment/create`,
  //       {
  //         name: data.name,
  //         lname: data.lname,
  //         email: data.email,
  //         address: data.address,
  //         contact: data.phone,
  //         displayPicture: data.res?.data?.image[0],
  //         resume: data.resume,
  //         coverLetter: data.coverLetter,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     Swal.fire({
  //       title: "Your application has been submitted successfully.",
  //       text: "A copy of your application will be sent to your email for your records.",
  //       icon: "success",
  //       confirmButtonColor: "#e74c3c",
  //     });

  //     reset();
  //   } catch (error) {
  //     console.log(error);

  //     Swal.fire({
  //       title: "Error",
  //       text: "An error occurred while submitting your application. Please try again later.",
  //       icon: "error",
  //       confirmButtonColor: "#e74c3c",
  //     });
  //     setLoading(false);
  //   }
  // };

  // const { role } = watch();

  const [showDropdown, setShowDropdown] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile && selectedFile.size > 12582912) {
      alert("File size exceeds the limit of 12MB");
      event.target.value = "";
      return;
    }
    setFile(selectedFile);
  };

  const handleDrop = (event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.size > 12582912) {
      alert("File size exceeds the limit of 12MB");
      return;
    }
    setFile(droppedFile);
  };

  const handleDragOver = (event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="container">
        <div className="">
          <span className="text-sm text-gray-500 ">Required Fields *</span>
          {/* <form className="py-5" onSubmit={handleSubmit(onSubmit)}>
            {/* <Input
  type="file"
  id="displayPicture"
  onChange={(e) => {
    const file = e.target.files?.[0]; // add a null check with the "?"
    setValue("displayPicture", file);
  }}
/> 
            <div className="flex flex-wrap">
              <div className="w-full my-5">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Photo
                  <span className="text-gray-600 font-normal">(Optional)</span>
                </label>
                 <ImageUploader
                  multiple={false}
                  images={images.firstImage ? [images.firstImage] : []}
                  setImages={updateImages("firstImage")}
                  files={files}
                  setFiles={setFiles}
                  variant={"profile"}
                />
              </div>
            </div>

            <div className="w-full my-5">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                resume*
              </label>
              <ResumeUploader
                // multiple={false}
                images={images.secondImage ? [images.secondImage] : []}
                setImages={updateImages("secondImage")}
                files={files}
                setFiles={setFiles}
              // variant={"profile"}
              /> 
        </div>

        {/*             
              <Button variant="contained" size="large" onClick={onSubmitImage}>
                Save
              </Button>

        <div className="flex justify-between pb-3">
          <div className="w-full md:w-1/2 pr-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              First Name*
            </label>
            <Input
              type="name"
              className={`block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 " id="grid-first-name" ${errors.name && "invalid"
                }`}
              placeholder="Imlaak"
              {...register("name", {
                required:
                  "Whoops! You need to provide your first name. Please do so now",
              })}
              onKeyUp={() => {
                trigger("name");
              }}
            />
            {errors.name && (
              <small className="text-[#223A64]">
                Whoops! You need to provide your first name. Please do so
                now
              </small>
            )}
          </div>
          <div className="w-full md:w-1/2 pr-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Last Name*
            </label>
            <Input
              type="lname"
              className={`block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 " id="grid-first-name" ${errors.lname && "invalid"
                }`}
              placeholder="pk"
              {...register("lname", {
                required:
                  "Whoops! You need to provide your last name. Please do so now",
              })}
              onKeyUp={() => {
                trigger("lname");
              }}
            />
            {errors.name && (
              <small className="text-[#223A64]">
                Whoops! You need to provide your last name. Please do so now
              </small>
            )}
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Email*
            </label>
            <Input
              type="email"
              className={`block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                id="grid-password" ${errors.email && "invalid"
                }`}
              placeholder="Email*"
              {...register("email", {
                required: "Email is Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message:
                    "Sorry, we can't move forward without your email address. Please enter it.",
                },
              })}
              onKeyUp={() => {
                trigger("email");
              }}
            />
            {errors.email && (
              <small className="text-[#223A64]">
                Sorry, we can't move forward without your email address.
                Please enter it.
              </small>
            )}
          </div>
        </div>
        <div className="pt-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Phone Number*
          </label>
          <Controller
            name="phone-input"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                inputStyle={{
                  border: "0px",
                  width: "100%",
                  height: "43px",
                }}
                containerStyle={{
                  border: "1px solid #969090",
                  borderRadius: "4px",
                  marginTop: "12px",
                }}
                country={"pk"}
                value={value}
                onChange={(phone) => {
                  console.log(phone);
                  setValue("phone", phone);
                  onChange(phone);
                }}
                inputProps={{
                  ref: register("phone-input", {
                    required:
                      "Wait a minute! We need your contact information to continue. Please provide it.",
                  }),
                  onBlur: () => {
                    trigger("phone-input");
                  },
                }}
              />
            )}
          />
          {errors["phone-input"] && (
            <small className="text-[#223A64]">
              Wait a minute! We need your contact information to continue.
              Please provide it.
            </small>
          )}
          <div className="flex flex-wrap pt-5">
            <div className="w-full">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Address*
              </label>
              <Input
                className={`block w-full bg-white text-gray-700 border border-gray-500 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        id="grid-password" ${errors.address && "invalid"
                  }`}
                type="address"
                placeholder="Address*"
                {...register("address", {
                  required:
                    "Attention please! Your address is required to proceed.",
                })}
                onKeyUp={() => {
                  trigger("address");
                }}
              />
              {errors.address && (
                <small className="text-[#345886]">
                  {"Attention please! Your address is required to proceed."}
                </small>
              )}
            </div>
          </div>

          <div className="flex flex-wrap py-5">
            <div className="w-full">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Cover Letter
                <span className="text-gray-600 font-normal">
                  (Optional)
                </span>
              </label>
              <textarea
                rows={7}
                className={`rounded-md w-full bg-white text-gray-700 border border-gray-500 py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ${errors.coverLetter && "invalid"
                  }`}
                placeholder="introduction..."
                {...register("coverLetter")}
                onKeyUp={() => {
                  trigger("coverLetter");
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-center py-5">
          <button className="text-base capitalize bg-[#345886] hover:bg-[#1f345c] py-2 text-white font-semibold lg:w-96 w-72 rounded-lg">
            Submit Application
          </button>
        </div>
      </form> */}
        </div >
      </div >
    </>
  );
};

export default CareerForm;
