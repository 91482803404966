import React, { FC, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import GoogleMapReact from "google-map-react";
import { DEMO_CAR_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
// import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import CarCardH from "components/CarCardH/CarCardH";

const DEMO_CARS = DEMO_CAR_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading="IMLAAK CONSTRUCTIONS LAHORE"
            subHeading={
              <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                Landlord
              </span>
            }
          />
          <div className="mb-8 lg:mb-11">{/* <TabFilters /> */}</div>
          <ol className="text-left border-l border-gray-300">
            <li>
              <div className="flex flex-start items-center pt-3">
                <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                <p className="text-gray-500 text-sm">
                  {" "}
                  <h4 className="text-gray-800 font-semibold  mb-1.5">
                    EMERGENCIES & SUPPORT
                  </h4>
                </p>
              </div>
              <div className="mt-0.5 ml-4 mb-6">
                <p className="text-gray-500 mb-3">
                  Our team responds to tenant inquiries & emergencies with
                  active support. In the rare instances when they’re necessary,
                  we handle evictions & legal necessities as well.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-start items-center pt-2">
                <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                <p className="text-gray-500 text-sm">
                  <h4 className="text-gray-800 font-semibold  mb-1.5">
                    PROPERTY MAINTENANCE FOR BUYERS & SELLERS
                  </h4>
                </p>
              </div>
              <div className="mt-0.5 ml-4 mb-6">
                <p className="text-gray-500 mb-3">
                  When buying or selling a home, professional property
                  maintenance can make all of the difference. Buyers can turn to
                  us for help with cleaning the home, washing windows and
                  sprucing up the yard before they move in. Sellers can contract
                  with Imlaak to ensure that the home makes the right first
                  impression and has great curb appeal.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-start items-center pt-2">
                <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                <p className="text-gray-500 text-sm">
                  <h4 className="text-gray-800 font-semibold  mb-1.5">
                    Property Leasing
                  </h4>
                </p>
              </div>
              <div className="mt-0.5 ml-4 pb-5">
                <p className="text-gray-500 mb-3">
                  Imlaak Rental Management Solutions has the professional
                  consultants available to show your home. The leasing process
                  involves setting the appointment, meeting the prospect at the
                  home, giving a guided tour through the home & determining the
                  needs of the prospect, the consultant will highlight how the
                  home meets the needs of the prospect & attempt to close the
                  sale. Imlaak Rental Management Solutions continues to follow
                  up with the prospect in an attempt to secure the rental
                  application.
                  <br />
                  Most importantly, Imlaak Rental Management Solutions tracks &
                  monitors the traffic on your home to indentify issues that may
                  be keeping your home from renting & will make recommendations
                  with the goal of leasing your rental home fast.
                </p>
              </div>
            </li>
            <li>
              <div className="flex flex-start items-center pt-2">
                <div className="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                <p className="text-gray-500 text-sm">
                  <h4 className="text-gray-800 font-semibold  mb-1.5">
                    EXCEPTIONAL SERVICES
                  </h4>
                </p>
              </div>
              <div className="mt-0.5 ml-4 pb-5">
                <p className="text-gray-500 mb-3">
                  <li>Full-service residential property management</li>
                  <li>Aggressive rent collection</li>
                  <li>Technology driven company</li>
                  <li>Extensive advertising/marketing of vacancies</li>
                  <li>Competitive rental/leasing rents and rates</li>
                  <li>Friendly, efficient full-time office staff</li>
                  <li>
                    Easy-to-read computerized monthly financial owner
                    statements/reports
                  </li>

                  <li>Access to reliable, licensed maintenance contractors</li>
                  <li>Supervise/coordinate maintenance</li>
                  <li>Thorough tenant screening</li>
                  <li>Administer legal proceedings, if necessary</li>
                  <li>Cooperate with Real Estate agents</li>
                  <li>Availability for emergencies</li>
                  <li>
                    Digital photographs of available rentals on our website
                  </li>
                </p>
              </div>
            </li>
          </ol>
        </div>

        <div
          className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
          onClick={() => setShowFullMapFixed(true)}
        >
          <i className="text-lg las la-map"></i>
          <span>Show map</span>
        </div>

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky bg-[url('https://www.phillyaptrentals.com/wp-content/uploads/2020/11/apartment-door.jpg')] top-0 xl:top-[88px]  left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
