import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog, Popover } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { BookmarkAltIcon, MapIcon, OfficeBuildingIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import CityInput from "components/HeroSearchForm/CityInput";
import LanguageInput from "components/HeroSearchForm/LanguageInput";
import ConsultantsNameInput from "components/HeroSearchForm/ConsultantsNameInput";

type Props = {
    ButtonText?: string;
    className?: string;
}
const ConsultantMobileFilter: React.FC<Props> = ({ ButtonText, className, }) => {
    const [isVisable, setIsVisable] = useState(false);

    useEffect(() => {
        setIsVisable(false);
    }, [window.location.pathname]);

    const handleOpenMenu = () => setIsVisable(true);
    const handleCloseMenu = () => setIsVisable(false);
    const renderContent = () => {
        return (
            <Transition appear show={isVisable} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={handleCloseMenu}
                >
                    <div className="fixed left-0 top-0 bottom-0 w-full md:w-auto z-max outline-none focus:outline-none">
                        <React.Fragment>
                            <Transition.Child
                                as={Fragment}
                                enter="transition duration-450 transform"
                                enterFrom="opacity-0 translate-y-14"
                                enterTo="opacity-100 -translate-y-0"
                                leave="transition duration-350 transform"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 -translate-y-14"
                            >
                                <div className="z-50 relative w-full h-screen py-2 transition transform shadow-lg bg-white">
                                    <div className="container flex flex-col py-12">
                                        <div className="py-1">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><MapIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Select City</span></div>
                                            <div className="relative !h-12 w-full border border-gray-400 rounded-md bg-white">
                                                <CityInput city={() => { }} />
                                            </div>
                                        </div>
                                        <div className="pt-3">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><OfficeBuildingIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Consultant Name</span></div>
                                            <ConsultantsNameInput cName={() => { }} />
                                        </div>
                                        <div className="pt-4">
                                            <div className="font-semibold text-base text-gray-600 flex justify-start pb-1"><BookmarkAltIcon className="w-6 h-6" /> <span className="font-semibold text-base text-gray-600 pl-2">Language</span></div>
                                            <div className="w-full rounded-md border border-gray-400">
                                                <LanguageInput lang={() => { }} />
                                            </div>
                                        </div>
                                        <div className="mt-7 flex justify-center">
                                            <Link to='/consultants' className="w-full">
                                                <button className="font-semibold text-base py-2.5 bg-[#345886] text-white text-center border w-full border-gray-400 rounded-md" onClick={handleCloseMenu}>Find Consultants</button>
                                            </Link>
                                        </div>
                                    </div>
                                    <span className="absolute right-2 top-2 p-1">
                                        <ButtonClose onClick={handleCloseMenu} />
                                    </span>
                                    <span className="absolute left-2 top-2 p-1">
                                        <button className="text-red-500 font-semibold">Reset</button>
                                    </span>
                                </div>
                            </Transition.Child>

                            <Transition.Child
                                as={Fragment}
                                enter=" duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave=" duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
                            </Transition.Child>
                        </React.Fragment>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    return (
        <>
            <button onClick={handleOpenMenu} className={`text-white uppercase !h-12 pt-3 w-full flex justify-center align-middle px-3 bg-[#345886] hover:bg-[#19315e] focus:outline-none rounded-md ${className}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-6 pr-2">
                    <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                </svg>
                {ButtonText}
            </button>
            {renderContent()}
        </>
    )
}

export { ConsultantMobileFilter }