import React, { FC, useState } from 'react'

export interface CurrencyFormatProps {
    price: any;
}
const CurrencyFormat: FC<CurrencyFormatProps> = ({ price }) => {
    let result = '';
    if (price < 100000) {
        result = price;
    } else if (price >= 100 && price < 10000) {
        result = (price / 100).toFixed(2).substring(0, 5) + ' Sau';
    } else if (price >= 1000 && price < 10000) {
        result = (price / 1000).toFixed(2).substring(0, 5) + ' Hazar';
    } else if (price >= 10000 && price < 100000) {
        result = (price / 10000).toFixed(2).substring(0, 5) + ' Hazar';
    } else if (price >= 100000 && price < 1000000) {
        result = (price / 100000).toFixed(2).substring(0, 5) + ' Lakh';
    } else if (price >= 1000000 && price < 10000000) {
        result = (price / 100000).toFixed(2).substring(0, 9) + ' Lakh';
    } else if (price >= 10000000 && price < 100000000) {
        result = (price / 10000000).toFixed(2).substring(0, 5) + ' Crore';
    } else if (price >= 100000000 && price < 1000000000) {
        result = (price / 10000000).toFixed(2).substring(0, 5) + ' Crore';
    } else if (price >= 1000000000 && price < 10000000000) {
        result = (price / 1000000000).toFixed(2).substring(0, 5) + ' Arab';
    } else if (price >= 10000000000 && price < 100000000000) {
        result = (price / 1000000000).toFixed(2).substring(0, 5) + ' Arab';
    } else if (price >= 100000000000 && price < 1000000000000) {
        result = (price / 100000000000).toFixed(2).substring(0, 5) + ' Kharab';
    } else if (price >= 1000000000000) {
        result = (price / 100000000000).toFixed(2).substring(0, 5) + ' Kharab';
    }

    let convertedAmount = result;
    return (
        <>
            <span className="">{convertedAmount}</span>
        </>
    )
}

export default CurrencyFormat