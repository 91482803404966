import { useContext, useEffect, useReducer, useState } from "react"
import axios from "axios"
import { API as apiHost } from "utils/config"
import Swal from "sweetalert2"
import { createContext } from "react"

const updateCurrentUrl = ({ value }: any) => {
  const nextURL = `/plot-finder/?openedMap=${value}`
  const nextTitle = ""
  const nextState = { additionalInformation: "" }
  // This will create a new entry in the browser's history, without reloading
  window.history.pushState(nextState, nextTitle, nextURL)
}

interface IBuyRentContext {
  properties: any
  getMyProperties: Function
  loading: boolean
  setLoading: Function
  getPropertyById: any
  dispatchFilter: any
  filter: any
  applyFilters: any
}

const BuyRentContextInitial = {
  properties: {},
  getMyProperties: () => { },
  propertyDetails: {},
  setPropertyDetails: () => { },
  loading: false,
  setLoading: () => { },
  getPropertyById: () => { },
  filter: {},
  dispatchFilter: () => { },
  applyFilters: () => { },
}

export const BuyRentContext = createContext<IBuyRentContext>(
  BuyRentContextInitial
)
const initialState: any = {
  price: [0, 0],
  purpose: localStorage.getItem("purpose") || "",
  // areaType: localStorage.getItem("areaType") || "",
  category: localStorage.getItem("category") || "",
}
function reducer(state: any, action: any) {
  switch (action.type) {
    case "updatePropertyPurpose":
      let newState = state
      if (action.value === "Rent") {
        if (newState.completionStatus) {
          delete newState.completionStatus
        }
      } else {
        if (action.value === "Buy") {
          if (newState.rentFrequency) {
            delete newState.rentFrequency
          }
        }
      }
      return { ...newState, purpose: action.value }
    case "updatePropertyAreaType":
      return { ...state, areaType: action.value }
    // case "updatePropertyCity":
    // return { ...state, city: action.value }
    case "updatePropertyRentFrequency":
      return { ...state, rentFrequency: action.value }
    case "updatePropertyFurnishingStatus":
      return { ...state, furnishingStatus: action.value }
    case "updatePropertyMaxArea":
      return { ...state, maxArea: action.value }
    case "updatePropertyMinArea":
      return { ...state, minArea: action.value }
    case "updatePropertyCompletionStatus":
      return { ...state, completionStatus: action.value }
    case "updatePropertyCity":
      return { ...state, cityName: action.value }
    case "updatePropertyLocation":
      return { ...state, community: action.value }
    case "updatePropertyCategory":
      let newCategory = state
      if (action.value === "Commercial") {
        if (newCategory.subCategory) {
          delete newCategory.subCategory
        }
        if (newCategory.bathrooms) {
          delete newCategory.bathrooms
        }
        if (newCategory.bedrooms) {
          delete newCategory.bedrooms
        }
      }
      if (action.value === "Residential") {
        if (newCategory.subCategory) {
          delete newCategory.subCategory
        }
      }
      return { ...newCategory, category: action.value }
      return { ...state, category: action.value }
    case "updatePropertySubCategory":
      return { ...state, subCategory: action.value }
    case "updatePropertyBedrooms":
      return { ...state, bedrooms: action.value }
    case "updatePropertyBathrooms":
      return { ...state, bathrooms: action.value }
    case "updatePropertyRange":
      return { ...state, price: action.value }
    case "resetFilter":
      return {
        ...state,
        priceRange: [0, 0],
        cityName: "",
        category: "",
        subCategory: "",
        bedrooms: "",
        bathrooms: "",
        minArea: "",
        maxArea: "",
      }
    // case "updateAreaMain":
    //   return { ...state, areaType: action.value }
    // case "updatePriceMain":
    //   return { ...state, price: action.value }

    case "updateBedBath":
      let updatedState = state
      if (!action.value) {
        delete updatedState[action.key]
      } else {
        updatedState = { ...updatedState, [action.key]: action.value }
      }
      return updatedState
    case "priceRangeReset":

      return { ...state, price: [0, 0] }

    default:
      throw new Error()
  }

}

const BuyRentProvider = (props: any) => {
  const token = localStorage.getItem("authToken")

  // const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])
  const [propertyDetails, setPropertyDetails] = useState({})
  const [userData, setUser] = useState("")
  const [filter, dispatchFilter] = useReducer(reducer, initialState)
  const [pageNumber, setPageNumber] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const [propertiesCount, setPropertiesCount] = useState(0)
  const [propertyMinArea, setPropertyMinArea] = useState("")
  const [propertyMaxArea, setPropertyMaxArea] = useState("")
  // console.log(filter, "filter in imlaak after change")

  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [areaType, setAreaType] = useState([])
  const [cities, setCities] = useState([])
  const [cityName, setCityName] = useState("")

  const applyFilters = (newFilter: any = {}) => {
    getMyProperties(Object.keys(newFilter)?.length ? newFilter : filter)

  }
  // const getAllProject = () => {
  //   getMyProperties({ projectType: 'All' })
  // }
  //fetch all properties
  const getMyProperties = async (query: any) => {
    if (!loading || !Boolean(properties)) {
      setLoading(true)
      const filters = JSON.stringify(query)
      const response = await axios.get(
        `${apiHost}/api/inventory/getAllListedProperties`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: { filters: filters, offset: 0, limit: perPage },
        }
      )
      const allProperty = await response?.data?.property
      const sortedData = allProperty.sort((a: any, b: any) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB.getTime() - dateA.getTime();
      });
      // const citiesNames = allProperty?.map((data: any) => data?.cityName)
      const cityNameData = allProperty.reduce((acc: any, data: any, index: any) => {
        if (data.cityName && !acc.some((item: any) => item?.name === data?.cityName)) {
          acc.push({ _id: acc.length + 1, name: data.cityName });
        }
        return acc;
      }, []);
      const uniqueCityNames = Array.from(new Set(cityNameData)) as any
      const citiesNames = Array.from(new Set(allProperty?.map((data: any) => data?.cityName))) as any

      setProperties(sortedData)
      setPropertiesCount(allProperty?.count)
      setLoading(false)
      setCities(uniqueCityNames)
      return response
    }
    return
  }

  const getPropertyById = async (id: any) => {
    const response = await axios.get(
      `${apiHost}/api/inventory/getPropertyById/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setPropertyDetails(response?.data?.property?.[0])
    return response
  }

  const getMe = async () => {
    setLoading(true)
    const tk = localStorage.getItem("authToken")
    if (tk) {
      try {
        const response = await axios.get(`${apiHost}/api/auth/finduser`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tk}`,
          },
        })

        if (response.status === 404) {
          localStorage.removeItem("authToken")
          setUser("")
          setLoading(false)
        } else if (response.status === 200) {
          const user = await response.data
          setUser(user)
          setLoading(false)
          return user
        }
      } catch (e) {
        setLoading(false)
        // localStorage.removeItem("authToken");
        // setUser('')
      }
    }
  }

  const getCategory = async () => {
    //todo api call
    //API CALL
    // const filters = JSON.stringify(query)
    const response = await axios.get(
      `${apiHost}/api/inventory/Category/getAllPropertyCategories`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setCategory(response?.data?.items)
    // return response
  }
  const getSubCategory = async () => {
    //todo api call
    //API CALL
    // const filters = JSON.stringify(query)
    const response = await axios.get(
      `${apiHost}/api/inventory/Category/getAllPropertyCategories`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setSubCategory(response?.data?.items)
    // return response
  }
  const getAreaType = async () => {
    const response = await axios.get(
      `${apiHost}/api/inventory/Area/getAllPropertyArea`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    setAreaType(response?.data?.items)
    // return response
  }

  const [users, setusers] = useState([])

  const value = {
    properties,
    getMyProperties,
    propertyDetails,
    setPropertyDetails,
    getMe,
    loading,
    setLoading,
    getPropertyById,
    filter,
    dispatchFilter,
    applyFilters,
    perPage,
    setPerPage,
    pageNumber,
    setPageNumber,
    propertiesCount,
    setPropertiesCount,
    getCategory,
    getSubCategory,
    category,
    subCategory,
    getAreaType,
    areaType,
    setPropertyMinArea,
    setPropertyMaxArea,
    setCityName,
    cityName,
    propertyMinArea,
    propertyMaxArea,
    cities,
  }

  return (
    <BuyRentContext.Provider value={value}>
      {props.children}
    </BuyRentContext.Provider>
  )
}
export default BuyRentProvider
