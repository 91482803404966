import React, { FC, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { ArrowSmRightIcon } from "@heroicons/react/solid";
import EmailFrom from "containers/ListingDetailPage/EmailFrom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";

export interface PropertyDetailPage2Props {
  className?: string;
  isPreviewMode?: boolean;
}

const PHOTOS: string[] = [
  "https://www.ghar47.com/wp-content/uploads/2021/04/Fully-Renovated-Apartment-For-Rent-in-DHA-Phase-2-Ext-Karachi-1.jpg",
  "https://q-xx.bstatic.com/xdata/images/hotel/max1000/271728622.jpg?k=b6dbbdfd1740e70ce4c96dac2d3177c37b29cb76872d5d0cea22f9bccc26fc3a",
  "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const Amenities_demos = [
  { name: "la-key", icon: "la-key" },
  { name: "la-luggage-cart", icon: "la-luggage-cart" },
  { name: "la-shower", icon: "la-shower" },
  { name: "la-smoking", icon: "la-smoking" },
  { name: "la-snowflake", icon: "la-snowflake" },
  { name: "la-spa", icon: "la-spa" },
  { name: "la-suitcase", icon: "la-suitcase" },
  { name: "la-suitcase-rolling", icon: "la-suitcase-rolling" },
  { name: "la-swimmer", icon: "la-swimmer" },
  { name: "la-swimming-pool", icon: "la-swimming-pool" },
  { name: "la-tv", icon: "la-tv" },
  { name: "la-umbrella-beach", icon: "la-umbrella-beach" },
  { name: "la-utensils", icon: "la-utensils" },
  { name: "la-wheelchair", icon: "la-wheelchair" },
  { name: "la-wifi", icon: "la-wifi" },
  { name: "la-baby-carriage", icon: "la-baby-carriage" },
  { name: "la-bath", icon: "la-bath" },
  { name: "la-bed", icon: "la-bed" },
  { name: "la-briefcase", icon: "la-briefcase" },
  { name: "la-car", icon: "la-car" },
  { name: "la-cocktail", icon: "la-cocktail" },
  { name: "la-coffee", icon: "la-coffee" },
  { name: "la-concierge-bell", icon: "la-concierge-bell" },
  { name: "la-dice", icon: "la-dice" },
  { name: "la-dumbbell", icon: "la-dumbbell" },
  { name: "la-hot-tub", icon: "la-hot-tub" },
  { name: "la-infinity", icon: "la-infinity" },
];

const PropertyDetailPage2: FC<PropertyDetailPage2Props> = ({
  className = "",
  isPreviewMode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: moment().add(4, "days"),
    endDate: moment().add(10, "days"),
  });
  // CUSTOM THEME STYLE
  // useEffect(() => {
  //   const $body = document.querySelector("body");
  //   if (!$body) return;
  //   $body.classList.add("theme-cyan-blueGrey");
  //   return () => {
  //     $body.classList.remove("theme-cyan-blueGrey");
  //   };
  // }, []);
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-2">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge name="PKR 30000" />
          <BtnLikeIcon />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-2xl ">
          450 Sqft Room For Rent
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4 justify-between">
          <span className="text-[#1f345c]">
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1 text-sm">DHA Phase 4, Lahore</span>
          </span>

          <div className="flex justify-between items-center">
            <ButtonPrimary
              className="rounded bg-neutral-900 mx-1 text-neutral-900"
              href={"#"}
            >
              <i className="las la-phone-volume mx-1 text-2xl"></i>
              Call Now
            </ButtonPrimary>
            <ButtonPrimary className="rounded" href={"#"}>
              <i className="las la-envelope mx-1 text-2xl"></i>
              Inquiry
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">
          Property Description
          <br />
          <span className="text-neutral-600  text-sm">
            Posted on - <span className=" font-thin"> November 8, 2022</span>
          </span>
        </h2>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-[#666666] text-[85%] dark:text-neutral-300  ">
          <span className="">Avail exclusive offers – LIMITED TIME!</span>
          <br />

          <span>
            <h2 className="mb-2  font-semibold text-gray-900 dark:text-white">
              Key Features:
            </h2>
            <ul className="space-y-1 max-w-md list-inside text-gray-500 dark:text-gray-400">
              <li className="flex items-center">
                <i className="las la-check-circle text-[#345886] mx-1"></i>1
                Starting from as low as Rs.38 Lacs only.
              </li>
              <li className="flex items-center">
                <i className="las la-check-circle text-[#345886] mx-1"></i>
                It is located on the main Islamabad Expressway.
              </li>
              <li className="flex items-center">
                <i className="las la-check-circle text-[#345886] mx-1"></i>
                Seamless Resale Options
              </li>
              <li className="flex items-center">
                <i className="las la-check-circle text-[#345886] mx-1"></i>
                Seamless Resale Options
              </li>
            </ul>
          </span>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Posted By</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Imlaak.pk
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              {/* <StartRating />
              <span className="mx-2">·</span> */}
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-[#666666] text-[75%] dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-[#666666] text-[75%] dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowSmRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            34-B, 1st Floor, Commercial Zone Sector C Commercial Area Sector C
            Bahria Town, Lahore, Punjab 54000
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <iframe
              className="w-full h-[70vh] rounded-lg shadow-xl"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.2373447917166!2d74.41441681496539!3d31.462656581388732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391906116ea342c7%3A0xe3cb0f0af677476e!2sImlaak.com!5e0!3m2!1sen!2s!4v1680465254677!5m2!1sen!2s"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}

        {/* CONTENT */}

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Masterpiece of Architecture</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-[#666666] text-[75%] dark:text-neutral-400 space-y-2">
              <li>
                The mall provides you a better experience and deep-rooted
                heritage, exquisite architecture, world-class shopping and
                incomparable entertainment under one magnificently opulent roof.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <>
        <div className="listingSectionSidebar__wrap shadow-xl">
          {/* PRICE */}
          <div className="flex justify-between">
            <span className="text-base ">
              Posted by:
              {/* <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span> */}
            </span>
            <StartRating />
          </div>

          {/* FORM */}
          <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-xl py-1 ">
            <div className="flex items-center space-x-4">
              <Avatar
                hasChecked
                hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
                sizeClass="h-14 w-14"
                radius="rounded-full"
              />
              <div>
                <a className="block text-xl font-medium" href="##">
                  Imlaak.pk
                </a>
                <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
                  {/* <StartRating /> */}
                  <span className=" bottom-2 px-1 rounded-r-lg text-md bg-slate-200 text-neutral-900 ">
                    <p>See All Properties</p>
                  </span>
                  <span className="mx-2">·</span>
                  <span> 12 Properties</span>
                </div>
              </div>
            </div>
          </form>

          <EmailFrom />
        </div>
      </>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        {/* <div className="container 2xl:px-14 mt-5 rounded-md sm:rounded-xl">
          {renderSection1()}
        </div> */}

        <header className="container 2xl:px-14 sm:px-14  mt-5 rounded-md grid grid-cols-3 sm:grid-cols-2 ">
          <div className="relative grid grid-cols-3 sm:grid-cols-1  shadow-xl">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              {/* <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
              /> */}
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 0 && i < 1).map((item, index) => (
              <div
                key={index}
                className={`relative overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                  }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-4 sm:aspect-h-3"
                  className="object-cover w-full h-full rounded-l"
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center  right-3 bottom-3 px-4 py-2 rounded-md bg-[#1f345c] text-neutral-100 cursor-pointer hover:bg-red-900 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <i className="las la-expand-arrows-alt text-xl"></i>
              <span className="ml-2 text-neutral-100  text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
          <div className=" bg-white grid  grid-cols-3 sm:grid-cols-1 shadow-xl rounded-r  h-100 ">
            <div className=" text-center ">
              <h1 className="text-xl text-[#223A64] mt-[30px] p-[5px] text-[2rem]">
                Property Name Hello 123
              </h1>
              <div className="flex items-center justify-between mt-10 xl:justify-center space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                <div className="flex items-center space-x-3 ">
                  <i className=" las la-user text-4xl "></i>
                  <span className="">6 Guests</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className=" las la-bed text-4xl"></i>
                  <span className=" ">6 Beds</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className=" las la-bath text-4xl"></i>
                  <span className=" ">3 Baths</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className=" las la-door-open text-4xl"></i>
                  <span className=" ">2 Bedrooms</span>
                </div>
              </div>
              <p className="text-[0.9rem] text-[#666666] items-center m-[10%]">
                Located only moments from the banks of the Thames, this spacious
                3 bedroom maisonette offers bright and spacious accommodation,
                comprising a gorgeous reception room full of natural light and a
                sleek modern kitchen.
              </p>

              <div className="">
                <button
                  type="submit"
                  className="mx-1 py-3 px-5 mt-1 text-sm font-medium text-center text-white rounded bg-[#223A64] sm:w-fit hover:bg-[#de1010] focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Send message
                </button>
                <button
                  type="submit"
                  className="py-3 px-5 mt-1  text-sm font-medium text-center text-white rounded bg-[#ed3b3b] sm:w-fit hover:bg-[#ff1619] focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Send message
                </button>
              </div>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {/* {renderSection1()} */}
          {renderSection2()}
          {/* {renderSection3()} */}

          {/* {renderSectionCheckIndate()} */}
          {renderSection5()}
          {/* {renderSection6()} */}
          {renderSection7()}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-12">{renderSidebar()}</div>
        </div>
      </main>

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}

          {/* SECTION */}
          {/* <SectionSubscribe2 className="pt-24 lg:pt-32" /> */}
        </div>
      )}
    </div>
  );
};

export default PropertyDetailPage2;
