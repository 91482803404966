import { useState, useEffect } from 'react';

const Navigation = () => {
    const [activeTab, setActiveTab] = useState('home');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.section');
            const navLinks = document.querySelectorAll('.nav_link');
            const currentSection = sections.length && [...sections].reduce((closest, section) => {
                return (Math.abs(section.getBoundingClientRect().top) < Math.abs(closest.getBoundingClientRect().top) ? section : closest);
            });
            const currentLinkId = currentSection.getAttribute('id');
            if (activeTab !== currentLinkId) {
                setActiveTab(currentLinkId);
            }
            navLinks.forEach(link => {
                link.classList.remove('active');
                if (link.href.includes(currentLinkId)) {
                    link.classList.add('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeTab]);


    return (
        <>
            <nav className='flex justify-between bg-[#345886] text-white sticky lg:top-[4.5rem] md:top-0 w-full lg:overflow-x-auto md:overflow-x-scroll overflow-x-scroll px-3 rounded-md py-2 left-0 right-0 top-0 z-50'>
                <a href="#overview" className={activeTab === 'overview' ? 'nav_link flex active items-center bg-white text-[#345886] font-semibold lg:py-2 md:py-1 py-.5 rounded-md px-3' : 'nav_link cursor-pointer flex items-center text-white hover:bg-white hover:text-[#345886] lg:py-2 md:py-1 py-.5 rounded-md px-3'}>Overview</a>
                <a href="#features" className={activeTab === 'features' ? 'nav_link active flex items-center bg-white text-[#345886] font-semibold lg:py-2 md:py-1 py-.5 rounded-md px-3' : 'nav_link cursor-pointer flex items-center text-white hover:bg-white hover:text-[#345886] lg:py-2 md:py-1 py-.5 rounded-md px-3'}>Features</a>
                <a href="#propertyType" className={activeTab === 'propertyType' ? 'nav_link active flex items-center bg-white text-[#345886] font-semibold lg:py-2 md:py-1 py-.5 rounded-md px-3' : 'nav_link cursor-pointer flex items-center text-white hover:bg-white hover:text-[#345886] lg:py-2 md:py-1 py-.5 rounded-md px-3'}>Property Types</a>
                <a href="#floor" className={activeTab === 'floor' ? 'nav_link active flex items-center bg-white text-[#345886] font-semibold lg:py-2 md:py-1 py-.5 rounded-md px-3' : 'nav_link cursor-pointer flex items-center text-white hover:bg-white hover:text-[#345886] lg:py-2 md:py-1 py-.5 rounded-md px-3'}>Floor Plan</a>
                <a href="#payment" className={activeTab === 'payment' ? 'nav_link active flex items-center bg-white text-[#345886] font-semibold lg:py-2 md:py-1 py-.5 rounded-md px-3' : 'nav_link cursor-pointer flex items-center text-white hover:bg-white hover:text-[#345886] lg:py-2 md:py-1 py-.5 rounded-md px-3'}>Payment Plan</a>
                <a href="#map" className={activeTab === 'map' ? 'nav_link active flex items-center bg-white text-[#345886] font-semibold lg:py-2 md:py-1 py-.5 rounded-md px-3' : 'nav_link cursor-pointer flex items-center text-white hover:bg-white hover:text-[#345886] lg:py-2 md:py-1 py-.5 rounded-md px-3'}>Map</a>
                <a href="#developer" className={activeTab === 'developer' ? 'nav_link active flex items-center bg-white text-[#345886] font-semibold lg:py-2 md:py-1 py-.5 rounded-md px-3' : 'nav_link cursor-pointer flex items-center text-white hover:bg-white hover:text-[#345886] lg:py-2 md:py-1 py-.5 rounded-md px-3'}>Developer</a>
            </nav>
        </>
    )
}

export default Navigation

