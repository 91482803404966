import React, { Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import MemberProfile from "./MemberProfile/MemberProfile";
import Login from "./Login";
import Avatar from "../../images/my.jpg";
import { useProfile } from "utils/checkAuth";
import Swal from "sweetalert2";
import pinContext from "context/pin/pinContext";
import { Menu } from "@headlessui/react";
import {
  ChevronDownIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { API } from "utils/config";
import Image from "../../images/avatars/download.png";
import LoginModal from "components/LoginModal/LoginModal";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const TopHeader = ({ afterChange }: any) => {
  const { userData, getMe } = React.useContext(pinContext);

  React.useEffect(() => {
    getMe();
  }, []);

  const { isLoggedIn } = useProfile();

  const logout = () => {
    window.localStorage.removeItem("authToken");
    Swal.fire({
      title: "Logout!",
      text: "Account Logout successfully",
      icon: "success",
      confirmButtonColor: "#e74c3c",
    }).then(() => window.location.reload());
  };

  const JwtToken = window.localStorage.getItem("authToken");
  const PORTAL_LINK = process.env.REACT_APP_PORTAL_URL
  const [currentOpenSubMenu, setCurrentOpenSubMenu] = React.useState(null);
  const [showModalLogin, setShowModalLogin] = React.useState(false);

  const handleCloseUserMenu = () => {
    setCurrentOpenSubMenu(null);
  };
  return (
    <>
      <div className="sm:px-16 px-2 flex justify-between">

        <div className="w-full align-middle">
          <button
            type="button"
            data-dropdown-toggle="language-dropdown-menu"
            className="inline-flex justify-center items-center p-2 text-sm text-gray-500 cursor-pointer dark:text-gray-700 hover:text-gray-900"
          >
            <svg
              className="mr-2 w-5 h-5 rounded-full"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 3900 3900"
            >
              <path fill="#b22234" d="M0 0h7410v3900H0z"></path>
              <path
                d="M0 450h7410m0 600H0m0 600h7410m0 600H0m0 600h7410m0 600H0"
                stroke="#fff"
                stroke-width="300"
              ></path>
              <path fill="#3c3b6e" d="M0 0h2964v2100H0z"></path>
              <g fill="#fff">
                <g id="d">
                  <g id="c">
                    <g id="e">
                      <g id="b">
                        <path
                          id="a"
                          d="M247 90l70.534 217.082-184.66-134.164h228.253L176.466 307.082z"
                        ></path>
                        <use xlinkHref="#a" y="420"></use>
                        <use xlinkHref="#a" y="840"></use>
                        <use xlinkHref="#a" y="1260"></use>
                      </g>
                      <use xlinkHref="#a" y="1680"></use>
                    </g>
                    <use xlinkHref="#b" x="247" y="210"></use>
                  </g>
                  <use xlinkHref="#c" x="494"></use>
                </g>
                <use xlinkHref="#d" x="988"></use>
                <use xlinkHref="#c" x="1976"></use>
                <use xlinkHref="#e" x="2470"></use>
              </g>
            </svg>
            English (US)
          </button>
        </div>

        <div className="items-center w-full lg:mt-1 md:mt-1 mt-0 align-middle">
          <ul className="flex lg:justify-end md:justify-between justify-end flex-row md:mt-0 md:text-sm md:font-medium md:border-0">
            <li className="hidden lg:block md:block mx-3">
              {/* <a href="#" className="block font-normal text-gray-800 md:hover:text-red-700 md:p-1 dark:text-gray-400 md:dark:hover:text-white dark:hover:text-white  dark:border-gray-700"><i className="las  la-heart text-md"></i>
                 Favourite properties</a> */}
              <Popover className="relative mt-1" id="login">
                {({ open, close }) => (
                  <>
                    <Popover.Button
                      className={` ${open
                        ? "text-black flex justify-center focus:outline-none  dark:text-gray-400 md:dark:hover:text-white dark:hover:text-white"
                        : "flex justify-center"
                        }`}
                    >
                      {/* <UserCircleIcon className="w-4 4-6 mt-[2px]" /> */}
                      {/* <span> Favourite properties</span> */}
                    </Popover.Button>
                    {/* <MemberProfile /> */}
                    <Menu
                      as="div"
                      className="relative inline-block cursor-pointer text-left z-40"
                    >
                      {isLoggedIn ? (
                        <div>
                          <Menu.Button className="inline-flex text-capitalize w-full justify-center cursor-pointer text-sm font-medium text-gray-700 dark:text-gray-700">
                            <i className="las la-heart text-md mt-1 pr-2" />{" "}
                            Favourite Properties
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {userData?.saveProperty?.map(
                                  (items: any, index: any) => (
                                    <div>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                            key={index}
                                          >
                                            <Link
                                              to={`/project-detail/${items?._id}`}
                                            >
                                              {items?.projectName}
                                            </Link>
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  )
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </div>
                      ) : (
                        <div>
                          {showModalLogin && (
                            <LoginModal
                              showModalLogin={showModalLogin}
                              setShowModalLogin={setShowModalLogin}
                              afterChange={afterChange}
                            />
                          )}{" "}
                          {/* Render the modal component only when showModalLogin is true */}
                          <Menu>
                            <Menu.Button
                              className="inline-flex w-full justify-center cursor-pointer text-sm font-medium text-gray-700 dark:text-gray-700"
                              onClick={() => setShowModalLogin(true)}
                            >
                              <i className="las la-heart text-md mt-1 pr-2" />{" "}
                              Favourite Properties
                            </Menu.Button>
                          </Menu>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-40 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <div>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        {/* <LoginModal showModalLogin={showModalLogin} setShowModalLogin={setShowModalLogin} afterChange={afterChange} /> */}
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </div>
                      )}
                    </Menu>
                  </>
                )}
              </Popover>
            </li>
            {/* <li className="hidden lg:block md:block mx-3">
                <a href="#" className="block font-normal text-gray-800 md:hover:text-red-700 md:p-1 dark:text-gray-400 md:dark:hover:text-white dark:hover:text-white  dark:border-gray-700"><i className="las la-star text-md"></i> Saved searches</a>
              </li> */}
            {isLoggedIn ? (
              <li>
                <Menu
                  as="div"
                  className="relative inline-block cursor-pointer text-left z-40"
                >
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center cursor-pointer text-sm font-medium text-gray-700 shadow-sm">
                      <UserCircleIcon className="w-5 h-5 mt-[5px]" />
                      <span className="mt-1 pl-1 capitalize text-black">
                        {" "}
                        {userData?.fname}
                      </span>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={`https://dashboard.imlaak.com?token=${JwtToken}`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                              target="_blank"
                            >
                              Dashboard
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              My Reviews
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="dashboard.imlaak.com"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Account settings
                            </a>
                          )}
                        </Menu.Item>
                        <form>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="submit"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block w-full px-4 py-2 text-left text-sm"
                                )}
                                onClick={() => {
                                  logout();
                                }}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </form>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </li>
            ) : (
              <li className="mx-3 relative">
                <Link to="/login" className=" text-sm leading-9 flex justify-between dark:text-black">
                  <UserCircleIcon className="w-6 mt-[0px] pr-2 dark:text-[#000]" />
                  <span > Login as Landlord</span>
                </Link>
              </li>
            )}
          </ul>
        </div>

      </div>
    </>
  );
};

export default TopHeader;
