import React, { FC, useState } from "react"
import GallerySlider from "components/GallerySlider/GallerySlider"
import StartRating from "components/StartRating/StartRating"
import { Link } from "react-router-dom"
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon"
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge"
import Badge from "shared/Badge/Badge"
import WhatsAppButton from "./whatsAppButton"
import CallModal from "components/HeroSearchForm/CallModal"
import EmailModal from "components/HeroSearchForm/EmailModal"
import NumberFormatter from "components/HeroSearchForm/NumberFormatter"
import VerifiedIMage from "../../images/verified.png"
import unVerifiedIMage from "../../images/unverified.png"

export interface ListingPropertyCardProps {
    className?: string
    data: any
    filterAreaType?: any
    email?: any;
    phone?: any;
    name?: any;
    image?: any;
}

function getOrdinalIndicator(n: number): string {
    const lastDigit = n % 10;
    const lastTwoDigits = n % 100;

    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
        return 'th';
    }

    switch (lastDigit) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

const ListingPropertyCard: FC<ListingPropertyCardProps> = ({
    className = "",
    data,
    phone,
    name,
    image,
    email,
    filterAreaType,
}) => {
    const {
        like,
        isAds = true,
        _id,
        bedrooms,
        isVerified,
        bathrooms,
        floorNo,
        areaType,
        propertyType,
        category,
        subCategory,
        title,
        sellingDetails,
        area,
        images,
        contact,
        price,
        salePrice,
        user,
        propertyImages,
        ProjectSelecting,
        projectName,
        consultantPhone,
        consultantEmail,
        consultantName,
        furnishingStatus,
        projectDetails,
        consultantDetails,
        project,
    } = data

    let result = ""

    if (salePrice < 100000) {
        result = salePrice
    } else if (salePrice >= 100 && salePrice < 10000) {
        result = (salePrice / 100).toFixed(2).substring(0, 5) + " Sau"
    } else if (salePrice >= 1000 && salePrice < 10000) {
        result = (salePrice / 1000).toFixed(2).substring(0, 5) + " Hazar"
    } else if (salePrice >= 10000 && salePrice < 100000) {
        result = (salePrice / 10000).toFixed(2).substring(0, 5) + " Hazar"
    } else if (salePrice >= 100000 && salePrice < 1000000) {
        result = (salePrice / 100000).toFixed(2).substring(0, 5) + " Lakh"
    } else if (salePrice >= 1000000 && salePrice < 10000000) {
        result = (salePrice / 100000).toFixed(2).substring(0, 9) + " Lakh"
    } else if (salePrice >= 10000000 && salePrice < 100000000) {
        result = (salePrice / 10000000).toFixed(2).substring(0, 5) + " Crore"
    } else if (salePrice >= 100000000 && salePrice < 1000000000) {
        result = (salePrice / 10000000).toFixed(2).substring(0, 5) + " Crore"
    } else if (salePrice >= 1000000000 && salePrice < 10000000000) {
        result = (salePrice / 1000000000).toFixed(2).substring(0, 5) + " Arab"
    } else if (salePrice >= 10000000000 && salePrice < 100000000000) {
        result = (salePrice / 1000000000).toFixed(2).substring(0, 5) + " Arab"
    } else if (salePrice >= 100000000000 && salePrice < 1000000000000) {
        result = (salePrice / 100000000000).toFixed(2).substring(0, 5) + " Kharab"
    } else if (salePrice >= 1000000000000) {
        result = (salePrice / 100000000000).toFixed(2).substring(0, 5) + " Kharab"
    }

    let convertedAmount = result
    const [showCallModal, setShowCallModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);

    const renderSliderGallery = () => {
        return (
            <div className='relative flex-shrink-0 w-full sm:w-72 object-cover rounded-xl'>
                <GallerySlider
                    galleryImgs={propertyImages}
                    uniqueID={`StayCardH_${_id}`}
                    imageClassCss='w-full'
                />
                {/* <BtnLikeIcon className="absolute right-3 top-3" projectId={item._id} /> */}
                {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
            </div>
        )
    }

    const renderTienIch = () => {
        return (
            <>
                <div className='hidden sm:grid grid-cols-3 gap-3'>
                    {bedrooms && (
                        <div className='space-y-3'>
                            <div className='flex items-center space-x-3'>
                                <i className='las la-bed text-lg'></i>
                                <span className='text-sm font-medium capitalize text-neutral-500'>
                                    {bedrooms} beds
                                </span>
                            </div>
                        </div>
                    )}
                    {bathrooms && (
                        <div className='space-y-3'>
                            <div className='flex items-center space-x-3'>
                                <i className='las la-bath text-lg'></i>
                                <span className='text-sm font-medium capitalize text-neutral-500'>
                                    {bathrooms} baths
                                </span>
                            </div>
                        </div>
                    )}
                    <div className='space-y-3'>
                        <div className='flex items-center space-x-3'>
                            <i className='las la-th-large text-lg'></i>
                            <span className='text-sm font-medium capitalize text-neutral-500'>
                                {"Sq. Ft."} {area}
                            </span>
                        </div>
                    </div>
                    {category === 'Commercial' && (
                        <>
                            <div className='space-y-3'>
                                <div className='flex items-center space-x-3'>
                                    <i className='las la-chart-area text-lg'></i>
                                    <span className='text-sm font-medium capitalize text-neutral-500'>
                                        {floorNo}<sup className="font-semibold">{getOrdinalIndicator(floorNo)}</sup> {"Floor"}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className='grid grid-cols-3 gap-3'>
                    {category === 'Residential' && (

                        <div className='space-y-3'>
                            <div className='flex items-center space-x-3'>
                                <i className='las la-chart-area text-lg'></i>
                                <span className='text-sm font-medium capitalize text-neutral-500'>
                                    {floorNo}<sup className="font-semibold">{getOrdinalIndicator(floorNo)}</sup> {"Floor"}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className='space-y-3'>
                        {ProjectSelecting === 'Registered_project' ? (
                            <>
                                <div className='flex items-center space-x-3 rounded-md '>
                                    <i className='las la-building text-lg'></i>
                                    <Link to={`/${project?.[0]?.projectSlug}`} className='text-sm font-medium capitalize text-neutral-500'>
                                        <Badge name={projectDetails?.[0]?.projectName} color='green' />
                                        {/* <span className="font-medium leading-4">Project:</span> {projectDetails[0]?.projectName}{" "} */}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='flex items-center space-x-4'>
                                    <i className='las la-building text-lg'></i>
                                    <span className='text-sm font-medium capitalize text-neutral-500'>
                                        {projectName?.length > 15 ? `${projectName?.substring(0, 15)} ...` : projectName}{" "}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </>
        )
    }

    const renderContent = () => {
        return (
            <div className='flex-grow p-3 sm:pr-6 flex flex-col items-start'>
                <Link to={`/property_detail/${_id}`}>
                    <div className='space-y-4 w-full relative'>
                        <div className='inline-flex space-x-3'>
                            <Badge
                                name={
                                    <div className='flex items-center'>
                                        <i className='text-sm las la-share-alt'></i>
                                        <span className='ml-1'>{category}</span>
                                    </div>
                                }
                            />
                            <Badge
                                name={
                                    <div className='flex items-center'>
                                        <i className='text-sm las la-user-friends'></i>
                                        <span className='ml-1'>{subCategory}</span>
                                    </div>
                                }
                                color='yellow'
                            />
                            {/* <Badge name={projectDetails[0]?.projectName} color='green' /> */}
                        </div>
                        <div className='flex items-center space-x-2'>
                            {/* {isAds && <Badge name='ADS' color='green' />} */}
                            <h2 className='text-lg font-medium capitalize'>
                                <span className='line-clamp-2'>
                                    {/* {title} */}
                                    {furnishingStatus === 'unfurnished' ? (
                                        <>
                                            {" "}
                                        </>
                                    ) : (
                                        <>
                                            {furnishingStatus}&nbsp;
                                        </>
                                    )}
                                    {subCategory}
                                    {bedrooms && (
                                        <>
                                            {bedrooms > 2 ? (
                                                <>
                                                    &nbsp;- Penthouse
                                                </>
                                            ) : (
                                                <>
                                                    &nbsp;- {bedrooms} Bedroom
                                                </>
                                            )}
                                        </>
                                    )}

                                    {/* in&nbsp;
                  {ProjectSelecting === 'Registered_project' ? (
                    <>
                      {projectDetails[0]?.projectName}{" "}
                    </>
                  ) : (
                    <>
                      {projectName}{" "}
                    </>
                  )} */}
                                </span>
                            </h2>
                        </div>
                        {renderTienIch()}
                        {/* <div className='w-14 border-b border-neutral-100 dark:border-neutral-800 '></div> */}
                        <div className='flex w-full justify-between items-end'>
                            <span className='flex items-center justify-center px-3 rounded leading-none text-base font-medium'>
                                PKR &nbsp; <NumberFormatter value={salePrice} />
                            </span>
                        </div>
                    </div>
                    <div className="absolute top-2 right-5">
                        {isVerified ? (
                            <img src={VerifiedIMage} alt="" className="w-[40px] object-contain" />
                        ) : (
                            <img src={unVerifiedIMage} alt="" className="w-[40px] object-contain" />
                        )}
                    </div>
                </Link>

                <div className='flex justify-between pt-4'>
                    <div className='flex flex-row'>
                        <button className='bg-[#345886] text-white font-base py-1 px-4 rounded inline-flex items-center hover:bg-[#223A64]' onClick={() => setShowCallModal(true)}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='#fff'
                                className='e6b01fef mr-1'
                            >
                                <path d='M13.3 10.3A7.6 7.6 0 0 1 11 10a.7.7 0 0 0-.7.1l-1 1.4a10.1 10.1 0 0 1-4.6-4.6L6 5.7A.7.7 0 0 0 6 5a7.4 7.4 0 0 1-.3-2.3A.7.7 0 0 0 5 2H2.8c-.4 0-.8.2-.8.7A11.4 11.4 0 0 0 13.3 14a.7.7 0 0 0 .7-.8V11a.7.7 0 0 0-.7-.6z'></path>
                            </svg>{" "}
                            <span>Call</span>
                        </button>
                        <button className='bg-[#345886] mx-4 text-white font-base py-1 px-4 rounded inline-flex items-center hover:bg-[#223A64]' onClick={() => setShowEmailModal(true)}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='#fff'
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                className='e6b01fef mr-1'
                            >
                                <path d='M13.3 2.7H2.7A1.3 1.3 0 0 0 1.3 4v8a1.3 1.3 0 0 0 1.4 1.3h10.6a1.3 1.3 0 0 0 1.4-1.3V4a1.3 1.3 0 0 0-1.4-1.3zm0 2.6L8 8.7 2.7 5.3V4L8 7.3 13.3 4z'></path>
                                <path fill='none' d='M0 0h16v16H0z'></path>
                            </svg>
                            <span>Email</span>
                        </button>
                    </div>
                    {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
                    {/* <span className="text-base font-semibold text-secondary-500">
  {price}

  <span className="text-sm font-medium capitalize text-neutral-500 dark:text-neutral-400 font-normal">
    /Yearly
  </span>
 
</span> */}
                    {/* <div className='ml-36'>

            <img src={logo} width={80} />
          </div> */}
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='py-2'>
                <div
                    className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border shadow border-neutral-100 dark:border-neutral-300 dark:shadow-lg rounded-2xl hover:shadow-xl transition-shadow will-change-transform ${className}`}
                    data-nc-id='StayCardH'
                >
                    <div className='flex flex-col sm:flex-row sm:items-center'>
                        <div className='rounded-xl object-cover'>{renderSliderGallery()}</div>

                        <div className='h-30'>
                            <WhatsAppButton
                                id={_id}
                                agencyName={name}
                                contentImage={image}
                                contact={phone}
                            />
                        </div>
                        <Link to={`/property_detail/${_id}`}>
                        </Link>
                        {renderContent()}
                    </div>
                </div>
            </div>
            <CallModal
                showCallModal={showCallModal}
                setShowCallModal={setShowCallModal}
                CallContent={phone}
            />
            <EmailModal
                ReceiverEmail={email}
                showEmailModal={showEmailModal}
                setShowEmailModal={setShowEmailModal}
            />
        </>
    )
}

export default ListingPropertyCard
