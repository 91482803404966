import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const projectsChildrenMenus: NavItemType[] = [
  // Appartments
  {
    id: ncNanoId(),
    href: "/add-listing-1",
    name: "APARTMENTS/HOUSES",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/sixty6-gulberg",
        name: "Sixty 6 Gulberg",
      },
      {
        id: ncNanoId(),
        href: "/goldcrest-dha-lahore-mall-residency",
        name: "Goldcrest DHA Lahore Residency",
      },
      {
        id: ncNanoId(),
        href: "/downtown-residences",
        name: "Downtown Residences",
      },
      {
        id: ncNanoId(),
        href: "/61-manor",
        name: "61 Manor",
      },
    ],
  },
  //

  //COMMERCIAL
  {
    id: ncNanoId(),
    href: "/checkout",
    name: "COMMERCIAL",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/goldcrest-dha-lahore-mall-residency",
        name: "Goldcrest DHA Lahore Mall",
      },
      {
        id: ncNanoId(),
        href: "/fairways-defence-raya-commercial-plazas",
        name: "Fairways Defence Raya Commercial Plazas",
      },
      {
        id: ncNanoId(),
        href: "/indigo-heights-lahore",
        name: "Indigo Heights Lahore",
      },
    ],
  },

  //COMMERCIAL
  {
    id: ncNanoId(),
    href: "/checkout",
    name: "OTHER",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/dream-gardens-lahore",
        name: "Dream Gardens Lahore",
      },
      {
        id: ncNanoId(),
        href: "/dream-gardens-lahore",
        name: "Damac Properties",
      },
    ],
  },
];

const RealtyChildrenMenus: NavItemType[] = [
  // Appartments
  {
    id: ncNanoId(),
    href: "#",
    name: "DHA LAHORE",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-7",
        name: "DHA Lahore Phase 7",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-8",
        name: "DHA Lahore Phase 8",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-9-prism",
        name: "DHA Lahore Phase 9 Prism",
      },
    ],
  },
  //

  //COMMERCIAL
  {
    id: ncNanoId(),
    href: "#",
    name: "GWADAR",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/sangar-housing-scheme-2",
        name: "Sangar Housing Scheme",
      },
      {
        id: ncNanoId(),
        href: "/jinnah-avenue-commercial-3",
        name: "Jinnah Avenue Commercial",
      },
      {
        id: ncNanoId(),
        href: "/airport-road-commercial-3",
        name: "Airport Road Commercial",
      },
    ],
  },

  //COMMERCIAL
  {
    id: ncNanoId(),
    href: "/checkout",
    name: "OTHER",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/apartments-in-lahore",
        name: "Apartments in Lahore",
      },
      {
        id: ncNanoId(),
        href: "/dha-multan",
        name: "DHA Multan",
      },
      {
        id: ncNanoId(),
        href: "/dubai-real-estate",
        name: "Dubai Real Estate",
      },
    ],
  },
];

const MapsChildrenMenus: NavItemType[] = [
  // Maps
  {
    id: ncNanoId(),
    href: "/dha-lahore-maps",
    name: "DHA Lahore Maps",
  }, {
    id: ncNanoId(),
    href: "/dha-lahore-maps",
    name: "DHA Multan Maps",
  }, {
    id: ncNanoId(),
    href: "/dha-lahore-maps",
    name: "DHA Peshawar Maps",
  }, {
    id: ncNanoId(),
    href: "/dha-lahore-maps",
    name: "Gawadar Maps",
  },
];

const plotsChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "#",
    name: "LAHORE",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/apartments-in-lahore",
        name: "Apartments in Lahore",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-1-to-4",
        name: "DHA Lahore Phase 1 to 4",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-5",
        name: "DHA Lahore Phase 5",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-6",
        name: "DHA Lahore Phase 6",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-7",
        name: "DHA Lahore Phase 7",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-8",
        name: "DHA Lahore Phase 8",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-9-prism",
        name: "DHA Lahore Phase 9 Prism",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-7",
        name: "DHA Lahore Phase 10",
      },
      {
        id: ncNanoId(),
        href: "/dha-lahore/dha-lahore-phase-7",
        name: "DHA Lahore Phase 11 Rahber",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "DUBAI",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/dubai-real-estate",
        name: "SAMANA DEVELOPERS",
      },
      {
        id: ncNanoId(),
        href: "/dubai-real-estate",
        name: "DAMAC",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "OTHER CITIES",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/sangar-housing-scheme-2",
        name: "GAWADAR",
      }, {
        id: ncNanoId(),
        href: "/dha-multan",
        name: "DHA Multan",
      }, {
        id: ncNanoId(),
        href: "/dha-multan",
        name: "DHA Gujranwala",
      }, {
        id: ncNanoId(),
        href: "/dha-multan",
        name: "DHA Peshawar",
      }, {
        id: ncNanoId(),
        href: "/dha-multan",
        name: "DHA Quetta",
      },
    ],
  },
  //
];

export const NAVIGATION_DEMO: NavItemType[] = [
  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Reality",
  //   type: "dropdown",
  //   children: plotsChildrenMenus,
  //   // isNew: true,
  // },
  {
    id: ncNanoId(),
    href: "/salePage",
    name: "Buy",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/projects",
    name: "Project",
    type: "none",
  },
  {
    id: ncNanoId(),
    href: "/developers",
    name: "Developers",
    type: "none",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/plot-finder",
  //   name: "Plot finder",
  //   type: "none",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Maps",
  //   type: "dropdown",
  //   children: MapsChildrenMenus,
  // },
];
