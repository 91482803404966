import React, { FC, useEffect, useMemo, useState, useRef } from "react";
import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";

export interface GallerySlider2Props {
    className?: string;
    imageClassCss?: string;
    ratioClass?: string;
    uniqueID: string;
    galleryImgs: string[];
}

const GallerySlider2: FC<GallerySlider2Props> = ({
    className = "",
    galleryImgs,
    ratioClass = "aspect-w-4 aspect-h-3",
    uniqueID = "uniqueID",
    imageClassCss = "",
}) => {
    const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const glideRef = useRef<any>(null);

    let MY_GLIDEJS = useMemo(() => {
        return new Glide(`.${UNIQUE_CLASS}`, {
            perView: 1,
            gap: 0,
            keyboard: false,
        });
    }, [UNIQUE_CLASS]);

    useEffect(() => {
        setTimeout(() => {
            glideRef.current = MY_GLIDEJS.mount();
        }, 10);
    }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

    useEffect(() => {
        if (glideRef.current) {
            glideRef.current.go(`=${selectedIndex}`);
        }
    }, [selectedIndex]);

    const handleThumbnailClick = (index: number) => {
        setSelectedIndex(index);
    };

    const renderDots = () => {
        return (
            <div
                className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
                data-glide-el="controls[nav]"
            >
                {galleryImgs?.map((_, i) => (
                    <button
                        className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
                        key={i}
                        data-glide-dir={`=${i}`}
                    />
                ))}
            </div>
        );
    };

    const renderSideIndexImages = () => {
        return (
            <div className="gallery-side space-y-2 overflow-y-scroll">
                {galleryImgs?.map((img, i) => (
                    <button
                        key={i}
                        className={`mb-2 cursor-pointer rounded-md overflow-hidden w-full lg:h-36 md:h-24 h-24 ${selectedIndex === i ? "ring-2 ring-[#233a65]" : ""}`}
                        onClick={() => handleThumbnailClick(i)}
                    >
                        <img src={img} alt={`${i}`} className="object-cover w-full h-full rounded-md" />
                    </button>
                ))}
            </div>
        );
    };

    const renderSliderGallery = () => {
        return (
            <>
                <div className="nc-GallerySlider grid grid-cols-9 gap-4 lg:h-[30rem] max-h-[30rem]  md:h-80 h-full overflow-hidden">
                    <div className={`${UNIQUE_CLASS} sm:col-span-7 col-span-9 gallery-main relative group overflow-hidden rounded-md`}>
                        <div className="glide__track relative inset-0" data-glide-el="track">
                            <ul className="glide__slides">
                                {galleryImgs?.length > 0 && (
                                    <>
                                        {galleryImgs?.map((item, index) => (
                                            <li key={index} className="glide__slide">
                                                <div className={`${ratioClass}`}>
                                                    <NcImage src={`${item}`} className={`object-contain w-full h-full rounded-md sm:rounded-xl`} />
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                )}
                            </ul>
                        </div>
                        {/* DOTS */}
                        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
                        {renderDots()}
                        {/* NAV */}
                        <div className="absolute opacity-100 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between z-40">
                            <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
                        </div>
                        <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-80 transition-opacity'></div>
                    </div>
                    <div className="w-full sm:flex hidden flex-col col-span-2 overflow-hidden">
                        {renderSideIndexImages()}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
            {renderSliderGallery()}
        </div>
    );
};

export default GallerySlider2;
