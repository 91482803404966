import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import { API } from "utils/config";
import image from "../../images/avatars/Image-26.png";

export interface consultantProps {
  id: string;
  consultant?: any;
  // add other properties here
}

const ConsultantCard: FC<consultantProps> = ({ consultant }) => {
  const image = consultant?.userAvatar?.[0]?.url
  return (
    <>
      <div className="col-span-1">
        <Link to={`/consultantProfile/${consultant?._id}`}>
          <div className="border rounded-md py-2 border-gray-300">
            <div className="border-b">
              <div
                className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-2 h-0 overflow-hidden group`}
              >
                <NcImage
                  src={`${image}`}
                  className="w-[120px] h-[120px] m-auto my-[2%] rounded-full"
                />
              </div>
              <h3 className="capitalize text-center text-base font-semibold py-1">
                <abbr title={consultant?.fname} className="no-underline">
                  {consultant?.fname && consultant?.fname.length > 17 ? `${consultant?.fname.slice(0, 17)}...` : consultant?.fname}
                </abbr>
              </h3>
            </div>
            <div className="flex flex-col px-3 pt-3 pb-1">
              <p className="py-[1.5px] font-semibold text-sm tracking-wider">
                Properties:{" "}
                <span className="font-normal text-black dark:text-white">
                  {consultant?.propertiesCount + 32}
                </span>
              </p>
              <p className="py-[1.5px] font-semibold text-sm tracking-wider">
                Languages:{" "}
                <span className="font-normal text-black dark:text-white">
                  {/* {consultant?.languages && consultant?.languages.join(", ")} */}

                  {consultant?.languages?.slice(0, 2).join(", ") + (consultant?.languages?.length > 2 ? ", ...." : "")}

                </span>
              </p>
              <p className="py-[1.5px] font-semibold text-sm tracking-wider">
                Landlords:{" "}
                <span className="font-normal text-black dark:text-white">
                  {consultant?.landlordCount + 21}
                </span>
              </p>
            </div>

            <div className="px-3 -mt-3">
              <button
                className="w-full text-white bg-[#0c4a6e] hover:bg-[#063c5c] focus:ring-none rounded focus:outline-none focus:ring-none font-normal tracking-wider text-md py-1.5 text-center dark:bg-[#0c4a6e] mt-5 dark:hover:bg-[#063c5c] dark:focus:ring-[#ff1616]"
                type="submit"
              >
                View Profile
              </button>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ConsultantCard;
