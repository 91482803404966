import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import { useState } from "react";
// import TwitterLogin from "components/twitterLogin";
import { API as apiHost } from "utils/config";
import { Alert, Box, CircularProgress, Button } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Popup } from "semantic-ui-react";
import GoogleLoginButton from "shared/Header/googlelogin";
import FacebookLoginButton from "shared/Header/facebooklgoin";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  number: string;
  password: string;
};

export interface PageSignUpProps {
  className?: string;
}

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const history = useHistory();
  const [apiError, setApiError] = useState("");
  const [errorType, setErrorType] = useState("");
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const submit = async (data: any) => {
    if (data.email && data.password) {
      const config: any = {
        header: {
          "Content-Type": "application/json",
        },
      };

      try {
        setLoading(true);
        const response = await axios.post(
          `${apiHost}/api/auth/login`,
          { email: data.email, password: data.password },
          config
        );
        if (response.status === 200 && response?.data?.token) {
          localStorage.setItem("authToken", response.data.token);
          const sessionData = {
            token: response.data.token,
            userId: response.data.user
          };

          // Call your session API
          await axios.post(
            `${apiHost}/api/sessions/create`,
            sessionData,
            config
          );

          history.push("/");
          setLoading(false);
        } else if (response.status === 201) {
          if (response?.data?.error === "password or email incorrect") {
            setErrorType("Invalid Credentials");
            setLoading(false);
            setOpen(true);
          } else {
            if (response?.data?.error === "user not exists") {
              setErrorType("No User");
              setOpen(true);
              setLoading(false);
            }
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        // setError(error.response.data.error);
        setTimeout(() => {
          // setError("");
        }, 5000);
      }
    } else {
      setError({ status: true, msg: "All Fields are Required", type: "error" });
      setLoading(false);
      setOpen(true); //call to popup
    }
  };

  const title = errorType ? <> Login Error </> : <Alert>{error.msg}</Alert>;

  const text = errorType ? (
    errorType === "No User" ? (
      <>
        User Does not Exists.<Link to="/signup" style={{ color: '#223A64' }}> Click here to register</Link>.
      </>
    ) : (
      <>Invalid Credentials</>
    )
  ) : null;

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = handleSubmit(submit);

  // console.log(errors);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      {/* {!loading && (
        <Box
          zIndex="1"
          bgcolor="white"
          p={2}
          position="absolute"
          top={"48%"}
          left={"calc(calc(100% - 242px) / 2)"}
          display="flex"
          alignItems="center"
          flexDirection={"column"}
          justifyItems="center"
        >
          <CircularProgress />
        </Box>
      )} */}
      <Popup
        open={open}
        setOpen={setOpen}
        title={title}
        text={text}
        actions={<>
          <Button onClick={handleClose}>Continue</Button>
        </>}
      />

      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {/* {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))} */}
            <GoogleLoginButton />
            <FacebookLoginButton />
            {/* <TwitterLogin /> */}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={onSubmit}>
            <label className="block">
              <Input
                id="email"
                placeholder="Email Adress"
                className="mt-1"
                // {...register("email",{ required:"Email is Reuired"})}
                {...register("email", {
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                onKeyUp={() => {
                  trigger("email");
                }}
                name="email"
              />
              {errors.email && (
                <p className="text-sm text-red-500 ...">Email is Required</p>
              )}
            </label>
            <label className="block">
              <Input
                type="password"
                id="password"
                placeholder="Password"
                className="mt-1"
                {...register("password", { required: "Password is Required" })}
                name="password"
              />
              {errors.password && (
                <p className="text-sm text-red-500 ...">Password is Required</p>
              )}
              <span className="flex justify-between items-center m-2 text-right dark:text-neutral-200">
                <Link to="/Forget" className="text-sm">
                  Forget password?
                </Link>
              </span>
            </label>

            <ButtonPrimary type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} color='info' sx={{ zIndex: 1 }} /> : 'Continue'}
            </ButtonPrimary>

            {error.status ? (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">{error.msg}</span>
              </div>
            ) : (
              ""
            )}

            {/* <Alert severity='error'>{error.msg}</Alert> */}
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Don't have an account? {` `}
            <Link to="/SignUp">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
