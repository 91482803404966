import { Box, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, IconButton, CircularProgress } from "@mui/material";
import React, { useState } from "react";
// import { numberToNotation } from "utils/numberToNotation";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button'
import { ResponsiveTypography } from "components/PlotListing";
import Swal from "sweetalert2";
const PinAddingForm = ({ handleSubmit, open, setOpen }) => {
  const [requestSent, setRequestSent] = useState(false)
  const handleClose = () => {
    setOpen(false);
  };

  const [formValue, setFormValue] = useState({
    plotCat: '',
    plotno: '',
    areaCat: '',
    area: 0,
    price: 0,
    blockNumber: '',
    phase: ''
  })

  const updateFormValue = (key, value) => {
    const updatedValue = {
      ...formValue,
      [key]: value
    }
    setFormValue(updatedValue)
  }


  const addPin = (e) => {
    e.preventDefault();
    const { plotCat, plotno, areaCat, area, price, blockNumber, phase } = formValue
    if (!plotCat || !plotno || !areaCat || !area || !price || !blockNumber || !phase) {
      Swal.fire({
        title: "All Fields are required",
        text: "Please fill out all the fields",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Okay",
        zIndex: 123
      })
    } else {
      setRequestSent(true)
      handleSubmit(formValue)
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="pin-adding-modal"
      aria-describedby="pin-adding-description"
    >
      <DialogTitle id="pin-adding-modal">
        <Box display='flex' justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Plot Addition</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {requestSent ?
          <Box zIndex='1' backgroundColor='white' p={2} position="absolute" top={'48%'} left={'calc(calc(100% - 262px) / 2)'} display='flex' alignItems='center' flexDirection={'column'} justifyCenter='center' >
            <CircularProgress />
            <ResponsiveTypography >Please hold on. Your pin is adding... </ResponsiveTypography>
          </Box>
          : null}
        <form disabled={requestSent} onSubmit={addPin}>
          <Grid container spacing={2} style={{ padding: '8px' }}>
            <Grid item md={12} xs={12} sm={12}>
              <Box width='100%'>
                <TextField
                  type='text'
                  style={{ width: '100%' }}
                  label="Phase"
                  variant="outlined"
                  required
                  onChange={(e) => updateFormValue('phase', e.target.value)}
                  value={formValue.phase} />
              </Box>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="plot-category">Plot Category</InputLabel>
                <Select
                  labelId="plot-category"
                  id="plot-category"
                  value={formValue.plotCat}
                  label="Plot Category"
                  onChange={(e) => updateFormValue('plotCat', e.target.value)}
                >
                  <MenuItem value=''></MenuItem>
                  <MenuItem value={'Residential'}>Residential</MenuItem>
                  <MenuItem value={'Commertial'}>Commertial</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <TextField
                fullWidth
                label="Plot Number"
                variant="outlined"
                required
                onChange={(e) => updateFormValue('plotno', e.target.value)}
                value={formValue.plotno} />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-area">Select Area</InputLabel>
                <Select
                  labelId="select-area"
                  id="select-area"
                  value={formValue.areaCat}
                  label="Select Area"
                  onChange={(e) => updateFormValue('areaCat', e.target.value)}
                >
                  <MenuItem value=''></MenuItem>
                  <MenuItem value='Kanal'>Kanal</MenuItem>
                  <MenuItem value='Marla'>Marla</MenuItem>
                  <MenuItem value='Square foot'>Square foot</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                fullWidth

                label="Area"
                variant="outlined"
                required
                onChange={(e) => {
                  const onlyNum = e.target.value.replace(/[^0-9]/g, '').replaceAll(',', '');
                  updateFormValue('area', onlyNum)
                }
                }
                value={new Intl.NumberFormat().format(parseInt(formValue.area || 0))}

              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <TextField
                fullWidth
                label="Block"
                variant="outlined"
                required
                onChange={(e) => updateFormValue('blockNumber', e.target.value)}
                value={formValue.blockNumber}
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Box height='78px'>
                <TextField
                  fullWidth

                  label="Price"
                  variant="outlined"
                  type='text'
                  onChange={(e) => {
                    const onlyNum = e.target.value.replace(/[^0-9]/g, '').replaceAll(',', '');
                    updateFormValue('price', onlyNum)
                  }
                  }
                  value={new Intl.NumberFormat().format(parseInt(formValue.price || 0))}

                  required
                />
                {/* {
                  formValue.price ? <Typography variant='subtitle2' textAlign={'center'} >
                    {numberToNotation(parseFloat(formValue.price), 1)} PKR
                  </Typography> : null
                } */}
              </Box>
            </Grid>

            <Grid item md={12} xs={12} sm={12}>
              <Box>
                <Button disabled={requestSent} size='large' variant="contained" type="submit" fullWidth>
                  Submit
                </Button>
              </Box>
            </Grid>

          </Grid>


        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>

      </DialogActions>
    </Dialog>

  );
};

export default PinAddingForm;
