import { Popover } from '@headlessui/react';
import AgentMoreFilter from 'components/HeroSearchForm/AgentMoreFilter';
import { CareerPageMobileFilters } from 'components/HeroSearchForm/CareerPageMobileFilters';
import SelectCityOptionInput from 'components/HeroSearchForm/SelectCityOptionInput';
import TimeTypeOptionInput from 'components/HeroSearchForm/TimeTypeoptionInput';
import pinContext from 'context/pin/pinContext';
import React, { FC, Fragment, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Transition } from 'semantic-ui-react';
import CareerCard, { CareerCardProps } from './CareerCard';

export interface CareerPageProps {
    className?: string;
}

// const DEMO_Career: CareerCardProps["data"][] = [
//     {
//         name: "React Front-End Developer",
//         location: "Lahore",
//         department: "Software Engineering",
//         timeType: "Full Time",
//     },
//     {
//         name: "PHP Developer",
//         location: "Rawalpindi",
//         department: "Software Engineering",
//         timeType: "Full Time",
//     },
//     {
//         name: "Laravel Developer",
//         location: "Quetta",
//         department: "Software Engineering",
//         timeType: "Full Time",
//     },
//     {
//         name: "Graphics Designer",
//         location: "Swat",
//         department: "Designer",
//         timeType: "Full Time",
//     },
//     {
//         name: "QA",
//         location: "Lahore",
//         department: "Software Engineering",
//         timeType: "Full Time",
//     },

// ];

const CareerPage: FC<CareerPageProps> = ({ className = "", }) => {

    // type QuizParams = {
    //     id: any;
    // };
    // let { id } = useParams<any>();
    // console.log(id, 'id')
    const { careers, getAllJobs, loading, setLoading  } = useContext(pinContext)
    useEffect(() => {
        getAllJobs()
    }, []);
    useEffect(() => {
        console.log(careers, "careers")
    }, [careers])
    
    return (
        <>
            <div className="container">
                <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-3 gap-2 py-2">
                    <div className="lg:col-span-2 md:col-span-1 col-span-2">
                        <div className="filter-search-form relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg viewBox="0 0 20 20" aria-hidden="true" className="pointer-events-none absolute inset-y-0 h-full w-5 fill-slate-500 transition">
                                    <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
                                </svg>
                            </div>
                            <input className="placeholder:text-slate-800 block bg-white py-3 !h-12 w-full border border-gray-300 rounded-md pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-500 focus:ring-1 sm:text-sm"
                                placeholder="Search by name ..." type="text" name="search" />
                        </div>
                    </div>
                    <div className="relative col-span-1 !h-12 bg-white dark:bg-slate-800 border border-gray-300 rounded-md lg:block md:block hidden">
                        <SelectCityOptionInput />
                    </div>
                    <div className="relative col-span-1 lg:block md:block hidden">
                        <input className="placeholder:text-slate-800 block bg-white py-3 !h-12 w-full border border-gray-300 rounded-md pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-500 focus:ring-1 sm:text-sm"
                            placeholder="Department" type="text" name="search" />
                    </div>
                    <div className="relative col-span-1 !h-12 bg-white dark:bg-slate-800 border border-gray-300 rounded-md lg:block md:block hidden">
                        <TimeTypeOptionInput />
                    </div>
                    <div className="col-span-1 block lg:hidden md:hidden">
                        <CareerPageMobileFilters className='' ButtonText='More Filters' />
                    </div>
                </div>
            </div>
            <hr />
            <div className="py-10 text-center">
                <p className="text-[2.1rem] pb-1 tracking-wide font-semibold">Work with us</p>
                <span className="text-lg tracking-wide font-medium">(Choose a job you love, and you will never have to work a day in your life.)</span>
            </div>
            <div className="container">
                {careers?.items?.map((item:any,index:any) => (
                    <CareerCard key={index} data={item} />
                ))}
            </div>
        </>
    )
}

export default CareerPage