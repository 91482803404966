import { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { API as apiHost } from "utils/config";
import Swal from "sweetalert2";
import { createContext } from "react";
import { Type } from "typescript";
const updateCurrentUrl = ({ value }: any) => {
  const nextURL = `/plot-finder/?openedMap=${value}`;
  const nextTitle = "";
  const nextState = { additionalInformation: "" };
  // This will create a new entry in the browser's history, without reloading
  window.history.pushState(nextState, nextTitle, nextURL);
};

const PinContextInitial = {
  pins: {},
  getPins: () => { },
  addPin: () => { },
  onSearch: () => { },
  getMe: () => { },
  updateUserStatus: () => { },
  getallUser: () => { },
  userData: {},
  deletePin: () => { },
  users: [],
  loading: false,
  setLoading: () => { },
  firstLoad: false,
  pageDetails: {},
  setFirstLoad: () => { },
  currentPin: "",
  currentUser: {},
  detailsModalOpen: false,
  setCurrentUser: () => { },
  clickedMarker: () => { },
  setDetailsModalOpen: () => { },
  getCurrentMap: () => { },
  mapChangeHandler: () => { },
  filter: {},
  dispatchFilter: () => { },
  applyFilters: () => { },
  getBlog: () => { },
  getSingleBlogById: () => { },
  getByConsultantId: () => { },
  blogs: [],
  showBanner: false,
  setShowBanner: () => { },
};

type IpinContext = keyof typeof PinContextInitial;

export const PinContext = createContext<IpinContext | any>(PinContextInitial);

function reducer(state: any, action: any) {
  switch (action.type) {
    case "updateProperty":
      debugger;
      return { ...state, plotCat: action.value };
    case "updateArea":
      return { ...state, areaCat: action.value };
    case "updatePrice":
      return { ...state, price: action.value };
    case "decrement":
      return { count: state.count - 1 };

    default:
      throw new Error();
  }
}

const initialState: any = {
  price: [0, 0],
};

const PinProvider = (props: any) => {
  const token = localStorage.getItem("authToken");
  // const [loading, setLoading] = useState(false)
  const [pins, setPins] = useState([]);
  const [userData, setUser] = useState("");
  const [singleBlog, setSingleBlog] = useState({});
  const [singleConsultant, setSingleConsultant] = useState({});
  const [filter, dispatchFilter] = useReducer(reducer, initialState);
  const [showBanner, setShowBanner] = useState(false);
  // map changing logic
  const [pageDetails, setPageDetails] = useState({
    text: "Imlaak Plot Listing Site",
    value: "",
    mapName: "Phase 1 and 2",
  });

  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [currentPin, setCurrentPin] = useState({});
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [dropDownphases, setDropDownphases] = useState([]);

  const applyFilters = () => {
    getPins(filter);
  };

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 1500);
  }, []);

  const clickedMarker = (data: any, user: any) => {
    setCurrentPin(data);
    setCurrentUser(user);
    setDetailsModalOpen(true);
  };

  const mapChangeHandler = (value: any) => {
    setLoading(true);
    setPageDetails(value);
    updateCurrentUrl(value);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const getCurrentMap = (data: any) => {
    if (window.location.pathname.includes("plot-finder")) {
      console.log("home page");
      const activeMap = new URLSearchParams(window.location.search).get(
        "openedMap"
      );
      let updatedMapView;
      if (activeMap) {
        // debugger
        updatedMapView = data?.[0].lahore.filter(
          (item: any) => item.imageName === activeMap
        );
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[1].bahawalpur.filter(
            (item: any) => item.imageName === activeMap
          );
        }
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[2].multan.filter(
            (item: any) => item.imageName === activeMap
          );
        }
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[3].gujrawala.filter(
            (item: any) => item.imageName === activeMap
          );
        }
        if (updatedMapView?.length === 0) {
          updatedMapView = data?.[0].lahore;
        }
      } else {
        updatedMapView = data?.[0].lahore;
        // debugger
      }
      mapChangeHandler({
        text: updatedMapView?.[0].imageLabel,
        value: updatedMapView?.[0].imageName,
        image: updatedMapView?.[0].imageUrl,
      });
    }
  };

  // end map changing logic

  //fetch all note
  const getPins = async (query: any) => {
    //todo api call
    //API CALL
    const filters = JSON.stringify(query);
    const response = await axios.get(`${apiHost}/api/pins/getAllPins`, {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${tk}`
      },
      params: { filters: filters },
    });
    setPins(response.data);
    return response;
  };

  const onSearch = async (text: any) => {
    if (text === "") {
      getPins({});
    }
    if (text !== "") {
      let filterPins = pins.filter((pin: any) => pin.plotno.includes(text));
      setPins(filterPins);
    }
  };

  //add a PIN
  const addPin = async (pinData: any) => {
    const tk = localStorage.getItem("authToken");
    const response = await fetch(`${apiHost}/api/pins/createPin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(pinData),
    });
    const pin = await response.json();
    if (response.status === 200) {
      Swal.fire({
        title: "Pin addition",
        text: "You have successfully added a ",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }
    return pin;
  };

  //DELETE PIN
  const deletePin = async (pinData: any) => {
    const tk = localStorage.getItem("authToken");
    const response = await fetch(`${apiHost}/api/pins/deletePin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(pinData),
    });
    const pin = await response.json();
    return pin;
  };

  //
  const getMe = async () => {
    setLoading(true);
    const tk = localStorage.getItem("authToken");
    if (tk) {
      try {
        const response = await axios.get(`${apiHost}/api/auth/finduser`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tk}`,
          },
        });

        if (response.status === 404) {
          localStorage.removeItem("authToken");
          setUser("");
          setLoading(false);
        } else if (response.status === 200) {
          const user = await response.data;
          if (user.role === "landlord" && !user.agencyDetails) {
            debugger;
            setShowBanner(true);
          }
          setUser(user);
          setLoading(false);
          return user;
        }
      } catch (e) {
        setLoading(false);
        // localStorage.removeItem("authToken");
        // setUser('')
      }
    }
  };

  const updateUserStatus = async (id: any, data: any) => {
    try {
      const tk = localStorage.getItem("authToken");

      await axios.post(
        `${apiHost}/api/auth/updateUserStatus`,
        { userId: id, status: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getallUser();
    } catch (err) {
      console.log(err);
    }
  };
  const [users, setusers] = useState([]);

  const getallUser = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/auth/getAllUsers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // if (response.status === 401) {
      // 	// setusers({ error: 'you are not authorize for this operation' })
      // }
      const alluser = await response.data;
      setusers(alluser.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getPhaseforDropdown = async () => {
    try {
      const dropdownPhases = await axios.get(
        `${apiHost}/api/phase/getPhaseforDropdown`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(dropdownPhases.data, "dropdownPhases");
      setDropDownphases(dropdownPhases.data.items);
      getCurrentMap(dropdownPhases.data.items);
    } catch (err) {
      console.log(err);
    }
  };

  const [blogs, setBlogs] = useState([]);
  const [pageNumber, setPageNumber] = useState(0)
  console.log("🚀 ~ file: PinContext.tsx:327 ~ PinProvider ~ pageNumber:", pageNumber)
  const [perPage, setPerPage] = useState(7)
  const [blogsCount, setBlogsCount] = useState(0)

  const getBlog = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`https://imlaak.com/wp-json/wp/v2/posts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // params: {
        //   // offset: pageNumber,
        //   limit: perPage
        // }
      })
      const allBlog = await response.data
      setBlogs(allBlog.blogs)
      console.log("PinContext Blo:gs", allBlog.blogs)
      setBlogsCount(allBlog.count)
      setLoading(false)

    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!loading) {
      getBlog();
    }
  }, [pageNumber]);

  const getSingleBlogById = async (id: any) => {
    try {
      const response = await axios.get(`${apiHost}/api/blog/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const singleBlog = await response.data;
      setSingleBlog(singleBlog.blog);
    } catch (err) {
      console.log(err);
    }
  };

  const value = {
    pins,
    getPins,
    addPin,
    onSearch,
    getMe,
    updateUserStatus,
    getallUser,
    userData,
    deletePin,
    users,
    loading,
    setLoading,
    pageNumber,
    setPageNumber,
    perPage,
    setPerPage,
    blogsCount,
    setBlogsCount,
    firstLoad,
    pageDetails,
    setFirstLoad,
    currentPin,
    currentUser,
    detailsModalOpen,
    setCurrentUser,
    clickedMarker,
    setDetailsModalOpen,
    getCurrentMap,
    mapChangeHandler,
    filter,
    dispatchFilter,
    applyFilters,
    getPhaseforDropdown,
    dropDownphases,
    getBlog,
    blogs,
    getSingleBlogById,
    singleBlog,
    showBanner,
    setShowBanner,
  };

  return (
    <PinContext.Provider value={value}>{props.children}</PinContext.Provider>
  );
};
export default PinProvider;
