import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';


export default function QuestionsModal({ openQ, setOpenQ, callback }) {
  const [questionOne, setQuestionOne] = useState(null);
  const [questionTwo, setQuestionTwo] = useState(false);

  // const handleForm = () => {
  //   setOpenQ(true);
  // };

  const handleClose = () => {
    setOpenQ(false);
  };


  const questionTwoHandler = () => {
    setQuestionTwo('yes')
    if (questionOne === 'yes') {
      callback()
    }
    setOpenQ(false)
  }
  return (
    <div>
      {/* <PopupQ2 openQ2={openQ2} setOpenQ2={setOpenQ2} /> */}


      <Dialog
        open={openQ}
        onClose={handleClose}
      >
        <DialogTitle >
          <Box display='flex' justifyContent="center" alignItems="center">
            <Typography sx={{
              fontSize: '18px',
            }} variant="h6" align='justify' color='primary'>Please Answer The Questions.
              {' '}
              {
                !questionOne && !questionTwo ? '1/2' : '2/2'
              }</Typography>
            <IconButton style={{ maxWidth: '100px', position: 'relative', top: '-13px', left: '20px', color: '#b82018' }} onClick={() => setOpenQ(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>



          <DialogContentText >
            {!questionOne && !questionTwo ?
              <Box sx={{ m: 2 }} textAlign="center">
                <p><strong>Is This Your Plot ?</strong></p>
                <Box display='flex' direction="row" mt={2} justifyContent="center" spacing={2} >
                  <Button variant='contained' color="success" onClick={() => setQuestionOne('yes')} >Yes</Button>
                  <Button variant='contained' color='warning' onClick={() => setOpenQ(false)}>No</Button>
                </Box>
              </Box> : null}
            {
              questionOne === 'yes' && !questionTwo ?

                <Box sx={{ m: 2 }} textAlign="center">
                  <p><strong>Do You Want to List it For Sale?</strong></p>
                  <Box display='flex' direction="row" mt={2} justifyContent="center" spacing={2}>
                    <Button variant='contained' color="success" onClick={questionTwoHandler}>Yes</Button>
                    <Button variant='contained' color='warning' onClick={() => setOpenQ(false)}>No</Button>
                  </Box>
                </Box>
                : null
            }

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button variant="outlined" onClick={handleForm}>Not sure</Button> */}
        </DialogActions>
      </Dialog>

    </div>
  );
}