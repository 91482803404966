import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory } from "react-router-dom";
import { Fragment, useRef, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { API as apiHost } from "utils/config";
import { CircularProgress, Box } from "@mui/material";
import axios from "axios";
import GoogleLoginButton from "shared/Header/googlelogin";
import FacebookLoginButton from "shared/Header/facebooklgoin";
import Swal from "sweetalert2";
import pinContext from "context/pin/pinContext";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "./signup.css";
import "react-phone-input-2/lib/style.css";


type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  number: string;
  password: string;
};

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [showhide, setShowhide] = useState("no");
  const handleshow = (e: any) => {
    const getshow = e.target.value;
    setShowhide(getshow);
  };

  const [loader, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    control,
    getValues,
    setValue,
    watch,
  } = useForm();

  console.log(useForm, "useFormdata");
  const { getMe }: any = React.useContext(pinContext);
  let history = useHistory();

  const [contact, setContact] = useState();

  // console.log(setContact, "contactno");

  const onSubmit = async (data: any) => {
    const config: any = {
      header: {
        "Content-Type": "application/json",
      },
    };
    // console.log(data, "dataofreg");
    if (!data.password) {
      setTimeout(() => { }, 5000);
      return console.log("no data in SignUp");
    } else {
      setLoading(true);
      axios
        .post(
          `${apiHost}/api/auth/register`,
          {
            fname: data.fname,
            email: data.email,
            password: data.password,
            contact: data.phone,
            role: 'landlord',
            isVerified: false,
            status: 'pending',
          },
          config
        )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              title: "Email sent!",
              text: "Please verify your email on clicking the link we have sent on your email!",
              icon: "success",
              confirmButtonColor: "#345886",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/login";
              }
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    reset();
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      {loader && (
        <Box
          zIndex="1"
          color="white"
          p={2}
          position="absolute"
          top={"48%"}
          left={"calc(calc(100% - 262px) / 2)"}
          display="flex"
          alignItems="center"
          flexDirection={"column"}
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          SignUp
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            <GoogleLoginButton />
            <FacebookLoginButton />
          </div>
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            {/* <div
              className={`mt-3 bg-white border rounded-md py-3`}
              style={{ position: "relative" }}
            >
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`text-neutral-500 w-full flex px-4 justify-between focus:outline-none text-sm dark:text-gray-400 ${open ? "" : ""
                        }`}
                    >
                      <span className="">Signing up as*</span>
                      <span>
                        <i className="las la-angle-down"></i>
                      </span>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-300"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-250"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel
                        className="z-50 absolute shadow-xl mt-4 rounded-md border bg-white w-full"
                      >
                        <div className="pt-2 pb-5 px-3">
                          <div className="mt-2 px-4">
                            <input
                              type="radio"
                              value="Landlord"
                              name="role"
                              className="text-[#345886] focus:outline-none focus:ring-0 cursor-pointer"
                              onClick={handleshow}
                              onChange={({ target }) => {
                                setValue("role", target.value);
                              }}
                            />
                            <span className="text-sm">
                              &nbsp; Landlord
                            </span>
                          </div>
                          <div className="mt-2 px-4">
                            <input
                              type="radio"
                              value="buyer/tenant"
                              name="role"
                              className="text-[#345886] focus:outline-none focus:ring-0 cursor-pointer"
                              onClick={handleshow}
                              onChange={({ target }) => {
                                setValue("role", target.value);
                              }}
                            />
                            <span className="text-sm">
                              &nbsp; Buyer/Tenant
                            </span>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div> */}
            <label className="block">
              <Input
                type="text"
                className={`border-1 rounded mt-2 ${errors.fname && "invalid"}`}
                placeholder="Name*"
                {...register("fname", {
                  required: "Name is required",
                })}
                onKeyUp={() => {
                  trigger("fname");
                }}
              />
              {errors.fname && (
                <small className="text-[#ff1616]">Name is required</small>
              )}
            </label>

            <Controller
              name="phone"
              control={control}
              rules={{ required: "Phone number is required." }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  inputStyle={{
                    border: "1px solid #eee",
                    width: "100%",
                    height: "43px",
                  }}
                  containerStyle={{
                    marginTop: "12px",
                  }}
                  country={"pk"}
                  value={value}
                  onChange={(phone) => {
                    console.log(phone);
                    setValue("", phone);
                    onChange(phone);
                  }}
                  inputProps={{
                    onBlur: () => {
                      trigger("");
                    },
                  }}
                />
              )}
            />
            {errors.phone && (
              <small className="text-[#ff1616]">Phone number is required</small>
            )}

            <Input
              type="email"
              className={`border-1 rounded mt-2 ${errors.email && "invalid"}`}
              placeholder="Email*"
              {...register("email", {
                required: "Email is Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              onKeyUp={() => {
                trigger("email");
              }}
            />
            {errors.email && (
              <small className="text-[#ff1616]">Invalid email address</small>
            )}

            <Input
              type="password"
              className={`border-1 rounded mt-2 ${errors.password && "invalid"
                }`}
              placeholder="Password*"
              {...register("password", {
                required: "Password is required",
              })}
              onKeyUp={() => {
                trigger("password");
              }}
            />
            {errors.password && (
              <small className="text-[#ff1616]">Password is required</small>
            )}

            <button
              type="submit"
              className="text-white text-xl cursor-pointer mt-2"
              style={{ width: "100%" }}
            >
              <ButtonPrimary className="w-full">
                {loader ? (
                  <CircularProgress
                    size={24}
                    color="error"
                    sx={{ zIndex: 1 }}
                  />
                ) : (
                  "Register"
                )}
              </ButtonPrimary>
            </button>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
