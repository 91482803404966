import { Link } from "react-router-dom";

const Gallary = () => {
  const RecentEvents = [
    {
      id: 1,
      title: "GUESTS",
      img: "https://Imlaak.pk/wp-content/uploads/2023/02/Post-23-900x700.jpg",
    },

    {
      id: 2,
      title: "GUESTS",
      img: "https://Imlaak.pk/wp-content/uploads/2023/02/Post-23-900x700.jpg",
    },
    {
      id: 3,
      title: "GUESTS",
      img: "https://Imlaak.pk/wp-content/uploads/2023/02/Post-23-900x700.jpg",
    },
    {
      id: 4,
      title: "GUESTS",
      img: "https://Imlaak.pk/wp-content/uploads/2022/12/Untitled-design-Copy-1089x700.jpg",
    },
    {
      id: 5,
      title: "GUESTS",
      img: "https://Imlaak.pk/wp-content/uploads/2022/12/Untitled-design-Copy-1089x700.jpg",
    },
    {
      id: 6,
      title: "GUESTS",
      img: "https://Imlaak.pk/wp-content/uploads/2022/12/Untitled-design-Copy-1089x700.jpg",
    },
  ];

  return (
    <>
      {/* <!-- Gallery --> */}
      <section className="overflow-hidden text-neutral-700 bg-red-100">
        <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
          <h1 className="text-center text-4xl font-bold tracking-tight text-slate-900 py-5 ">
            Recent Events
          </h1>
          <div className="-m-1 flex flex-wrap md:-m-2">
            {RecentEvents.map((event: any, index: any) => (
              <div className="flex w-1/3 flex-wrap">
                <div key={index} className="w-full p-1 md:p-2">
                  <Link to={`/event-detail/${event.id}`}>
                    <img
                      alt="gallery"
                      className="block h-full w-full saturate-150 rounded-lg object-cover object-center"
                      src={event.img}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="overflow-hidden text-neutral-700 py-5 bg-red-100">
        <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
          <h1 className="text-center text-4xl font-bold tracking-tight text-slate-900 py-5 ">
            Upcoming Events
          </h1>
          <div className="-m-1 flex flex-wrap md:-m-2">
            {RecentEvents.map((event: any, index: any) => (
              <div className="flex xl:w-1/2  md:w-1/2 w-1/1  flex-wrap">
                <div key={index} className="w-full p-1 md:p-1">
                  <Link
                    to={`/event-detail/${event.id}`}
                    className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                    <img
                      className="object-cover w-full rounded-t-lg h-96 md:h-52 object-cover md:w-48  md:rounded-none md:rounded-l-lg"
                      src={event.img}
                      alt=""
                    />
                    <div className="flex flex-col justify-between p-4 leading-normal">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Event Title 2021
                      </h5>
                      <p className="mb-2 font-normal text-gray-700 line-clamp-3 dark:text-gray-400">
                        <i className="las la-map-marked px-1 text-xl font-bold"></i>
                        Gulberb, Lahore
                        <br></br>
                        <i className="las la-calendar px-1 text-xl font-bold"></i>
                        25 Oct 2023
                        <br></br>
                        <i className="las la-at px-1  font-bold "></i>
                        sales@Imlaak.pk
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallary;
