import React, { FC, useContext, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import Navigation2 from "shared/Navigation2/Navigation2";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import pinContext from "context/pin/pinContext";
import { Alert, Container, Link } from "@mui/material";
import Swal from "sweetalert2";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const { showBanner, setShowBanner } = useContext(pinContext);

  useEffect(() => {
    if (showBanner) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Profile",
        text: "You have logged in as a landlord and your profile is incomplete.",
        footer:
          '<a href="http://imlaak-dashboard.vercel.app" target="_blank" className="text-[#345886] font-semibold">Click here to complete your profile</a>',
        confirmButtonColor: "#c33833",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setShowBanner(false);
          // window.location.reload(); // reload the page
        }
      });
    }
  }, [showBanner]);

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
    >
      <div className="container-fluid px-3 py-3 relative flex justify-between items-center space-x-1 xl:space-x-1  bg-white dark:bg-neutral-900 backdrop-blur-2xl bg-opacity-70 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700">
        <div className="hidden lg:block xl:block flex d-flex justify-start flex-grow px-10 space-x-1 sm:space-x-1 2xl:space-x-1">
          <Navigation />
        </div>
        <Logo />
        <div className="flex d-flex justify-end flex-grow space-x-1 px-10 sm:space-x-1 2xl:space-x-1">
          <Navigation2 />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <SwitchDarkMode />
          </div>
          <div className="flex items-center lg:hidden xl:hidden">
            {/* <SwitchDarkMode /> */}

            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
