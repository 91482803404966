import React from "react";
// import FacebookLogin from 'react-facebook-login';
import { Redirect } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import facebookSvg from "images/Facebook.svg";



const FacebookLoginButton = () => {
  const responseFacebook = (data) => {
    console.log("ihsan khan");
    console.log(data);
    // debugger;

  };
  const componentClicked = () => {
    console.log(componentClicked)
  }
  return (

    <FacebookLogin
      appId="945499022825118"
      autoLoad={false}
      fields="name,email,picture"
      onClick={componentClicked}
      callback={responseFacebook}
      render={renderProps => (
        <button
          className="text-center text-white rounded py-2.5 w-full bg-[#4267B2] hover:bg-[#375aa1]"
          onClick={renderProps.onClick}>
          <i className="lab la-facebook-f text-lg"></i> &nbsp;
          Continue with Facebook</button>
      )}
    />
  );
};

export default FacebookLoginButton;
