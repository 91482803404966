import pinContext from "./pinContext";
import { useEffect, useState } from "react";
import axios from "axios";
import { API as apiHost } from "../../utils/config";
import Swal from "sweetalert2";
const PinProvider = (props) => {
  const token = localStorage.getItem("authToken");
  const [loading, setLoading] = useState(false);
  const [pins, setPins] = useState([]);
  const [userData, setUser] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [dropDownphases, setDropDownphases] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [singleBlog, setSingleBlog] = useState({});
  const [singleConsultant, setSingleConsultant] = useState({});
  const [singleAgent, setSingleAgent] = useState({});
  const [singleDeveloper, setSingleDeveloper] = useState({});
  const [singleJob, setSingleJob] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [perPage, setPerPage] = useState();
  const [blogsCount, setBlogsCount] = useState(0);
  // const [agenciesCount, setConsultantsCount] = (0);
  const [showBanner, setShowBanner] = useState(false);

  const [filters, setFilters] = useState({ fname: '', city: '', languages: [] });

  const getBlog = async () => {
    try {
      setLoading(true);
      // const response = await axios.get(`https://imlaak.com/wp-json/wp/v2/posts`, {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      //   params: {
      //     offset: pageNumber,
      //     limit: perPage,
      //   },
      // });
      // const allBlog = await response.data;
      // // console.log(allBlog, "All PinState Blogs");
      // setBlogs(allBlog.blogs);
      // setBlogsCount(allBlog.count);
      // setLoading(false);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (!loading) {
      getBlog();
    }
  }, [pageNumber]);

  const getSingleBlogById = async (id) => {
    try {
      const response = await axios.get(`${apiHost}/api/blog/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const singleBlog = await response.data;
      setSingleBlog(singleBlog.blog);
    } catch (err) {
      console.log(err);
    }
  };

  //fetch all note
  const getPins = async (query) => {
    //todo api call
    //API CALL
    const filters = JSON.stringify(query);
    const response = await axios.get(`${apiHost}/api/pins/getAllPins`, {
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${tk}`
      },
      params: { filters: filters },
    });
    setPins(response.data);
    return response;
  };

  const onSearch = async (text) => {
    if (text === "") {
      getPins();
    }
    if (text !== "") {
      let filterPins = pins.filter((pin) => pin.plotno.includes(text));
      setPins(filterPins);
    }
  };

  //add a PIN
  const addPin = async (pinData) => {
    const tk = localStorage.getItem("authToken");
    const response = await fetch(`${apiHost}/api/pins/createPin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(pinData),
    });
    const pin = await response.json();
    if (response.status === 200) {
      Swal.fire({
        title: "Pin addition",
        text: "You have successfully added a ",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }
    return pin;
  };

  //DELETE PIN
  const deletePin = async (pinData) => {
    const tk = localStorage.getItem("authToken");
    const response = await fetch(`${apiHost}/api/pins/deletePin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(pinData),
    });
    const pin = await response.json();
    return pin;
  };

  //DELETE blog
  const deleteBlog = async (blogData) => {
    const tk = localStorage.getItem("authToken");
    const response = await fetch(`${apiHost}/blog/deleteBlog`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(blogData),
    });
    const blogs = await response.json();
    return blogs;
  };

  const getMe = async () => {
    setLoading(true);
    const tk = localStorage.getItem("authToken");
    if (tk) {
      try {
        const response = await axios.get(`${apiHost}/api/auth/finduser`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tk}`,
          },
        });

        if (response.status === 404) {
          localStorage.removeItem("authToken");
          setUser("");
          setLoading(false);
        } else if (response.status === 200) {
          const user = await response.data;
          // if (user.role === "landlord" && !user.agencyDetails) {
          //   setShowBanner(true);
          // }

          setUser(user);
          setLoading(false);
          return user;
        }
      } catch (e) {
        setLoading(false);
        // localStorage.removeItem("authToken");
        // setUser('')
      }
    }
  };

  // const getMe = async () => {
  //   setLoading(true)
  //   const { accessToken } = JSON.parse(
  //     localStorage.getItem("persist:accessToken") || "{}"
  //   )
  //   const accessToken1 = accessToken.replaceAll('"', "")
  //   debugger
  //   if (accessToken1) {
  //     try {
  //       const response = await axios.get(`${apiHost}/api/auth/finduser`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${accessToken1}`,
  //         },
  //       })
  //       if (response.status === 404) {
  //         localStorage.removeItem("accessToken")
  //         setUser("")
  //         setLoading(false)
  //       } else if (response.status === 200) {
  //         const user = await response.data
  //         setUser(user)
  //         console.log(user, "user a gya hai")
  //         setLoading(false)
  //         return user
  //       }
  //     } catch (e) {
  //       setLoading(false)
  //       // localStorage.removeItem("accessToken");
  //       // setUser('')
  //     }
  //   }
  // }

  const updateUserStatus = async (id, data) => {
    try {
      const tk = localStorage.getItem("authToken");

      await axios.post(
        `${apiHost}/api/auth/updateUserStatus`,
        { userId: id, status: data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getallUser();
    } catch (err) {
      console.log("error is there");

      console.log(err);
    }
  };

  const getEvaluation = async () => {
    try {
      const tk = localStorage.getItem("authToken");

      const allEvaluation = await axios.get(
        `${apiHost}/api/evaluation/getEvaluations`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: { limit: 20, offset: 0 },
        }
      );
      console.log(allEvaluation, "allEvaluation");
      setEvaluation(allEvaluation);
    } catch (err) {
      console.log("error is there");

      console.log(err);
    }
  };

  const carryOutEvaluation = async (data) => {
    try {
      const tk = localStorage.getItem("authToken");

      const allEvaluation = await axios.post(
        `${apiHost}/api/evaluation/carryOutEvaluation`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(allEvaluation, "allEvaluation");
      setEvaluation(allEvaluation);
    } catch (err) {
      console.log("error is there");

      console.log(err);
    }
  };

  const getPhaseforDropdown = async (callback) => {
    try {
      const dropdownPhases = await axios.get(
        `${apiHost}/api/phase/getPhaseforDropdown`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(dropdownPhases.data, "dropdownPhases")
      // debugger
      setDropDownphases(dropdownPhases.data.items);
      callback(dropdownPhases.data.items);
    } catch (err) {
      // console.log("error is there");

      console.log(err);
    }
  };

  const [users, setusers] = useState([]);

  const getallUser = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/auth/getAllUsers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        // setusers({ error: 'you are not authorize for this operation' })
      }
      const alluser = await response.data;
      setConsultants(alluser.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [consultants, setConsultants] = useState([]);

  const getConsultants = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/auth/getAllConsultants`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const allUsers = response?.data;
      const consultants = allUsers.data.filter(user => user?.role === "consultant");
      setConsultants(consultants);
    } catch (err) {
      console.log(err);
    }
  };

  const [landlords, setLandlords] = useState([]);
  const getLandlords = async () => {
    try {
      const response = await axios.get(`${apiHost}/api/auth/getAllAgents`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const allUsers = response?.data;
      setLandlords(allUsers);
    } catch (err) {
      console.log(err);
    }
  };

  const [consultantsData, setConsultantsData] = useState([]);
  const getAllConsultants = async (query) => {
    const filters = JSON.stringify(query);
    const response = await axios.get(`${apiHost}/api/auth/getAllConsultants`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: { filters: filters },
    });
    const resData = response?.data?.data
    const consultants = resData?.filter((row) => row?.email !== 'consultant@imlaak.com')
    setConsultantsData(consultants);

    return response;
  };

  const getByConsultantId = async (id) => {
    try {
      const response = await axios.get(
        `${apiHost}/api/auth/getByConsultantId/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const singleData = await response?.data?.results?.[0];
      setSingleConsultant(singleData);
    } catch (err) {
      console.log(err);
    }
  };

  const [consultantListedProperties, setConsultantListedProperties] = useState([]);
  const getConsultantListedProperties = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiHost}/api/inventory/getConsultantListedProperties/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const singleData = await response.data;
      setLoading(false);
      setConsultantListedProperties(singleData);
    } catch (err) {
      console.log(err);
    }
  };

  const getSingleAgentById = async (id) => {
    try {
      const response = await axios.get(`${apiHost}/api/auth/getByAgentId/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const agentdata = await response.data;
      setSingleAgent(agentdata);
    } catch (err) {
      console.log(err);
    }
  };

  const getsingleDeveloperById = async (id) => {
    try {
      const response = await axios.get(`${apiHost}/api/developer/getByDeveloperId/${id}`, {
        header: {
          "Content-Type": "applicaton/json",
        },
      });
      const singledata = await response?.data?.developer;
      setSingleDeveloper(singledata);
    } catch (err) {
      console.log(err)
    }
  }

  const getByCareerId = async (id) => {
    try {
      const response = await axios.get(`${apiHost}/api/career/getByCareerId/${id}`, {
        header: {
          "Content-Type": "applicaton/json",
        },
      });
      const singledata = await response.data;
      setSingleJob(singledata);
    } catch (err) {
      console.log(err)
    }
  }



  const [developers, setDeveloper] = useState([])

  const getAllDevelopers = async () => {
    if (developers.length === 0) {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiHost}/api/developer/getAllDevelopers`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //  console.log(response,'responsedeveloper')
        const developerdata = response.data;
        setDeveloper(developerdata);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };


  const [careers, setCareers] = useState([])
  const getAllJobs = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiHost}/api/career/getAllJobs`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response, 'responsedeAllJob')
      const careerdata = response.data;
      setCareers(careerdata);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const [approveAgencies, setApproveAgencies] = useState([])
  const getAllApproveAgencies = async () => {
    if (approveAgencies.length === 0) {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiHost}/api/auth/getAllConsultants`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //  console.log(response,'responsedeveloper')
        const agenciesdata = response.data;
        setApproveAgencies(agenciesdata);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };


  const [agentByAgency, setAgentByAgency] = useState([])
  const getAllAgentByAgency = async (id) => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${apiHost}/api/auth/getAllAgentByAgency/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      //  console.log(response,'responsedeveloper')
      const agentdata = response.data
      // console.log(agentdata, "agentdata")
      setAgentByAgency(agentdata)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  // //fetch all properties
  // const getMyProperties = async (query) => {
  //   //todo api call
  //   //API CALL
  //   const filters = JSON.stringify(query)
  //   const response = await axios.get(
  //     `${apiHost}/api/project/getAllListedProperties`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       params: { filters: filters },
  //     }
  //   )
  //   setProperties(response.data)
  //   return response
  // }

  return (
    <pinContext.Provider
      value={{
        pins,
        getPins,
        addPin,
        onSearch,
        getMe,
        perPage,
        setPerPage,
        pageNumber,
        setPageNumber,
        blogsCount,
        setBlogsCount,
        updateUserStatus,
        getAllConsultants,
        consultantsData,
        getConsultantListedProperties,
        consultantListedProperties,
        getallUser,
        consultants,
        getConsultants,
        userData,
        deletePin,
        filters,
        setFilters,
        users,
        consultants,
        loading,
        setLoading,
        getEvaluation,
        evaluation,
        setEvaluation,
        carryOutEvaluation,
        getPhaseforDropdown,
        dropDownphases,
        // getBlog,
        // blogs,
        // getSingleBlogById,
        // singleBlog,
        showBanner,
        setShowBanner,
        getByConsultantId,
        getSingleAgentById,
        singleConsultant,
        singleAgent,
        singleDeveloper, setSingleDeveloper,
        singleJob, setSingleJob,
        getsingleDeveloperById,
        getByCareerId,
        getAllDevelopers,
        developers,
        getAllApproveAgencies,
        approveAgencies,
        getAllAgentByAgency,
        agentByAgency,
        getAllJobs,
        careers,

        // landlords
        getLandlords,
        landlords,
        setLandlords,
      }}
    >
      {props.children}
    </pinContext.Provider>
  );
};
export default PinProvider;
