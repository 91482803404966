import { Star } from "heroicons-react";
import moment from "moment";
import React, { FC, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { API } from "utils/config";
import { FaStar } from "react-icons/fa";

export interface ConsultantProfileTabProps {
  id: string;
  consultant?: any;
  propertyCount?: any;
}

const ConsultantProfileTab: FC<ConsultantProfileTabProps> = ({
  consultant,
  propertyCount,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const consultantData = consultant
  return (
    <>
      <div className="text-left">
        <div className="text-xl font-semibold">About</div>
        <div className="py-1.5">
          <span className="font-semibold text-sm">Languages: </span>
          <span className="text-sm capitalize">
            {" "}
            {consultantData?.languages &&
              consultantData?.languages.join(", ")}
          </span>
        </div>
        {/* <div className=" py-1.5">
          <span className="font-semibold text-sm">Specialities: </span>
          <span className="text-sm capitalize"> Real Estate</span>
        </div> */}
        <div className="py-1.5">
          <span className="font-semibold text-sm">Specialities : </span>
          <span className="text-sm capitalize">
            {consultantData?.specialties &&
              consultantData?.specialties.join(", ")}
          </span>
        </div>
        <div className="py-1.5">
          <span className="font-semibold text-sm">Properties : </span>
          <span className="text-sm capitalize">{consultant?.propertiesCount} Properties </span>
        </div>
        <div className="py-1.5">
          <span className="font-semibold text-sm">Description : </span>
          <span className="text-sm text-justify">
            {/* {consultantData?.description} */}
            {expanded ? consultantData?.description : (consultantData?.description?.length > 150 ? consultantData?.description.slice(0, 150) + "..." : consultantData?.description)}
          </span>
          {consultantData?.description?.length > 150 && (
            <button
              className="text-[#223a64] font-bold"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
        <div className="py-1.5">
          <span className="font-semibold text-sm">Experience : </span>
          <span className="text-sm text-justify">
            {consultantData?.experience}+ Years
          </span>
        </div>

        {consultantData?.reviews?.length > 0 && (
          <div className="py-2.5 lg:mt-8 md:mt-8 mt-5">
            <div className="text-xl font-semibold">Reviews</div>
            <div className="grid grid-cols-1 gap-5 mt-5">
              {consultantData?.reviews?.map((items: any, index: any) => (
                <div className="col-span-1" key={index}>
                  <div className="py-2">
                    <div className="flex justify-between align-middle">
                      <div className="flex justify-start py-1">
                        <NcImage src={`${items?.reviewerDetails?.userAvatar?.[0]?.url}`} className="rounded-full lg:w-[4vw] md:w-[4vw] w-[15vw] lg:h-[4vw] md:h-[4vw] h-[15vw] border-2 border-[#263c67]" />
                        <div className="pl-4">
                          <h5 className="text-base tracking-tight text-grey dark:text-white leading-9 capitalize">
                            {/* {items?.reviewerDetails?.fname} */}
                            {items?.reviewerDetails?.fname?.length > 8 ? items?.reviewerDetails?.fname.slice(0, 8) + "..." : items?.reviewerDetails?.fname}
                          </h5>
                          <span className="text-[12.5px] text-gray-400">
                            {moment(items?.createdAt).format("DD MMM YYYY")}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center align-middle mt-2.5 mb-5">
                        {[...Array(5)].map((_, starIndex) => (
                          <FaStar
                            key={starIndex}
                            size={20}
                            color={starIndex < items.rating ? "#263c67" : "#e4e5e9"}
                            style={{ cursor: 'pointer', transition: 'color 200ms', margin: '0px 2px' }}
                          // className="dark:text-white"
                          />
                        ))}
                        <span className="ml-2 font-normal text-gray-900 dark:text-white">{items.rating.toFixed(1)}</span>
                      </div>
                    </div>
                    <div className="pt-4">
                      <p className="mb-3 font-normal dark:text-gray-400 text-sm">{items?.content}</p>
                    </div>
                  </div>
                </div>
              ))}


            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConsultantProfileTab;
