import LanguageInput from "components/HeroSearchForm/LanguageInput";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ConsultantMobileFilter } from "./ConsultantMobileFilter";
import ConsultantsNameInput from "components/HeroSearchForm/ConsultantsNameInput";
import CityInput from "components/HeroSearchForm/CityInput";
import { BuyRentContext } from "context/property/BuyRentContext";
import pinContext from "context/pin/pinContext";

const ConsultantFilter = ({
  filter,
  filterCity,
  filterLang,
  filteredDataCons,
}: any) => {
  const { consultants, getConsultants } = useContext(pinContext);

  const [name, setName]: any = useState("");
  const [city, setCity]: any = useState("");
  const [language, setLanguage]: any = useState("");

  // Callback function to receive data from the child
  const handleName = (data: any) => {
    setName(data);
    filter(data);
  };
  const handleCity = (data: any) => {
    setCity(data);
    filterCity(data);
  };
  const handleLanguage = (data: any) => {
    setLanguage(data);
    filterLang(data);
  };
  const [values, setValues]: any = useState("");

  // Refreshes the current page
  const handleRefreshClick = () => {
    const filer = consultants.filter((data: any) => data?.fname === name && data?.address === city || data?.languages.includes(language));
    filteredDataCons(filer)
  };
  useEffect(() => {
    getConsultants();
  }, []);
  return (
    <>
      <div className="lg:px-20 md:px-10 px-5 py-4 grid-cols-6 lg:grid md:grid hidden gap-2">
        <div className="col-span-2 relative rounded-md">
          <ConsultantsNameInput cName={handleName} />
        </div>
        <div className="col-span-2 relative rounded-md">
          <CityInput city={handleCity} />
        </div>
        <div className="col-span-2 relative rounded-md">
          <LanguageInput lang={handleLanguage} />
        </div>
        <div className="col-span-1 filter-search-form relative filter-border">
          <button
            onClick={handleRefreshClick}
            className="hidden btn bg-[#0c4a6e] hover:bg-[#093f5e] cursor-pointer text-center text-white submit-btn w-full py-3 rounded-md"
          >
            View All
          </button>
        </div>
      </div>
      <div className="px-5 py-2.5 grid grid-cols-3 gap-2 lg:hidden md:hidden">
        <div className="col-span-2 relative rounded-md">
          <CityInput city={handleCity} />
        </div>
        <div className="col-span-1">
          <ConsultantMobileFilter ButtonText="Filter" />
        </div>
      </div>
    </>
  );
};

export default ConsultantFilter;
