import React, { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { API as apiHost } from "utils/config"
import axios from "axios"
import FacebookLoginButton from "./facebooklgoin"
import GoogleLoginButton from "./googlelogin"
import { CircularProgress } from "@mui/material"
import Swal from "sweetalert2"

import Input from "shared/Input/Input"
import pinContext from "context/pin/pinContext"
const LoginForm = ({
  afterChange,
  onClickForget,
  forgetColor,
  rememberColor,
  freeMemberClass,
  NewToImlaakClass,
  loginBtnClass,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  })
  const { getMe } = React.useContext(pinContext)
  let history = useHistory()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      history.push("/")
    }
  }, [history])
  const handleSubmit = async (e) => {
    e.preventDefault()
    const config = {
      header: {
        "Content-Type": "apllication/json",
      },
    }
    try {
      setLoading(true)
      const response = await axios.post(
        `${apiHost}/api/auth/login`,
        { email, password },
        config
      )
      setLoading(false)
      if (response.status === 200 && response?.data.token) {
        Swal.fire({
          title: "Welcome to Imlaak!",
          text: "Account successfully Login",
          type: "warning",
          confirmButtonColor: "#223A64",
          confirmButtonText: "OK",
        })

        setError({
          status: true,
          msg: "Login Success",
          type: "bg-teal-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
        })
        localStorage.setItem("authToken", response.data.token)
        getMe()
        history.push("/")
        setLoading(false)
      } else if (response.status === 201) {
        if (response?.data?.error === "password or email incorrect") {
          setError({
            status: true,
            msg: "password or email incorrect",
            type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
          })
        } else {
          if (response?.data.error === "user not exists") {
            setError({
              status: true,
              msg: "user not exists",
              type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
            })
          }
        }
      }
    } catch (error) {
      console.log(error)
      setError(error.response.data.error)
      setTimeout(() => {
        setError("")
      }, 2000)
    }
  }
  return (
    <>
      <div className=''>
        <div className='text-center text-white rounded py-2.5 w-full bg-[#4267B2]'>
          <FacebookLoginButton />
        </div>
        <div className='text-center mt-2 text-black rounded  w-full'>
          <GoogleLoginButton />
        </div>
        <div className={`text-center text-${rememberColor} mt-2`}>OR</div>
        <div className='mt-2'>
          {/* FORM */}
          <form onSubmit={handleSubmit}>
            <Input
              showLabel={false}
              type='email'
              placeholder='email@domain.com'
              className='rounded focus:border-gray-300'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              showLabel={false}
              type='password'
              className='rounded mt-3 outline-hidden focus:border-gray-300'
              placeholder='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='mt-3'>
              <button
                type='submit'
                className='font-semibold'
                disabled={loading}
                style={{ width: "100%" }}
              >
                <div
                  className={`text-center bg-[#223A64] p-2.5 rounded text-white font-semibold ${loginBtnClass}`}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      color='error'
                      sx={{ zIndex: 1 }}
                    />
                  ) : (
                    "Continue"
                  )}
                </div>
              </button>
              {error.status ? (
                <div className={error.type} role='alert'>
                  <p className='font-bold'>{error.msg} </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
          <span
            className={`flex justify-between mt-2 text-${rememberColor} dark:text-neutral-200`}
          >
            <span className='cursor-pointer'>
              {" "}
              <input
                type='radio'
                className='text-xs text-[#223A64] focus:border-none outline-none cursor-pointer'
              />{" "}
              &nbsp;Remember Me
            </span>
            <button
              type='button'
              className={`text-sm font-normal text-${forgetColor}`}
              onClick={onClickForget}
            >
              Forgot password?
            </button>
          </span>

          <div
            className={`text-center text-${rememberColor} ${NewToImlaakClass} text-base mt-3`}
          >
            <span>New to Imlaak?</span>
          </div>
          <div
            className={`mt-3 bg-white py-2.5 rounded text-center hover:bg-[#223A64] ${freeMemberClass} cursor-pointer`}
            onClick={() => {
              history.push("/signup")
              afterChange()
            }}
          >
            <div>Become a Free Member</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginForm
