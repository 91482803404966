import React, { useEffect, useState, Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import GuestsInput, { GuestsInputProps } from "./GuestsInput"
import { FC } from "react"
import { Item } from "semantic-ui-react"

export interface ResidentialOptionProps {
  haveDefaultValue?: boolean
}
export interface ResidentialOption {
  tabs?: string[]
  haveDefaultValue?: boolean
  handleProjectType?: any
  filter?: any
  subCategory?: any
}

const ResidentialOption: FC<ResidentialOption> = ({
  haveDefaultValue,
  handleProjectType,
  filter,
  subCategory,
}) => {
  const [ResidentialInput, setResidentialInput] = useState<any>(
    filter?.subCategory
  )
  const renderResidentialInput = () => {
    return (
      <div className='pb-2 grid grid-cols-2 gap-2'>
        {subCategory.map(({ value, label }: any) => (
          <div
            className={`w-full py-1 col-span-1 px-0 rounded-full text-center text-xs cursor-pointer my-1 ${ResidentialInput === value
                ? "bg-[#223A64] font-semibold text-white"
                : "border border-neutral-300 dark:border-neutral-700 text-[#223A64]"
              }`}
            onClick={(e) => {
              {
                setResidentialInput(value)
                handleProjectType(value)
              }
            }}
          >
            {label}
          </div>
        ))}
      </div>
    )
  }
  const renderForm = () => {
    return (
      <div className='px-1 -mt-2 font-medium'>{renderResidentialInput()}</div>
    )
  }

  return renderForm()
}

export default ResidentialOption
