import { useContext, useEffect, useState } from "react";
import { BuyRentContext } from "context/property/BuyRentContext"
import pinContext from "context/pin/pinContext";
var data = require("../../data/jsons/__cityNames.json");

export default function ConsultantsNameInput({ cName }) {
    const [value, setValue] = useState("");


    const { consultants, getConsultants } = useContext(pinContext);
    useEffect(() => {
        getConsultants();

    }, []);
    const { dispatchFilter, setCityName, applyFilters } = useContext(BuyRentContext)

    const onChange = (event) => {
        setValue(event.target.value);

    };

    const onSearch = (searchTerm) => {
        setValue(searchTerm);
        cName(searchTerm);
    };

    return (
        <>
            <div className="">
                <div className="search-container">
                    <div className="search-inner">
                        <div className="filter-search-form relative">
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#345886" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                            </div>
                            <input className="dark:bg-slate-800 bg-white text-base !h-12 w-full border border-slate-300 dark:border-0 dark:text-white rounded-md pl-5 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-0 sm:text-sm"
                                placeholder="Enter Consultant Name" type="text" value={value} onChange={onChange} />
                            <button onClick={() => onSearch(value)}></button>
                        </div>

                    </div>
                    <div className="absolute bg-white w-full z-40 shadow-xl rounded-xl mt-2 dropdown">
                        {consultants
                            .filter((item) => {
                                const searchTerm = value.toLowerCase();
                                const fullName = item.fname.toLowerCase();

                                return (
                                    searchTerm &&
                                    fullName.startsWith(searchTerm) &&
                                    fullName !== searchTerm
                                );
                            })
                            .slice(0, 10)
                            .map((item) => (
                                <div
                                    onClick={() => onSearch(item.fname)}
                                    className="dropdown-row px-5 border-b-[2px] capitalize cursor-pointer dark:text-[#0c4a6e] border-[#0c4a6e] rounded-xl py-2 bg-white hover:bg-gray-100"
                                    key={item._id}
                                >
                                    {item.fname}
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}
