import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import appleSvg from "images/Apple.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import axios from "axios";
import { API as apiHost } from '../../utils/config'
import { Button, Alert } from '@mui/material';
import { Popup } from "semantic-ui-react";
import Swal from "sweetalert2";

export interface PageForgetProps {
  className?: string;
}
const PageForget: FC<PageForgetProps> = ({ className = "" }) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const Submit = async (e: React.ChangeEvent<any>) => {
    e.preventDefault()
    try {

      const response = await axios.post(
        `${apiHost}/api/auth/forgotpassword`,
        { email: value }
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Password Reset.",
          text: "An email with reset password link has been sent to your gmail. Please follow the link.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "",
          confirmButtonText: "OK",
        })
        window.location.href = '/';
        // setOpen(true)
      }
    } catch (error) {
      if (error instanceof Error) {

        setError(error.message);
      }
      setValue("");
      setTimeout(() => {
        setError("");
      }, 5000);


    }
  }

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Popup
        open={open}
        setOpen={setOpen}
        title={'Email Sent'}
        text={'An email with reset password link has been sent to your gmail. Please follow the link.'}
        actions={<>
          <Button onClick={handleClose}>Continue</Button>
        </>}
      />
      <Helmet>
        <title>Forget</title>
      </Helmet>
      <div className="container mb-40 mt-40 lg:mb-32">
        <h2 className="my-10  flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forget Password
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">


          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={Submit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200 ">
                Don't worry, happens to us all.
                <p className="p-2">We'll send you a <strong>One-Time Password</strong> (OTP)</p>
              </span>
              <Input
                value={value}
                type="email"
                placeholder="Email *"
                className="mt-1"
                onChange={(e) => setValue(e.target.value)}
                required
              />

            </label>
            {error ? <Alert severity="error">Plz enter valid Email</Alert> : ""}

            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>


        </div>
      </div>
    </div>
  );
};

export default PageForget;
