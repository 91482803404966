import Input from "shared/Input/Input"
import Swal from "sweetalert2"
import { CircularProgress } from "@mui/material"
import FacebookLoginButton from "shared/Header/facebooklgoin"
import React, { Fragment, useEffect, useState } from "react"
import { API as apiHost } from "utils/config"
import axios from "axios"
import { useHistory } from "react-router-dom"
import pinContext from "context/pin/pinContext"
import Forget from "shared/Header/Forget"
import LoginForm from "shared/Header/LoginForm"

const LoginModule = ({ afterChange, setShowModalLogin, showModalLogin }) => {
  const [toggleState, setToggleState] = useState(1)
  const toggleTab = (index) => {
    setToggleState(index)
  }

  // start Login modal function
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  })
  const { getMe } = React.useContext(pinContext)
  let history = useHistory()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  // useEffect(() => {
  //   if (localStorage.getItem("authToken")) {
  //     history.push("/");
  //   }
  // }, [history]);

  const validateForm = () => {
    if (email === "") {
      setError({
        status: true,
        msg: "Email is required",
        type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
      })
      return false
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError({
        status: true,
        msg: "Invalid Email",
        type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
      })
      return false
    } else if (password === "") {
      setError({
        status: true,
        msg: "Password is required",
        type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
      })
      return false
    }
    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Validate email and password before making a request
    if (!email) {
      setError({
        status: true,
        msg: "Email is required",
        type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
      })
      return
    }

    if (!password) {
      setError({
        status: true,
        msg: "Password is required",
        type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
      })
      return
    }

    // Show loading spinner
    setLoading(true)

    try {
      const response = await axios.post(`${apiHost}/api/auth/login`, {
        email,
        password,
      })

      if (response.status === 200 && response?.data.token) {
        Swal.fire({
          title: "Welocome to Imlaak!",
          text: "Account successfully Login",
          icon: "success",
          confirmButtonColor: "#e74c3c",
        })
        setError({
          status: true,
          msg: "Login Success",
          type: "bg-teal-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
        })
        setShowModalLogin(false)
        localStorage.setItem("authToken", response.data.token)
        getMe()
        history.push("/")
      } else if (response.status === 201) {
        if (response?.data?.error === "password or email incorrect") {
          setError({
            status: true,
            msg: "password or email incorrect",
            type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
          })
        } else {
          if (response?.data.error === "user not exists") {
            setError({
              status: true,
              msg: "user not exists",
              type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
            })
          }
        }
      }
    } catch (error) {
      setError({
        status: true,
        msg: "Something went wrong, please try again later",
        type: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mt-2",
      })
    } finally {
      setLoading(false)
    }
  }

  // End Login modal function

  return (
    <div>
      {/* START LOGIN MODAL */}
      {showModalLogin ? (
        <>
          <div className='w-[1200px] z-50'>
            <div className='fixed inset-0 bg-zinc-800 bg-opacity-90 transition-opacity'></div>
            <div className='flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='w-auto z-40 my-6 mx-auto max-w-3xl'>
                <div className='border-t-4 border-[#223A64] rounded-md shadow-lg relative flex flex-col w-80 bg-white outline-none focus:outline-none'>
                  <div className={toggleState === 1 ? "block p-4" : "hidden"}>
                    <button
                      className='bg-transparent border-0 text-black absolute top-0 right-0 m-0.5'
                      onClick={() => setShowModalLogin(false)}
                    >
                      <span className='text-black opacity-7 h-8 w-8 text-xl block py-0'>
                        <i className='las la-times text-[#345886] text-2xl'></i>
                      </span>
                    </button>
                    <div className='mt-4'>
                      <LoginForm
                        rememberColor='black'
                        forgetColor='blue-400'
                        loginBtnClass='hover:bg-[#1f345c]'
                        freeMemberClass='hover:text-white text-[#223A64] border border-[#223A64] text-[14px] font-semibold'
                        NewToImlaakClass='hidden'
                        onClickForget={() => toggleTab(2)}
                      />
                    </div>
                  </div>
                  <div className={toggleState === 2 ? "block p-4" : "hidden"}>
                    <Forget
                      inputColor='black'
                      buttonColor='black'
                      onclickBtn={() => toggleTab(1)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default LoginModule
