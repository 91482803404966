import React, { useState, useContext, useEffect } from "react";
import "./mapform.css";

import { PinContext } from "services/context/pin/PinContext";
import { Button, Link } from "@mui/material";
import Popup from "../Popup/Popup";
import PinsPopupModal from "../Models/pinDetailsModel";
import PinAddingForm from "../form/Form";
import QuestionsModal from "../popupQuestion/PopupQ";
import './styles.css'
import Leaflet from "../Leaflet";

const MapForm = ({ mapImage, pageDetails, pinAdding, currentUser, currentPin, setCurrentUser, detailsModalOpen, setDetailsModalOpen, clickedMarker, setFirstLoad }) => {
  const [mapEvent, setMapEvent] = useState({})
  const [openQ, setOpenQ] = useState(false)
  const { value: phase } = pageDetails || {};
  const [open, setOpen] = useState(false);
  const { getPins, addPin, userData, deletePin } =
    useContext(PinContext);
  const [userError, setUserError] = useState(false);
  const [mapPins, setMapPins] = useState([]);
  const [plotFormOpen, setPlotFormOpen] = useState(false)

  const [getMeCalled, setGetMeCalled] = useState(false);

  const getCurrentLoggedInUser = async () => {
    if (!currentUser?.email && !getMeCalled) {
      console.log(userData, 'userData')
      setGetMeCalled(true);
      setCurrentUser(userData);
      return userData;
    }
    return currentUser
  };
  useEffect(() => {
    setTimeout(() => { setUserError(false) }, 60000)
  }, [userError])
  useEffect(() => {
    getCurrentLoggedInUser();
  }, []);

  const deletePinFunction = async (id) => {
    await deletePin({ pinId: id });
    await fetchPins();
    setDetailsModalOpen(false)
  };

  const callback = () => {
    setPlotFormOpen(true)
  }
  const fetchPins = async () => {
    const filters = { map: phase };
    const res = await getPins(filters);
    setMapPins(res.data);
  };

  useEffect(() => {
    if (mapImage) {
      // loadMap();
      fetchPins();
    }
  }, [mapImage]);

  const showForm = async (mapE) => {
    // _hideForm();
    const authToken = await localStorage.getItem('authToken')
    if (userData && authToken) {
      if (userData.isVerified) {
        if (userData.status === "approved") {
          if (userData.role === 'employee' || userData.role === 'admin') {
            setMapEvent(mapE);
            setPlotFormOpen(true)
          }
          else {
            setOpenQ(true);
          }
        } else {
          setUserError(true);
          setOpen(true);

          return false;
        }
      } else {
        setOpen(true);
      }
    } else {
      setOpen(true);
      return false;
    }
    // inputPlotRef.current.focus();
  };

  const handleSubmit = async (values) => {
    const { lat, lng } = mapEvent.latlng;
    const newPin = {
      ...values,
      coordinates: [lat, lng],
      fname: userData.fname,
      lname: userData.lname,
      contact: userData.contact,
      email: userData.email,
      map: phase,
    };
    // pinAdding(true);
    // setFirstLoad(true)
    // setReLoadMap(true)
    await addPin(newPin);

    await fetchPins()
    setPlotFormOpen(false)
    // setReLoadMap(false)
    // pinAdding(false);
  };
  const sendMail = () => {
    const url = `mailto:
          faisalseraj47@gmail.com?subject=I want to update the status of my account.
          &body=%20My account is in ${userData.status} how can this be updated?`;
    window.open(url, "popup", "width=600,height=600");
  };

  const handleRegister = () => {
    window.location.href = "/register"
  };

  const handleLogin = () => {
    window.location.href = "/login"
  };

  const title = !localStorage.getItem('authToken') ? 'You are not Logged in to Imlaak' : !userData?.isVerified ? <> Your Account is not Verfied. You cannot add a pin. </> : userError ? `Your Profile is in ${userData.status} state. You cannot add a pin` : 'You Are Not Authorized'
  const text = !localStorage.getItem('authToken') ? 'Please Login First to Add a Plot on Socity Map' : !userData?.isVerified ?
    <a href='/verification' style={{ fontSize: '15px' }}>
      Click here to verify your account
    </a> :
    userError ? <>
      Please contact{" "}
      <Link onClick={sendMail} sx={{ color: "blue !important" }}>
        Imlaak Customer Support.
      </Link></> : null
  return (
    <>
      {
        plotFormOpen ?
          <PinAddingForm open={plotFormOpen} setOpen={setPlotFormOpen} handleSubmit={handleSubmit} />
          : null
      }

      {
        openQ ?
          <QuestionsModal openQ={openQ} setOpenQ={setOpenQ} callback={callback} />
          : null
      }
      {detailsModalOpen ? (
        <PinsPopupModal
          open={detailsModalOpen}
          setOpen={setDetailsModalOpen}
          details={currentPin}
          user={userData}
          deletePin={deletePinFunction}
        />
      ) : null}
      <Popup
        open={open}
        setOpen={setOpen}
        title={title}
        text={text}
        actions={<>
          {
            !localStorage.getItem('authToken') ? <>
              <Button onClick={handleRegister}>Register</Button>
              <Button onClick={handleLogin} autoFocus>
                Login
              </Button>
            </> :
              <Button onClick={() => setOpen(false)} >
                close
              </Button>

          }
        </>}
      />
      <Leaflet
        mapImage={mapImage}
        mapPins={mapPins}
        showForm={(data) => { }}
        currentUser={userData}
        clickedMarker={clickedMarker}
        deletePin={deletePinFunction}
      />

    </>
  );
};
export default MapForm;
