import React, {
  FC,
  useState,
  ReactNode,
  Fragment,
  useContext,
  useEffect,
} from "react";
import StayCardH from "components/StayCardH/StayCardH";
import { Popover, Transition } from "@headlessui/react";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/BuyRentPagination/BuyRentPagination";
import HeaderFilter from "./HeaderFilter";
import { StayDataType } from "data/types";
import ResidentialCommercialFilter from "components/HeroSearchForm/ResidentialCommercialFilter";
import BedsEstimateFormInput from "components/HeroSearchForm/BedsEstimateFormInput";
import BathEstimateFormInput from "components/HeroSearchForm/BathEstimateFormInput";
import PriceRangeInput from "components/HeroSearchForm/PriceRangeInput";
import BuyRentEstimateFilter from "components/HeroSearchForm/BuyRentEstimateFilter";
import Imlaak from "../../images/logos/favicon_white.svg";
import { BuyRentContext } from "context/property/BuyRentContext";
import AgentMoreFilter from "components/HeroSearchForm/AgentMoreFilter";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

export interface RentPageProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const RentPage: FC<RentPageProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Properties for Rent in Pakistan",
  subHeading = "",
  headingIsCenter,
  tabs = ["All", "Furnished", "Unfurnished"],
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  const {
    getMyProperties,
    properties,
    loading,
    perPage,
    setPerPage,
    pageNumber,
    setPageNumber,
  }: any = useContext(BuyRentContext);
  useEffect(() => {
    // debugger
    getMyProperties();
  }, []);
  //   useEffect(() => {
  console.log(properties, "properties");
  //   }, [properties])

  return (
    <>
      <div className="container my-2">
        <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-4 gap-2">
          <div className="lg:col-span-2 md:col-span-1 col-span-2">
            <div className="filter-search-form relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </div>
              <input
                className="placeholder:text-slate-800 block bg-white py-3 !h-12 w-full border border-gray-300 rounded-md pl-9 pr-3 shadow-sm focus:outline-none focus:border-gray-300 focus:ring-gray-500 focus:ring-1 sm:text-sm"
                placeholder="Enter Keyword"
                type="text"
                name="search"
              />
            </div>
          </div>
          <div className="relative col-span-1">
            <div className="bg-white rounded-md dark:bg-slate-800 py-3 border !h-12 border-gray-300">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={` ${
                        open
                          ? "text-black focus:outline-none w-full px-3 flex justify-between"
                          : "px-3 flex justify-between w-full"
                      }`}
                    >
                      <div className="flex justify-between w-full">
                        <button className="inline-block align-middle lg:text-base md:text-sm text-xs">
                          Property Type
                        </button>
                        <i className="las la-caret-down lg:mt-1 md:mt-1 mt-1 inline-block align-middle"></i>
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute bg-white dark:bg-neutral-800 overflow-hidden p-4 z-50 mt-5 lg:-ml-3 md:-ml-3 -ml-36 lg:w-96 md:w-96 w-[22.5rem] rounded-xl shadow-xl border">
                        <ResidentialCommercialFilter />
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
          <div className="relative col-span-1 !h-12 bg-white py-3 dark:bg-slate-800 border border-gray-300 rounded-md lg:block md:block hidden">
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={` ${
                      open
                        ? "relative text-black focus:outline-none w-full flex justify-between"
                        : "flex justify-between w-full"
                    }`}
                  >
                    <div className="flex justify-between w-full px-3">
                      <button className="inline-block align-middle lg:text-base md:text-sm text-base">
                        Beds & Baths
                      </button>
                      <i className="las la-caret-down lg:mt-1 md:mt-1 sm:mt-1 inline-block align-middle h-full"></i>
                    </div>
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute pb-4 bg-white dark:bg-neutral-800 z-50 mt-4 lg:w-96 md:w-96 w-[22.5rem] rounded-md shadow-xl border">
                      <div className="pt-2 px-2">
                        <span className="px-5">Beds</span>
                        <BedsEstimateFormInput />
                      </div>
                      <div className="px-2">
                        <span className="px-5">Baths</span>
                        <BathEstimateFormInput />
                      </div>
                      <div className="flex justify-between px-3 mt-2">
                        <button className="rounded-md bg-white text-center text-[#223A64] border-2 border-[#223A64] mx-1 w-6/12">
                          RESET
                        </button>
                        <button className="rounded-md bg-[#223A64] text-center mx-1 w-6/12 py-2 text-white">
                          DONE
                        </button>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
          <div className="col-span-1 lg:block md:block hidden">
            <div className="relative rounded-md border text-center border-gray-300 z-20">
              <PriceRangeInput />
            </div>
          </div>
          <div className="col-span-1 block lg:hidden md:hidden relative">
            <AgentMoreFilter />
          </div>
        </div>
      </div>

      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          <div className="">
            <div className=" relative flex min-h-screen">
              {/* CARDSSSS */}
              <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
                {/* <Heading2 /> */}
                <div className="mb-8 lg:mb-3">
                  <HeaderFilter
                    tabActive={"All"}
                    subHeading={subHeading}
                    tabs={tabs}
                    heading={heading}
                    onClickTab={() => {}}
                  />
                </div>

                <div className="grid grid-cols-1 gap-8">
                  {properties?.property?.map((item: any) => (
                    <>
                      {loading ? (
                        <div role="status" className="grid grid-cols-1 gap-8">
                          <div className="col-span-1 p-4 animate-pulse md:p-6 mr-4">
                            <div className="grid grid-cols-2">
                              <div className="col-span-1 flex items-center w-64 justify-center h-48 mb-4 bg-gray-300 rounded">
                                <svg
                                  className="w-12 h-12 text-gray-200 dark:text-gray-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 640 512"
                                >
                                  <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                                </svg>
                              </div>
                              <div className="col-span-1 flex flex-col justify-start">
                                <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                                <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                                <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                                <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                                <div className="h-2 bg-gray-200 rounded-full mt-7"></div>
                              </div>
                            </div>
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={item.id}
                          onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                          onMouseLeave={() => setCurrentHoverID((_) => -1)}
                        >
                          <StayCardH data={item} />
                        </div>
                      )}
                    </>
                  ))}
                </div>
                <div className="flex mt-16 mb-10 justify-center items-center">
                  <nav>
                    <ul className="inline-flex items-center -space-x-px">
                      <li>
                        <button
                          onClick={() => setPageNumber(pageNumber - 1)}
                          className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 border bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-[#223A64] dark:hover:border-[#223A64] hover:bg-[#e31414] dark:hover:bg-[#e31414]"
                          disabled={pageNumber === 0}
                        >
                          <i className="las la-angle-left text-xl"></i>
                        </button>
                      </li>
                      <Pagination />
                      <li>
                        <button
                          onClick={() => setPageNumber(pageNumber + 1)}
                          className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 border bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-[#223A64] dark:hover:border-[#223A64] hover:bg-[#e31414] dark:hover:bg-[#e31414]"
                        >
                          <i className="las la-angle-right text-xl"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {/* MAPPPPP */}
              <div
                className={`xl:flex-grow xl:static xl:block ${
                  showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
                }`}
              >
                {showFullMapFixed && (
                  <ButtonClose
                    onClick={() => setShowFullMapFixed(false)}
                    className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="block px-5 flex-grow mt-14 lg:mt-0">
          <div className="sticky top-24">
            <div className="grid md:grid-cols-1 aspect-w-8 aspect-h-4 lg:grid-cols-1 mb-5 gap-4 h-64">
              <div className="rounded-xl bg-[url('https://cdn4.vectorstock.com/i/1000x1000/50/68/house-background-vector-745068.jpg')] bg-cover  bg-wreap bg-center">
                <div className="rounded-xl">
                  <a href="#" className="block p-6 max-w-sm rounded-md">
                    <h5 className="mb-2 text-2xl font-bold text-white tracking-wide">
                      Experience Search 2.0
                    </h5>
                    <p className="font-normal text-lg text-white py-10 mb-20">
                      Find homes by commute time
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-1 aspect-w-8 aspect-h-4 lg:grid-cols-1 mb-5  gap-4 ">
              <div className="relative px-2 flex-grow mt-14 lg:mt-8">
                <div className="top-24">
                  <div className="rounded-lg bg-[#F2003C] text-center pt-7 pb-6 shadow-md">
                    <h5 className="text-2xl font-semibold text-center text-white">
                      <img
                        src={Imlaak}
                        className="w-10 ml-12 -mt-3 absolute"
                        alt=""
                      />
                      Imlaak.pk
                    </h5>
                    <form className="pt-5">
                      <input
                        type="text"
                        className="bg-white rounded outline-none border-0 w-10/12"
                        placeholder="Name"
                      />
                      <input
                        type="email"
                        className="mt-3 bg-white rounded outline-none border-0 w-10/12"
                        placeholder="Email"
                      />
                      <button className="mt-5 bg-white shadow-lg text-black py-1.5 px-7 rounded-xl hover:bg-white hover:text-[#223A64] hover:font-semibold">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RentPage;
