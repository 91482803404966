// FilterPage.js
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Autocomplete from 'components/HeroSearchForm/Autocomplete';
import { ProjectContext } from 'context/project/ProjectContext';
import ProjectCompletionDate from 'components/HeroSearchForm/ProjectCompletionDate';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ResidentialCommercialFilter from 'components/HeroSearchForm/ResidentialCommercialFilter';
import NewPriceRangeInput from 'components/HeroSearchForm/NewPriceRangeInput';
import MultiSelectPropertyTypes from './MultiSelectPropertyTypes';

interface Project {
    _id: string;
    name: string;
}
const propertyTypeOptions = [
    { value: 'residential', label: 'Residential' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Serviced Apartments', label: 'Serviced Apartments' },
    { value: 'Hotel Rooms', label: 'Hotel Rooms' },
    { value: 'PentHouse', label: 'PentHouse' },
    { value: 'Hotel Apartments', label: 'Hotel Apartments' },
    { value: 'Condominiums', label: 'Condominiums' },
    { value: 'commercial', label: 'Commercial' },
    { value: 'Office', label: 'Office' },
    { value: 'Shop', label: 'Shop' },
    { value: 'Buildings', label: 'Buildings' },
    { value: 'Showroom', label: 'Showroom' },
];
const ProjectFiltersPage: React.FC = () => {
    const { getAllProjectsData, projectsData, setFilters, resetFilters, filters }: any = useContext(ProjectContext);
    const [rangePrices, setRangePrices] = useState<[number, number]>(filters?.priceRange || [0, 990000000]);
    const [propertyType, setPropertyType] = useState<string[]>(filters.propertyType || []);
    const [cityInput, setCityInput] = useState('');
    const [projectNameInput, setProjectNameInput] = useState('');
    const [developerInput, setDeveloperInput] = useState('');
    useEffect(() => {
        getAllProjectsData();
    }, []);

    const nameSuggestions = projectsData?.map((project: any) => ({
        _id: project?._id?.toString(),
        name: project?.projectName?.projectName
    }));

    const developerSet = new Set<string>();
    const developerSuggestions: Project[] = [];
    projectsData.forEach((project: any, index: any) => {
        if (!developerSet.has(project?.developer?.name)) {
            developerSet.add(project?.developer?.name);
            developerSuggestions.push({ _id: index.toString(), name: project?.developer?.name });
        }
    });

    const citySet = new Set<string>();
    const citySuggestions: Project[] = [];
    projectsData.forEach((project: any, index: any) => {
        if (!citySet.has(project?.city)) {
            citySet.add(project?.city);
            citySuggestions.push({ _id: index.toString(), name: project?.city });
        }
    });

    const handlePriceChange = (newRange: [number, number]) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            priceRange: newRange
        }));
    };

    const handleCompletionChange = (completionDate: string) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            completionDate
        }));
    };

    // const handlePropertyTypeChange = (type: string) => {
    //     setFilters((prevFilters: any) => ({
    //         ...prevFilters,
    //         propertyType: type
    //     }));
    // };
    const handlePropertyTypeChange = (selectedTypes: string[]) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            propertyType: selectedTypes
        }));
    };

    const handleSelectionChange = (filterType: string, project: Project) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: project.name
        }));
    };

    const handleInputChange = (filterType: string, value: string) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: value
        }));
    };

    const handleResetFilters = () => {
        setRangePrices([0, 99000000000]);
        setCityInput('');
        setProjectNameInput('');
        setDeveloperInput('');
        setPropertyType([]);
        resetFilters();
    };

    return (
        <>
            <div className='grid grid-cols-4 gap-2 pt-5 pb-2'>
                <div className="col-span-1 border rounded-lg">
                    <Autocomplete
                        suggestions={citySuggestions}
                        placeholder="City..."
                        value={filters.city}
                        onInputChange={(value: any) => handleInputChange('city', value)}
                        onSelectionChange={(project) => handleSelectionChange('city', project)}
                    />
                </div>

                <div className="col-span-2 border rounded-lg">
                    <Autocomplete
                        suggestions={nameSuggestions}
                        placeholder="Search by name..."
                        value={filters.projectName}
                        onInputChange={(value: any) => handleInputChange('projectName', value)}
                        onSelectionChange={(project) => handleSelectionChange('projectName', project)}
                    />
                </div>

                <div className="col-span-1">
                    {/* <Popover>
                        {({ open }) => (
                            <>
                                <Popover.Button
                                    className={` ${open ? "text-black focus:outline-none w-full flex justify-between" : "flex justify-between w-full"}`}
                                >
                                    <div className="flex relative justify-between w-full">
                                        <button className="inline-block align-middle h-full">
                                            {propertyType ? propertyType : "Project Type"}
                                        </button>
                                        <ChevronDownIcon
                                            className="h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute bg-white dark:bg-slate-800 overflow-hidden p-4 z-50 mt-5 lg:w-96 md:w-96 w-84 right-0 shadow-xl border rounded-md">
                                        <ResidentialCommercialFilter handleProjectType={handlePropertyTypeChange} />
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover> */}
                    <MultiSelectPropertyTypes
                        options={propertyTypeOptions}
                        selectedOptions={propertyType}
                        setSelectedOptions={handlePropertyTypeChange}
                    />
                </div>
            </div>
            <div className='grid grid-cols-4 gap-2 pb-5 '>
                <div className="col-span-1 border rounded-lg relative">
                    <NewPriceRangeInput
                        rangePrices={rangePrices}
                        setRangePrices={handlePriceChange}
                        filter={{ priceRange: rangePrices }}
                    />
                </div>

                <div className="col-span-1 relative rounded-lg bg-white dark:bg-slate-800 !h-12 border py-1">
                    <ProjectCompletionDate onCompletionChange={handleCompletionChange} />
                </div>

                <div className="col-span-1 border rounded-lg">
                    <Autocomplete
                        suggestions={developerSuggestions}
                        placeholder="Developer Name..."
                        value={filters.developer}
                        onInputChange={(value: any) => handleInputChange('developer', value)}
                        onSelectionChange={(project) => handleSelectionChange('developer', project)}
                    />
                </div>

                <div className="col-span-1 border-0 rounded-lg">
                    {/* <input
                        onClick={handleResetFilters}
                        type="submit"
                        id="search-sell"
                        name="search"
                        className="btn bg-[#345886] hover:bg-[#223A64] cursor-pointer text-white w-full !h-12 rounded-md"
                        value="Reset Filters"
                    /> */}
                    <button
                        onClick={handleResetFilters}
                        className="btn bg-[#345886] hover:bg-[#223A64] cursor-pointer text-white w-full !h-12 rounded-md"
                    >
                        Reset Filters
                    </button>
                </div>
            </div>
        </>
    );
};

export default ProjectFiltersPage;