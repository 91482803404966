import { Fragment, useState, useContext, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Check } from "@mui/icons-material";
import { ProjectContext } from "context/project/ProjectContext";
interface Props {
  selected?: string;
  setSelected?: (value: string) => void;
}
const SelectCityOptionInput: React.FC<Props> = ({ selected, setSelected }) => {
  // const [selected, setSelected] = useState<string>("");
  const [cities, setCities] = useState<string[]>([]);

  const { getAllProjects, projects } = useContext(ProjectContext);

  // useEffect(() => {
  // })
  const projectData = projects?.project
  useEffect(() => {
    getAllProjects();
    if (projectData && projectData) {
      const cityNames: string[] = projectData?.reduce((acc: string[], row: any) => {
        const city = row?.city;
        if (typeof city === 'string' && city) {
          acc.push(city);
        }
        return acc;
      }, []);

      const uniqueCityNames: string[] = Array.from(new Set(cityNames));
      setCities(uniqueCityNames);
    }
  }, []);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-pointer rounded-md border-gray-300 py-3 pl-3 pr-10 text-left focus:border-gray-300 focus:outline-none focus:ring-none sm:text-sm">
              <span className="flex items-center">
                <span className="block text-base truncate">
                  {selected || "Select City"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronDownIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </span>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {cities.map((city, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-3 pr-9 ${active ? "bg-[#223A64] text-white" : "text-gray-900"
                      }`
                    }
                    value={city}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`${selected ? "font-semibold" : "font-normal"} block truncate`}>
                          {city}
                        </span>
                        {selected && (
                          <span className={`absolute inset-y-0 right-0 flex items-center pr-3 ${active ? "text-white" : "text-[#223A64]"
                            }`}>
                            <Check className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default SelectCityOptionInput;


// import { Fragment, useState, useContext, useEffect } from "react";
// import { Listbox, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/solid";
// import { Check } from "@mui/icons-material";
// import { ProjectContext } from "context/project/ProjectContext";

// function classNames(...classes: string[]) {
//   return classes.filter(Boolean).join(" ");
// }
// export default function SelectCityOptionInput({
//   selected,
//   setSelected,
// }: any) {
//   const { cities, getAllCityOfPakistan }: any = useContext(ProjectContext);
//   useEffect(() => {
//     getAllCityOfPakistan();
//   }, []);

//   return (
//     <Listbox value={selected} onChange={setSelected}>
//       {({ open }) => (
//         <>
//           <div className="relative">
//             <Listbox.Button className="relative w-full cursor-pointer rounded-md border-gray-300 py-3 pl-3 pr-10 text-left focus:border-gray-300 focus:outline-none focus:ring-none sm:text-sm">
//               <span className="flex items-center">
//                 <span className="block text-base truncate">
//                   {selected?.cityLocation ? selected?.cityLocation : "Select City"}
//                 </span>
//               </span>
//               <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
//                 <ChevronDownIcon
//                   className="h-6 w-6 text-gray-400"
//                   aria-hidden="true"
//                 />
//               </span>
//             </Listbox.Button>

//             <Transition
//               show={open}
//               as={Fragment}
//               leave="transition ease-in duration-100"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <Listbox.Options className="absolute z-10 cursor-pointer max-h-56 w-full mt-4 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
//                 {cities?.map((person: any) => (
//                   <Listbox.Option
//                     key={person._id}
//                     className={({ active }) =>
//                       classNames(
//                         active
//                           ? "text-white bg-[#223A64] text-lg"
//                           : "text-gray-900",
//                         "relative cursor-pointer select-none py-1 text-lg border-b pl-3 pr-9"
//                       )
//                     }
//                     value={person}
//                   >
//                     {({ selected, active }) => (
//                       <>
//                         <div className="flex items-center" key={person._id}>
//                           <span
//                             className={classNames(
//                               selected ? "font-semibold" : "font-normal",
//                               "ml-3 text-base block truncate py-1 cursor-pointer"
//                             )}
//                           >
//                             {person.cityLocation}
//                           </span>
//                         </div>

//                         {selected ? (
//                           <span
//                             className={classNames(
//                               active ? "text-white" : "text-[#223A64]",
//                               "absolute inset-y-0 cursor-pointer right-0 flex items-center pr-4"
//                             )}
//                           >
//                             <Check
//                               className="h-5 w-5 text-[#345886]"
//                               aria-hidden="true"
//                             />
//                           </span>
//                         ) : null}
//                       </>
//                     )}
//                   </Listbox.Option>
//                 ))}
//               </Listbox.Options>
//             </Transition>
//           </div>
//         </>
//       )}
//     </Listbox>
//   );
// }
