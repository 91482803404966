import React from "react";
import { FC } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
  index: string;
  nextHref: string;
  backtHref: string;
  nextBtnText?: string;
  children: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "01",
  children,
  nextHref,
  nextBtnText,
  backtHref,
}) => {
  return (
    <div
      className={`nc-PageAddAgency1 px-4 max-w-3xl mx-auto pb-24 pt-10 sm:py-10 lg:pb-32`}
      data-nc-id="PageAddAgency1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 3
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          <Link
                to={backtHref}
                type="button"
              >
                <input type="submit" id="search-sell" name="search" className="btn px-5 bg-[#c1c1c1] text-white submit-btn w-full !h-12 rounded" value="Go back" />
              </Link>
          <Link
                to={nextHref}
                type="button"
              >
                <input type="submit" id="search-sell" name="search" className="btn px-5 bg-[#006169] text-white submit-btn w-full !h-12 rounded" value={nextBtnText || "Continue"} />
              </Link>
            
        </div>
      </div>
     </div>
  );
};

export default CommonLayout;
