import React, { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Check } from "@mui/icons-material";

interface YearOption {
    label: string;
    value: string;
}

// Helper function to generate year options
const generateArrayOfYears = (): YearOption[] => {
    const max = 2024;
    // const max = new Date().getFullYear();
    const min = max + 7;
    const years: YearOption[] = [];

    for (var i = max; i <= min; i++) {
        years.push({
            label: i === min ? `By ${i} or later` : `By ${i}`,
            value: i.toString(),
        });
    }
    years.unshift({ label: "Completed", value: "Completed" });
    return years;
};

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}
const years = generateArrayOfYears();

interface ProjectCompletionDateProps {
    onCompletionChange: (value: string) => void;
}

const ProjectCompletionDate: React.FC<ProjectCompletionDateProps> = ({ onCompletionChange }) => {
    const [selected, setSelected] = useState<string>(years[0].value); // Default to the first option

    const handleChange = (value: string) => {
        setSelected(value);
        onCompletionChange(value);
    };

    return (
        <Listbox value={selected} onChange={handleChange}>
            {({ open }) => (
                <>
                    <div className="relative px-4">
                        <Listbox.Button className="relative w-full cursor-pointer py-2 text-left sm:text-sm">
                            <span className="flex items-center">
                                <span className="block text-base truncate dark:text-white">
                                    {selected}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center">
                                    <ChevronDownIcon
                                        className='h-6 w-6 text-gray-400'
                                        aria-hidden='true'
                                    />
                                </span>
                            </span>
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 cursor-pointer max-h-56 w-full mt-2 -ml-4 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {years.map((year) => (
                                    <Listbox.Option
                                        key={year.label}
                                        className={({ active }) =>
                                            active ? "text-white bg-[#223A64] py-2 pl-3" : "text-gray-900"
                                                + " relative cursor-pointer select-none py-2 text-lg border-b pl-3 pr-9"
                                        }
                                        value={year.value}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <span
                                                        className={classNames(
                                                            selected ? "font-semibold" : "font-normal",
                                                            "text-base block truncate px-3 py-1 cursor-pointer"
                                                        )}
                                                    >
                                                        {year.label}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? "text-white" : "text-[#223A64]",
                                                            "absolute inset-y-0 cursor-pointer right-0 flex items-center"
                                                        )}
                                                    >
                                                        <Check
                                                            className="h-5 w-5 text-[#345886]"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox >
    );
};

export default ProjectCompletionDate;
