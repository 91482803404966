import React from "react";
import Agency from "../../images/bg/agency.svg";
import Gallary from "./Gallary";

const Events = () => {
  const stats = [
    { id: 1, name: "GUESTS", value: "3000" },
    { id: 2, name: "SPEAKERS", value: "5" },
    { id: 3, name: " PANELLISTS", value: "2" },
    { id: 4, name: " Prizes", value: "5" },
    { id: 5, name: " Awards", value: "12" },
  ];
  return (
    <>
      <div className="relative sm:flex-row flex-col overflow-hidden lg:h-[60vh]">
        <div className="sm:mx-7 mx-0 md:mx-0">
          <div className="relative z-10 bg-red-50 dark:bg-red-50 lg:h-[80vh] lg:rounded-l-xl md:rounded-l-xl lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-red-50 lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <div className="relative px-4 pt-6 sm:px-6 lg:px-8"></div>
            <main className="mx-auto max-w-7xl px-4 lg:px-8 sm:py-10 py-5">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl font-bold tracking-tight text-slate-900 text-center">
                  <span className="block xl:inline">Imlaak Event 2022</span>{" "}
                </h1>
                <p className="mt-3 sm:pl-5 pl-0 mx-auto sm:mt-5 sm:max-w-xl md:mt-5">
                  <span className="block mt-2 text-neutral-600 dark:text-neutral-400 text-base text-justify">
                    Nevertheless, Imlaak.pk is an all-way round in terms of real
                    estate concerns and needs, however, we do not merely
                    virtually exist rather our professional team of property
                    experts, real estate investment consultants, and experienced
                    builders are also there to get you going ahead with the real
                    estate projects and find a trusted real estate agent in the
                    single visual meeting.
                  </span>
                </p>
                <div className="mt-5 flex justify-center">
                  <div className="rounded-md shadow">
                    <button className="flex w-full py-2.5 items-center justify-center rounded-md border border-transparent bg-red-300 px-8 text-base font-medium text-white hover:bg-red-400 md:px-10">
                      More Detail
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 rounded-xl lg:right-0 mx-8 lg:block md:hidden hidden">
          <img
            className="h-56 w-64 object-cover rounded-xl sm:h-72 md:h-84 lg:h-full lg:w-full"
            src={Agency}
            alt=""
          />
        </div>
      </div>

      <div className="bg-red-700 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl  px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-y-10  gap-x-8 text-center lg:grid-cols-5">
            {stats.map((stat) => (
              <div className="border-b-2  lg:border-b-0   ">
                <div
                  key={stat.id}
                  className="mx-auto flex max-w-xs flex-col gap-y-4"
                >
                  <dt className="text-base font-semibold sm:text-4xl leading-7 text-gray-100">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-xl font-semibold tracking-tight text-gray-100 sm:text-xl">
                    {stat.value}
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="relative container  bg-red-200 pt-[120px] pb-[110px] lg:pt-[150px]">
        <div className="">
          <div className=" flex flex-wrap">
            <div className="w-full px-4 lg:w-6/12">
              <div className="hero-content">
                <h1 className="text-dark mb-3 text-4xl font-bold leading-snug sm:text-[42px] lg:text-[40px] xl:text-[35px]">
                  Your dream our commitment
                </h1>
                <p className="text-base text-justify mb-8 max-w-[480px] mt-3  sm:mt-5 sm:max-w-xl md:mt-5">
                  Imlaak.pk is an online property portal and a real estate
                  project marketing company. We are home to the information,
                  data, and connections for property buyers, sellers and
                  investors. Here you can find details of approved and verified
                  projects of reputed real estate developers, according to your
                  budget, choice and requirement. Whether you are looking for
                  residential plot, commercial plot, apartment, home or an
                  office, we have all the range, that is being offered on easy
                  instalment payment plan.
                </p>
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-3/12">
              <div className="lg:ml-auto lg:text-right">
                <div className="relative z-10 inline-block pt-11 lg:pt-0">
                  <img
                    src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg"
                    alt="hero"
                    className="max-w-full lg:ml-auto"
                  />
                  <span className="absolute -left-8 -bottom-8 z-[-1]">
                    <svg
                      width="93"
                      height="93"
                      viewBox="0 0 93 93"
                      fill="#FF0000	"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="24.5" cy="46.5" r="2.5" fill="#FF0000" />
                      <circle cx="24.5" cy="68.5" r="2.5" fill="#FF0000" />
                      <circle cx="24.5" cy="90.5" r="2.5" fill="#FF0000" />
                      <circle cx="46.5" cy="2.5" r="2.5" fill="#FF0000" />
                      <circle cx="46.5" cy="24.5" r="2.5" fill="#FF0000" />
                      <circle cx="46.5" cy="46.5" r="2.5" fill="#FF0000" />
                      <circle cx="46.5" cy="68.5" r="2.5" fill="#FF0000" />
                      <circle cx="46.5" cy="90.5" r="2.5" fill="#FF0000" />
                      <circle cx="68.5" cy="2.5" r="2.5" fill="#FF0000" />
                      <circle cx="68.5" cy="24.5" r="2.5" fill="#FF0000" />
                      <circle cx="68.5" cy="46.5" r="2.5" fill="#FF0000" />
                      <circle cx="68.5" cy="68.5" r="2.5" fill="#FF0000" />
                      <circle cx="68.5" cy="90.5" r="2.5" fill="#FF0000" />
                      <circle cx="90.5" cy="2.5" r="2.5" fill="#FF0000" />
                      <circle cx="90.5" cy="24.5" r="2.5" fill="#FF0000" />
                      <circle cx="90.5" cy="46.5" r="2.5" fill="#FF0000" />
                      <circle cx="90.5" cy="68.5" r="2.5" fill="#FF0000" />
                      <circle cx="90.5" cy="90.5" r="2.5" fill="#FF0000" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-red-50 py-5">
        <h1 className="text-center text-4xl font-bold tracking-tight  text-slate-900 py-5 ">
          MEET OUR PRESTIGIOUS SPEAKERS
        </h1>
        <div className="grid mb-8 container rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-2">
          <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Very easy this was to integrate
              </h3>
              <p className="my-4 font-light">
                An internationally recognised serial entrepreneur and author,
                Spencer Lodge has almost three decades of experience building
                startups across the world into 9-figure organisations.
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center space-x-3">
              <img
                className="rounded-full w-12 h-12"
                src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg"
                alt="profile picture"
              />
              <div className="space-y-0.5 font-medium dark:text-white text-left">
                <div>CEO</div>
                <div className="text-sm font-light text-gray-500 dark:text-gray-400"></div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-tr-lg dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Serial Entrepreneur, Founder, Business Expert and Author
              </h3>
              <p className="my-4 font-light">
                An internationally recognised serial entrepreneur and author,
                Spencer Lodge has almost three decades of experience building
                startups across the world into 9-figure organisations.
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center space-x-3">
              <img
                className="rounded-full w-12 h-12"
                src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg"
                alt="profile picture"
              />
              <div className="space-y-0.5 font-medium dark:text-white text-left">
                <div>Abdullah Jalal</div>
                <div className="text-sm font-light text-gray-500 dark:text-gray-400"></div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-b border-gray-200 rounded-bl-lg md:border-b-0 md:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Software Engineer at EmporionSoft
              </h3>
              <p className="my-4 font-light">
                An internationally recognised serial entrepreneur and author,
                Spencer Lodge has almost three decades of experience building
                startups across the world into 9-figure organisations.
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center space-x-3">
              <img
                className="rounded-full w-12 h-12"
                src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg"
                alt="profile picture"
              />
              <div className="space-y-0.5 font-medium dark:text-white text-left">
                <div> Ihsan Khan</div>
                <div className="text-sm font-light text-gray-500 dark:text-gray-400"></div>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col items-center justify-center p-8 text-center bg-white border-gray-200 rounded-b-lg md:rounded-br-lg dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Software Engineer at EmporionSoft
              </h3>
              <p className="my-4 font-light">
                An internationally recognised serial entrepreneur and author,
                Spencer Lodge has almost three decades of experience building
                startups across the world into 9-figure organisations.
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center space-x-3">
              <img
                className="rounded-full w-12 h-12"
                src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-male-avatar-simple-cartoon-design-png-image_1934458.jpg"
                alt="profile picture"
              />
              <div className="space-y-0.5 font-medium dark:text-white text-left">
                <div>Rana Kaleem</div>
                <div className="text-sm font-light text-gray-500 dark:text-gray-400"></div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>

      <div className="">
        <Gallary />
      </div>
    </>
  );
};

export default Events;
