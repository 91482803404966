import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  showLabel?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-md",
      children,
      type = "text",
      placeholder,
      showLabel = true,
      ...args
    },
    ref
  ) => {
    return (
      <div style={{ position: "relative" }}>
        {showLabel ? (
          <label
            style={{
              fontSize: "12px",
              position: "absolute",
              backgroundColor: "inherit",
              top: -12,
              left: 10,
            }}
          >
            {/* {placeholder} */}
          </label>
        ) : null}
        <input
          placeholder={placeholder}
          ref={ref}
          type={type}
          className={`block w-full border-neutral-200 focus:border-gray-500 focus:ring-none focus:ring-none focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-none dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
          {...args}
        />
      </div>
    );
  }
);

export default Input;
